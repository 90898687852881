import React, { Component, Fragment } from "react"
import {
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  Tooltip,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined"
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/AmpTaskFormData"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import moment from "moment"
import MomentUtils from "@date-io/moment"
import { regexConstants } from "../../../../constants/regEx"
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import {
  addTaskFormApi,
  fillEditFromApi,
  uploadFileApi,
  getFillEditFromApi,
  getAMPComplianceApi,
  deleteAttachment,
} from "../apiServices"
import { globalPutService } from "../../../../utils/globalApiServices"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined"
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined"
import AmpConfirmDeleteAttachment from "./AmpConfirmDeleteAttachment"
import { Label } from "@material-ui/icons"
import { permissionCheckFnforCamo } from "../../../../utils"

class AmpTaskForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: {},
      data: formValues,
      regex: "^[0-9]+$",
      numberError: false,
      selectLastDDate: null,
      selectNextDueDate: null,
      attachments: [],
      btnStatus: "",
      newUploadedAttachments: [],
      open: false,
      deleteAttachmentId: 0,
      showSuccessUpload: false,
      globalValidationInputs: {},
      validatedFields: [],
    }

    this.addTaskFormApi = addTaskFormApi.bind(this)
    // this.uploadFileApi = uploadFileApi.bind(this)
    // this.fillEditFromApi = fillEditFromApi.bind(this)
    // this.getAMPComplianceApi = getAMPComplianceApi.bind(this)
    // this.deleteAttachment = deleteAttachment.bind(this)
  }

  componentDidMount = () => {
    if (this.props.editFormId) {
      this.fillEditData()
    }
    // console.log(this.props);
  }

  onFieldChange = (keyParam, value) => {
    const { data } = this.state

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [keyParam]: value,
      },
    }))

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        nextDue_FH: data.nextDue_FH,
      },
    }))
  }

  onRestErrorKey = (keyParam) => {
    this.setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: "",
      },
    }))
  }
  handleSubmit = (e) => {
    e.preventDefault()

    let validationInputs = {}
    const { data } = this.state
    this.setState({ file: "" })

    validationInputs = {
      MPD_item_no:
        taskErrorCode["MPD_item_no"][
          fieldValidation({
            ...taskErrorCode["MPD_item_no_obj"],
            fieldval: data.MPD_item_no,
          })
        ],
      task_card_no:
        taskErrorCode["task_card_no"][
          fieldValidation({
            ...taskErrorCode["task_card_no_obj"],
            fieldval: data.task_card_no,
          })
        ],
      Task_Description:
        taskErrorCode["Task_Description"][
          fieldValidation({
            ...taskErrorCode["Task_Description_obj"],
            fieldval: data.Task_Description,
          })
        ],
    }

    data.threshold_D =
      data.threshold_D == "" ? (data.threshold_D = null) : data.threshold_D
    data.threshold_FH =
      data.threshold_FH == "" ? (data.threshold_FH = null) : data.threshold_FH
    data.threshold_FC =
      data.threshold_FC == "" ? (data.threshold_FC = null) : data.threshold_FC
    if (
      data.threshold_D == null &&
      data.threshold_FH == null &&
      data.threshold_FC == null
    ) {
      validationInputs = {
        ...validationInputs,
        threshold_D:
          taskErrorCode["threshold_D"][
            fieldValidation({
              ...taskErrorCode["threshold_D_obj"],
              fieldval:
                data.threshold_D !== null ? String(data.threshold_D) : "",
            })
          ],
        threshold_FH:
          taskErrorCode["threshold_FH"][
            fieldValidation({
              ...taskErrorCode["threshold_FH_obj"],
              fieldval:
                data.threshold_FH !== null ? String(data.threshold_FH) : "",
            })
          ],
        threshold_FC:
          taskErrorCode["threshold_FC"][
            fieldValidation({
              ...taskErrorCode["threshold_FC_obj"],
              fieldval:
                data.threshold_FC !== null ? String(data.threshold_FC) : "",
            })
          ],
      }
    } else if (data.threshold_D !== null) {
      validationInputs = {
        ...validationInputs,
        interval_D:
          taskErrorCode["interval_D"][
            fieldValidation({
              ...taskErrorCode["interval_D_obj"],
              fieldval: data.interval_D,
            })
          ],
        lastDone_D:
          taskErrorCode["lastDone_D"][
            fieldValidation({
              ...taskErrorCode["lastDone_D_obj"],
              fieldval: data.lastDone_D,
            })
          ],
      }
    } else if (data.threshold_FH !== null) {
      validationInputs = {
        ...validationInputs,
        interval_FH:
          taskErrorCode["interval_FH"][
            fieldValidation({
              ...taskErrorCode["interval_FH_obj"],
              fieldval: data.interval_FH,
            })
          ],
        lastDone_FH:
          taskErrorCode["lastDone_FH"][
            fieldValidation({
              ...taskErrorCode["lastDone_FH_obj"],
              fieldval: data.lastDone_FH,
            })
          ],
      }
    } else if (data.threshold_FC !== null) {
      validationInputs = {
        ...validationInputs,
        interval_FC:
          taskErrorCode["interval_FC"][
            fieldValidation({
              ...taskErrorCode["interval_FC_obj"],
              fieldval: data.interval_FC,
            })
          ],

        lastDone_FC:
          taskErrorCode["lastDone_FC"][
            fieldValidation({
              ...taskErrorCode["lastDone_FC_obj"],
              fieldval: data.lastDone_FC,
            })
          ],
      }
    }

    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))

      // add task api
      const props = this.props.props
      console.log(props)
      const ampFormData = data
      ampFormData.asset_id = props.match.params.asset
      ampFormData.asset_type_id = props.match.params.assetType
      ampFormData.nextDue_FH = null
      ampFormData.nextDue_FC = null
      ampFormData.nextDue_D = null
      ampFormData.remainingValues_D = null
      ampFormData.remainingValues_FH = null
      ampFormData.remainingValues_FC = null
      addTaskFormApi(ampFormData, props).then(async (response) => {
        const uploadId = response.data.data.id
        if (uploadId) {
          await this.uploadFileTest(uploadId, props, false)
        }
      })
      if (this.state.btnStatus !== "addAnother") {
        this.props.closeAddForm()
      } else {
        return false
      }
    } else {
      this.setState({ error: validationInputs })
    }
  }

  // edit task form
  fillEditData = () => {
    const updateId = this.props.editFormId
    this.setState({ attachments: this.props.attachments })
    if (updateId === undefined || updateId === null) {
      this.setState({
        data: {},
      })
    } else {
      this.setState({
        data: this.props.editFormData,
      })
    }
  }

  onFocusValidation = (keyParam) => {
    let validationInputs = {}
    const { data } = this.state
    validationInputs = {
      MPD_item_no:
        taskErrorCode["MPD_item_no"][
          fieldValidation({
            ...taskErrorCode["MPD_item_no_obj"],
            fieldval: data.MPD_item_no,
          })
        ],
      task_card_no:
        taskErrorCode["task_card_no"][
          fieldValidation({
            ...taskErrorCode["task_card_no_obj"],
            fieldval: data.task_card_no,
          })
        ],
      Task_Description:
        taskErrorCode["Task_Description"][
          fieldValidation({
            ...taskErrorCode["Task_Description_obj"],
            fieldval: data.Task_Description,
          })
        ],
    }

    data.threshold_D =
      data.threshold_D == "" ? (data.threshold_D = null) : data.threshold_D
    data.threshold_FH =
      data.threshold_FH == "" ? (data.threshold_FH = null) : data.threshold_FH
    data.threshold_FC =
      data.threshold_FC == "" ? (data.threshold_FC = null) : data.threshold_FC
    if (
      data.threshold_D == null &&
      data.threshold_FH == null &&
      data.threshold_FC == null
    ) {
      validationInputs = {
        ...validationInputs,
        threshold_D:
          taskErrorCode["threshold_D"][
            fieldValidation({
              ...taskErrorCode["threshold_D_obj"],
              fieldval:
                data.threshold_D !== null ? String(data.threshold_D) : "",
            })
          ],
        threshold_FH:
          taskErrorCode["threshold_FH"][
            fieldValidation({
              ...taskErrorCode["threshold_FH_obj"],
              fieldval:
                data.threshold_FH !== null ? String(data.threshold_FH) : "",
            })
          ],
        threshold_FC:
          taskErrorCode["threshold_FC"][
            fieldValidation({
              ...taskErrorCode["threshold_FC_obj"],
              fieldval:
                data.threshold_FC !== null ? String(data.threshold_FC) : "",
            })
          ],
      }
    } else if (data.threshold_D !== null) {
      validationInputs = {
        ...validationInputs,
        interval_D:
          taskErrorCode["interval_D"][
            fieldValidation({
              ...taskErrorCode["interval_D_obj"],
              fieldval: data.interval_D,
            })
          ],
        lastDone_D:
          taskErrorCode["lastDone_D"][
            fieldValidation({
              ...taskErrorCode["lastDone_D_obj"],
              fieldval: data.lastDone_D,
            })
          ],
      }
    } else if (data.threshold_FH !== null) {
      validationInputs = {
        ...validationInputs,
        interval_FH:
          taskErrorCode["interval_FH"][
            fieldValidation({
              ...taskErrorCode["interval_FH_obj"],
              fieldval: data.interval_FH,
            })
          ],
        lastDone_FH:
          taskErrorCode["lastDone_FH"][
            fieldValidation({
              ...taskErrorCode["lastDone_FH_obj"],
              fieldval: data.lastDone_FH,
            })
          ],
      }
    } else if (data.threshold_FC !== null) {
      validationInputs = {
        ...validationInputs,
        interval_FC:
          taskErrorCode["interval_FC"][
            fieldValidation({
              ...taskErrorCode["interval_FC_obj"],
              fieldval: data.interval_FC,
            })
          ],

        lastDone_FC:
          taskErrorCode["lastDone_FC"][
            fieldValidation({
              ...taskErrorCode["lastDone_FC_obj"],
              fieldval: data.lastDone_FC,
            })
          ],
      }
    }

    // for (let key in validationInputs) {
    //   if (key === keyParam) {
    //     this.state.globalValidationInputs[key] = validationInputs[key]
    //   }
    // }
    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))
      this.setState({ validationInputs: {} })
    } else {
      this.setState({ error: validationInputs })
    }

    // Object.keys(this.state.globalValidationInputs).every((k) => {
    //   if (this.state.globalValidationInputs[k] === "") {
    //     this.setState((prevState) => ({
    //       ...prevState,
    //       data: {
    //         ...prevState.data,
    //       },
    //     }));
    //   } else {
    //     this.setState({ error: this.state.globalValidationInputs });
    //   }
    // });
  }

  updateData = async (e) => {
    e.preventDefault()

    let validationInputs = {}
    const { data } = this.state
    this.setState({ file: "" })

    validationInputs = {
      MPD_item_no:
        taskErrorCode["MPD_item_no"][
          fieldValidation({
            ...taskErrorCode["MPD_item_no_obj"],
            fieldval: data.MPD_item_no,
          })
        ],
      task_card_no:
        taskErrorCode["task_card_no"][
          fieldValidation({
            ...taskErrorCode["task_card_no_obj"],
            fieldval: data.task_card_no,
          })
        ],
      Task_Description:
        taskErrorCode["Task_Description"][
          fieldValidation({
            ...taskErrorCode["Task_Description_obj"],
            fieldval: data.Task_Description,
          })
        ],
    }

    data.threshold_D =
      data.threshold_D == "" ? (data.threshold_D = null) : data.threshold_D
    data.threshold_FH =
      data.threshold_FH == "" ? (data.threshold_FH = null) : data.threshold_FH
    data.threshold_FC =
      data.threshold_FC == "" ? (data.threshold_FC = null) : data.threshold_FC
    if (
      data.threshold_D == null &&
      data.threshold_FH == null &&
      data.threshold_FC == null
    ) {
      validationInputs = {
        ...validationInputs,
        threshold_D:
          taskErrorCode["threshold_D"][
            fieldValidation({
              ...taskErrorCode["threshold_D_obj"],
              fieldval:
                data.threshold_D !== null ? String(data.threshold_D) : "",
            })
          ],
        threshold_FH:
          taskErrorCode["threshold_FH"][
            fieldValidation({
              ...taskErrorCode["threshold_FH_obj"],
              fieldval:
                data.threshold_FH !== null ? String(data.threshold_FH) : "",
            })
          ],
        threshold_FC:
          taskErrorCode["threshold_FC"][
            fieldValidation({
              ...taskErrorCode["threshold_FC_obj"],
              fieldval:
                data.threshold_FC !== null ? String(data.threshold_FC) : "",
            })
          ],
      }
    } else if (data.threshold_D !== null) {
      validationInputs = {
        ...validationInputs,
        interval_D:
          taskErrorCode["interval_D"][
            fieldValidation({
              ...taskErrorCode["interval_D_obj"],
              fieldval: data.interval_D,
            })
          ],
        lastDone_D:
          taskErrorCode["lastDone_D"][
            fieldValidation({
              ...taskErrorCode["lastDone_D_obj"],
              fieldval: data.lastDone_D,
            })
          ],
      }
    } else if (data.threshold_FH !== null) {
      validationInputs = {
        ...validationInputs,
        interval_FH:
          taskErrorCode["interval_FH"][
            fieldValidation({
              ...taskErrorCode["interval_FH_obj"],
              fieldval: data.interval_FH,
            })
          ],
        lastDone_FH:
          taskErrorCode["lastDone_FH"][
            fieldValidation({
              ...taskErrorCode["lastDone_FH_obj"],
              fieldval: data.lastDone_FH,
            })
          ],
      }
    } else if (data.threshold_FC !== null) {
      validationInputs = {
        ...validationInputs,
        interval_FC:
          taskErrorCode["interval_FC"][
            fieldValidation({
              ...taskErrorCode["interval_FC_obj"],
              fieldval: data.interval_FC,
            })
          ],

        lastDone_FC:
          taskErrorCode["lastDone_FC"][
            fieldValidation({
              ...taskErrorCode["lastDone_FC_obj"],
              fieldval: data.lastDone_FC,
            })
          ],
      }
    }

    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))
      const updateId = this.props.editFormId
      const updatedData = this.state.data
      const props = this.props.props
      // updatedData.remainingValues_FC =
      //   updatedData &&
      //   updatedData.remainingValues_FC === updatedData.remainingValues_FC
      //     ? null
      //     : null;
      // updatedData.remainingValues_FH =
      //   updatedData &&
      //   updatedData.remainingValues_FH === updatedData.remainingValues_FH
      //     ? null
      //     : null;
      updatedData.nextDue_FH = null
      updatedData.nextDue_FC = null
      updatedData.nextDue_D = null
      updatedData.remainingValues_D = null
      updatedData.remainingValues_FH = null
      updatedData.remainingValues_FC = null

      this.setState({
        data: this.props.editFormData,
      })
      if (updateId) {
        await this.uploadFileTest(updateId, props, true)
      }
      await this.props.updateFormData(updateId, updatedData, props)
      this.props.closeAddForm()
    } else {
      this.setState({ error: validationInputs })
    }
  }

  uploadFileTest = async (uploadId, props, isUpdate) => {
    if (this.state.newUploadedAttachments.length > 0) {
      let newAttachmentObj = []
      let formdata = new FormData()
      const module_type_id = 1
      Object.keys(this.state.newUploadedAttachments).map((key, index) => {
        newAttachmentObj.push({
          file: this.state.newUploadedAttachments[key],
          file_name: this.state.newUploadedAttachments[key].name,
          file_type: this.state.newUploadedAttachments[key].type,
          module_type_id,
          module_id: uploadId,
        })
        formdata.append(
          "[" + key + "]file",
          this.state.newUploadedAttachments[key]
        )
        formdata.append(
          "[" + key + "]file_type",
          this.state.newUploadedAttachments[key].type
        )
        formdata.append(
          "[" + key + "]file_name",
          this.state.newUploadedAttachments[key].name
        )
        formdata.append("[" + key + "]module_id", uploadId)
        formdata.append("[" + key + "]module_type_id", module_type_id)
      })
      this.props.fileUploadData(uploadId, formdata, props, true)
      if (this.state.btnStatus === "addAnother") {
        this.resetBothAttachmentArray()
      }
    } else {
      if (this.state.btnStatus === "addAnother") {
        this.setState((prevState) => ({
          ...prevState,
          data: formValues,
          attachments: [],
        }))
        document.getElementById("asset-form").reset()
        this.props.fileUploadData(uploadId, null, props, false)
      } else {
        if (!isUpdate) {
          this.props.fileUploadData(uploadId, null, props, false)
        }
      }
    }
  }

  resetBothAttachmentArray = () => {
    this.setState((prevState) => ({
      ...prevState,
      data: formValues,
      attachments: [],
      newUploadedAttachments: [],
      open: false,
      deleteAttachmentId: 0,
    }))
    document.getElementById("asset-form").reset()
  }

  onUpload = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    for (let i = 0; i < chosenFiles.length; i++) {
      if (chosenFiles[i].size > 52428800) {
        this.props.props.enqueueSnackbar(
          `${chosenFiles[i].name} Maximum file size should be 50MB.`,
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        )
        delete chosenFiles[i]
        continue
      } else {
        this.handleUploadFiles(chosenFiles)
        console.log(window)
        // document.querySelector(".MuiDialog-paper").scrollTop = 800;
        this.setState({
          showSuccessUpload: true,
        })
        setTimeout(() => {
          if (this.state.attachments) {
            this.setState({
              showSuccessUpload: false,
            })
          }
        }, 2500)
      }
    }
  }

  handleUploadFiles = (files) => {
    const uploaded = [...this.state.newUploadedAttachments]
    const existingAttachments = [...this.state.attachments]
    files.some((file) => {
      uploaded.push(file)
    })
    files.map((file) => {
      var objc = {
        id: 0,
        module_id: 0,
        module_type_id: 2,
        file_name: file.name,
        file_type: file.type,
        file: "",
        name: file.name,
      }
      existingAttachments.push(objc)
    })
    this.setState({
      newUploadedAttachments: uploaded,
      attachments: existingAttachments,
    })
    if (this.state.attachments) {
      this.scrollToBottom()
    }
    // this.props.props.enqueueSnackbar("Uploaded Successfully", {
    //   variant: "success",
    //   anchorOrigin: { vertical: "bottom", horizontal: "right" },
    //   autoHideDuration: 1000,
    // });
  }

  handleClickAttachment = (e, id) => {
    this.setState({ open: true })
    this.setState({ deleteAttachmentId: id })
  }

  handleCloseAttachment = (e) => {
    this.setState({ open: false })
    this.setState({ deleteAttachmentId: 0 })
  }
  deleteAttachmentFile = async (id) => {
    if (id !== undefined && id != 0) {
      const props = this.props.props
      deleteAttachment(id, props)
      var array = [...this.state.attachments]
      array = array.filter((item) => item.id !== id)
      this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
    }
  }
  removeAttachment = (e, obj) => {
    var array = [...this.state.attachments]
    var newuploadarray = [...this.state.newUploadedAttachments]
    var filteredArray = []
    newuploadarray.map((file) => {
      if (file.name == obj.file_name && file.type == obj.file_type) {
      } else {
        filteredArray.push(file)
      }
    })
    array = array.filter((item) => item !== obj)
    this.setState({
      newUploadedAttachments: filteredArray,
      attachments: array,
    })
  }

  saveAndAddAnother = async (e) => {
    await this.setState({ btnStatus: "addAnother" })
    await this.handleSubmit(e)
  }

  addFormData = async (e) => {
    await this.setState({ btnStatus: "add" })
    this.handleSubmit(e)
  }

  scrollToBottom = (event) => {
    document
      .querySelector(".MuiDialog-paper")
      .scrollTo(0, document.documentElement.scrollHeight)
    // document.querySelector(".MuiDialog-paper").scrollTo({
    //   top: document.documentElement.scrollHeight + 100,
    //   behavior: "smooth",
    // });
  }
  nextDueValue=()=>{
    const { data} = this.state
    let todayDate = new Date()

    var date = new Date(data.lastDone_D)
    date.setDate(date.getDate() + parseInt(data.interval_D))
    // data.nextDue_D = moment(date).format(backendDateFormat);
    var calDate = new Date()
    const lDate = moment(data.lastDone_D)
    const todayD = moment(calDate)
    let diffDay = lDate.diff(todayD, "days")
    if(data.interval_D && data.interval_D > 0 && data.lastDone_D && data.interval_D > 0){
      data.nextDue_D =  moment(date).format(backendDateFormat)
      }
      return  data.nextDue_D
    // data.interval_D &&
    // data.interval_D > 0 &&
    // data.lastDone_D &&
    // data.interval_D > 0
    //   ? (data.nextDue_D =
    //       moment(date).format(backendDateFormat))
    //   : null
  }

  render() {
    const fileTypes = [
      ".GIF",
      ".PDF",
      ".DOC",
      ".DOCX",
      ".XLSX",
      ".TXT",
      ".XLS",
      "image/*",
    ]
    const { data, error } = this.state
    let todayDate = new Date()

    var date = new Date(data.lastDone_D)
    date.setDate(date.getDate() + parseInt(data.interval_D))
    // data.nextDue_D = moment(date).format(backendDateFormat);
    var calDate = new Date()
    const lDate = moment(data.lastDone_D)
    const todayD = moment(calDate)
    let diffDay = lDate.diff(todayD, "days")

    return (
      <Fragment>
        <div id="AmpTaskForm">
          <DialogTitle className="just-center">
            {this.props.formTitle}
            <CloseIcon
              onClick={this.props.closeAddForm}
              className="close-icon"
            />
          </DialogTitle>
          <form
            id="asset-form"
            onSubmit={
              this.props.actionType === "add"
                ? (e) => this.handleSubmit(e)
                : (e) => this.updateData(e)
            }
          >
            <DialogContent>
              <Grid spacing={1} container>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    label={
                      <React.Fragment>
                        MPD Item Number <span style={{ color: "red" }}> *</span>
                      </React.Fragment>
                    }
                    name="MPD_item_no"
                    id="MPD_item_no"
                    value={data.MPD_item_no}
                    error={error.MPD_item_no ? true : false}
                    helperText={error.MPD_item_no ? error.MPD_item_no : ""}
                    onChange={(e, value) => {
                      const input = e.target.value
                      if (input.length <= 20) {
                        this.onFieldChange("MPD_item_no", input)
                        this.onRestErrorKey("MPD_item_no")
                      } else {
                        const truncatedInput = input.slice(0, 20)
                        this.onFieldChange("MPD_item_no", truncatedInput)
                        this.onRestErrorKey("MPD_item_no")
                      }
                    }}
                    onFocus={() => this.onRestErrorKey("MPD_item_no")}
                    onBlur={() => this.onFocusValidation("MPD_item_no")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    label={
                      <React.Fragment>
                        Task Card Number<span style={{ color: "red" }}> *</span>
                      </React.Fragment>
                    }
                    name="task_card_no"
                    id="task_card_no"
                    value={data.task_card_no}
                    error={error.task_card_no ? true : false}
                    helperText={error.task_card_no ? error.task_card_no : ""}
                    onChange={(e, value) => {
                      const input = e.target.value
                      if (input.length <= 20) {
                        this.onFieldChange("task_card_no", input)
                        this.onRestErrorKey("task_card_no")
                      } else {
                        const truncatedInput = input.slice(0, 20)
                        this.onFieldChange("task_card_no", truncatedInput)
                        this.onRestErrorKey("task_card_no")
                      }
                    }}
                    onFocus={() => this.onRestErrorKey("task_card_no")}
                    onBlur={() => this.onFocusValidation("task_card_no")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    label="Previous LDND Task Number"
                    name="previous_LDND_Taskcard"
                    id="previous_LDND_Taskcard"
                    value={
                      data.previous_LDND_Taskcard
                        ? data.previous_LDND_Taskcard
                        : ""
                    }
                    onChange={(e, value) => {
                      const input = e.target.value
                      if (input.length <= 20) {
                        this.onFieldChange("previous_LDND_Taskcard", input)
                        this.onRestErrorKey("previous_LDND_Taskcard")
                      } else {
                        const truncatedInput = input.slice(0, 20)
                        this.onFieldChange(
                          "previous_LDND_Taskcard",
                          truncatedInput
                        )
                        this.onRestErrorKey("previous_LDND_Taskcard")
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid spacing={1} container>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label={
                      <React.Fragment>
                        Task Description<span style={{ color: "red" }}> *</span>
                      </React.Fragment>
                    }
                    name="Task_Description"
                    id="Task_Description"
                    multiline
                    minRows={5}
                    error={error.Task_Description ? true : false}
                    helperText={
                      error.Task_Description ? error.Task_Description : ""
                    }
                    value={data.Task_Description ? data.Task_Description : ""}
                    onChange={(e, value) => {
                      const input = e.target.value
                      if (input.length <= 255) {
                        this.onFieldChange("Task_Description", input)
                        this.onRestErrorKey("Task_Description")
                      } else {
                        const truncatedInput = input.slice(0, 255)
                        this.onFieldChange("Task_Description", truncatedInput)
                        this.onRestErrorKey("Task_Description")
                      }
                    }}
                    onFocus={() => this.onRestErrorKey("Task_Description")}
                    onBlur={() => this.onFocusValidation("Task_Description")}
                  />
                </Grid>
              </Grid>
              <div className="border-grey"></div>
              <Grid id="data-container" spacing={1} container>
                <Grid item xs={4}>
                  <label className="ml-10px font-14">THRESHOLD</label>
                  <Grid spacing={1} container>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        label={
                          <React.Fragment>
                            Days
                            {data.threshold_FH == null &&
                            data.threshold_FC == null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : null}
                          </React.Fragment>
                        }
                        name="threshold_D"
                        id="threshold_D"
                        error={error.threshold_D ? true : false}
                        helperText={error.threshold_D ? error.threshold_D : ""}
                        value={
                          data.threshold_D || data.threshold_D === 0
                            ? data.threshold_D
                            : ""
                        }
                        onChange={(e, value) => {
                          const input = e.target.value
                          if (
                            input.length <= 5 &&
                            regexConstants.onlyNumeric.test(e.target.value)
                          ) {
                            this.onFieldChange("threshold_D", input)
                            this.onRestErrorKey("threshold_D")
                          } else if (
                            input.length > 5 &&
                            regexConstants.onlyNumeric.test(input)
                          ) {
                            const truncatedInput = input.slice(0, 5)
                            this.onFieldChange("threshold_D", truncatedInput)
                            this.onRestErrorKey("threshold_D")
                          } else {
                            e.preventDefault()
                          }
                        }}
                        onFocus={() => this.onRestErrorKey("threshold_D")}
                        onBlur={() => this.onFocusValidation("threshold_D")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        label={
                          <React.Fragment>
                            FH
                            {data.threshold_D == null &&
                            data.threshold_FC == null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : null}
                          </React.Fragment>
                        }
                        name="threshold_FH"
                        id="threshold_FH"
                        error={error.threshold_FH ? true : false}
                        helperText={
                          error.threshold_FH ? error.threshold_FH : ""
                        }
                        value={
                          data.threshold_FH || data.threshold_FH === 0
                            ? data.threshold_FH
                            : ""
                        }
                        onFocus={() => this.onRestErrorKey("threshold_FH")}
                        onChange={(e, value) => {
                          const input = e.target.value
                          if (
                            input.length <= 10 &&
                            regexConstants.onlyNumeric.test(e.target.value)
                          ) {
                            this.onFieldChange("threshold_FH", input)
                            this.onRestErrorKey("threshold_FH")
                          } else if (
                            input.length > 10 &&
                            regexConstants.onlyNumeric.test(input)
                          ) {
                            const truncatedInput = input.slice(0, 10)
                            this.onFieldChange("threshold_FH", truncatedInput)
                            this.onRestErrorKey("threshold_FH")
                          } else {
                            e.preventDefault()
                          }
                        }}
                        onBlur={() => this.onFocusValidation("threshold_FH")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        label={
                          <React.Fragment>
                            FC
                            {data.threshold_FH == null &&
                            data.threshold_D == null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : null}
                          </React.Fragment>
                        }
                        name="threshold_FC"
                        id="threshold_FC"
                        error={error.threshold_FC ? true : false}
                        helperText={
                          error.threshold_FC ? error.threshold_FC : ""
                        }
                        value={
                          data.threshold_FC || data.threshold_FC === 0
                            ? data.threshold_FC
                            : ""
                        }
                        onFocus={() => this.onRestErrorKey("threshold_FC")}
                        onChange={(e, value) => {
                          const input = e.target.value
                          if (
                            input.length <= 10 &&
                            regexConstants.onlyNumeric.test(e.target.value)
                          ) {
                            this.onFieldChange("threshold_FC", input)
                            this.onRestErrorKey("threshold_FC")
                          } else if (
                            input.length > 10 &&
                            regexConstants.onlyNumeric.test(input)
                          ) {
                            const truncatedInput = input.slice(0, 10)
                            this.onFieldChange("threshold_FC", truncatedInput)
                            this.onRestErrorKey("threshold_FC")
                          } else {
                            e.preventDefault()
                          }
                        }}
                        onBlur={() => this.onFocusValidation("threshold_FC")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <label className="ml-10px font-14">INTERVAL</label>
                  <Grid spacing={1} container>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        label={
                          <React.Fragment>
                            Days
                            {data.threshold_D !== undefined &&
                            data.threshold_D !== null &&
                            data.threshold_D !== "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : null}
                          </React.Fragment>
                        }
                        name="interval_D"
                        id="interval_D"
                        error={error.interval_D ? true : false}
                        helperText={error.interval_D ? error.interval_D : ""}
                        value={
                          data.interval_D || data.interval_D === 0
                            ? data.interval_D
                            : ""
                        }
                        onChange={(e, value) => {
                          const input = e.target.value
                          if (
                            input.length <= 5 &&
                            regexConstants.onlyNumeric.test(e.target.value)
                          ) {
                            this.onFieldChange("interval_D", input)
                            this.onRestErrorKey("interval_D")
                          } else if (
                            input.length > 5 &&
                            regexConstants.onlyNumeric.test(input)
                          ) {
                            const truncatedInput = input.slice(0, 5)
                            this.onFieldChange("interval_D", truncatedInput)
                            this.onRestErrorKey("interval_D")
                          } else {
                            e.preventDefault()
                          }
                        }}
                        onFocus={() => this.onRestErrorKey("interval_D")}
                        onBlur={() => this.onFocusValidation("interval_D")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        label={
                          <React.Fragment>
                            FH
                            {data.threshold_FH !== undefined &&
                            data.threshold_FH !== null &&
                            data.threshold_FH !== "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : null}
                          </React.Fragment>
                        }
                        name="interval_FH"
                        id="interval_FH"
                        error={error.interval_FH ? true : false}
                        helperText={error.interval_FH ? error.interval_FH : ""}
                        value={
                          data.interval_FH || data.interval_FH === 0
                            ? data.interval_FH
                            : ""
                        }
                        onChange={(e, value) => {
                          const input = e.target.value
                          if (
                            input.length <= 10 &&
                            regexConstants.onlyNumeric.test(e.target.value)
                          ) {
                            this.onFieldChange("interval_FH", input)
                            this.onRestErrorKey("interval_FH")
                          } else if (
                            input.length > 10 &&
                            regexConstants.onlyNumeric.test(input)
                          ) {
                            const truncatedInput = input.slice(0, 10)
                            this.onFieldChange("interval_FH", truncatedInput)
                            this.onRestErrorKey("interval_FH")
                          } else {
                            e.preventDefault()
                          }
                        }}
                        onFocus={() => this.onRestErrorKey("interval_FH")}
                        onBlur={() => this.onFocusValidation("interval_FH")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        label={
                          <React.Fragment>
                            FC
                            {data.threshold_FC !== undefined &&
                            data.threshold_FC !== null &&
                            data.threshold_FC !== "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : null}
                          </React.Fragment>
                        }
                        name="interval_FC"
                        id="interval_FC"
                        error={error.interval_FC ? true : false}
                        helperText={error.interval_FC ? error.interval_FC : ""}
                        value={
                          data.interval_FC || data.interval_FC === 0
                            ? data.interval_FC
                            : ""
                        }
                        onChange={(e, value) => {
                          const input = e.target.value
                          if (
                            input.length <= 10 &&
                            regexConstants.onlyNumeric.test(e.target.value)
                          ) {
                            this.onFieldChange("interval_FC", input)
                            this.onRestErrorKey("interval_FC")
                          } else if (
                            input.length > 10 &&
                            regexConstants.onlyNumeric.test(input)
                          ) {
                            const truncatedInput = input.slice(0, 10)
                            this.onFieldChange("interval_FC", truncatedInput)
                            this.onRestErrorKey("interval_FC")
                          } else {
                            e.preventDefault()
                          }
                        }}
                        onFocus={() => this.onRestErrorKey("interval_FC")}
                        onBlur={() => this.onFocusValidation("interval_FC")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <label className="ml-10px font-14">LAST DONE</label>
                  <Grid spacing={1} container>
                    <Grid item xs={4} className="input-calender">
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          id="lastDone_D"
                          label={
                            <React.Fragment>
                              Date
                              {data.threshold_D !== undefined &&
                              data.threshold_D !== null &&
                              data.threshold_D !== "" ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : null}
                            </React.Fragment>
                          }
                          format={fieldDateFormat}
                          inputVariant="outlined"
                          // autoOk={true}
                          fullWidth
                          disableFuture
                          InputLabelProps={{ shrink: true }}
                          minDate={moment().subtract(25, "years")}
                          value={data.lastDone_D}
                          error={error.lastDone_D ? true : false}
                          helperText={error.lastDone_D ? error.lastDone_D : ""}
                          onFocus={() => this.onRestErrorKey("lastDone_D")}
                          onChange={(data, value) => {
                            this.onFieldChange(
                              "lastDone_D",
                              moment(data).format(backendDateFormat)
                            )
                            this.onFocusValidation("lastDone_D")
                            this.onRestErrorKey("lastDone_D")
                          }}
                          onFocusCapture={() =>
                            this.onFocusValidation("lastDone_D")
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        label={
                          <React.Fragment>
                            FH
                            {data.threshold_FH !== undefined &&
                            data.threshold_FH !== null &&
                            data.threshold_FH !== "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : null}
                          </React.Fragment>
                        }
                        name="lastDone_FH"
                        id="lastDone_FH"
                        error={error.lastDone_FH ? true : false}
                        helperText={error.lastDone_FH ? error.lastDone_FH : ""}
                        value={data.lastDone_FH ? data.lastDone_FH : ""}
                        onChange={(e, value) => {
                          const input = e.target.value
                          if (
                            input.length <= 10 &&
                            regexConstants.onlyNumeric.test(e.target.value)
                          ) {
                            this.onFieldChange("lastDone_FH", input)
                            this.onRestErrorKey("lastDone_FH")
                          } else if (
                            input.length > 10 &&
                            regexConstants.onlyNumeric.test(input)
                          ) {
                            const truncatedInput = input.slice(0, 10)
                            this.onFieldChange("lastDone_FH", truncatedInput)
                            this.onRestErrorKey("lastDone_FH")
                          } else {
                            e.preventDefault()
                          }
                        }}
                        onFocus={() => this.onRestErrorKey("lastDone_FH")}
                        onBlur={() => {
                          //this.onFocusValidation("lastDone_D");
                          this.onFocusValidation("lastDone_FH")
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        label={
                          <React.Fragment>
                            FC
                            {data.threshold_FC !== undefined &&
                            data.threshold_FC !== null &&
                            data.threshold_FC !== "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : null}
                          </React.Fragment>
                        }
                        name="lastDone_FC"
                        id="lastDone_FC"
                        error={error.lastDone_FC ? true : false}
                        helperText={error.lastDone_FC ? error.lastDone_FC : ""}
                        value={data.lastDone_FC ? data.lastDone_FC : ""}
                        onChange={(e, value) => {
                          const input = e.target.value
                          if (
                            input.length <= 10 &&
                            regexConstants.onlyNumeric.test(e.target.value)
                          ) {
                            this.onFieldChange("lastDone_FC", input)
                            this.onRestErrorKey("lastDone_FC")
                          } else if (
                            input.length > 10 &&
                            regexConstants.onlyNumeric.test(input)
                          ) {
                            const truncatedInput = input.slice(0, 10)
                            this.onFieldChange("lastDone_FC", truncatedInput)
                            this.onRestErrorKey("lastDone_FC")
                          } else {
                            e.preventDefault()
                          }
                        }}
                        onFocus={() => this.onRestErrorKey("lastDone_FC")}
                        onBlur={() => this.onFocusValidation("lastDone_FC")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid id="data-container" spacing={1} container>
                <Grid item xs={6}>
                  <label className="ml-10px font-14">NEXT DUE</label>
                  <Grid spacing={1} container>
                    <Grid item xs={4} className="input-calender" id="disabled">
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          id="date_of_manufacture"
                          format={fieldDateFormat}
                          inputVariant="outlined"
                          label="Date"
                          fullWidth
                          disableFuture
                          disabled
                          InputLabelProps={{ shrink: true }}
                          minDate={moment().subtract(25, "years")}
                          value={this.nextDueValue()}
                          error={error.nextDue_D ? true : false}
                          helperText={error.nextDue_D ? error.nextDue_D : ""}
                          onFocus={() => this.onRestErrorKey("nextDue_D")}
                          onChange={(data, value) =>
                            this.onFieldChange(
                              "nextDue_D",
                              moment(data).format(backendDateFormat)
                            )
                          }
                          className="disabled-input"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        label="FH"
                        name="nextDue_FH"
                        id="nextDue_FH"
                        value={
                          data.lastDone_FH &&
                          data.interval_FH &&
                          data.interval_FH > 0 &&
                          parseInt(data.lastDone_FH) + parseInt(data.interval_FH) >  0 ? (data.nextDue_FH = (parseInt(data.lastDone_FH) + parseInt(data.interval_FH) ).toFixed(
                                Number.isInteger(
                                  parseInt(data.lastDone_FH) +
                                    parseInt(data.interval_FH)
                                )
                                  ? 0
                                  : 2
                              ))
                            :  data.lastDone_FH === '' ? (data.nextDue_FH = (parseInt(data.threshold_FH) + parseInt(data.interval_FH) ).toFixed(
                              Number.isInteger(
                                parseInt(data.threshold_FH) +
                                  parseInt(data.interval_FH)
                              )
                                ? 0
                                : 2
                            )): ''
                        }
                        inputProps={{ readOnly: true }}
                        disabled
                        className="disabled-input"
                        onChange={(e, value) =>
                          this.onFieldChange("nextDue_FH", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        label="FC"
                        name="nextDue_FC"
                        id="nextDue_FC"
                        value={
                          data.lastDone_FC &&
                          data.interval_FC &&
                          data.interval_FC > 0 &&
                          parseInt(data.lastDone_FC) +
                            parseInt(data.interval_FC) >
                            0
                            ? (data.nextDue_FC = (
                                parseInt(data.lastDone_FC) +
                                parseInt(data.interval_FC)
                              ).toFixed(
                                Number.isInteger(
                                  parseInt(data.lastDone_FC) +
                                    parseInt(data.interval_FC)
                                )
                                  ? 0
                                  : 2
                              ))
                            : ""
                        }
                        inputProps={{ readOnly: true }}
                        disabled
                        className="disabled-input"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <label className="ml-10px font-14">REMAINING</label>
                  <Grid spacing={1} container>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        label="Days"
                        name="remainingValues_D"
                        id="remainingValues_D"
                        // value={data.remainingValues_D}
                        value={
                          data.interval_D &&
                          data.interval_D > 0 &&
                          data.lastDone_D &&
                          data.interval_D > 0
                            ? (data.remainingValues_D = moment(diffDay).add(
                                data.interval_D
                              ))
                            : ""
                        }
                        inputProps={{ readOnly: true }}
                        disabled
                        className="disabled-input"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        label="FH"
                        name="remainingValues_FH"
                        id="remainingValues_FH"
                        value={
                          this.props.headerTsn &&
                          data.interval_FH &&
                          data.interval_FH > 0 &&
                          data.lastDone_FH &&
                          parseInt(data.lastDone_FH) +
                            parseInt(data.interval_FH) >
                            0
                            ? (data.remainingValues_FH =
                                data.nextDue_FH !== 0
                                  ? Number.isInteger(
                                      data.nextDue_FH - this.props.headerTsn
                                    )
                                    ? data.nextDue_FH - this.props.headerTsn
                                    : (
                                        data.nextDue_FH - this.props.headerTsn
                                      ).toFixed(2)
                                  : "")
                            : ""
                        }
                        inputProps={{ readOnly: true }}
                        disabled
                        className="disabled-input"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        label="FC"
                        name="remainingValues_FC"
                        id="remainingValues_FC"
                        value={
                          this.props.headerCsn &&
                          data.interval_FC &&
                          data.interval_FC > 0 &&
                          data.lastDone_FC &&
                          parseInt(data.lastDone_FC) +
                            parseInt(data.interval_FC) >
                            0
                            ? (data.remainingValues_FC = (
                                data.nextDue_FC - this.props.headerCsn
                              ).toFixed(
                                Number.isInteger(
                                  data.nextDue_FC - this.props.headerCsn
                                )
                                  ? 0
                                  : 2
                              ))
                            : ""
                        }
                        inputProps={{ readOnly: true }}
                        disabled
                        className="disabled-input"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className="border-grey"></div>
              <Grid spacing={1} container>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label={<React.Fragment>Remarks</React.Fragment>}
                    name="remarks"
                    id="remarks"
                    multiline
                    minRows={5}
                    error={error.remarks ? true : false}
                    helperText={error.remarks ? error.remarks : ""}
                    value={data.remarks ? data.remarks : ""}
                    onChange={(e, value) => {
                      const input = e.target.value
                      if (input.length <= 255) {
                        this.onFieldChange("remarks", input)
                        this.onRestErrorKey("remarks")
                      } else {
                        const truncatedInput = input.slice(0, 255)
                        this.onFieldChange("remarks", truncatedInput)
                        this.onRestErrorKey("remarks")
                      }
                    }}
                    onFocus={() => this.onRestErrorKey("remarks")}
                    onBlur={() => this.onFocusValidation("remarks")}
                  />
                </Grid>
              </Grid>

              <Grid spacing={1} container>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label={<React.Fragment>Workorder Reference</React.Fragment>}
                    name="workOrder"
                    id="workOrder"
                    multiline
                    minRows={5}
                    error={error.workOrder ? true : false}
                    helperText={error.workOrder ? error.workOrder : ""}
                    value={data.workOrder ? data.workOrder : ""}
                    onChange={(e, value) => {
                      const input = e.target.value
                      if (input.length <= 255) {
                        this.onFieldChange("workOrder", input)
                        this.onRestErrorKey("workOrder")
                      } else {
                        const truncatedInput = input.slice(0, 255)
                        this.onFieldChange("workOrder", truncatedInput)
                        this.onRestErrorKey("workOrder")
                      }
                    }}
                    onFocus={() => this.onRestErrorKey("workOrder")}
                    onBlur={() => this.onFocusValidation("workOrder")}
                  />
                </Grid>

                <Grid item xs={6}>
                  <div className="upload-textbox">
                    <Button variant="contained" component="label">
                      <div id="upload-success">
                        <span className="d-flex align-items-center">
                          <AddPhotoAlternateOutlinedIcon className="upload-icon" />
                          {/* <span className="upload-text upload">
                            {this.state.attachments.length === 0
                              ? "Upload "
                              : ""}
                          </span> */}
                          <input
                            className="upload-input"
                            onChange={this.onUpload}
                            id="fileUplaod"
                            name="file"
                            type="file"
                            accept={fileTypes}
                            multiple
                          />
                          <span className="upload-text">
                            {this.state.attachments.length === 0
                              ? "Upload or drop a file right here"
                              : " Upload Another?"}
                          </span>
                        </span>
                        <Tooltip title="JPG,PNG,GIF,PDF,DOC,DOCX,XLSX,TXT,JPEG,XLS">
                          <span className="upload-text overflow">
                            &nbsp;JPG,PNG,GIF,PDF,DOC,DOCX,XLSX,TXT,JPEG,XLS
                          </span>
                        </Tooltip>
                      </div>
                      {this.state.showSuccessUpload ? (
                        <div id="success-text">
                          <span className="upload-text">
                            Uploaded successfully!
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </Button>
                  </div>
                  {/* file listing */}
                </Grid>
              </Grid>

              {/* <Grid id="data-container" spacing={1} container>
                <Grid spacing={1} container> */}
              <div className="d-flex align-items-center show-attachments">
                {(() => {
                  if (
                    this.state.attachments &&
                    this.state.attachments.length > 0
                  ) {
                    return Object.keys(this.state.attachments).map(
                      (key1, index) => {
                        return (
                          <div
                            className="position-relative d-flex align-items-center attachment-margin"
                            key={index}
                          >
                            <span className="file-name">
                              {this.state.attachments[index].name}
                            </span>
                            <DescriptionOutlinedIcon className="file-icon" />
                            {permissionCheckFnforCamo({
                              primaryKey: "amp_compliance",
                              keyIndex: "D",
                            }) ||
                            permissionCheckFnforCamo({
                              primaryKey: "amp_compliance",
                              keyIndex: "OD",
                              userId: this.state.data.created_by
                                ? this.state.data.created_by
                                : undefined,
                            }) ||
                            this.state.attachments[index].id === 0 ? (
                              <CancelOutlinedIcon
                                className="cancel-icon"
                                onClick={
                                  this.props.actionType === "add"
                                    ? (e) =>
                                        this.removeAttachment(
                                          e,
                                          this.state.attachments[index]
                                        )
                                    : (e) => {
                                        if (
                                          this.state.attachments[index].id === 0
                                        ) {
                                          this.removeAttachment(
                                            e,
                                            this.state.attachments[index]
                                          )
                                        } else {
                                          this.handleClickAttachment(
                                            e,
                                            this.state.attachments[index].id
                                          )
                                        }
                                      }
                                }
                              />
                            ) : null}
                          </div>
                        )
                      }
                    )
                  }
                })()}
              </div>
              {/* </Grid>
              </Grid> */}
            </DialogContent>
            <DialogActions className="actions-btn">
              <Button
                type="button"
                className="btn btn-primary"
                onClick={
                  this.props.actionType === "add"
                    ? (e) => this.addFormData(e)
                    : (e) => this.updateData(e)
                }
              >
                {this.props.actionType === "add" ? "Add" : "Update"}
              </Button>

              {this.props.actionType === "add" ? (
                <Button
                  onClick={(e) => this.saveAndAddAnother(e)}
                  type="button"
                  className="btn btn-primary"
                >
                  Save & Add Another
                </Button>
              ) : (
                ""
              )}
              <Button
                type="button"
                className="btn btn-primary"
                onClick={this.props.closeAddForm}
              >
                Close
              </Button>
            </DialogActions>
            <Dialog
              open={this.state.open}
              onClose={this.handleCloseAttachment}
              id="htDialog"
            >
              <AmpConfirmDeleteAttachment
                handleCloseAttachment={() => this.handleCloseAttachment()}
                deleteAttachmentFile={() =>
                  this.deleteAttachmentFile(this.state.deleteAttachmentId)
                }
              />
            </Dialog>
          </form>
        </div>
      </Fragment>
    )
  }
}
export default AmpTaskForm
