import React, { Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";

function MntSideNav(props) {
  return (
    <Fragment>
      <ul className="list-unstyled">
        {[
          {
            label: "AMP/MRB Compliance",
            path: `/${props.match.params.asset}/${props.match.params.assetType}/amp/list`,
          },
          {
            label: "Hard Time Components",
            path: `/${props.match.params.asset}/${props.match.params.assetType}/ht/list`,
            activeStrCheck: "airframe",
          },
          {
            label: "Airworthiness Directives (ADs)",
            path: `/${props.match.params.asset}/${props.match.params.assetType}/ad/list`,
            activeStrCheck: "airframe",
          },
          {
            label: "Service Bulletins",
            path: `/${props.match.params.asset}/${props.match.params.assetType}/sb/list`,
            activeStrCheck: "airframe",
          },
          {
            label: "OC-CM Components",
            path: `/${props.match.params.asset}/${props.match.params.assetType}/occm/list`,
            activeStrCheck: "airframe",
          },
          {
            label: "Structure Repairs",
            path: `/${props.match.params.asset}/${props.match.params.assetType}/sr/list`,
            activeStrCheck: "airframe",
          },
          {
            label: "Modifications",
            path: `/${props.match.params.asset}/${props.match.params.assetType}/mods/list`,
            activeStrCheck: "airframe",
          },
          {
            label: "Analytics",
            // path: `/${props.match.params.asset}/${props.match.params.assetType}/analytics/list`,
            path: "/dashboard",
            activeStrCheck: "airframe",
          },
        ].map((item, index) => (
          <li key={index}>
            <NavLink to={item.path}>{item.label}</NavLink>
          </li>
        ))}
      </ul>
    </Fragment>
  );
}

export default withRouter(MntSideNav);
