import React, { Component, Fragment } from "react"
import {
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormLabel,
  FormControlLabel,
  Radio,
  Dialog,
  Tooltip,
} from "@material-ui/core"
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined"
// import Radio from '@material-ui/core/Radio';
import RadioGroup from "@material-ui/core/RadioGroup"
import CloseIcon from "@material-ui/icons/Close"
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/OccmTaskFormData"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import moment from "moment"
import MomentUtils from "@date-io/moment"
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import {
  addOccmFormApi,
  deleteAdAttachment,
  fillOccmEditFormApi,
  UpdateOccmTaskFormApi,
  uploadOccmFile,
} from "../apiService"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined"
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined"
import OccmConfirmDeleteAttachment from "./OccmConfirmDeleteAttachment"
import { regexConstants } from "../../../../constants/regEx"
import { permissionCheckFnforCamo } from "../../../../utils"

class OccmTaskForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: {},
      data: formValues,
      regex: "^[0-9]+$",
      numberError: false,
      selectLastDDate: null,
      selectNextDueDate: null,
      attachments: [],
      newUploadedAttachments: [],
      remainingValue: "",
      btnStatus: "",
      open: false,
      deleteAttachmentId: 0,
      showSuccessUpload: false,
      globalValidationInputs: {},
      validatedFields: [],
    }
    this.addOccmFormApi = addOccmFormApi.bind(this)
  }

  componentDidMount = () => {
    if (this.props.editFormId) {
      this.fillEditData()
    }
  }

  onFieldChange = (keyParam, value) => {
    const { data } = this.state

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [keyParam]: value,
      },
    }))
  }

  onRestErrorKey = (keyParam) => {
    this.setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: "",
      },
    }))
  }
  handleSubmit = (e) => {
    e.preventDefault()
    let validationInputs = {}
    const { data } = this.state
    this.setState({ file: "" })

    validationInputs = {
      ...validationInputs,
      ata_chapter:
        taskErrorCode["ata_chapter"][
          fieldValidation({
            ...taskErrorCode["ata_chapter_obj"],
            fieldval: data.ata_chapter,
          })
        ],
      part_number:
        taskErrorCode["part_number"][
          fieldValidation({
            ...taskErrorCode["part_number_obj"],
            fieldval: data.part_number,
          })
        ],
      part_serial_number:
        taskErrorCode["part_serial_number"][
          fieldValidation({
            ...taskErrorCode["part_serial_number_obj"],
            fieldval: data.part_serial_number,
          })
        ],
      part_installation_date:
        taskErrorCode["part_installation_date"][
          fieldValidation({
            ...taskErrorCode["part_installation_date_obj"],
            fieldval: data.part_installation_date,
          })
        ],
      position:
        taskErrorCode["position"][
          fieldValidation({
            ...taskErrorCode["position_obj"],
            fieldval: data.position,
          })
        ],
      faa_easa_certificate:
        taskErrorCode["faa_easa_certificate"][
          fieldValidation({
            ...taskErrorCode["faa_easa_certificate_obj"],
            fieldval: data.faa_easa_certificate,
          })
        ],
      part_description:
        taskErrorCode["part_description"][
          fieldValidation({
            ...taskErrorCode["part_description_obj"],
            fieldval: data.part_description,
          })
        ],
    }
    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))

      // add task api
      const props = this.props.props
      const OccmData = data
      OccmData.part_csn = data.part_csn === "" ? null : data.part_csn
      OccmData.part_tsn = data.part_tsn === "" ? null : data.part_tsn
      OccmData.asset_id = props.match.params.asset
      OccmData.asset_type_id = props.match.params.assetType
      addOccmFormApi(OccmData, props).then(async (response) => {
        const uploadId = response.data.data.id
        if (uploadId) {
          await this.uploadFileTest(uploadId, props, false)
        }
      })
      if (this.state.btnStatus !== "addAnother") {
        this.props.closeAddForm()
      } else {
        return false
      }
    } else {
      this.setState({ error: validationInputs })
    }
  }

  // edit task form
  fillEditData = () => {
    const updateId = this.props.editFormId
    this.setState({ attachments: this.props.attachments })
    if (updateId === undefined || updateId === null) {
      this.setState({
        data: {},
      })
    } else {
      this.setState({
        data: this.props.editFormData,
      })
    }
  }

  onFocusValidation = (keyParam) => {
    let validationInputs = {}
    const { data } = this.state
    validationInputs = {
      ...validationInputs,
      ata_chapter:
        taskErrorCode["ata_chapter"][
          fieldValidation({
            ...taskErrorCode["ata_chapter_obj"],
            fieldval: data.ata_chapter,
          })
        ],
      part_number:
        taskErrorCode["part_number"][
          fieldValidation({
            ...taskErrorCode["part_number_obj"],
            fieldval: data.part_number,
          })
        ],
      part_serial_number:
        taskErrorCode["part_serial_number"][
          fieldValidation({
            ...taskErrorCode["part_serial_number_obj"],
            fieldval: data.part_serial_number,
          })
        ],
      part_installation_date:
        taskErrorCode["part_installation_date"][
          fieldValidation({
            ...taskErrorCode["part_installation_date_obj"],
            fieldval: data.part_installation_date,
          })
        ],
      position:
        taskErrorCode["position"][
          fieldValidation({
            ...taskErrorCode["position_obj"],
            fieldval: data.position,
          })
        ],
      faa_easa_certificate:
        taskErrorCode["faa_easa_certificate"][
          fieldValidation({
            ...taskErrorCode["faa_easa_certificate_obj"],
            fieldval: data.faa_easa_certificate,
          })
        ],
      part_description:
        taskErrorCode["part_description"][
          fieldValidation({
            ...taskErrorCode["part_description_obj"],
            fieldval: data.part_description,
          })
        ],
    }

    // for (let key in validationInputs) {
    //   if (key === keyParam) {
    //     this.state.globalValidationInputs[key] = validationInputs[key]
    //   }
    // }
    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))
      this.setState({ validationInputs: {} })
    } else {
      this.setState({ error: validationInputs })
    }
  }

  updateData = async (e) => {
    e.preventDefault()
    let validationInputs = {}
    const { data } = this.state
    this.setState({ file: "" })

    validationInputs = {
      ...validationInputs,
      ata_chapter:
        taskErrorCode["ata_chapter"][
          fieldValidation({
            ...taskErrorCode["ata_chapter_obj"],
            fieldval: data.ata_chapter,
          })
        ],
      part_number:
        taskErrorCode["part_number"][
          fieldValidation({
            ...taskErrorCode["part_number_obj"],
            fieldval: data.part_number,
          })
        ],
      part_serial_number:
        taskErrorCode["part_serial_number"][
          fieldValidation({
            ...taskErrorCode["part_serial_number_obj"],
            fieldval: data.part_serial_number,
          })
        ],
      part_installation_date:
        taskErrorCode["part_installation_date"][
          fieldValidation({
            ...taskErrorCode["part_installation_date_obj"],
            fieldval: data.part_installation_date,
          })
        ],
      position:
        taskErrorCode["position"][
          fieldValidation({
            ...taskErrorCode["position_obj"],
            fieldval: data.position,
          })
        ],
      faa_easa_certificate:
        taskErrorCode["faa_easa_certificate"][
          fieldValidation({
            ...taskErrorCode["faa_easa_certificate_obj"],
            fieldval: data.faa_easa_certificate,
          })
        ],
      part_description:
        taskErrorCode["part_description"][
          fieldValidation({
            ...taskErrorCode["part_description_obj"],
            fieldval: data.part_description,
          })
        ],
    }
    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))

      const updateId = this.props.editFormId
      const updatedData = this.state.data
      const props = this.props.props
      this.setState({
        data: this.props.editFormData,
      })
      if (updateId) {
        await this.uploadFileTest(updateId, props, true)
      }
      await this.props.updateFormData(updateId, updatedData, props)
      this.props.closeAddForm()
    } else {
      this.setState({ error: validationInputs })
    }
  }

  uploadFileTest = async (uploadId, props, isUpdate) => {
    if (this.state.newUploadedAttachments.length > 0) {
      let newAttachmentObj = []
      let formdata = new FormData()
      const module_type_id = 3

      Object.keys(this.state.newUploadedAttachments).map((key, index) => {
        newAttachmentObj.push({
          file: this.state.newUploadedAttachments[key],
          file_name: this.state.newUploadedAttachments[key].name,
          file_type: this.state.newUploadedAttachments[key].type,
          module_type_id,
          module_id: uploadId,
        })
        formdata.append(
          "[" + key + "]file",
          this.state.newUploadedAttachments[key]
        )
        formdata.append(
          "[" + key + "]file_type",
          this.state.newUploadedAttachments[key].type
        )
        formdata.append(
          "[" + key + "]file_name",
          this.state.newUploadedAttachments[key].name
        )
        formdata.append("[" + key + "]module_id", uploadId)
        formdata.append("[" + key + "]module_type_id", module_type_id)
      })
      // uploadOccmFile(uploadId, formdata, props)
      this.props.fileUploadData(uploadId, formdata, props, true)
      if (this.state.btnStatus === "addAnother") {
        this.resetBothAttachmentArray()
      }
    } else {
      if (this.state.btnStatus === "addAnother") {
        this.setState((prevState) => ({
          ...prevState,
          data: formValues,
          attachments: [],
        }))
        document.getElementById("asset-form").reset()
        this.props.fileUploadData(uploadId, null, props, false)
      } else {
        if (!isUpdate) {
          this.props.fileUploadData(uploadId, null, props, false)
        }
      }
    }
  }

  resetBothAttachmentArray = () => {
    this.setState((prevState) => ({
      ...prevState,
      data: formValues,
      attachments: [],
      newUploadedAttachments: [],
      open: false,
      deleteAttachmentId: 0,
    }))
    document.getElementById("asset-form").reset()
  }

  onUpload = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    for (let i = 0; i < chosenFiles.length; i++) {
      if (chosenFiles[i].size > 52428800) {
        this.props.props.enqueueSnackbar(
          `${chosenFiles[i].name} Maximum file size should be 50MB.`,
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        )
        delete chosenFiles[i]
        continue
      } else {
        this.handleUploadFiles(chosenFiles)
        // document.querySelector(".MuiDialog-paper").scrollTop = 800;
        this.setState({
          showSuccessUpload: true,
        })
        setTimeout(() => {
          if (this.state.attachments) {
            this.setState({
              showSuccessUpload: false,
            })
          }
        }, 2500)
      }
    }
  }

  handleUploadFiles = (files) => {
    const uploaded = [...this.state.newUploadedAttachments]
    const existingAttachments = [...this.state.attachments]
    files.some((file) => {
      uploaded.push(file)
    })
    files.map((file) => {
      var objc = {
        id: 0,
        module_id: 0,
        module_type_id: 3,
        file_name: file.name,
        file_type: file.type,
        file: "",
        name: file.name,
      }
      existingAttachments.push(objc)
    })

    this.setState({
      newUploadedAttachments: uploaded,
      attachments: existingAttachments,
    })
  }

  handleClickAttachment = (e, id) => {
    this.setState({ open: true })
    this.setState({ deleteAttachmentId: id })
  }

  handleCloseAttachment = (e) => {
    this.setState({ open: false })
    this.setState({ deleteAttachmentId: 0 })
  }

  deleteAttachmentFile = async (id) => {
    if (id !== undefined && id != 0) {
      const props = this.props.props
      deleteAdAttachment(id, props)
      // const item = this.props.item
      // // const props = this.props
      // fillOccmEditFormApi(item, props).then((response) => {
      //   this.setState({
      //     attachments: response.data.data.attachments,
      //     open: false,
      //     deleteAttachmentId:0
      //   })
      // })
      var array = [...this.state.attachments]
      array = array.filter((item) => item.id !== id)
      this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
    }
  }

  removeAttachment = (e, obj) => {
    var array = [...this.state.attachments]
    var newuploadarray = [...this.state.newUploadedAttachments]

    var filteredArray = []

    newuploadarray.map((file) => {
      if (file.name == obj.file_name && file.type == obj.file_type) {
      } else {
        filteredArray.push(file)
      }
    })
    // Set the state with the new filtered array
    array = array.filter((item) => item !== obj)
    this.setState({
      newUploadedAttachments: filteredArray,
      attachments: array,
    })

    // var index = array.indexOf(e.target.value)
    // if (index !== -2) {
    //   array.splice(index, 1)
    //   this.setState((prevState) => ({
    //     ...prevState,
    //     attachments: array,
    //   }))
    // }
  }

  saveAndAddAnother = async (e) => {
    await this.setState({ btnStatus: "addAnother" })
    await this.handleSubmit(e)
  }

  addFormData = async (e) => {
    await this.setState({ btnStatus: "add" })
    this.handleSubmit(e)
  }

  render() {
    const fileTypes = [
      ".GIF",
      ".PDF",
      ".DOC",
      ".DOCX",
      ".XLSX",
      ".TXT",
      ".XLS",
      "image/*",
    ]
    const { data, error } = this.state
    return (
      <Fragment>
        <div id="AmpTaskForm">
          <div id="HtTaskForm">
            <DialogTitle>
              {this.props.formTitle}
              <CloseIcon
                onClick={this.props.closeAddForm}
                className="close-icon"
              />
            </DialogTitle>
            <form
              id="asset-form"
              onSubmit={
                this.props.actionType === "add"
                  ? (e) => this.handleSubmit(e)
                  : (e) => this.updateData(e)
              }
            >
              <DialogContent>
                <Grid spacing={1} container>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          ATA Chapter<span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="ata_chapter"
                      id="ata_chapter"
                      value={data.ata_chapter}
                      error={error.ata_chapter ? true : false}
                      helperText={error.ata_chapter ? error.ata_chapter : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 15 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("ata_chapter", input)
                          this.onRestErrorKey("ata_chapter")
                        } else if (
                          input.length > 15 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 15)
                          this.onFieldChange("ata_chapter", truncatedInput)
                          this.onRestErrorKey("ata_chapter")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 15 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("ata_chapter", input)
                          this.onRestErrorKey("ata_chapter")
                        } else if (
                          input.length > 15 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 15)
                          this.onFieldChange("ata_chapter", truncatedInput)
                          this.onRestErrorKey("ata_chapter")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 15 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("ata_chapter", input)
                          this.onRestErrorKey("ata_chapter")
                        } else if (
                          input.length > 15 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 15)
                          this.onFieldChange("ata_chapter", truncatedInput)
                          this.onRestErrorKey("ata_chapter")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("ata_chapter")}
                      onBlur={() => this.onFocusValidation("ata_chapter")}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Part Number<span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="part_number"
                      id="part_number"
                      value={data.part_number}
                      error={error.part_number ? true : false}
                      helperText={error.part_number ? error.part_number : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("part_number", input)
                          this.onRestErrorKey("part_number")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("part_number", truncatedInput)
                          this.onRestErrorKey("part_number")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("part_number")}
                      onBlur={() => this.onFocusValidation("part_number")}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Part Serial Number
                          <span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="part_serial_number"
                      id="part_serial_number"
                      value={data.part_serial_number}
                      error={error.part_serial_number ? true : false}
                      helperText={
                        error.part_serial_number ? error.part_serial_number : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("part_serial_number", input)
                          this.onRestErrorKey("part_serial_number")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange(
                            "part_serial_number",
                            truncatedInput
                          )
                          this.onRestErrorKey("part_serial_number")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("part_serial_number")}
                      onBlur={() =>
                        this.onFocusValidation("part_serial_number")
                      }
                    />
                  </Grid>
                  <Grid item xs={3} className="input-calender">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        id="part_installation_date"
                        label={
                          <React.Fragment>
                            Part Installation Date
                            <span style={{ color: "red" }}> *</span>
                          </React.Fragment>
                        }
                        format={fieldDateFormat}
                        inputVariant="outlined"
                        // autoOk={true}
                        fullWidth
                        disableFuture
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(25, "years")}
                        value={data.part_installation_date}
                        error={error.part_installation_date ? true : false}
                        helperText={
                          error.part_installation_date
                            ? error.part_installation_date
                            : ""
                        }
                        onFocus={() =>
                          this.onRestErrorKey("part_installation_date")
                        }
                        onChange={(data, value) => {
                          this.onFieldChange(
                            "part_installation_date",
                            moment(data).format(backendDateFormat)
                          )
                          this.onRestErrorKey("part_installation_date")
                        }}
                        onFocusCapture={() =>
                          this.onFocusValidation("part_installation_date")
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Part Description
                          <span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="part_description"
                      id="part_description"
                      multiline
                      minRows={5}
                      value={data.part_description}
                      error={error.part_description ? true : false}
                      helperText={
                        error.part_description ? error.part_description : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 255) {
                          this.onFieldChange("part_description", input)
                          this.onRestErrorKey("part_description")
                        } else {
                          const truncatedInput = input.slice(0, 255)
                          this.onFieldChange("part_description", truncatedInput)
                          this.onRestErrorKey("part_description")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("part_description")}
                      onBlur={() => this.onFocusValidation("part_description")}
                    />
                  </Grid>
                </Grid>
                <div className="border-grey"></div>

                <Grid spacing={1} container>
                  <Grid item xs={2}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Aircraft TSN Installation
                        </React.Fragment>
                      }
                      name="aircraft_tsn"
                      id="aircraft_tsn"
                      value={data.aircraft_tsn}
                      error={error.aircraft_tsn ? true : false}
                      helperText={error.aircraft_tsn ? error.aircraft_tsn : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("aircraft_tsn", input)
                          this.onRestErrorKey("aircraft_tsn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("aircraft_tsn", truncatedInput)
                          this.onRestErrorKey("aircraft_tsn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("aircraft_tsn", input)
                          this.onRestErrorKey("aircraft_tsn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("aircraft_tsn", truncatedInput)
                          this.onRestErrorKey("aircraft_tsn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("aircraft_tsn", input)
                          this.onRestErrorKey("aircraft_tsn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("aircraft_tsn", truncatedInput)
                          this.onRestErrorKey("aircraft_tsn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("aircraft_tsn")}
                      onBlur={() => this.onFocusValidation("aircraft_tsn")}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Aircraft CSN Installation
                        </React.Fragment>
                      }
                      name="aircraft_csn"
                      id="aircraft_csn"
                      value={data.aircraft_csn}
                      error={error.aircraft_csn ? true : false}
                      helperText={error.aircraft_csn ? error.aircraft_csn : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("aircraft_csn", input)
                          this.onRestErrorKey("aircraft_csn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("aircraft_csn", truncatedInput)
                          this.onRestErrorKey("aircraft_csn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("aircraft_csn", input)
                          this.onRestErrorKey("aircraft_csn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("aircraft_csn", truncatedInput)
                          this.onRestErrorKey("aircraft_csn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("aircraft_csn", input)
                          this.onRestErrorKey("aircraft_csn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("aircraft_csn", truncatedInput)
                          this.onRestErrorKey("aircraft_csn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("aircraft_csn")}
                      onBlur={() => this.onFocusValidation("aircraft_csn")}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      variant="outlined"
                      label="Part TSN Installation"
                      name="part_tsn"
                      id="part_tsn"
                      value={data.part_tsn}
                      error={error.part_tsn ? true : false}
                      helperText={error.part_tsn ? error.part_tsn : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("part_tsn", input)
                          this.onRestErrorKey("part_tsn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("part_tsn", truncatedInput)
                          this.onRestErrorKey("part_tsn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("part_tsn", input)
                          this.onRestErrorKey("part_tsn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("part_tsn", truncatedInput)
                          this.onRestErrorKey("part_tsn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("part_tsn", input)
                          this.onRestErrorKey("part_tsn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("part_tsn", truncatedInput)
                          this.onRestErrorKey("part_tsn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("part_tsn")}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextField
                      variant="outlined"
                      label="Part CSN Installation"
                      name="part_csn"
                      id="part_csn"
                      value={data.part_csn}
                      error={error.part_csn ? true : false}
                      helperText={error.part_csn ? error.part_csn : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("part_csn", input)
                          this.onRestErrorKey("part_csn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("part_csn", truncatedInput)
                          this.onRestErrorKey("part_csn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("part_csn", input)
                          this.onRestErrorKey("part_csn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("part_csn", truncatedInput)
                          this.onRestErrorKey("part_csn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("part_csn", input)
                          this.onRestErrorKey("part_csn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("part_csn", truncatedInput)
                          this.onRestErrorKey("part_csn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("part_csn")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Position<span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="position"
                      id="position"
                      value={data.position}
                      error={error.position ? true : false}
                      helperText={error.position ? error.position : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("position", input)
                          this.onRestErrorKey("position")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("position", truncatedInput)
                          this.onRestErrorKey("position")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("position")}
                      onBlur={() => this.onFocusValidation("position")}
                    />
                  </Grid>
                </Grid>
                <div className="border-grey"></div>
                <Grid id="data-container" spacing={1} container>
                  {/* <Grid item xs={2}>
                    <label>
                      FAA / EASA Certificate{" "}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <FormControl
                      error={error.faa_easa_certificate ? true : false}
                      helperText={
                        error.faa_easa_certificate
                          ? error.faa_easa_certificate
                          : ""
                      }
                      onFocus={() =>
                        this.onRestErrorKey("faa_easa_certificate")
                      }
                    >
                      <RadioGroup
                        required
                        row
                        aria-label="faa_easa_certificate"
                        name="faa_easa_certificate"
                        defaultValue="top"
                        value={data.faa_easa_certificate}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "faa_easa_certificate",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                        {error.faa_easa_certificate && (
                          <FormLabel component="faa_easa_certificate">
                            <span style={{ color: "red" }}>
                              {error.faa_easa_certificate}
                            </span>
                          </FormLabel>
                        )}
                      </RadioGroup>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={2}>
                    <label>
                      FAA / EASA Certificate
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <FormControl
                      error={error.faa_easa_certificate ? true : false}
                      helperText={
                        error.faa_easa_certificate
                          ? error.faa_easa_certificate
                          : ""
                      }
                      onFocus={() =>
                        this.onRestErrorKey("faa_easa_certificate")
                      }
                    >
                      <RadioGroup
                        required
                        row
                        aria-label="faa_easa_certificate"
                        name="faa_easa_certificate"
                        defaultValue="top"
                        value={data.faa_easa_certificate}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "faa_easa_certificate",
                            e.target.value
                          )
                        }
                        onBlur={() =>
                          this.onFocusValidation("faa_easa_certificate")
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                      {error.faa_easa_certificate && (
                        <FormLabel component="faa_easa_certificate">
                          <span style={{ color: "red" }}>
                            {error.faa_easa_certificate}
                          </span>
                        </FormLabel>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <label>Shop Report </label>
                    <FormControl component="fieldset">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="shop_report"
                        name="shop_report"
                        defaultValue="top"
                        value={data.shop_report}
                        onChange={(e, value) =>
                          this.onFieldChange("shop_report", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <label>Certificate of Conformity</label>
                    <FormControl component="fieldset">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="conformity_certificate"
                        name="conformity_certificate"
                        defaultValue="top"
                        value={data.conformity_certificate}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "conformity_certificate",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                        <FormControlLabel
                          value="N/A"
                          control={<Radio size="small" color="primary" />}
                          label="N/A"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <label>Goods receipt note</label>
                    <FormControl component="fieldset">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="receipt_note"
                        name="receipt_note"
                        defaultValue="top"
                        value={data.receipt_note}
                        onChange={(e, value) =>
                          this.onFieldChange("receipt_note", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <label>Installation Work order </label>
                    <FormControl component="fieldset">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="work_order"
                        name="work_order"
                        defaultValue="top"
                        value={data.work_order}
                        onChange={(e, value) =>
                          this.onFieldChange("work_order", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                        <FormControlLabel
                          value="N/A"
                          control={<Radio size="small" color="primary" />}
                          label="N/A"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      label="Remark"
                      name="remark"
                      id="remark"
                      multiline
                      minRows={5}
                      value={data.remark}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 255) {
                          this.onFieldChange("remark", input)
                          this.onRestErrorKey("remark")
                        } else {
                          const truncatedInput = input.slice(0, 255)
                          this.onFieldChange("remark", truncatedInput)
                          this.onRestErrorKey("remark")
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="upload-textbox">
                      <Button variant="contained" component="label">
                        <div id="upload-success">
                          <span className="d-flex align-items-center">
                            <AddPhotoAlternateOutlinedIcon className="upload-icon" />
                            {/* <span className="upload-text upload">
                              {this.state.attachments.length === 0
                                ? "Upload "
                                : ""}
                            </span> */}
                            <input
                              className="upload-input"
                              onChange={this.onUpload}
                              id="fileUplaod"
                              name="file"
                              type="file"
                              accept={fileTypes}
                              multiple
                            />
                            <span className="upload-text">
                              {this.state.attachments.length === 0
                                ? "Upload or drop a file right here"
                                : " Upload Another?"}
                            </span>
                          </span>
                          <Tooltip title="JPG,PNG,GIF,PDF,DOC,DOCX,XLSX,TXT,JPEG,XLS">
                            <span className="upload-text overflow">
                              &nbsp;JPG,PNG,GIF,PDF,DOC,DOCX,XLSX,TXT,JPEG,XLS
                            </span>
                          </Tooltip>
                        </div>
                        {this.state.showSuccessUpload ? (
                          <div id="success-text">
                            <span className="upload-text">
                              Uploaded successfully!
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid id="data-container" spacing={1} container>
                  <Grid spacing={1} container>
                    <div className="d-flex align-items-center show-attachments">
                      {(() => {
                        if (
                          this.state.attachments &&
                          this.state.attachments.length > 0
                        ) {
                          return Object.keys(this.state.attachments).map(
                            (key1, index) => {
                              return (
                                <div
                                  className="position-relative d-flex align-items-center attachment-margin"
                                  key={index}
                                >
                                  <span className="file-name">
                                    {this.state.attachments[index].name}
                                  </span>
                                  <DescriptionOutlinedIcon className="file-icon" />
                                  {permissionCheckFnforCamo({
                                    primaryKey: "occm_component",
                                    keyIndex: "D",
                                  }) ||
                                  permissionCheckFnforCamo({
                                    primaryKey: "occm_component",
                                    keyIndex: "OD",
                                    userId: this.state.data.created_by
                                      ? this.state.data.created_by
                                      : undefined,
                                  }) ||
                                  this.state.attachments[index].id === 0 ? (
                                    <CancelOutlinedIcon
                                      className="cancel-icon"
                                      onClick={
                                        this.props.actionType === "add"
                                          ? (e) =>
                                              this.removeAttachment(
                                                e,
                                                this.state.attachments[index]
                                              )
                                          : (e) => {
                                              if (
                                                this.state.attachments[index]
                                                  .id === 0
                                              ) {
                                                this.removeAttachment(
                                                  e,
                                                  this.state.attachments[index]
                                                )
                                              } else {
                                                this.handleClickAttachment(
                                                  e,
                                                  this.state.attachments[index]
                                                    .id
                                                )
                                              }
                                            }
                                      }
                                    />
                                  ) : null}
                                </div>
                              )
                            }
                          )
                        }
                      })()}
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="actions-btn">
                <Button
                  type="button"
                  className="btn btn-primary"
                  // onClick={ this.props.closeAddForm}
                  onClick={
                    this.props.actionType === "add"
                      ? (e) => this.addFormData(e)
                      : (e) => this.updateData(e)
                  }
                >
                  {this.props.actionType === "add" ? "Add" : "Update"}
                </Button>

                {this.props.actionType === "add" ? (
                  <Button
                    onClick={(e) => this.saveAndAddAnother(e)}
                    type="button"
                    className="btn btn-primary"
                  >
                    Save & Add Another
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.props.closeAddForm}
                >
                  Close
                </Button>
              </DialogActions>
              <Dialog
                open={this.state.open}
                onClose={this.handleCloseAttachment}
                id="htDialog"
              >
                <OccmConfirmDeleteAttachment
                  handleCloseAttachment={() => this.handleCloseAttachment()}
                  deleteAttachmentFile={() =>
                    this.deleteAttachmentFile(this.state.deleteAttachmentId)
                  }
                />
              </Dialog>
            </form>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default OccmTaskForm
