import React, { useCallback, useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import {
  Dialog,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
} from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined"
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined"
import ConfirmDialog from "../components/ConfirmDialog"
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments"
import { fieldDateFormat } from "../../../../constants"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import SrTaskForm from "./SrTaskForm"
import moment from "moment"
import { fillEditFromApi } from "../apiService"
import { permissionCheckFnforCamo } from "../../../../utils"

const SrList = ({
  item,
  index,
  handleDeleteRow,
  updateFormData,
  props,
  fileUploadData,
  currentRecordPerPage,
  downloadAllApi,
  headerTsn,
  headerCsn,
}) => {
  const [open, setOpen] = useState(false)
  const [openEditForm, setEditForm] = useState(false)
  const [editFormId, setEditFormId] = useState(null)
  const [editFormData, setEditFormData] = useState(null)
  const [formTitle, setFormTitle] = useState("")
  const [attachments, setAttachments] = useState(null)
  const [showFullDescription, setShowFullDescription] = useState({})
  const [showFullRemarks, setShowFullRemarks] = useState({})
  const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false)
  const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] =
    useState(null)
  const [moduleId, setmoduleId] = useState(null)

  const handleClick = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  let todayDate = new Date()
  const remainingValuesDate =
    item.interval_D -
    Math.round(
      (todayDate - new Date(Date.parse(item.lastDone_D))) /
        (1000 * 60 * 60 * 24)
    )

  const closeAddForm = () => {
    setEditForm(false)
  }

  const handleEditRow = () => {
    fillEditFromApi(item, props).then((response) => {
      setEditFormData(response.data.data)
      setEditFormId(response.data.data.id)
      setAttachments(response.data.data.attachments)
      setFormTitle("Update Structure Repairs")
      setEditForm(true)
    })
  }
  const downAllAttach = () => {
    fillEditFromApi(item, props).then((response) => {
      setmoduleId(response.data.data.id)
    })
    if (item.attachments.length > 0) {
      getAttachments(item.attachments)
    }
  }

  const openAttachment = (file) => {
    window.open(`https://qa.camo.sparta.aero:8223${file}`, "_blank")
  }

  const formatDate = (date) => {
    return moment(date).format("DD-MM-YYYY")
  }

  const toggleDescription = (id) => {
    setShowFullDescription({
      ...showFullDescription,
      [id]: !showFullDescription[id],
    })
  }

  const toggleRemarks = (id) => {
    setShowFullRemarks({
      ...showFullRemarks,
      [id]: !showFullRemarks[id],
    })
  }

  const getAttachments = (attachments) => {
    setAllAttachmentsforDialogue(attachments)
    setAttachmentsDialogue(true)
  }

  const handleCloseAttachmentDialogue = () => {
    setAttachmentsDialogue(false)
  }

  const convertToDate = (value) => {
    if (typeof value === "string") {
      return formatDate(value)
    } else if (typeof value === "number") {
      return value
    } else {
      return "--"
    }
  }

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        style={{ cursor: "pointer" }}
        className={
          item.remainingValues_D < 0 || item.remainingValues_D == null
            ? "red-row"
            : "none"
        }
      >
        <TableCell>
          {permissionCheckFnforCamo({
            primaryKey: "repair",
            keyIndex: "U",
          }) ? (
            <Tooltip title="Edit">
              <IconButton
                className="edit-icon"
                onClick={(e) => {
                  handleEditRow(e)
                }}
              >
                <CreateOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {permissionCheckFnforCamo({
            primaryKey: "repair",
            keyIndex: "D",
          }) ||
          permissionCheckFnforCamo({
            primaryKey: "repair",
            keyIndex: "OD",
            userId: item.created_by,
          }) ? (
            <Tooltip title="Delete" arrow>
              <IconButton className="delete-icon" onClick={handleClick}>
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </TableCell>
        <TableCell>{item.ata_chapter ? item.ata_chapter : "--"}</TableCell>
        <TableCell>
          {item.repair_ref_number ? item.repair_ref_number : "--"}
        </TableCell>
        <TableCell>{item.repair_type ? item.repair_type : "--"}</TableCell>
        <TableCell style={{ width: "200px", textAlign: "left" }}>
          {item.repair_description && item.repair_description.length > 150 ? (
            <div>
              {showFullDescription[item.id] ? (
                <span>
                  {item.repair_description}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault()
                      toggleDescription(item.id)
                    }}
                  >
                    ..less
                  </a>
                </span>
              ) : (
                <span>
                  {item.repair_description.substring(0, 150)}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault()
                      toggleDescription(item.id)
                    }}
                  >
                    ..more
                  </a>
                </span>
              )}
            </div>
          ) : item.repair_description &&
            item.repair_description.length <= 150 ? (
            <span>{item.repair_description}</span>
          ) : (
            "--"
          )}
        </TableCell>

        <TableCell>
          {item.repeat_inspection ? item.repeat_inspection : "--"}
        </TableCell>
        <TableCell>
          {item.repair_location ? item.repair_location : "--"}
        </TableCell>
        <TableCell>{item.repair_detail ? item.repair_detail : "--"}</TableCell>
        <TableCell>
          {item.repair_compliance_ref ? item.repair_compliance_ref : "--"}
        </TableCell>
        <TableCell>
          {item.repair_compliance_date
            ? moment(item.repair_compliance_date).format(fieldDateFormat)
            : "--"}
        </TableCell>
        <TableCell>
          {item.repair_compliance_tsn ? item.repair_compliance_tsn : "--"}
        </TableCell>
        <TableCell>
          {item.repair_compliance_csn ? item.repair_compliance_csn : "--"}
        </TableCell>

        <TableCell>{convertToDate(item?.remaining?.due_at)}</TableCell>
        <TableCell>
          {item.last_done_date
            ? moment(item.last_done_date).format(fieldDateFormat)
            : "--"}
        </TableCell>
        <TableCell>{item.last_done_fh ? item.last_done_fh : "--"}</TableCell>
        <TableCell>{item.last_done_fc ? item.last_done_fc : "--"}</TableCell>
        <TableCell style={{ width: "200px", textAlign: "left" }}>
          {item.remarks && item.remarks.length > 150 ? (
            <div>
              {showFullDescription[item.id] ? (
                <span>
                  {item.remarks}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault()
                      toggleDescription(item.id)
                    }}
                  >
                    ..less
                  </a>
                </span>
              ) : (
                <span>
                  {item.remarks.substring(0, 150)}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault()
                      toggleDescription(item.id)
                    }}
                  >
                    ..more
                  </a>
                </span>
              )}
            </div>
          ) : item.remarks && item.remarks.length <= 150 ? (
            <span>{item.remarks}</span>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell>
          {item.attachments.length > 0 ? (
            <>
              <Tooltip title="View Attachments">
                <span
                  className="center"
                  onClick={() => {
                    downAllAttach()
                  }}
                >
                  <AttachFileIcon className="attach-icon" />

                  <a href="#" style={{ color: "#0e7fe1" }}>
                    {item.attachments.length}
                  </a>
                </span>
              </Tooltip>
            </>
          ) : (
            item.attachments.length
          )}
        </TableCell>
      </TableRow>
      <Dialog open={open} onClose={handleClose} id="htDialog">
        <ConfirmDialog
          handleClose={() => handleClose()}
          handleDeleteRow={handleDeleteRow}
        />
      </Dialog>

      {/* edit form dialog */}
      <Dialog
        position="relative"
        maxWidth="lg"
        open={openEditForm}
        onClose={closeAddForm}
      >
        <SrTaskForm
          formTitle={formTitle}
          closeAddForm={() => closeAddForm()}
          props={props}
          editFormId={editFormId}
          editFormData={editFormData}
          attachments={attachments}
          item={item}
          headerTsn={headerTsn}
          headerCsn={headerCsn}
          updateFormData={updateFormData}
          fileUploadData={fileUploadData}
          currentRecordPerPage={currentRecordPerPage}
        />
      </Dialog>
      <Dialog
        open={openGetAttachmentsDialogue}
        onClose={handleCloseAttachmentDialogue}
        id="htDialog"
      >
        <ShowAttachments
          handleClose={() => handleCloseAttachmentDialogue()}
          handleShowAttachments={AllAttachmentsforDialogue}
          openAttachment={openAttachment}
          files={{ title: "SR", extension: "zip", key: "" }}
          downloadAllApi={downloadAllApi}
          props={props}
          moduleId={moduleId}
        />
      </Dialog>
    </>
  )
}
export default withRouter(SrList)
