import React, { Component } from "react"
import { Tab, Tabs } from "@material-ui/core"
import { trackActivity } from "../../../utils/mixpanel"
class AssetListTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
    }
  }
  render() {
    const { item, tabIndex, onTabChange } = this.props
    return (
      <Tabs
        value={tabIndex}
        onChange={onTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          label={`ALL (${item.asset_count.aircraft})`}
          value={0}
          onClick={() =>
            trackActivity("Item Clicked", {
              page_title: "Asset Listing Tab",
              tab_name: "All",
            })
          }
        />
        <Tab
          label={`Aircraft (${item.asset_count.aircraft})`}
          value={1}
          onClick={() =>
            trackActivity("Item Clicked", {
              page_title: "Asset Listing Tab",
              tab_name: "Aircraft",
            })
          }
        />
        {/* <Tab label={`Engine (${item.asset_count.engine})`} value={2}onClick={() => trackActivity('Item Clicked',
                      {
                          page_title: 'Asset Listing Tab',
                          tab_name: 'Engine'
                      })}
                /> */}
      </Tabs>
    )
  }
}
export default AssetListTab
