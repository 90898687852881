import React, { Component, Fragment } from "react"
import { withSnackbar } from "notistack"
import { Footer, BackBanner, LoginForm } from "../components"
import { updateAuthInfoState, loginApi, lessorUpdateApi } from "../apiServices"
import { fieldValidation } from "../../../utils/formValidation"
import { regexConstants } from "../../../constants/regEx"
import { Redirect } from "react-router-dom"

class Login extends Component {
  constructor(props) {
    super(props)
    this.updateAuthInfoState = updateAuthInfoState.bind(this)
    this.lessorUpdateApi = lessorUpdateApi.bind(this)
    this.loginApi = loginApi.bind(this)
    this.state = {
      authInfo: {
        email: "",
        password: "",
      },
      error: {},
      errorCode: {
        email: {
          0: "",
          1: "Please enter Email",
          2: "Email must be up to 30 characters",
          3: "Please enter valid Email",
          4: "Please enter valid Email",
        },
        emailObj: {
          required: true,
          maxLength: 30,
          regexPattern: regexConstants.email,
        },
        password: {
          0: "",
          1: "Please enter Password",
          2: "Password must be between 8 and 14 characters",
        },
        passwordObj: {
          required: true,
          minLength: 8,
          maxLength: 14,
        },
      },
      formSubmitLoader: false,
    }
  }

  handleChange = (value, key) => {
    this.updateAuthInfoState(value, key)
  }
  onLogin = () => {
    const { errorCode, authInfo } = this.state
    let validationInputs = {
      email:
        errorCode["email"][
          fieldValidation({
            ...errorCode["emailObj"],
            fieldval: authInfo.email,
          })
        ],
      password:
        errorCode["password"][
          fieldValidation({
            ...errorCode["passwordObj"],
            fieldval: authInfo.password,
          })
        ],
    }
    if (authInfo.email.length > errorCode["emailObj"].maxLength) {
      validationInputs.email = errorCode["email"][2]
    }

    if (authInfo.password.length > errorCode["passwordObj"].maxLength) {
      validationInputs.password = errorCode["password"][2]
    }

    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.loginApi(this.props, this.state.authInfo)
    } else {
      this.setState({ error: validationInputs })
    }
  }
  render() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    if (userInfo == null || userInfo == undefined) {
      const { authInfo, error, formSubmitLoader } = this.state
      return (
        <Fragment>
          <BackBanner>
            <div className="auth-form-wrapper">
              <h2 className="form-title">Login to your account</h2>
              <LoginForm
                error={error}
                authInfo={authInfo}
                handleChange={(value, key) => this.handleChange(value, key)}
                onLogin={() => this.onLogin()}
                formSubmitLoader={formSubmitLoader}
              />
            </div>
          </BackBanner>
          <Footer />
        </Fragment>
      )
    } else if (
      userInfo.user &&
      userInfo.user.name &&
      userInfo.user.designation
    ) {
      return <Redirect to="/select-lessor" />
    }
  }
}

export default withSnackbar(Login)
