import { IconButton, TableCell, TableRow, Tooltip, Dialog, } from "@material-ui/core";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AdTaskForm from "./AdTaskForm";
import { fillAdEditFormApi } from "../apiService";
import ConfirmDialog from "./ConfirmDialog";
// import ShowAttachments from "../../ShowAttachments";
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments";
import moment from "moment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { permissionCheckFnforCamo } from "../../../../utils";

const AdList = ({ item, index, handleDeleteRow, updateFormData, props, fileUploadData, currentRecordPerPage, downloadAllApi, headerTsn, headerCsn, }) => {
  const [open, setOpen] = useState(false);
  const [openEditForm, setEditForm] = useState(false);
  const [editFormId, setEditFormId] = useState(null);
  const [editFormData, setEditFormData] = useState(null);
  const [formTitle, setFormTitle] = useState("");
  const [attachments, setAttachments] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState({});
  const [showFullRemarks, setShowFullRemarks] = useState({});
  const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false);
  const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] =
    useState(null);
  const [moduleId, setmoduleId] = useState(null);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const closeAddForm = () => {
    setEditForm(false);
  };

  const handleEditRow = () => {
    fillAdEditFormApi(item, props).then((response) => {
      setEditFormData(response.data.data);
      setEditFormId(response.data.data.id);
      setAttachments(response.data.data.attachments);
      setFormTitle("Update Airworthiness Directives (ADs)");
      setEditForm(true);
    });
  };

  const downAllAttach = () => {
    fillAdEditFormApi(item, props).then((response) => {
      setmoduleId(response.data.data.id);
    });
    if (item.attachments.length > 0) {
      getAttachments(item.attachments);
    }
  };

  const openAttachment = (file) => {
    window.open(`https://qa.camo.sparta.aero:8223${file}`, "_blank");
  };

  const formatDate = (date) => {
    return moment(date).format("DD-MM-YYYY");
  };
  const toggleDescription = (id) => {
    setShowFullDescription({
      ...showFullDescription,
      [id]: !showFullDescription[id],
    });
  };
  const toggleRemarks = (id) => {
    setShowFullRemarks({
      ...showFullRemarks,
      [id]: !showFullRemarks[id],
    });
  };
  const getAttachments = (attachments) => {
    setAllAttachmentsforDialogue(attachments);
    setAttachmentsDialogue(true);
  };
  const handleCloseAttachmentDialogue = () => {
    setAttachmentsDialogue(false);
  };

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        style={{ cursor: "pointer" }}
        className={item.remainingValues_D < 0 || item.remainingValues_D == null ? "red-row" : "none"}
      >
        <TableCell> {permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "U", }) ? (
          <Tooltip title="Edit">
            <IconButton className="edit-icon" onClick={(e) => { handleEditRow(e); }}>
              <CreateOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : null}
          {permissionCheckFnforCamo({
            primaryKey: "ad_status",
            keyIndex: "D",
          }) ||
            permissionCheckFnforCamo({ primaryKey: "ad_status", keyIndex: "OD", userId: item.created_by, }) ? (
            <Tooltip title="Delete" arrow>
              <IconButton className="delete-icon" onClick={handleClick}>
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </TableCell>
        <TableCell>{item.ata ? item.ata : "--"}</TableCell>
        <TableCell>{item.ad_no ? item.ad_no : "--"}</TableCell>
        <TableCell>  {item.issuing_authority ? item.issuing_authority : "--"} </TableCell>
        <TableCell>{item.applicability ? item.applicability : "--"}</TableCell>
        <TableCell style={{ width: "200px", textAlign: "left" }}>
          {item.description && item.description.length > 150 ? (
            <div>
              {showFullDescription[item.id] ? (
                <span> {item.description}
                  <a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  >..less </a>
                </span>
              ) : (
                <span> {item.description.substring(0, 150)}
                  <a href="#" style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleDescription(item.id); }}  > ..more </a>
                </span>
              )}
            </div>
          ) : item.description && item.description.length <= 150 ? (
            <span>{item.description}</span>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell>{item.ad_type ? item.ad_type : "--"}</TableCell>
        <TableCell>{item.effectivity ? item.effectivity : "--"}</TableCell>
        <TableCell>{item.effective_date ? formatDate(item.effective_date) : "--"}  </TableCell>
        <TableCell> {item.dimension_type ? item.dimension_type : "--"}  </TableCell>
        <TableCell> {item.ad_compliance_status ? item.ad_compliance_status : "--"} </TableCell>
        <TableCell>{item.ad_compliance_tsn ? item.ad_compliance_tsn : "--"} </TableCell>
        <TableCell> {item.ad_compliance_csn ? item.ad_compliance_csn : "--"} </TableCell>
        <TableCell>{item.ad_compliance_date ? formatDate(item.ad_compliance_date) : "--"} </TableCell>
        <TableCell> {item.remaining.due_at ? item.dimension_type === "D" ? formatDate(item.remaining.due_at) : item.remaining.due_at : item.due_date}</TableCell>
        <TableCell>{item.sb_number ? item.sb_number : "--"}</TableCell>
        <TableCell>
          {item.attachments.length > 0 ? (
            <>
              <Tooltip title="View Attachments">
                <span className="center" onClick={() => { downAllAttach(); }} >
                  <AttachFileIcon className="attach-icon" />
                  <a href="#" style={{ color: "#0e7fe1" }}>  {item.attachments.length} </a>
                </span>
              </Tooltip>
            </>
          ) : (
            item.attachments.length
          )}
        </TableCell>
      </TableRow>
      <Dialog open={open} onClose={handleClose} id="htDialog">
        <ConfirmDialog
          handleClose={() => handleClose()}
          handleDeleteRow={handleDeleteRow}
        />
      </Dialog>

      {/* edit form dialog */}
      <Dialog
        position="relative"
        maxWidth="lg"
        open={openEditForm}
        onClose={closeAddForm}
      >
        <AdTaskForm
          formTitle={formTitle}
          closeAddForm={() => closeAddForm()}
          props={props}
          editFormId={editFormId}
          editFormData={editFormData}
          attachments={attachments}
          item={item}
          headerTsn={headerTsn}
          headerCsn={headerCsn}
          updateFormData={updateFormData}
          fileUploadData={fileUploadData}
          currentRecordPerPage={currentRecordPerPage}
        />
      </Dialog>
      <Dialog
        open={openGetAttachmentsDialogue}
        onClose={handleCloseAttachmentDialogue}
        id="htDialog"
      >
        <ShowAttachments
          handleClose={() => handleCloseAttachmentDialogue()}
          handleShowAttachments={AllAttachmentsforDialogue}
          openAttachment={openAttachment}
          files={{ title: "AMP", extension: "zip", key: "" }}
          downloadAllApi={downloadAllApi}
          props={props}
          moduleId={moduleId}
        />
      </Dialog>
    </>
  );
};
export default withRouter(AdList);
