import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

const ModsConfirmDeleteAttachment = ({
  handleCloseAttachment,
  deleteAttachmentFile,
  item,
}) => {
  return (
    <>
      <div class="ConfirmDialog">
        <DialogTitle>Confirm the action</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton>
            <Close onClick={handleCloseAttachment} style={{ fill: "white" }} />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>
            Do you really want to delete the selected Attachment?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleCloseAttachment}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              deleteAttachmentFile(item);
              handleCloseAttachment();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </div>
    </>
  );
};

export default ModsConfirmDeleteAttachment;
