import React, { Component } from "react";
import { withSnackbar } from "notistack";
import {
  getAMPComplianceApi,
  deleteAssetDetailRow,
  EditTaskFormApi,
  fillEditFromApi,
  UpdateTaskFormApi,
  uploadFileApi,
  importFileApi,
  exportAssetDetailListApi,
  DownloadAllApi,
} from "../apiServices";
import { Box, Button, Grid, IconButton } from "@material-ui/core";
import AmpSidebar from "../components/AmpSidebar";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import AmpSearchBar from "../components/AmpSearchBar";
import AmpTable from "../components/AmpTable";
import {
  convertFilterObject,
  removeEmptyKey,
  filterOptions,
  permissionCheckFnforCamo,
} from "../../../../utils";
import { AmpTableHead, assetFilterOps } from "./AmpTableHead";
import {
  EmptyCollection,
  PageLoader,
  AccessDenied,
} from "../../../../shared_elements";
import AmpList from "../components/AmpList";
import BasicDetailHeader from "../../BasicDetailHeader";
import RefreshIcon from "@material-ui/icons/Refresh";
import PieChartOutlinedIcon from "@material-ui/icons/PieChartOutlined";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

class AMPCompliance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addFormDialog: false,
      sidebarOpen: true,
      tabIndex: 0,
      pageLoader: false,
      sort: "",
      sort_by: "",
      assetsDetail: {
        list: [],
        pagination: {},
        asset_count: {},
      },
      headerTsn: "",
      headerCsn: "",
      currentRecordPerPage: 50,
    };
    this.getAMPComplianceApi = getAMPComplianceApi.bind(this);
    this.openAddForm = this.openAddForm.bind(this);
    this.closeAddForm = this.closeAddForm.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.handleDeleteRow = deleteAssetDetailRow.bind(this);
    this.handleEditRow = fillEditFromApi.bind(this);
    // this.handleEditRow = EditTaskFormApi.bind(this);
    this.UpdateTaskFormApi = UpdateTaskFormApi.bind(this);
    this.uploadFileApi = uploadFileApi.bind(this);
    this.importFileApi = importFileApi.bind(this);
    this.downloadAllApi = DownloadAllApi.bind(this);
    this.exportAssetDetailListApi = exportAssetDetailListApi.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.pullData();
  }

  pullData = (tsn, csn) => {
    //console.log(tsn)
    this.setState({ headerTsn: tsn });
    this.setState({ headerCsn: csn });
  };
  getData(item) {
    this.getAMPComplianceApi(
      { per_page: this.state.currentRecordPerPage },
      "skeletonLoader",
      this.props,
      item ? item : null
    );
  }

  openAddForm() {
    this.setState({ addFormDialog: true });
  }
  closeAddForm() {
    this.setState({ addFormDialog: false });
  }

  toggleSidebar() {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }
  createSortHandler = (sortField) => {
    console.log(sortField);
    if (sortField === "previous_taskCard") {
      sortField = "previous_LDND_Taskcard";
    } else if (sortField === "task_description") {
      sortField = "Task_Description";
    }

    const { sort, sort_by, filter, tabIndex, assetsDetail } = this.state;
    this.setState({
      sort: sortField,
      sort_by:
        sortField === sort ? (sort_by === "asc" ? "desc" : "asc") : "desc",
    })
    if (sortField === sort) {
      if (sort_by === "asc") {
        this.getAMPComplianceApi(
          {
            ...filter,
            sort: sortField,
            sort_by: "desc",
            per_page: assetsDetail.pagination.per_page,
            asset_type: tabIndex,
          },
          "pageLoader",
          this.props
        );
      } else {
        this.getAMPComplianceApi(
          {
            ...filter,
            sort: sortField,
            sort_by: "asc",
            per_page: assetsDetail.pagination.per_page,
            asset_type: tabIndex,
          },
          "pageLoader",
          this.props
        );
      }
    } else {
      this.getAMPComplianceApi(
        {
          ...filter,
          sort: sortField,
          sort_by: "asc",
          per_page: assetsDetail.pagination.per_page,
          asset_type: tabIndex,
        },
        "pageLoader",
        this.props
      );
    }
  };

  submitFilter = (filterOptions) => {
    const { tabIndex, applyingFilter } = this.state;
    this.getAMPComplianceApi(
      {
        ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions),
        asset_type: tabIndex,
      },
      "pageLoader",
      this.props
    );
  };

  searchResults = (item) => {
    if (item !== null) {
      this.getData(item);
    }
  };

  updateFormData = async (updateId, updatedData, props) => {
    await this.UpdateTaskFormApi(
      updateId,
      updatedData,
      props,
      this.state.currentRecordPerPage
    );
  };

  fileUploadData = async (uploadId, formdata, props, haveAttachments) => {
    await this.uploadFileApi(
      uploadId,
      formdata,
      props,
      this.state.currentRecordPerPage,
      haveAttachments
    );
  };

  importFile = (formData, props, isXLSX) => {
    this.importFileApi(
      formData,
      props,
      isXLSX,
      this.state.currentRecordPerPage
    );
  };

  exportAssetDetailListFunc = (props) => {
    this.exportAssetDetailListApi(props);
  };

  downloadAllFilesApi = (props) => {
    this.downloadAllApi(props);
  };

  refreshComp = () => {
    this.getData();
  };

  render() {
    const {
      tabIndex,
      applyingFilter,
      sort,
      sort_by,
      assetsDetail,
      pageLoader,
    } = this.state;
    const { aircraftTypes, engineTypes, lessees } = this.props;

    let filterOptions = assetFilterOps;
    if (tabIndex === 0 || tabIndex === 1) {
      filterOptions = {
        ...filterOptions,
        aircraft_type: {
          ...filterOptions.aircraft_type,
          options: aircraftTypes,
        },
      };
      if (tabIndex === 1) {
        delete filterOptions.engine_type;
      }
    }
    if (tabIndex === 0 || tabIndex === 2) {
      filterOptions = {
        ...filterOptions,
        engine_type: {
          ...filterOptions.engine_type,
          options: engineTypes,
        },
      };
      if (tabIndex === 2) {
        delete filterOptions.aircraft_type;
      }
    }

    filterOptions = {
      ...filterOptions,
      lessee: {
        ...filterOptions.lessee,
        options: lessees,
      },
    };

    return (
      <section className="amp-listing-section">
        {pageLoader ? <PageLoader /> : null}
        <Grid className="mt-0" spacing={3} container>
          <Grid className="pt-0 pr-0" xs={12} item>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <h3 className="module-heading">AMP/MRB Compliance</h3>
              </Box>
              <Box className="mr-18">
                <Link to="/dashboard">
                  <Tooltip title="Analytics">
                    <IconButton className="p-5px" color="primary">
                      <PieChartOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Link>
                <Tooltip title="Refresh">
                  <IconButton
                    className="p-5px"
                    color="primary"
                    onClick={this.refreshComp}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <BasicDetailHeader props={this.props} func={this.pullData} />
            {permissionCheckFnforCamo({
              primaryKey: "amp_compliance",
              keyIndex: "R",
            }) ? (
              <div className="custom-border">
                <AmpSearchBar
                  currentRecordPerPage={this.state.currentRecordPerPage}
                  headerTsn={this.state.headerTsn}
                  headerCsn={this.state.headerCsn}
                  applyingFilter={applyingFilter}
                  filterOptions={filterOptions}
                  tabIndex={tabIndex}
                  pageLoader={"pageLoader"}
                  props={this.props}
                  importFile={this.importFile}
                  fileUploadData={this.fileUploadData}
                  searchData={(e) => this.searchResults(e.target.value)}
                  exportReportFn={(file) =>
                    this.exportAssetDetailListFunc(
                      {
                        download: file.extension,
                        ...{
                          ...convertFilterObject(
                            removeEmptyKey(applyingFilter),
                            filterOptions
                          ),
                          asset_type: tabIndex,
                        },
                      },
                      "pageLoader"
                    )
                  }
                />
                <AmpTable
                  currentRecordPerPage={this.state.currentRecordPerPage}
                  heads={AmpTableHead}
                  sort={sort}
                  sort_by={sort_by}
                  data={assetsDetail.list.map((item, index) => (
                    <AmpList
                      currentRecordPerPage={this.state.currentRecordPerPage}
                      key={index}
                      item={item}
                      index={index}
                      headerTsn={this.state.headerTsn}
                      headerCsn={this.state.headerCsn}
                      handleDeleteRow={(data) =>
                        this.handleDeleteRow(
                          item,
                          this.props,
                          this.state.currentRecordPerPage
                        )
                      }
                      updateFormData={this.updateFormData}
                      handleEditRow={(data) =>
                        this.handleEditRow(item, this.props)
                      }
                      props={this.props}
                      fileUploadData={this.fileUploadData}
                      downloadAllApi={(file) =>
                        this.downloadAllFilesApi(
                          {
                            download: file.extension,
                            ...{
                              ...convertFilterObject(
                                removeEmptyKey(applyingFilter),
                                filterOptions
                              ),
                              asset_type: tabIndex,
                              item,
                            },
                          },

                          "pageLoader"
                        )
                      }
                    />
                  ))}
                  createSortHandler={this.createSortHandler}
                  pagination={assetsDetail.pagination}
                  onChangePage={(event, newPage) =>
                    this.getAMPComplianceApi(
                      {
                        ...convertFilterObject(
                          removeEmptyKey({
                            ...applyingFilter,
                            asset_type: this.state.tabIndex,
                            per_page: assetsDetail.pagination.per_page,
                            page: newPage + 1,
                          }),
                          filterOptions
                        ),
                      },
                      "pageLoader",
                      this.props
                    )
                  }
                  onChangeRowsPerPage={(event) =>
                    this.setState(
                      { currentRecordPerPage: event.target.value },
                      () => {
                        this.getAMPComplianceApi(
                          {
                            ...convertFilterObject(
                              removeEmptyKey({
                                ...applyingFilter,
                                asset_type: this.state.tabIndex,
                                per_page: this.state.currentRecordPerPage,
                              }),
                              filterOptions
                            ),
                          },
                          "pageLoader",
                          this.props
                        );
                      }
                    )
                  }
                  noRecord={
                    assetsDetail.list.length ? null : !this.state.pageLoader ? (
                      <EmptyCollection title="No Records Found" />
                    ) : null
                  }
                />
              </div>
            ) : (
              <AccessDenied />
            )}
          </Grid>
        </Grid>
      </section>
    );
  }
}
export default withSnackbar(AMPCompliance);
