import React from "react"
import { Tooltip, IconButton, Button } from "@material-ui/core"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"

const DownloadXLSAssetDetail = ({ exportReportFn, files }) => {
  const handleClose = (event, files, triggerExport) => {
    if (triggerExport) {
      exportReportFn(files)
    }
  }

  return (
    <>
      <Button
        onClick={(event) => handleClose(event, files, "triggerExport")}
        files={{ title: "XLSX", extension: "xls", key: "" }}
      >
        <ExitToAppIcon className="import-task-icon" />
        {/* Export   */}
        {files.extension === "docx"}
        {files.title}
      </Button>
    </>
  )
}

export default DownloadXLSAssetDetail
