import { getLocalStorageInfo } from "../../../../utils";

export const SbTableHeadMain = [
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },

  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  {
    id: "SBCompliancedetails",
    label: "SB Compliance details",
    sortOption: false,
    colspan: 3,
  },
  {
    id: "SBNextDue",
    label: "SB Next Due",
    sortOption: false,
    colspan: 3,
  },
];

export const SbTableHead = [
  { id: "actions", label: "Actions", sortOption: false },
  { id: "ata_chapter", label: "ATA Chapter", sortOption: true },
  { id: "sb_ref_number", label: "SB Reference Number", sortOption: true },
  { id: "sb_issued_by", label: "SB Issued by", sortOption: true },
  { id: "sb_applicability", label: "SB Applicability", sortOption: true },
  { id: "description", label: "SB Description", sortOption: true },
  { id: "type", label: "SB Type", sortOption: false },
  { id: "effectivity", label: "SB Effectivity", sortOption: false },
  { id: "effectivity_date", label: "SB Effective Date", sortOption: false },
  { id: "sb_interval_type", label: "SB Compliance Period", sortOption: false },
  {
    id: "sb_compliance_status",
    label: "SB Compliance Status",
    sortOption: false,
  },
  { id: "TSN", label: "TSN", sortOption: false },
  { id: "CSN", label: "CSN", sortOption: false },
  { id: "DateofCompliance", label: "Date of Compliance", sortOption: false },

  { id: "due_at", label: "Due at", sortOption: false },
  { id: "remaining", label: "Remaining", sortOption: false },
  { id: "attachments", label: "Attachments" },
];

export const assetFilterOps = {
  aircraft_type: {
    inputType: "dropdown",
    placeholder: "Select Aircraft Type",
    title: "Aircraft Type",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  engine_type: {
    inputType: "dropdown",
    placeholder: "Select Engine Type",
    title: "Engine Type",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  lessee: {
    inputType: "dropdown",
    placeholder: "Select Lessee",
    title: "Lessee",
    options: [],
    labelKey: "name",
    valueKey: "slug",
    multiple: true,
  },
  lessor_name: {
    inputType: "text",
    placeholder: "Enter Lessor",
    title: "Lessor",
  },
  owner: {
    inputType: "text",
    placeholder: "Enter Owner ",
    title: "Owner",
  },
  ownership_type: {
    inputType: "dropdown",
    placeholder: "Select Ownership Type",
    title: "Ownership",
    options: [
      { label: "Owned", value: 1 },
      { label: "Managed", value: 2 },
    ],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
  registration: {
    inputType: "text",
    placeholder: "Enter Registeration Number",
    title: "Registeration Number",
  },
  portfolio: {
    inputType: "text",
    placeholder: "Enter Portfolio",
    title: "Portfolio",
  },
  serial_number: {
    inputType: "text",
    placeholder: "Enter Serial Number",
    title: "Serial Number",
  },
  status: {
    inputType: "dropdown",
    placeholder: "Select Status",
    title: "Status",
    options:
      getLocalStorageInfo() &&
      getLocalStorageInfo().defaultLessor &&
      getLocalStorageInfo().defaultLessor.id === 442
        ? [
            { label: "Prospect", value: "11" },
            { label: "In Storage", value: "10" },
            { label: "Lease Return", value: "9" },
            { label: "MOU Signed", value: "8" },
            { label: "MOU Issued", value: "7" },
            { label: "Lease Issued", value: "6" },
            { label: "Archive", value: "5" },
            { label: "Written Off/ Sold / Part out", value: "4" },
            { label: "OnGround", value: "3" },
            { label: "Off Lease", value: "2" },
            { label: "On Lease", value: "1" },
          ]
        : [
            { label: "Archive", value: "5" },
            { label: "On Lease", value: "1" },
            { label: "Off Lease", value: "2" },
            { label: "On Ground", value: "3" },
            { label: "Written Off/ Sold / Part out", value: "4" },
          ],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
};
