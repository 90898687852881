import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import defaultlessor from "../../../assets/images/default_lessor.png";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { getUserProfileDetails } from "../apiServices";
import config from "../../../config";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: false,
      pageLoader: false,
      userProfileDetails: {},
      masterUrl: config.api.networkInterface.endsWith("/")
        ? config.api.networkInterface.slice(0, -1)
        : config.api.networkInterface,
    };
    this.getUserProfileDetails = getUserProfileDetails.bind(this);
  }
  async componentDidMount() {
    getUserProfileDetails()
      .then((response) => {
        if (response.data.statusCode === 200) {
          this.setState({
            userProfileDetails: response.data.data,
            assignedRoles: "--",
          });
          this.getRoles();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getRoles = () => {
    if (
      this.state.userProfileDetails &&
      this.state.userProfileDetails.sparta_group.length > 0
    ) {
      const groupNames = this.state.userProfileDetails.sparta_group.map(
        (group) => group.name
      );
      const commaSeparatedNames = groupNames.join(", ");
      this.setState({
        assignedRoles: commaSeparatedNames,
      });
    }
  };

  render() {
    return (
      <section className="asset-listing-section">
        <div className="user-detail">
          <Box display="flex" style={{ alignItems: "center" }}>
            <Box flexGrow={1}>
              <h3 className="asset-list-heading">Profile</h3>
              <Card style={{ marginTop: "30px" }}>
                <CardContent>
                  <Grid container>
                    <Grid container xs={2}>
                      <Grid item xs={12} style={{ marginLeft: "20px" }}>
                        <img
                          src={
                            this.state.userProfileDetails &&
                            this.state.userProfileDetails.profile_pic
                              ? this.state.masterUrl +
                                this.state.userProfileDetails.profile_pic
                              : defaultlessor
                          }
                          alt="Default lessor"
                          className="profile-img"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={10}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <label className="label">Name</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.first_name
                              ? this.state.userProfileDetails.first_name
                              : ""}{" "}
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.last_name
                              ? this.state.userProfileDetails.last_name
                              : ""}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Designation</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.designation
                              ? this.state.userProfileDetails.designation
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Email</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.email
                              ? this.state.userProfileDetails.email
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Phone Number</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.contact_no
                              ? this.state.userProfileDetails.contact_no
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Resume</label>
                          <h6 style={{ fontSize: "1rem" }}>--</h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">
                            Alternate Phone Number
                          </label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {
                              this.state.userProfileDetails
                                .alternative_contact_no
                            }
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Category</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.category
                              ? this.state.userProfileDetails.category.label
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Department</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {/* {this.state.userProfileDetails && this.state.userProfileDetails.department.label} */}
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.department
                              ? this.state.userProfileDetails.department.label
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Profile Type</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.type
                              ? this.state.userProfileDetails.type.label
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Country</label>
                          <h6 style={{ fontSize: "1rem" }}>--</h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">
                            Overall Work Experience
                          </label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.experience
                              ? this.state.userProfileDetails.experience +
                                " Years"
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">City</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.city
                              ? this.state.userProfileDetails.city
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">License Issued By</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.license_issued_by
                              ? this.state.userProfileDetails.license_issued_by
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Daily Fee Rate</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.fee_rate
                              ? this.state.userProfileDetails.fee_rate
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">
                            License Type Endorsements
                          </label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.license_endorsements
                              ? this.state.userProfileDetails
                                  .license_endorsements
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Special Approvals</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.special_approvals
                              ? this.state.userProfileDetails.special_approvals
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Skill Sets</label>
                          <h6 style={{ fontSize: "1rem" }}>--</h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">License</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.licenses
                              ? this.state.userProfileDetails.licenses
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Language Proficiency</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.language_proficiency
                              ? this.state.userProfileDetails
                                  .language_proficiency
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Passport Issued By</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.passport_issued_by
                              ? this.state.userProfileDetails.passport_issued_by
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Roles</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.assignedRoles}
                          </h6>
                        </Grid>
                        <Grid item xs={6}>
                          <label className="label">Special Trainings</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.special_trainings
                              ? this.state.userProfileDetails.special_trainings
                              : "--"}
                          </h6>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                          <label className="label">Additional Remarks</label>
                          <h6 style={{ fontSize: "1rem" }}>
                            {this.state.userProfileDetails &&
                            this.state.userProfileDetails.additional_remarks
                              ? this.state.userProfileDetails.additional_remarks
                              : "--"}
                          </h6>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  aircraftTypes: state.shareReducer.aircraftTypes,
  engineTypes: state.shareReducer.engineTypes,
  lessees: state.shareReducer.lessees,
  operators: state.shareReducer.operators,
  regions: state.shareReducer.regions,
  portfolios: state.shareReducer.portfolios,
  pltConstants: state.shareReducer.pltConstants,
  aircraftMntGrp: state.shareReducer.aircraftMntGrp,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
