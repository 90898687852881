const env = process.env.REACT_APP_ENV
export const appConfig = {
  api: {
    networkInterface: {
      development: "https://dev.beta.sparta.aero:8222/",
      staging: "https://qa.camo.sparta.aero:8223/",
      production: "",
    }[env],
    // add more here
    s3ImageUrl: {
      development:
        "https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/",
      staging:
        "https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/",
      production:
        "https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/",
    }[env],
    // appUrl: {
    //   development: "http://local.sparta.aero:3000/",
    //   staging: "https://qa.records.sparta.aero/",
    //   production: "https://records.sparta.aero/",
    // }[env],
  },
  mixpanel: {
    key: {
      production: "8bfa65c7f2e1b2cab185b450096d8942",
      staging: "c4e6387ced1f81d89691c3c39701abfc",
      development: "c4e6387ced1f81d89691c3c39701abfc",
    }[env],
  },
  domain: {
    subDomian: {
      development: "https://dev.console.sparta.aero/",
      staging: "https://qa.console.sparta.aero/",
      production: "https://sparta.aero/",
    }[env],
    authDomain: {
      development: "development",
      staging: "qa",
      production: "sparta.aero",
    }[env],
  },
}

export default appConfig
