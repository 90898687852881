import axios from 'axios';
import config from '../config';
import { getGlobalCookie } from './';
var axiosInstance = axios.create();
axiosInstance.defaults.baseURL = config.api.networkInterface;
axiosInstance.interceptors.request.use(function (config) {
  let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if(lessorAccess){
    config['headers']['Authorization'] = lessorAccess.access;
  }
  if (userInfo) {
    config['headers']['Authorization'] = userInfo.access;
  }
  config['headers']['Access-Control-Allow-Origin'] = "*";
  return config;
},function (error) {
  return Promise.reject(error);
});
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return error.response;
  }
);
export default axiosInstance;
