import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { withSnackbar } from "notistack"
import { Button, Grid, Menu, MenuItem, Paper } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import { STabsLoader, STableLoader } from "../../../shared_elements/loaders"
import {TableListComp,PageLoader,FilterUIComp,EmptyCollection,AssetAddDropdown,ExportManu,LicenseFailModal,AccessDenied,
} from "../../../shared_elements"
import { AssetList, AssetListTab, AddAircraft, AddEngine } from "../components"
import { convertFilterObject, removeEmptyKey, permissionCheckFn, permissionCheckFnforCamo, checkApiStatus,} from "../../../utils"
import {getAircraftTypesAc,getEngineTypesAc,getLesseeListAc,getOperatorListAc,getRegionListAc,getPlatformConstantsAc,getPortfolioListAc,getAircraftMntProgramAc,getLeaseStatus,getSubStatus,
} from "../../../shared_elements/actionCreators"
import { assetListHd, maintenanceFilterOps } from "../"
import {getAssetListApi,exportAssetListApi,addNewAssetApi,} from "../apiServices"
import { trackActivity } from "../../../utils/mixpanel"
import DownloadXlsx from "../../../shared_elements/components/DownloadXlsx"
import DownloadPdf from "../../../shared_elements/components/DownloadPdf"
import SaveAltIcon from "@material-ui/icons/SaveAlt"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"

class AssetListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      skeletonLoader: false,
      pageLoader: false,
      addAssetType: "",
      addAssetModal: false,
      licenseModal: false,
      licenseModalMessage: "",
      tabIndex: 0,
      sort: "",
      sort_by: "",
      assets: {
        list: [],
        pagination: {},
        asset_count: {},
      },
      filter: {},
      applyingFilter: {},
      ownersList: [],
      menuAnchorEl: null,
    }
    this.getAssetListApi = getAssetListApi.bind(this)
    this.exportAssetListApi = exportAssetListApi.bind(this)
    this.addNewAssetApi = addNewAssetApi.bind(this)
  }

  componentDidMount() {
    this.getAssetListApi({ per_page: 50 }, "skeletonLoader")
    this.props.getPlatformConstants()
    this.props.getLesseeList()
    this.props.getOperatorList()
    this.props.getRegionList()
    this.props.getPortfolioList()
    this.props.getEngineTypes()
    this.props.getAircraftTypes()
    this.props.getLeaseStatus()
    this.props.getSubStatus()
    trackActivity("Page Visited", {
      page_title: "Asset Listing",
    })
  }
  addAircraftAsset = (props, url, payload) => {
    this.addNewAssetApi(props, url, payload).then((response) => {
      this.setState({ formSubmitLoader: false })
      if (checkApiStatus(response)) {
        this.setState({ addAssetModal: false })
        this.getAssetListApi({ per_page: 50 }, "skeletonLoader")
        this.props.enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        })
        trackActivity("Item Added", {
          asset_type: "Aircraft",
          page_title: "Asset Listing",
        })
      } else if (response && response.data && response.data.status === "FAIL") {
        this.props.enqueueSnackbar(response.data.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        })
      }
    })
  }
  changeTabs = (event, newValue) => {
    this.setState((prevState) => ({
      ...prevState,
      tabIndex: newValue,
    }))
    this.getAssetListApi(this.props, { asset_type: newValue }, "pageLoader")
  }

  onTabChange = (e, newValue) => {
    const { assets } = this.state
    this.setState({ tabIndex: newValue, applyingFilter: {}, filter: {} })
    if (newValue === 0) {
      this.getAssetListApi(
        { per_page: assets.pagination.per_page },
        "pageLoader"
      )
    } else {
      this.getAssetListApi(
        { asset_type: newValue, per_page: assets.pagination.per_page },
        "pageLoader"
      )
    }
  }

  createSortHandler = (sortField) => {
    if (sortField === "current_registration_number") {
      sortField = "registration"
    } else if (sortField === "location") {
      sortField = "country"
    } else if (sortField === "lessor") {
      sortField = "lessor_name"
    }

    const { sort, sort_by, filter, tabIndex, assets } = this.state
    this.setState({
      sort: sortField,
      sort_by:
        sortField === sort ? (sort_by === "asc" ? "desc" : "asc") : "asc",
    })
    if (sortField === sort) {
      if (sort_by === "asc") {
        this.getAssetListApi(
          {
            ...filter,
            sort: sortField,
            sort_by: "desc",
            per_page: assets.pagination.per_page,
            asset_type: tabIndex,
          },
          "pageLoader"
        )
      } else {
        this.getAssetListApi(
          {
            ...filter,
            sort: sortField,
            sort_by: "asc",
            per_page: assets.pagination.per_page,
            asset_type: tabIndex,
          },
          "pageLoader"
        )
      }
    } else {
      this.getAssetListApi(
        {
          ...filter,
          sort: sortField,
          sort_by: "asc",
          per_page: assets.pagination.per_page,
          asset_type: tabIndex,
        },
        "pageLoader"
      )
    }
  }

  toggleAssetType = (addAssetType) => {
    this.setState({ addAssetType: addAssetType, addAssetModal: true })
  }
  applyFilter = (keyParam, value) => {
    this.setState((prevState) => ({
      ...prevState,
      applyingFilter: {
        ...prevState.applyingFilter,
        [keyParam]: value,
      },
    }))
  }

  removeFilter = (keyParam) => {
    const { filter, applyingFilter } = this.state
    let query = Object.assign({}, filter)
    delete query[keyParam]
    this.getAssetListApi(
      {
        ...query,
        asset_type: this.state.tabIndex,
        per_page: this.state.assets.pagination.per_page,
      },
      "pageLoader"
    )
    this.setState({ applyingFilter: delete applyingFilter[keyParam] })
  }
  submitFilter = (filterOptions) => {
    const { tabIndex, applyingFilter } = this.state
    this.getAssetListApi(
      {
        ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions),
        asset_type: tabIndex,
      },
      "pageLoader"
    )
  }
  clearFilter = () => {
    const { tabIndex } = this.state
    this.getAssetListApi({ asset_type: tabIndex }, "pageLoader")
    this.setState({ applyingFilter: {} })
  }

  onHandleClick = (event) => {
    this.setState({
      menuAnchorEl: event.currentTarget,
    })
  }
  onHandleClose = () => {
    this.setState({
      menuAnchorEl: null,
    })
  }

  render() {
    const openMenu = Boolean(this.state.menuAnchorEl)
    const {skeletonLoader,pageLoader,addAssetType,addAssetModal,assets,tabIndex,filter,applyingFilter,sort,sort_by,ownersList,licenseModal,licenseModalMessage} = this.state
    const { portfolios, regions, aircraftTypes, engineTypes, pltConstants, lessees, operators, aircraftMntGrp, leaseStatus, subStatus} = this.props
    let filterOptions = maintenanceFilterOps
    if (tabIndex === 0 || tabIndex === 1) {
      filterOptions = {
        ...filterOptions,
        aircraft_type: {
          ...filterOptions.aircraft_type,
          options: aircraftTypes,
        },
      }
      if (tabIndex === 1) {
        delete filterOptions.engine_type
      }
    }
    if (tabIndex === 0 || tabIndex === 2) {
      filterOptions = {
        ...filterOptions,
        engine_type: {
          ...filterOptions.engine_type,
          options: engineTypes,
        },
      }
      if (tabIndex === 2) {
        delete filterOptions.aircraft_type
      }
    }

    filterOptions = {
      ...filterOptions,
      lessee: {
        ...filterOptions.lessee,
        options: lessees,
      },
    }
    return (
      <section className="asset-listing-section">
        <div style={{ width: "100%" }}>
          <Box display="flex" style={{ alignItems: "center" }}>
            <Box flexGrow={1}>
              <h3 className="asset-list-heading">Assets</h3>
              {skeletonLoader ? (
                <STabsLoader count={1} />
              ) : permissionCheckFn({
                  primaryKey: "technical_specs",
                  secondaryKey: "overview",
                  keyIndex: "C",
                }) ? (
                <span className="add-asset-cta">
                  <AssetAddDropdown toggleAssetType={this.toggleAssetType} />
                </span>
              ) : null}
            </Box>

            <Box>
              {assets.list.length ? (
                <div className="d-flex">
                  <div className="asset-export">
                    {permissionCheckFnforCamo({
                      primaryKey: "asset_management",
                      keyIndex: "R",
                    }) ? (
                      <Button
                        id="basic-button"
                        aria-controls={openMenu ? "basic-menu" : ""}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? "true" : ""}
                        onClick={this.onHandleClick}
                        startIcon={<SaveAltIcon />}
                      >
                        EXPORT
                      </Button>
                    ) : null}
                    <Menu
                      id="basic-menu"
                      anchorEl={this.state.menuAnchorEl}
                      open={openMenu}
                      onClose={this.onHandleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem className="export-list">
                        <DownloadXlsx
                          title="Export Exel"
                          files={{ title: "EXCEL", extension: "xls", key: "" }}
                          exportReportFn={(file) =>
                            this.exportAssetListApi(
                              {
                                download: file.extension,
                                ...{
                                  ...convertFilterObject(
                                    removeEmptyKey(applyingFilter),
                                    filterOptions
                                  ),
                                  asset_type: tabIndex,
                                },
                              },
                              "pageLoader"
                            )
                          }
                        />
                      </MenuItem>
                      <MenuItem className="export-list">
                        <DownloadPdf
                          title="Export PDF"
                          files={{ title: "PDF", extension: "pdf", key: "" }}
                          exportReportFn={(file) =>
                            this.exportAssetListApi(
                              {
                                download: file.extension,
                                ...{
                                  ...convertFilterObject(
                                    removeEmptyKey(applyingFilter),
                                    filterOptions
                                  ),
                                  asset_type: tabIndex,
                                },
                              },
                              "pageLoader"
                            )
                          }
                        />
                      </MenuItem>
                    </Menu>

                    {/* <ExportManu
                  title="Export Asset List"
                  files={[{title:'Excel', extension: 'xls', key:''}, {title:'PDF', extension: 'pdf', key:''}]}
                  exportReportFn={(file) => this.exportAssetListApi({download:file.extension, ...{ ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions), asset_type: tabIndex}}, 'pageLoader')}
                /> */}
                  </div>

                  <div>
                    <span className="add-asset-cta">
                      <AssetAddDropdown
                        toggleAssetType={this.toggleAssetType}
                      />
                    </span>
                  </div>
                </div>
              ) : null}
            </Box>
          </Box>
        </div>
        {pageLoader ? <PageLoader /> : null}

        {permissionCheckFnforCamo({
          primaryKey: "asset_management",
          keyIndex: "R",
        }) ? (
          <div>
            {skeletonLoader ? (
              <STabsLoader count={3} />
            ) : (
              <AssetListTab
                item={assets}
                onTabChange={this.onTabChange}
                tabIndex={tabIndex}
              />
            )}
          </div>
        ) : null}

        <Fragment>
          {skeletonLoader ? (
            <STableLoader count={7} />
          ) : (
            <Fragment>
              {permissionCheckFnforCamo({
                primaryKey: "asset_management",
                keyIndex: "R",
              }) ? (
                <div>
                  <Paper className="plt-filter-comp d-flex align-items-center">
                    <FilterUIComp
                      filter={filter}
                      applyingFilter={applyingFilter}
                      removeFilter={this.removeFilter}
                      removeAllFilter={() => {
                        this.setState({ applyingFilter: {} }, () =>
                          this.getAssetListApi(
                            {
                              asset_type: this.state.tabIndex,
                              per_page: assets.pagination.per_page,
                            },
                            "pageLoader"
                          )
                        )
                      }}
                      submitFilter={() =>
                        this.getAssetListApi(
                          {
                            ...convertFilterObject(
                              removeEmptyKey({
                                ...applyingFilter,
                                asset_type: this.state.tabIndex,
                                per_page: assets.pagination.per_page,
                              }),
                              filterOptions
                            ),
                          },
                          "pageLoader"
                        )
                      }
                      applyFilter={this.applyFilter}
                      filterMenu={filterOptions}
                    />
                  </Paper>

                  <TableListComp
                    heads={assetListHd}
                    sort={sort}
                    sort_by={sort_by}
                    data={assets.list.map((item, index) => (
                      <AssetList
                        key={index}
                        item={item}
                        index={index}
                        props={this.props}
                      />
                    ))}
                    createSortHandler={this.createSortHandler}
                    pagination={assets.pagination}
                    onChangePage={(event, newPage) =>
                      this.getAssetListApi(
                        {
                          ...convertFilterObject(
                            removeEmptyKey({
                              ...applyingFilter,
                              asset_type: this.state.tabIndex,
                              per_page: assets.pagination.per_page,
                              page: newPage + 1,
                            }),
                            filterOptions
                          ),
                        },
                        "pageLoader"
                      )
                    }
                    onChangeRowsPerPage={(event) =>
                      this.getAssetListApi(
                        {
                          ...convertFilterObject(
                            removeEmptyKey({
                              ...applyingFilter,
                              asset_type: this.state.tabIndex,
                              per_page: event.target.value,
                            }),
                            filterOptions
                          ),
                        },
                        "pageLoader"
                      )
                    }
                    noRecord={
                      assets.list.length ? null : (
                        <EmptyCollection title="No Records Found" />
                      )
                    }
                  />
                </div>
              ) : (
                <AccessDenied></AccessDenied>
              )}
            </Fragment>
          )}
        </Fragment>
        {addAssetType === "Aircraft" ? (
          <AddAircraft
            fetchMntGrops={(aircraftId) =>
              this.props.getAircraftMntProgram(aircraftId)
            }
            addAircraftAsset={this.addAircraftAsset}
            aircraftMntGrp={aircraftMntGrp}
            leaseStatus={leaseStatus}
            subStatus={subStatus}
            portfolios={portfolios}
            ownersList={ownersList}
            lessees={lessees}
            operators={operators}
            pltConstants={pltConstants}
            aircraftTypes={aircraftTypes}
            addAssetType={addAssetType}
            addAssetModal={addAssetModal}
            toggleAssetType={() =>
              this.setState({ addAssetType: "", addAssetModal: false })
            }
            toggleLicenseModalFn={(modal, message) =>
              this.setState({
                licenseModal: modal,
                licenseModalMessage: message,
                addAssetModal: false,
              })
            }
          />
        ) : null}
        {addAssetType === "Engine" && addAssetModal ? (
          <AddEngine
            portfolios={portfolios}
            ownersList={ownersList}
            regions={regions}
            lessees={lessees}
            operators={operators}
            pltConstants={pltConstants}
            engineTypes={engineTypes}
            addAssetType={addAssetType}
            addAssetModal={addAssetModal}
            toggleAssetType={() =>
              this.setState({ addAssetType: "", addAssetModal: false })
            }
            toggleLicenseModalFn={(modal, message) =>
              this.setState({
                licenseModal: modal,
                licenseModalMessage: message,
                addAssetModal: false,
              })
            }
          />
        ) : null}
        <LicenseFailModal
          licenseModalMessage={licenseModalMessage}
          licenseModal={licenseModal}
          toggleModalFn={() =>
            this.setState({ licenseModal: false, licenseModalMessage: "" })
          }
        />
      </section>
    )
  }
}
const mapStateToProps = (state) => ({
  aircraftTypes: state.shareReducer.aircraftTypes,
  engineTypes: state.shareReducer.engineTypes,
  lessees: state.shareReducer.lessees,
  operators: state.shareReducer.operators,
  regions: state.shareReducer.regions,
  portfolios: state.shareReducer.portfolios,
  pltConstants: state.shareReducer.pltConstants,
  aircraftMntGrp: state.shareReducer.aircraftMntGrp,
  leaseStatus: state.shareReducer.leaseStatus,
  subStatus: state.shareReducer.subStatus,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAircraftTypes: (props, query) =>
      dispatch(getAircraftTypesAc(props, query)),
    getRegionList: () => dispatch(getRegionListAc()),
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
    getLesseeList: () => dispatch(getLesseeListAc()),
    getOperatorList: () => dispatch(getOperatorListAc()),
    getPortfolioList: () => dispatch(getPortfolioListAc()),
    getAircraftMntProgram: (aircraftId) =>
      dispatch(getAircraftMntProgramAc(aircraftId)),
    getPlatformConstants: () =>
      dispatch(
        getPlatformConstantsAc({
          constant_types: [
            "asset_status",
            "credit_classification",
            "engine_position",
            "lg_position",
          ],
        })
      ),
    getLeaseStatus: () =>
      dispatch(getLeaseStatus({ constant_types: ["lease_status"] })),
    getSubStatus: () =>
      dispatch(getSubStatus({ constant_types: ["asset_sub_status"] })),
  }
}
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(AssetListing)
)
