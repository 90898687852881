import { Button, Menu, MenuItem } from "@material-ui/core"
import React from "react"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"

const CamoMgmtDropdown = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div>
      <Button
        className="btn btn-primary"
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disabled
        // onClick={handleClick}
        // endIcon={<KeyboardArrowDownIcon />}
      >
        CAMO Management
      </Button>
      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>CAMO Management</MenuItem>
      </Menu> */}
    </div>
  )
}
export default CamoMgmtDropdown
