import React, { Component, Fragment } from "react"
import { Label } from "@material-ui/icons"
import {Button,Dialog,DialogActions,DialogContent,DialogTitle,FormControl,FormControlLabel,FormLabel,Grid,InputLabel,MenuItem,Radio,RadioGroup,Select,TextField,Tooltip,} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { formValues, taskErrorCode } from "../containers/SrTaskFormData"
import {KeyboardDatePicker,MuiPickersUtilsProvider,} from "@material-ui/pickers"
import moment from "moment"
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import MomentUtils from "@date-io/moment"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined"
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined"
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined"
import { addTaskFormApi, deleteAttachment } from "../apiService"
import SrConfirmDeleteAttachment from "./SrConfirmDeleteAttachment"
import { fieldValidation } from "../../../../utils/formValidation"
import { permissionCheckFnforCamo } from "../../../../utils"

class SrTaskForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: {},
      data: formValues,
      regex: "^[0-9]+$",
      numberError: false,
      selectLastDDate: null,
      selectNextDueDate: null,
      attachments: [],
      btnStatus: "",
      newUploadedAttachments: [],
      open: false,
      deleteAttachmentId: 0,
      showSuccessUpload: false,
      // repairDue: null,
      remainingValue: "",
      globalValidationInputs: {},
      validatedFields: [],
    }

    this.addTaskFormApi = addTaskFormApi.bind(this)
    this.dropDownChange = this.dropDownChange.bind(this)
    this.dropDownChange1 = this.dropDownChange1.bind(this)
    this.dropDownChange2 = this.dropDownChange2.bind(this)
  }

  componentDidMount = () => {
    if (this.props.editFormId) {
      this.fillEditData()
    }
  }

  onFieldChange = (keyParam, value) => {
    const { data } = this.state
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [keyParam]: value,
      },
    }))
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        remaining_val: this.state.remainingValue,
      },
    }))
  }

  onRestErrorKey = (keyParam) => {
    this.setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: "",
      },
    }))
  }

  handleSubmit = (e) => {
    e.preventDefault()

    let validationInputs = {}
    const { data } = this.state
    this.setState({ file: "" })

    validationInputs = {
      ...validationInputs,
      ata_chapter: taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter, })],
      repair_ref_number: taskErrorCode["repair_ref_number"][fieldValidation({ ...taskErrorCode["repair_ref_number_obj"], fieldval: data.repair_ref_number, })],
      repair_type: taskErrorCode["repair_type"][fieldValidation({ ...taskErrorCode["repair_type_obj"], fieldval: data.repair_type, })],
      repair_description: taskErrorCode["repair_description"][fieldValidation({ ...taskErrorCode["repair_description_obj"], fieldval: data.repair_description, })],
      srm_ref_document: taskErrorCode["srm_ref_document"][fieldValidation({ ...taskErrorCode["srm_ref_document_obj"], fieldval: data.srm_ref_document, })],
    }
    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))

      // add task api
      const props = this.props.props

      const repairData = data
      repairData.asset_id = props.match.params.asset
      repairData.asset_type_id = props.match.params.assetType
      // Remove properties from the repairData object
      delete repairData.repair_DUE
      delete repairData.remaining_val
      repairData.last_done_fc =
      repairData && repairData.last_done_fc === "" ? null : repairData.last_done_fc
      repairData.last_done_fh =
      repairData && repairData.last_done_fh === "" ? null : repairData.last_done_fh
       addTaskFormApi(repairData, props).then(async (response) => {
        const uploadId = response.data.data.id
        if (uploadId) {
          await this.uploadFileTest(uploadId, props, false)
        }
      })
      if (this.state.btnStatus !== "addAnother") {
        this.setState((prevState) => ({
          error: {
            ...prevState.error,
            dimension_interval: "",
          },
        }))
        this.props.closeAddForm()
      } else {
        this.setState((prevState) => ({
          error: {
            ...prevState.error,
            // dimension_interval: "",
          },
        }))
        return false
      }
    } else {
      this.setState({ error: validationInputs })
    }
  }

  // edit task form
  fillEditData = () => {
    const updateId = this.props.editFormId
    this.setState({ attachments: this.props.attachments })
    if (updateId === undefined || updateId === null) {
      this.setState({
        data: {},
      })
    } else {
      this.setState({
        data: this.props.editFormData,
      })
    }
  }

  onFocusValidation = (keyParam) => {
    let validationInputs = {}
    const { data } = this.state
    validationInputs = {
      ...validationInputs,
      ata_chapter: taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter, })],
      repair_ref_number: taskErrorCode["repair_ref_number"][fieldValidation({ ...taskErrorCode["repair_ref_number_obj"], fieldval: data.repair_ref_number, })],
      repair_type: taskErrorCode["repair_type"][fieldValidation({ ...taskErrorCode["repair_type_obj"], fieldval: data.repair_type, })],
      repair_description: taskErrorCode["repair_description"][fieldValidation({ ...taskErrorCode["repair_description_obj"], fieldval: data.repair_description, })],
      srm_ref_document: taskErrorCode["srm_ref_document"][fieldValidation({ ...taskErrorCode["srm_ref_document_obj"], fieldval: data.srm_ref_document, })],
    }

    for (let key in validationInputs) {
      if (key === keyParam) {
        this.state.globalValidationInputs[key] = validationInputs[key]
      }
    }
    if (
      Object.keys(this.state.globalValidationInputs).every((k) => {
        return this.state.globalValidationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))
      this.setState({ globalValidationInputs: {} })
    } else {
      this.setState({ error: this.state.globalValidationInputs })
    }
  }

  updateData = async (e) => {
    e.preventDefault()

    let validationInputs = {}
    const { data } = this.state
    this.setState({ file: "" })

    validationInputs = {
      ...validationInputs,
      ata_chapter: taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter, })],
      repair_ref_number: taskErrorCode["repair_ref_number"][fieldValidation({ ...taskErrorCode["repair_ref_number_obj"], fieldval: data.repair_ref_number, })],
      repair_type: taskErrorCode["repair_type"][fieldValidation({ ...taskErrorCode["repair_type_obj"], fieldval: data.repair_type, })],
      repair_description: taskErrorCode["repair_description"][fieldValidation({ ...taskErrorCode["repair_description_obj"], fieldval: data.repair_description, })],
      srm_ref_document: taskErrorCode["srm_ref_document"][fieldValidation({ ...taskErrorCode["srm_ref_document_obj"], fieldval: data.srm_ref_document, })],
    }

    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))
      const updateId = this.props.editFormId
      const updatedData = this.state.data
      updatedData.remaining.due_at = updatedData.repair_DUE
      updatedData.remaining.remaining = updatedData.remaining_val
      if (updatedData.repair_status === "CLOSED") {
        delete updatedData.repair_DUE
        delete updatedData.remaining_val
      }
      updatedData.last_done_fc =
        updatedData && updatedData.last_done_fc === ""
          ? null
          : updatedData.last_done_fc
      updatedData.last_done_fh =
        updatedData && updatedData.last_done_fh === ""
          ? null
          : updatedData.last_done_fh
      const props = this.props.props
      this.setState({
        data: this.props.editFormData,
      })
      if (updateId) {
        await this.uploadFileTest(updateId, props, true)
      }
      await this.props.updateFormData(updateId, updatedData, props)
      this.props.closeAddForm()
    } else {
      this.setState({ error: validationInputs })
    }
  }

  uploadFileTest = async (uploadId, props, isUpdate) => {
    if (this.state.newUploadedAttachments.length > 0) {
      let newAttachmentObj = []
      let formdata = new FormData()
      const module_type_id = 7
      Object.keys(this.state.newUploadedAttachments).map((key, index) => {
        newAttachmentObj.push({
          file: this.state.newUploadedAttachments[key],
          file_name: this.state.newUploadedAttachments[key].name,
          file_type: this.state.newUploadedAttachments[key].type,
          module_type_id,
          module_id: uploadId,
        })
        formdata.append("[" + key + "]file", this.state.newUploadedAttachments[key])
        formdata.append("[" + key + "]file_type", this.state.newUploadedAttachments[key].type)
        formdata.append("[" + key + "]file_name", this.state.newUploadedAttachments[key].name)
        formdata.append("[" + key + "]module_id", uploadId)
        formdata.append("[" + key + "]module_type_id", module_type_id)
      })
      this.props.fileUploadData(uploadId, formdata, props, true)
      if (this.state.btnStatus === "addAnother") {
        this.resetBothAttachmentArray()
      }
    } else {
      if (this.state.btnStatus === "addAnother") {
        this.setState((prevState) => ({
          ...prevState,
          data: formValues,
          attachments: [],
        }))
        document.getElementById("asset-form").reset()
        this.props.fileUploadData(uploadId, null, props, false)
      } else {
        if (!isUpdate) {
          this.props.fileUploadData(uploadId, null, props, false)
        }
      }
    }
  }

  resetBothAttachmentArray = () => {
    this.setState((prevState) => ({
      ...prevState,
      data: formValues,
      attachments: [],
      newUploadedAttachments: [],
      open: false,
      deleteAttachmentId: 0,
    }))
    document.getElementById("asset-form").reset()
  }

  onUpload = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    for (let i = 0; i < chosenFiles.length; i++) {
      if (chosenFiles[i].size > 52428800) {
        this.props.props.enqueueSnackbar(
          `${chosenFiles[i].name} Maximum file size should be 50MB.`,
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        )
        delete chosenFiles[i]
        continue
      } else {
        this.handleUploadFiles(chosenFiles)
        // document.querySelector(".MuiDialog-paper").scrollTop = 800;
        this.setState({
          showSuccessUpload: true,
        })
        setTimeout(() => {
          if (this.state.attachments) {
            this.setState({
              showSuccessUpload: false,
            })
          }
        }, 2500)
      }
    }
  }

  handleUploadFiles = (files) => {
    const uploaded = [...this.state.newUploadedAttachments]
    const existingAttachments = [...this.state.attachments]
    files.some((file) => {
      uploaded.push(file)
    })
    files.map((file) => {
      var objc = {
        id: 0,
        module_id: 1,
        module_type_id: 7,
        file_name: file.name,
        file_type: file.type,
        file: "",
        name: file.name,
      }
      existingAttachments.push(objc)
    })
    this.setState({
      newUploadedAttachments: uploaded,
      attachments: existingAttachments,
    })
  }

  handleClickAttachment = (e, id) => {
    this.setState({ open: true })
    this.setState({ deleteAttachmentId: id })
  }

  handleCloseAttachment = (e) => {
    this.setState({ open: false })
    this.setState({ deleteAttachmentId: 0 })
  }
  deleteAttachmentFile = async (id) => {
    if (id !== undefined && id != 0) {
      const props = this.props.props
      deleteAttachment(id, props)
      var array = [...this.state.attachments]
      array = array.filter((item) => item.id !== id)
      this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
    }
  }
  removeAttachment = (e, obj) => {
    var array = [...this.state.attachments]
    var newuploadarray = [...this.state.newUploadedAttachments]
    var filteredArray = []
    newuploadarray.map((file) => {
      if (file.name == obj.file_name && file.type == obj.file_type) {
      } else {
        filteredArray.push(file)
      }
    })
    array = array.filter((item) => item !== obj)
    this.setState({
      newUploadedAttachments: filteredArray,
      attachments: array,
    })
  }

  saveAndAddAnother = async (e) => {
    await this.setState({ btnStatus: "addAnother" })
    await this.handleSubmit(e)
  }

  addFormData = async (e) => {
    await this.setState({ btnStatus: "add" })
    this.handleSubmit(e)
  }

  dropDownChange(e, keyParam, value) {
    //set selection to the value selected
    this.setState(
      (prevState) => (
        console.log(prevState),
        {
          ...prevState,
          data: {
            ...prevState.data,
            repair_type: e.target.value,
          },
        }
      )
    )
  }

  dropDownChange1(e, keyParam, value) {
    //set selection to the value selected
    this.setState(
      (prevState) => (
        console.log(prevState),
        {
          ...prevState,
          data: {
            ...prevState.data,
            repair_interval_type: e.target.value,
          },
        }
      )
    )
  }
  dropDownChange2(e, keyParam, value) {
    //set selection to the value selected
    const { data } = this.state
    this.setState(
      (prevState) => (
        console.log(prevState),
        {
          ...prevState,
          data: {
            ...prevState.data,
            repair_status: e.target.value,
          },
        }
      )
    )
  }

  nextDueValue = () => {
    const { data } = this.state
    if (data.repair_status === "CLOSED") {
      if (["FH" , "FC" , "D" ,"FH,D" , "FC,D" , "FC,FH,D","FC,FH"].includes(data.repair_interval_type)) {
        data.repair_DUE = ""
      }
    } else {
      if (data.repair_interval_type === "FH") {
        data.repair_DUE = Number.isInteger(+data.last_done_fh + +data.repair_interval) && (+data.last_done_fh + +data.repair_interval).toFixed(2)
      } else if (data.repair_interval_type === "FC") {
        data.repair_DUE = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2)
      } else if (data.repair_interval_type === "D" && data.last_done_date !== null) {
        data.repair_DUE = moment(data.last_done_date).add(data.repair_interval, "days").format("DD-MM-YYYY")
      }else if (data.repair_interval_type === "FC,D") {
        const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2)
        const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY");
        data.repair_DUE = fcDue < moment(dateDue, "DD-MM-YYYY") ? fcDue : dateDue;
      }else if (data.repair_interval_type === "FH,D") {
        const fhDue = Number.isInteger(+data.last_done_fh + +data.repair_interval) && (+data.last_done_fh + +data.repair_interval).toFixed(2)
        const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY");
        data.repair_DUE = fhDue < moment(dateDue, "DD-MM-YYYY") ? fhDue : dateDue;
      }else if (data.repair_interval_type === "FC,FH,D") {
        const fhDue = Number.isInteger(+data.last_done_fh + +data.repair_interval) && (+data.last_done_fh + +data.repair_interval).toFixed(2)
        const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2)
        const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY");
        if (fhDue <= fcDue && fhDue <= moment(dateDue, "DD-MM-YYYY")) {
          data.repair_DUE = fhDue;
        } else if (fcDue <= fhDue && fcDue <= moment(dateDue, "DD-MM-YYYY")) {
          data.repair_DUE = fcDue;
        } else {
          data.repair_DUE = dateDue;
        }
      }else if (data.repair_interval_type === "FC,FH") {
        const fhDue = Number.isInteger(+data.last_done_fh + +data.repair_interval) && (+data.last_done_fh + +data.repair_interval).toFixed(2)
        const fcDue = Number.isInteger(+data.last_done_fc + +data.repair_interval) && (+data.last_done_fc + +data.repair_interval).toFixed(2)
        data.repair_DUE = fcDue < fhDue ? fcDue : fhDue;
      }

    }
    return data.repair_DUE
  }
  remainingVal = () => {
    const { data } = this.state
    if (data.repair_status === "CLOSED") {
      if (["FH", "FC", "D", "FH,D", "FC,D", "FC,FH,D", "FC,FH"].includes(data.repair_interval_type)) {
        data.remaining_val = ""
      }
    } else {
      var date = new Date()
      const lDate = moment(data.last_done_date)
      const todayD = moment(date)
      let remDay = lDate.diff(todayD, "days")
      if (data.repair_interval_type === "D") {
        data.remaining_val = moment(remDay).add(data.repair_interval)
      } else if (data.repair_interval_type === "FC") {
        data.remaining_val = Number.isInteger(data.repair_DUE - this.props.headerCsn) && (data.repair_DUE - this.props.headerCsn).toFixed(2)
      } else if (data.repair_interval_type === "FH") {
        data.remaining_val = Number.isInteger(data.repair_DUE - this.props.headerTsn) && (data.repair_DUE - this.props.headerTsn).toFixed(2)
      } else if (data.repair_interval_type === "FC,D") {
        const fcRem_val = Number.isInteger(data.repair_DUE - this.props.headerCsn) && (data.repair_DUE - this.props.headerCsn).toFixed(2)
        const dRem_val = moment(remDay).add(data.dimension_interval_days)
        data.remaining_val = fcRem_val < moment(dRem_val, "DD-MM-YYYY") ? fcRem_val : dRem_val;
      } else if (data.repair_interval_type === "FH,D") {
        const fhRem_val = Number.isInteger(data.repair_DUE - this.props.headerTsn) && (data.repair_DUE - this.props.headerTsn).toFixed(2)
        const dRem_val = moment(remDay).add(data.dimension_interval_days)
        data.remaining_val = fhRem_val < moment(dRem_val, "DD-MM-YYYY") ? fhRem_val : dRem_val;
      } else if (data.repair_interval_type === "FC,FH,D") {
        const fcRem_val = Number.isInteger(data.repair_DUE - this.props.headerCsn) && (data.repair_DUE - this.props.headerCsn).toFixed(2)
        const fhRem_val = Number.isInteger(data.repair_DUE - this.props.headerTsn) && (data.repair_DUE - this.props.headerTsn).toFixed(2)
        const dRem_val = moment(remDay).add(data.dimension_interval_days)

        if (fhRem_val <= fcRem_val && fhRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
          data.remaining_val = fhRem_val;
        } else if (fcRem_val <= fhRem_val && fcRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
          data.remaining_val = fcRem_val;
        } else {
          data.remaining_val = dRem_val;
        }
      } else if (data.repair_interval_type === "FC,FH") {
        const fcRem_val = Number.isInteger(data.repair_DUE - this.props.headerCsn) && (data.repair_DUE - this.props.headerCsn).toFixed(2)
        const fhRem_val = Number.isInteger(data.repair_DUE - this.props.headerTsn) && (data.repair_DUE - this.props.headerTsn).toFixed(2)
        data.remaining_val = fcRem_val < fhRem_val ? fcRem_val : fhRem_val;
      }
    }
    return data.remaining_val
  }

  render() {
    const fileTypes = [ ".GIF", ".PDF", ".DOC", ".DOCX", ".XLSX", ".TXT", ".XLS", "image/*",]
    const { data, error } = this.state
    return (
      <Fragment>
        <div id="AmpTaskForm">
          <div id="adTaskForm">
            <DialogTitle className="just-center">
              {this.props.formTitle}
              <CloseIcon
                onClick={this.props.closeAddForm}
                className="close-icon"
              />
            </DialogTitle>
            <form
              id="asset-form"
              onSubmit={
                this.props.actionType === "add"
                  ? (e) => this.handleSubmit(e)
                  : (e) => this.updateData(e)
              }
            >
              <DialogContent>
                <Grid spacing={1} container id="ht-dropdown">
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          ATA chapter
                          <span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="ata_chapter"
                      id="ata_chapter"
                      value={data.ata_chapter ? data.ata_chapter : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("ata_chapter", input)
                          this.onRestErrorKey("ata_chapter")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      inputProps={{ maxLength: 15 }}
                      error={error.ata_chapter ? true : false}
                      helperText={error.ata_chapter ? error.ata_chapter : ""}
                      onBlur={() => this.onFocusValidation("ata_chapter")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Repair Ref Number{" "}
                          <span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="repair_ref_number"
                      id="repair_ref_number"
                      value={
                        data.repair_ref_number ? data.repair_ref_number : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("repair_ref_number", input)
                          this.onRestErrorKey("repair_ref_number")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      inputProps={{ maxLength: 25 }}
                      error={error.repair_ref_number ? true : false}
                      helperText={
                        error.repair_ref_number ? error.repair_ref_number : ""
                      }
                      onBlur={() => this.onFocusValidation("repair_ref_number")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item>
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">
                          Repair Type
                        </InputLabel>
                        <Select
                          labelId="repair_type"
                          id="repair_type"
                          name="repair_type"
                          variant="outlined"
                          error={error.repair_type ? true : false}
                          helperText={
                            error.repair_type ? error.repair_type : ""
                          }
                          value={data.repair_type}
                          onChange={this.dropDownChange}
                          onBlur={() => this.onFocusValidation("repair_type")}
                        >
                          <MenuItem value={"DENT"}>Dent</MenuItem>
                          <MenuItem value={"GOUGE"}>Gouge</MenuItem>
                          <MenuItem value={"SCRATCH"}>Scratch</MenuItem>
                          <MenuItem value={"CORROSION"}>Corrosion</MenuItem>
                          <MenuItem value={"PAINT DAMAGE"}>
                            Paint Damage
                          </MenuItem>
                          <MenuItem value={"CRACK"}>Crack</MenuItem>
                          <MenuItem value={"SCRIBE LINR DAMAGE"}>
                            Scribe Line Damage
                          </MenuItem>
                          <MenuItem value={"FOD"}>FOD</MenuItem>
                          <MenuItem value={"CAT A"}>Cat A</MenuItem>
                          <MenuItem value={"CAT B"}>Cat B</MenuItem>
                          <MenuItem value={"CAT C"}>Cat C</MenuItem>
                          <MenuItem value={"OTHERS"}>Others</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Repair Description{" "}
                          <span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="repair_description"
                      id="repair_description"
                      multiline
                      minRows={5}
                      error={error.repair_description ? true : false}
                      helperText={
                        error.repair_description ? error.repair_description : ""
                      }
                      value={
                        data.repair_description ? data.repair_description : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 255) {
                          this.onFieldChange("repair_description", input)
                          this.onRestErrorKey("repair_description")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e) => {
                        const input = e.clipboardData
                          .getData("text")
                          .slice(0, 255)
                        this.onFieldChange("repair_description", input)
                        this.onRestErrorKey("repair_description")
                        e.preventDefault()
                      }}
                      onFocus={() => this.onRestErrorKey("repair_description")}
                      onBlur={() =>
                        this.onFocusValidation("repair_description")
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>Repair compliance ref</React.Fragment>
                      }
                      multiline
                      minRows={5}
                      name="repair_compliance_ref"
                      id="repair_compliance_ref"
                      value={data.repair_compliance_ref}
                      error={error.repair_compliance_ref ? true : false}
                      helperText={
                        error.repair_compliance_ref
                          ? error.repair_compliance_ref
                          : ""
                      }
                      inputProps={{ maxLength: 255 }}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("repair_compliance_ref", input)
                          this.onRestErrorKey("repair_compliance_ref")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() =>
                        this.onRestErrorKey("repair_compliance_ref")
                      }
                    />
                  </Grid>
                </Grid>
                <div className="border-grey"></div>
                <Grid spacing={1} container>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={<React.Fragment>Repair inspection</React.Fragment>}
                      name="repair_inspection"
                      id="repair_inspection"
                      value={data.repair_inspection}
                      error={error.repair_inspection ? true : false}
                      helperText={
                        error.repair_inspection ? error.repair_inspection : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("repair_inspection", input)
                          this.onRestErrorKey("repair_inspection")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("repair_inspection")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={<React.Fragment>Repair location</React.Fragment>}
                      name="repair_location"
                      id="repair_location"
                      value={data.repair_location}
                      error={error.repair_location ? true : false}
                      helperText={
                        error.repair_location ? error.repair_location : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("repair_location", input)
                          this.onRestErrorKey("repair_location")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("repair_location")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="Repair detail"
                      name="repair_detail"
                      id="repair_detail"
                      value={data.repair_detail ? data.repair_detail : ""}
                      error={error.repair_location ? true : false}
                      helperText={
                        error.repair_location ? error.repair_location : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("repair_detail", input)
                          this.onRestErrorKey("repair_detail")
                        } else {
                          e.preventDefault()
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={<React.Fragment>Repair TSN</React.Fragment>}
                      name="repair_compliance_tsn"
                      id="repair_compliance_tsn"
                      value={data.repair_compliance_tsn}
                      error={error.repair_compliance_tsn ? true : false}
                      helperText={
                        error.repair_compliance_tsn
                          ? error.repair_compliance_tsn
                          : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("repair_compliance_tsn", input)
                          this.onRestErrorKey("repair_compliance_tsn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() =>
                        this.onRestErrorKey("repair_compliance_tsn")
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={<React.Fragment>Repair CSN</React.Fragment>}
                      name="repair_compliance_csn"
                      id="repair_compliance_csn"
                      value={data.repair_compliance_csn}
                      error={error.repair_compliance_csn ? true : false}
                      helperText={
                        error.repair_compliance_csn
                          ? error.repair_compliance_csn
                          : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("repair_compliance_csn", input)
                          this.onRestErrorKey("repair_compliance_csn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() =>
                        this.onRestErrorKey("repair_compliance_csn")
                      }
                    />
                  </Grid>
                  <Grid item xs={4} className="input-calender">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        id="repair_compliance_date"
                        label={
                          <React.Fragment>
                            Repair compliance Date
                          </React.Fragment>
                        }
                        format={fieldDateFormat}
                        inputVariant="outlined"
                        // autoOk={true}
                        fullWidth
                        disableFuture
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(25, "years")}
                        value={data.repair_compliance_date}
                        error={error.repair_compliance_date ? true : false}
                        helperText={
                          error.repair_compliance_date
                            ? error.repair_compliance_date
                            : ""
                        }
                        onFocus={() =>
                          this.onRestErrorKey("repair_compliance_date")
                        }
                        onChange={(data, value) => {
                          this.onFieldChange(
                            "repair_compliance_date",
                            moment(data).format(backendDateFormat)
                          )
                          this.onRestErrorKey("repair_compliance_date")
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid spacing={1} container id="data-container">
                  <Grid item xs={3} id="ht-dropdown" className="repair-period">
                    <FormControl>
                      <InputLabel
                        id="demo-simple-select-label"
                        className="dropdown-label"
                      >
                        Repair Status
                      </InputLabel>
                      <Select
                        labelId="repair_status"
                        name="repair_status"
                        id="repair_status"
                        error={error.repair_status ? true : false}
                        helperText={
                          error.repair_status ? error.repair_status : ""
                        }
                        variant="outlined"
                        value={data.repair_status}
                        onChange={this.dropDownChange2}
                      >
                        <MenuItem value={"OPEN"}>OPEN</MenuItem>
                        <MenuItem value={"CLOSED"}>CLOSED</MenuItem>
                        <MenuItem value={"REPETITIVE"}>REPETITIVE</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} className="input-calender">
                    <label className="mb-10">LAST DONE</label>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        id="last_done_date"
                        label={<React.Fragment>Date</React.Fragment>}
                        format={fieldDateFormat}
                        inputVariant="outlined"
                        // autoOk={true}
                        fullWidth
                        disableFuture
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(25, "years")}
                        value={data.last_done_date}
                        error={error.last_done_date ? true : false}
                        helperText={
                          error.last_done_date ? error.last_done_date : ""
                        }
                        onFocus={() => this.onRestErrorKey("last_done_date")}
                        onChange={(data, value) => {
                          this.onFieldChange(
                            "last_done_date",
                            moment(data).format(backendDateFormat)
                          )
                          this.onRestErrorKey("last_done_date")
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={3} id="lastdone-input">
                    <TextField
                      variant="outlined"
                      label={<React.Fragment>Last Done FH</React.Fragment>}
                      name="last_done_fh"
                      id="last_done_fh"
                      value={data.last_done_fh}
                      error={error.last_done_fh ? true : false}
                      helperText={error.last_done_fh ? error.last_done_fh : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("last_done_fh", input)
                          this.onRestErrorKey("last_done_fh")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("last_done_fh")}
                    />
                  </Grid>
                  <Grid item xs={3} id="lastdone-input">
                    <TextField
                      variant="outlined"
                      label={<React.Fragment>Last Done FC</React.Fragment>}
                      name="last_done_fc"
                      id="last_done_fc"
                      value={data.last_done_fc}
                      error={error.last_done_fc ? true : false}
                      helperText={error.last_done_fc ? error.last_done_fc : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("last_done_fc", input)
                          this.onRestErrorKey("last_done_fc")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("last_done_fc")}
                    />
                  </Grid>
                </Grid>
                <Grid spacing={1} container id="data-container">
                  <Grid item xs={3} id="ht-dropdown" className="repair-period">
                    <FormControl>
                      <InputLabel
                        id="demo-simple-select-label"
                        className="dropdown-label"
                      >
                        Repair Compliance Period
                      </InputLabel>
                      <Select
                        labelId="repair_interval_type"
                        name="repair_interval_type"
                        id="repair_interval_type"
                        error={error.repair_interval_type ? true : false}
                        helperText={
                          error.repair_interval_type
                            ? error.repair_interval_type
                            : ""
                        }
                        variant="outlined"
                        value={data.repair_interval_type}
                        onChange={this.dropDownChange1}
                      >
                        <MenuItem value={"D"}>Days</MenuItem>
                        <MenuItem value={"FH"}>FH</MenuItem>
                        <MenuItem value={"FC"}>FC</MenuItem>
                        <MenuItem value={"FC,D"}>FC & D</MenuItem>
                        <MenuItem value={"FH,D"}>FH & D</MenuItem>
                        <MenuItem value={"FC,FH,D"}>FH,FC & D</MenuItem>
                        <MenuItem value={"FC,FH"}>FC & FH</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {data.repair_interval_type == "FC" || data.repair_interval_type == "FC,D" || data.repair_interval_type == "FC,FH" || data.repair_interval_type == "FC,FH,D" ?<Grid item 
                     xs={ data.repair_interval_type == "FC" ? 3 : data.repair_interval_type == "FC,D"  ? 2 : data.repair_interval_type == "FC,FH"  ? 1 :  data.repair_interval_type == "FC,FH,D" ? 1 : 3}
                    //  xs={data.repair_interval_type == "FC,D" || data.repair_interval_type == "FH,D" || data.repair_interval_type == "FC,FH,D" ? 2: 3 }
                    id="dimensions-input-sr">
                    <TextField
                      variant="outlined"
                      label= {data.repair_interval_type == "FC" || data.repair_interval_type == "FC,D" || data.repair_interval_type == "FC,FH,D"  ||  data.repair_interval_type == "FC,FH" ? "FC Interval" : "FC, Interval"}
                      name="dimension_interval_fc"
                      id="dimension_interval_fc"
                      value={data.dimension_interval_fc}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e, value) =>
                        this.onFieldChange("dimension_interval_fc", e.target.value)
                      }
                    />
                  </Grid>: null}
                  {data.repair_interval_type == "FH" || data.repair_interval_type == "FH,D" || data.repair_interval_type == "FC,FH" || data.repair_interval_type == "FC,FH,D" ?<Grid item 
                     xs={ data.repair_interval_type == "FH" ? 3 : data.repair_interval_type == "FH,D" ? 2 : data.repair_interval_type == "FC,FH"  ? 1 :  data.repair_interval_type == "FC,FH,D" ? 1 : 3}
                    //  xs={data.repair_interval_type == "FC,D" || data.repair_interval_type == "FH,D" || data.repair_interval_type == "FC,FH,D" ? 2: 3 }
                    id="dimensions-input-sr">
                    <TextField
                      variant="outlined"
                      label= {data.repair_interval_type == "FH" || data.repair_interval_type == "FH,D" || data.repair_interval_type == "FC,FH,D"  ||  data.repair_interval_type == "FC,FH" ? "FH Interval" : "FH, Interval"}
                      name="repair_interval"
                      InputLabelProps={{ shrink: true }}
                      id="repair_interval"
                      value={data.repair_interval}
                      onChange={(e, value) =>
                        this.onFieldChange("repair_interval", e.target.value)
                      }
                    />
                  </Grid>: null}
                  {
                    data.repair_interval_type == "D" || data.repair_interval_type == "FC,D" || data.repair_interval_type == "FH,D" || data.repair_interval_type == "FC,FH,D" ? (
                      <Grid item 
                        xs={data.repair_interval_type == "D" ? 3 : data.repair_interval_type == "FC,D" || data.repair_interval_type == "FH,D"  ? 1 : data.repair_interval_type == "FC,FH,D" ? 1: 3 }
                        id="dimensions-input-sr">
                        <TextField
                          label ="days"
                          variant="outlined"
                          name="dimension_interval_days"
                          id="dimension_interval_days"
                          value={data.dimension_interval_days}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e, value) =>
                            this.onFieldChange("dimension_interval_days", e.target.value)
                          }
                        />
                      </Grid>
                    ) : null
                  }
                  <Grid item xs={3}>
                    <label className="ml-10px font-14">NEXT DUE </label>
                    <Grid spacing={2} container>
                      <Grid className="remaining-input" item xs={6}>
                        <TextField
                          variant="outlined"
                          label={data.repair_interval_type === "D" ? "Date" : data.repair_interval_type === "FC" ? "FC" : data.repair_interval_type === "FH" ? "FH" : data.repair_interval_type === "FH,D" ? "FH/Date" :
                          data.repair_interval_type === "FC,D" ? "FC/Date" : data.repair_interval_type === "FC,FH,D" ? "FH/FC/Date" : data.repair_interval_type === "FC,FH" ? "FC/FH " : "Date"}
                          name="repair_DUE"
                          id="repair_DUE"
                          value={this.nextDueValue()}
                          onChange={(e, value) =>
                            this.onFieldChange("repair_DUE", e.target.value)
                          }
                          inputProps={{ readOnly: true }}
                          disabled
                          className="disabled-input"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <label className="ml-10px font-14">REMAINING</label>
                    <Grid spacing={1} container>
                      <Grid className="remaining-input" item xs={6}>
                        <TextField
                          variant="outlined"
                          label={data.repair_interval_type === "D" ? "Days" : data.repair_interval_type === "FC" ? "FC" : data.repair_interval_type === "FH" ? "FH" :
                          data.repair_interval_type === "FH,D" ? "FH/Days" : data.repair_interval_type === "FC,D" ? "FC/Days" : data.repair_interval_type === "FC,FH,D" ? "FH/FC/Days" : data.repair_interval_type === "FC,FH" ? "FC/FH" : "Days"}
                          name="remaining_val"
                          id="remaining_val"
                          value={this.remainingVal()}
                          onChange={(e, value) =>
                            this.onFieldChange("remaining_val", e.target.value)
                          }
                          inputProps={{ readOnly: true }}
                          disabled
                          className="disabled-input"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div className="border-grey"></div>
                <Grid id="data-container" spacing={1} container>
                  <Grid item xs={3}>
                    <label>D&B Compliance DFP</label>
                    <FormControl
                      error={error.dnb_compliance_dfp ? true : false}
                      helperText={
                        error.dnb_compliance_dfp ? error.dnb_compliance_dfp : ""
                      }
                      onFocus={() => this.onRestErrorKey("dnb_compliance_dfp")}
                    >
                      <RadioGroup
                        required
                        row
                        aria-label="dnb_compliance_dfp"
                        name="dnb_compliance_dfp"
                        defaultValue="top"
                        value={data.dnb_compliance_dfp}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "dnb_compliance_dfp",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                      {error.dnb_compliance_dfp && (
                        <FormLabel component="dnb_compliance_dfp">
                          <span style={{ color: "red" }}>
                            {error.dnb_compliance_dfp}
                          </span>
                        </FormLabel>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <label>Release Certificate</label>
                    <FormControl
                      component="fieldset"
                      error={error.release_certificate ? true : false}
                      helperText={
                        error.release_certificate
                          ? error.release_certificate
                          : ""
                      }
                    >
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="release_certificate"
                        name="release_certificate"
                        defaultValue="top"
                        value={data.release_certificate}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "release_certificate",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                      {error.release_certificate && (
                        <FormLabel component="release_certificate">
                          <span style={{ color: "red" }}>
                            {error.release_certificate}
                          </span>
                        </FormLabel>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <label>Certificate of Conformity</label>
                    <FormControl
                      component="fieldset"
                      error={error.certificate_of_conformity ? true : false}
                      helperText={
                        error.certificate_of_conformity
                          ? error.certificate_of_conformity
                          : ""
                      }
                    >
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="certificate_of_conformity"
                        name="certificate_of_conformity"
                        defaultValue="top"
                        value={data.certificate_of_conformity}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "certificate_of_conformity",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                        <FormControlLabel
                          value="N/A"
                          control={<Radio size="small" color="primary" />}
                          label="N/A"
                        />
                      </RadioGroup>
                      {error.certificate_of_conformity && (
                        <FormLabel component="certificate_of_conformity">
                          <span style={{ color: "red" }}>
                            {error.certificate_of_conformity}
                          </span>
                        </FormLabel>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <label>
                      SRM reference Documents{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <FormControl
                      error={error.srm_ref_document ? true : false}
                      helperText={
                        error.srm_ref_document ? error.srm_ref_document : ""
                      }
                      onFocus={() => this.onRestErrorKey("srm_ref_document")}
                    >
                      <RadioGroup
                        required
                        row
                        aria-label="srm_ref_document"
                        name="srm_ref_document"
                        defaultValue="top"
                        value={data.srm_ref_document}
                        onChange={(e, value) =>
                          this.onFieldChange("srm_ref_document", e.target.value)
                        }
                        onBlur={() =>
                          this.onFocusValidation("srm_ref_document")
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                        <FormControlLabel
                          value="N/A"
                          control={<Radio size="small" color="primary" />}
                          label="N/A"
                        />
                      </RadioGroup>
                      {error.srm_ref_document && (
                        <FormLabel component="srm_ref_document">
                          <span style={{ color: "red" }}>
                            {error.srm_ref_document}
                          </span>
                        </FormLabel>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid id="data-container" spacing={1} container>
                  <Grid item xs={3}>
                    <label>OEM Repair Scheme</label>
                    <FormControl
                      component="fieldset"
                      error={error.oem_repair_scheme ? true : false}
                      helperText={
                        error.oem_repair_scheme ? error.oem_repair_scheme : ""
                      }
                    >
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="oem_repair_scheme"
                        name="oem_repair_scheme"
                        defaultValue="top"
                        value={data.oem_repair_scheme}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "oem_repair_scheme",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                        <FormControlLabel
                          value="N/A"
                          control={<Radio size="small" color="primary" />}
                          label="N/A"
                        />
                      </RadioGroup>
                      {error.oem_repair_scheme && (
                        <FormLabel component="oem_repair_scheme">
                          <span style={{ color: "red" }}>
                            {error.oem_repair_scheme}
                          </span>
                        </FormLabel>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <label>FAA 8100-9 / Equivalent</label>
                    <FormControl
                      component="fieldset"
                      error={error.faa_equivalent ? true : false}
                      helperText={
                        error.faa_equivalent ? error.faa_equivalent : ""
                      }
                    >
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="faa_equivalent"
                        name="faa_equivalent"
                        defaultValue="top"
                        value={data.faa_equivalent}
                        onChange={(e, value) =>
                          this.onFieldChange("faa_equivalent", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                        <FormControlLabel
                          value="N/A"
                          control={<Radio size="small" color="primary" />}
                          label="N/A"
                        />
                      </RadioGroup>
                      {error.faa_equivalent && (
                        <FormLabel component="faa_equivalent">
                          <span style={{ color: "red" }}>
                            {error.faa_equivalent}
                          </span>
                        </FormLabel>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      label="Remark"
                      name="remark"
                      id="remark"
                      multiline
                      minRows={5}
                      value={data.remarks}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 255) {
                          this.onFieldChange("remark", input)
                          this.onRestErrorKey("remark")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e) => {
                        const input = e.clipboardData
                          .getData("text")
                          .slice(0, 255)
                        this.onFieldChange("remark", input)
                        this.onRestErrorKey("remark")
                        e.preventDefault()
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="upload-textbox">
                      <Button variant="contained" component="label">
                        <div id="upload-success">
                          <span className="d-flex align-items-center">
                            <AddPhotoAlternateOutlinedIcon className="upload-icon" />
                            {/* <span className="upload-text upload">
                            {this.state.attachments.length === 0
                              ? "Upload "
                              : ""}
                          </span> */}
                            <input
                              className="upload-input"
                              onChange={this.onUpload}
                              id="fileUplaod"
                              name="file"
                              type="file"
                              accept={fileTypes}
                              multiple
                            />
                            <span className="upload-text">
                              {this.state.attachments.length === 0
                                ? "Upload or drop a file right here"
                                : " Upload Another?"}
                            </span>
                          </span>
                          <Tooltip title="JPG,PNG,GIF,PDF,DOC,DOCX,XLSX,TXT,JPEG,XLS">
                            <span className="upload-text overflow">
                              &nbsp;JPG,PNG,GIF,PDF,DOC,DOCX,XLSX,TXT,JPEG,XLS
                            </span>
                          </Tooltip>
                        </div>
                        {this.state.showSuccessUpload ? (
                          <div id="success-text">
                            <span className="upload-text">
                              Uploaded successfully!
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                {/* <Grid id="data-container" spacing={1} container>
                  <Grid spacing={1} container> */}
                <div className="d-flex align-items-center show-attachments">
                  {(() => {
                    if (
                      this.state.attachments &&
                      this.state.attachments.length > 0
                    ) {
                      return Object.keys(this.state.attachments).map(
                        (key1, index) => {
                          return (
                            <div
                              className="position-relative d-flex align-items-center attachment-margin"
                              key={index}
                            >
                              <span className="file-name">
                                {this.state.attachments[index].name}
                              </span>
                              <DescriptionOutlinedIcon className="file-icon" />
                              {permissionCheckFnforCamo({
                                primaryKey: "repair",
                                keyIndex: "D",
                              }) ||
                              permissionCheckFnforCamo({
                                primaryKey: "repair",
                                keyIndex: "OD",
                                userId: this.state.data.created_by
                                  ? this.state.data.created_by
                                  : undefined,
                              }) ||
                              this.state.attachments[index].id === 0 ? (
                                <CancelOutlinedIcon
                                  className="cancel-icon"
                                  onClick={
                                    this.props.actionType === "add"
                                      ? (e) =>
                                          this.removeAttachment(
                                            e,
                                            this.state.attachments[index]
                                          )
                                      : (e) => {
                                          if (
                                            this.state.attachments[index].id ===
                                            0
                                          ) {
                                            this.removeAttachment(
                                              e,
                                              this.state.attachments[index]
                                            )
                                          } else {
                                            this.handleClickAttachment(
                                              e,
                                              this.state.attachments[index].id
                                            )
                                          }
                                        }
                                  }
                                />
                              ) : null}
                            </div>
                          )
                        }
                      )
                    }
                  })()}
                </div>
                {/* </Grid>
                </Grid> */}
              </DialogContent>
              <DialogActions className="actions-btn">
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={
                    this.props.actionType === "add"
                      ? (e) => this.addFormData(e)
                      : (e) => this.updateData(e)
                  }
                >
                  {this.props.actionType === "add" ? "Add" : "Update"}
                </Button>

                {this.props.actionType === "add" ? (
                  <Button
                    onClick={(e) => this.saveAndAddAnother(e)}
                    type="button"
                    className="btn btn-primary"
                  >
                    Save & Add Another
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.props.closeAddForm}
                >
                  Close
                </Button>
              </DialogActions>
              <Dialog
                open={this.state.open}
                onClose={this.handleCloseAttachment}
                id="htDialog"
              >
                <SrConfirmDeleteAttachment
                  handleCloseAttachment={() => this.handleCloseAttachment()}
                  deleteAttachmentFile={() =>
                    this.deleteAttachmentFile(this.state.deleteAttachmentId)
                  }
                />
              </Dialog>
            </form>
          </div>
        </div>
      </Fragment>
    )
  }
}
export default SrTaskForm
