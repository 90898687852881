import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Dialog,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ConfirmDialog from "../components/ConfirmDialog";
import { fillSbEditFormApi } from "../apiService";
import SbTaskForm from "./SbTaskForm";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import moment from "moment";
// import ShowAttachments from "../../ShowAttachments";
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { permissionCheckFnforCamo } from "../../../../utils";

const SbList = ({
  item,
  index,
  handleDeleteRow,
  updateFormData,
  props,
  fileUploadData,
  currentRecordPerPage,
  headerData,
  downloadAllApi,
  headerTsn,
  headerCsn,
}) => {
  const [open, setOpen] = useState(false);
  const [openEditForm, setEditForm] = useState(false);
  const [editFormId, setEditFormId] = useState(null);
  const [editFormData, setEditFormData] = useState(null);
  const [formTitle, setFormTitle] = useState("");
  const [attachments, setAttachments] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState({});
  const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false);
  const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] =
    useState(null);
  const [moduleId, setmoduleId] = useState(null);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const closeAddForm = () => {
    setEditForm(false);
  };

  const handleEditRow = () => {
    fillSbEditFormApi(item, props).then((response) => {
      setEditFormData(response.data.data);
      setEditFormId(response.data.data.id);
      setAttachments(response.data.data.attachments);
      setFormTitle("Update SB Components");
      setEditForm(true);
    });
  };

  const downAllAttach = () => {
    fillSbEditFormApi(item, props).then((response) => {
      setmoduleId(response.data.data.id);
    });
    if (item.attachments.length > 0) {
      getAttachments(item.attachments);
    }
  };

  const openAttachment = (file) => {
    window.open(`https://qa.camo.sparta.aero:8223${file}`, "_blank");
  };

  const formatDate = (date) => {
    return moment(date).format("DD-MM-YYYY");
  };

  const toggleDescription = (id) => {
    setShowFullDescription({
      ...showFullDescription,
      [id]: !showFullDescription[id],
    });
  };

  const getAttachments = (attachments) => {
    setAllAttachmentsforDialogue(attachments);
    setAttachmentsDialogue(true);
  };

  const handleCloseAttachmentDialogue = () => {
    setAttachmentsDialogue(false);
  };

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        style={{ cursor: "pointer" }}
        className={
          item.remainingValues_D < 0 || item.remainingValues_D == null
            ? "red-row"
            : "none"
        }
      >
        <TableCell>
          {permissionCheckFnforCamo({
            primaryKey: "sb_status",
            keyIndex: "U",
          }) ? (
            <Tooltip title="Edit">
              <IconButton
                className="edit-icon"
                onClick={(e) => {
                  handleEditRow(e);
                }}
              >
                <CreateOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {permissionCheckFnforCamo({
            primaryKey: "sb_status",
            keyIndex: "D",
          }) ||
          permissionCheckFnforCamo({
            primaryKey: "sb_status",
            keyIndex: "OD",
            userId: item.created_by,
          }) ? (
            <Tooltip title="Delete" arrow>
              <IconButton className="delete-icon" onClick={handleClick}>
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </TableCell>
        <TableCell>{item.ata_chapter ? item.ata_chapter : "--"}</TableCell>
        <TableCell>{item.sb_ref_number ? item.sb_ref_number : "--"}</TableCell>
        <TableCell>{item.sb_issued_by ? item.sb_issued_by : "--"}</TableCell>
        <TableCell>
          {item.sb_applicability ? item.sb_applicability : "--"}
        </TableCell>
        <TableCell style={{ width: "200px", textAlign: "left" }}>
          {item.description && item.description.length > 150 ? (
            <div>
              {showFullDescription[item.id] ? (
                <span>
                  {item.description}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleDescription(item.id);
                    }}
                  >
                    ..less
                  </a>
                </span>
              ) : (
                <span>
                  {item.description.substring(0, 150)}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleDescription(item.id);
                    }}
                  >
                    ..more
                  </a>
                </span>
              )}
            </div>
          ) : item.description && item.description.length <= 150 ? (
            <span>{item.description}</span>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell>{item.type ? item.type : "--"}</TableCell>

        <TableCell>{item.effectivity ? item.effectivity : "--"}</TableCell>
        <TableCell>
          {item.effectivity_date ? formatDate(item.effectivity_date) : "--"}
        </TableCell>
        <TableCell>
          {item.sb_interval_type ? item.sb_interval_type : "--"}
        </TableCell>
        <TableCell>
          {item.sb_compliance_status ? item.sb_compliance_status : "--"}
        </TableCell>
        <TableCell>
          {item.sb_compliance_tsn ? item.sb_compliance_tsn : "--"}
        </TableCell>
        <TableCell>
          {item.sb_compliance_csn ? item.sb_compliance_csn : "--"}
        </TableCell>
        <TableCell>
          {item.sb_compliance_date ? formatDate(item.sb_compliance_date) : "--"}
        </TableCell>
        <TableCell>
          {item.remaining.due_at
            ? item.sb_interval_type === "D"
              ? formatDate(item.remaining.due_at)
              : item.remaining.due_at
            : "--"}
        </TableCell>
        <TableCell>
          {item.remaining.remaining ? item.remaining.remaining : "--"}
        </TableCell>
        <TableCell>
          {item.attachments.length > 0 ? (
            <>
              <Tooltip title="View Attachments">
                <span
                  className="center"
                  onClick={() => {
                    downAllAttach();
                  }}
                >
                  <AttachFileIcon className="attach-icon" />

                  <a href="#" style={{ color: "#0e7fe1" }}>
                    {item.attachments.length}
                  </a>
                </span>
              </Tooltip>
            </>
          ) : (
            item.attachments.length
          )}
        </TableCell>
      </TableRow>
      <Dialog open={open} onClose={handleClose} id="htDialog">
        <ConfirmDialog
          handleClose={() => handleClose()}
          handleDeleteRow={handleDeleteRow}
        />
      </Dialog>

      {/* edit form dialog */}
      <Dialog
        position="relative"
        maxWidth="lg"
        open={openEditForm}
        onClose={closeAddForm}
      >
        <SbTaskForm
          formTitle={formTitle}
          closeAddForm={() => closeAddForm()}
          props={props}
          headerTsn={headerTsn}
          headerCsn={headerCsn}
          editFormId={editFormId}
          editFormData={editFormData}
          attachments={attachments}
          item={item}
          updateFormData={updateFormData}
          fileUploadData={fileUploadData}
          currentRecordPerPage={currentRecordPerPage}
          headerData={headerData}
        />
      </Dialog>

      <Dialog
        open={openGetAttachmentsDialogue}
        onClose={handleCloseAttachmentDialogue}
        id="htDialog"
      >
        <ShowAttachments
          handleClose={() => handleCloseAttachmentDialogue()}
          handleShowAttachments={AllAttachmentsforDialogue}
          openAttachment={openAttachment}
          files={{ title: "AMP", extension: "zip", key: "" }}
          downloadAllApi={downloadAllApi}
          props={props}
          moduleId={moduleId}
        />
      </Dialog>
    </>
  );
};
export default withRouter(SbList);
