import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

const ConfirmDialog = ({ handleClose, handleDeleteRow, item }) => {
  return (
    <>
      <DialogTitle>Confirm the action</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton>
          <Close onClick={handleClose} style={{ fill: "white" }} />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>Do you really want to delete this record?</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          CANCEL
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            handleDeleteRow(item);
            handleClose();
          }}
        >
          CONFIRM
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmDialog;
