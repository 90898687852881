import { getLocalStorageInfo } from "../../../../utils"

export const AdTableMainHead = [
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },

  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },

  { id: "adcompliance", label: "AD Compliance", sortOption: true, colspan: 3 },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
  { id: "", label: "", sortOption: false },
]

export const AdTableHead = [
  { id: "action", label: "Action", sortOption: false },
  { id: "ata", label: "ATA Chapter", sortOption: true },
  { id: "ad_no", label: "AD Reference Number", sortOption: true },
  { id: "issuing_authority", label: "AD Issuing Authority", sortOption: true },
  { id: "applicability", label: "AD Applicability", sortOption: true },
  { id: "description", label: "AD Description", sortOption: true },
  { id: "ad_type", label: "AD type", sortOption: false },
  { id: "effectivity", label: "AD Effectivity", sortOption: false },
  { id: "effective_date", label: "AD Effective Date", sortOption: false },
  { id: "dimension_type", label: "AD Compliance Period", sortOption: false },
  {
    id: "ad_compliance_status",
    label: "AD Compliance Status",
    sortOption: false,
  },

  { id: "ad_compliance_tsn", label: "TSN", sortOption: false },

  { id: "ad_compliance_csn", label: "CSN", sortOption: false },
  { id: "ad_compliance_date", label: "Date", sortOption: false },
  { id: "ad_next_due", label: "AD Next Due", sortOption: false },

  { id: "sb_number", label: "Corresponding SB number" },
  { id: "attachments", label: "Attachments" },
]

export const assetFilterOps = {
  aircraft_type: {
    inputType: "dropdown",
    placeholder: "Select Aircraft Type",
    title: "Aircraft Type",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  engine_type: {
    inputType: "dropdown",
    placeholder: "Select Engine Type",
    title: "Engine Type",
    options: [],
    labelKey: "name",
    valueKey: "id",
    multiple: true,
  },
  lessee: {
    inputType: "dropdown",
    placeholder: "Select Lessee",
    title: "Lessee",
    options: [],
    labelKey: "name",
    valueKey: "slug",
    multiple: true,
  },
  lessor_name: {
    inputType: "text",
    placeholder: "Enter Lessor",
    title: "Lessor",
  },
  owner: {
    inputType: "text",
    placeholder: "Enter Owner ",
    title: "Owner",
  },
  ownership_type: {
    inputType: "dropdown",
    placeholder: "Select Ownership Type",
    title: "Ownership",
    options: [
      { label: "Owned", value: 1 },
      { label: "Managed", value: 2 },
    ],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
  registration: {
    inputType: "text",
    placeholder: "Enter Registeration Number",
    title: "Registeration Number",
  },
  portfolio: {
    inputType: "text",
    placeholder: "Enter Portfolio",
    title: "Portfolio",
  },
  serial_number: {
    inputType: "text",
    placeholder: "Enter Serial Number",
    title: "Serial Number",
  },
  status: {
    inputType: "dropdown",
    placeholder: "Select Status",
    title: "Status",
    options:
      getLocalStorageInfo() &&
      getLocalStorageInfo().defaultLessor &&
      getLocalStorageInfo().defaultLessor.id === 442
        ? [
            { label: "Prospect", value: "11" },
            { label: "In Storage", value: "10" },
            { label: "Lease Return", value: "9" },
            { label: "MOU Signed", value: "8" },
            { label: "MOU Issued", value: "7" },
            { label: "Lease Issued", value: "6" },
            { label: "Archive", value: "5" },
            { label: "Written Off/ Sold / Part out", value: "4" },
            { label: "OnGround", value: "3" },
            { label: "Off Lease", value: "2" },
            { label: "On Lease", value: "1" },
          ]
        : [
            { label: "Archive", value: "5" },
            { label: "On Lease", value: "1" },
            { label: "Off Lease", value: "2" },
            { label: "On Ground", value: "3" },
            { label: "Written Off/ Sold / Part out", value: "4" },
          ],
    labelKey: "label",
    valueKey: "value",
    multiple: true,
  },
}
