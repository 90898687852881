import React, { Component, Fragment } from "react"
import { withSnackbar } from "notistack"
import {Button,CircularProgress,Dialog,DialogActions,DialogContent,DialogTitle,Grid,TextField,Checkbox,Radio,RadioGroup,FormControl,FormLabel,FormControlLabel,Tooltip,Drawer,} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import MomentUtils from "@date-io/moment"
import {MuiPickersUtilsProvider,KeyboardDatePicker,} from "@material-ui/pickers"
import moment from "moment"
import { backendDateFormat, fieldDateFormat } from "../../../constants"
import { checkApiStatus, checkLicenseStatus, getLocalStorageInfo,} from "../../../utils"
import { addNewAssetApi } from "../apiServices"
import { fieldValidation } from "../../../utils/formValidation"
import { aircraftCrud, aircraftErrorCode } from "../"
import { trackActivity } from "../../../utils/mixpanel"
import CloseIcon from "@material-ui/icons/Close"
import { getLeaseStatus } from "../../../shared_elements/actionCreators"
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete"
import { string } from "prop-types"

class AddAircraft extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitLoader: false,
      data: aircraftCrud,
      error: {},
      openDrawer: false,
      address: "",
      coordinates: {
        lat: null,
        lng: null,
      },
      globalValidationInputs: {},
      validatedFields: [],
    }
    // this.addNewAssetApi = addNewAssetApi.bind(this)
  }

  handleSelect = async (value) => {
    const results = await geocodeByAddress(value)
    const ll = await getLatLng(results[0])
    this.setState({
      address: value ? value : "",
      coordinates: ll ? ll : null,
    })
  }
  handleChange = (fieldName, value) => {
    this.setState({
      [fieldName]: value,
    })
  }
  onFieldChange = (keyParam, value) => {
    if (keyParam === "aircraft_type") {
      if (value) {
        this.props.fetchMntGrops(value.id)
      } else {
        this.onFieldChange("maintenance_event_program_group", null)
      }
    }
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [keyParam]: value,
      },
    }))
  }
  onRestErrorKey = (keyParam) => {
    this.setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: "",
      },
    }))
  }
  onAddNewAsset = () => {
    const { data } = this.state
    // Apply the validation Here
    let validationInputs = {}
    validationInputs = {
      ...validationInputs,
      msn: aircraftErrorCode["msn"][fieldValidation({ ...aircraftErrorCode["msnObj"], fieldval: data.msn != null ? String(data.msn) : "", })],
      aircraft_type: aircraftErrorCode["aircraft_type"][fieldValidation({ ...aircraftErrorCode["aircraft_typeObj"], fieldval: data.aircraft_type != null ? String(data.aircraft_type) : "", })],
      status: aircraftErrorCode["status"][fieldValidation({ ...aircraftErrorCode["statusObj"], fieldval: data.status != null ? String(data.status) : "", })],
      date_of_manufacture: aircraftErrorCode["date_of_manufacture"][fieldValidation({ ...aircraftErrorCode["date_of_manufactureObj"], fieldval: data.date_of_manufacture != null ? String(data.date_of_manufacture) : "", })],
      portfolio: data.ownership_type === 2 ? data.owner_portfolio ? "" : "Please select Portfolio" : "",
      lessee_id: data.status && data.status.value == 1 ? aircraftErrorCode["lessee_id"][fieldValidation({ ...aircraftErrorCode["lessee_idObj"], fieldval: data.lessee_id != null ? String(data.lessee_id) : "", })] : "",
      sub_status: data.status && data.status.value == 1 ? aircraftErrorCode["sub_status"][fieldValidation({ ...aircraftErrorCode["sub_statusObj"], fieldval: data.sub_status !== null ? String(data.sub_status) : "", })] : "",
      operator: data.same_operator_lessee ? "" : aircraftErrorCode["operator"][fieldValidation({ ...aircraftErrorCode["operator_obj"], fieldval: data.operator != null ? String(data.operator) : "", })],
    }
    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState({ formSubmitLoader: true })
      let payload = Object.assign({}, data)
      payload = {
        ...payload,
        aircraft_type: data.aircraft_type.id,
        credit_classification: data.credit_classification && data.credit_classification.value ? data.credit_classification.value : null,
        maintenance_event_program_group: data.maintenance_event_program_group && data.maintenance_event_program_group.value ? data.maintenance_event_program_group.value : null,
        status: data.status && data.status.value ? data.status.value : null,
        sub_status: data.sub_status && data.sub_status.value ? data.sub_status.value : null,
        lessee_id: data.lessee_id && data.lessee_id.id ? data.lessee_id.id : null,
        operator: data.operator && data.operator.id ? data.operator.id : null,
        country: this.state.address ? this.state.address : null,
        latitude: this.state.coordinates && this.state.coordinates.lat ? this.state.coordinates.lat : null,
        longitude: this.state.coordinates && this.state.coordinates.lng ? this.state.coordinates.lng : null,
      }
      if (payload.ownership_type === 2) {
        if (payload.owner_portfolio.id) {
          payload = {
            ...payload,
            owner_portfolio: payload.owner_portfolio.id,
          }
        }
      }
      this.props.addAircraftAsset(this.props, "console/add-aircraft/", payload)
      this.setState({ data: aircraftCrud })
      this.setState({ formSubmitLoader: false })
    } else {
      this.setState({ error: validationInputs })
    }
  }
  toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    this.setState({ openDrawer: true })
  }

  onFocusValidation = (keyParam) => {
    let validationInputs = {}
    const { data } = this.state
    validationInputs = {
      ...validationInputs,
      msn: aircraftErrorCode["msn"][fieldValidation({ ...aircraftErrorCode["msnObj"], fieldval: data.msn != null ? String(data.msn) : "", })],
      aircraft_type: aircraftErrorCode["aircraft_type"][fieldValidation({ ...aircraftErrorCode["aircraft_typeObj"], fieldval: data.aircraft_type != null ? String(data.aircraft_type) : "", })],
      status: aircraftErrorCode["status"][fieldValidation({ ...aircraftErrorCode["statusObj"], fieldval: data.status != null ? String(data.status) : "", })],
      date_of_manufacture: aircraftErrorCode["date_of_manufacture"][fieldValidation({ ...aircraftErrorCode["date_of_manufactureObj"], fieldval: data.date_of_manufacture != null ? String(data.date_of_manufacture) : "", })],
      portfolio: data.ownership_type === 2 ? data.owner_portfolio ? "" : "Please select Portfolio" : "",
      lessee_id: data.status && data.status.value == 1 ? aircraftErrorCode["lessee_id"][fieldValidation({ ...aircraftErrorCode["lessee_idObj"], fieldval: data.lessee_id != null ? String(data.lessee_id) : "", })] : "",
      sub_status: data.status && data.status.value == 1 ? aircraftErrorCode["sub_status"][fieldValidation({ ...aircraftErrorCode["sub_statusObj"], fieldval: data.sub_status !== null ? String(data.sub_status) : "", })] : "",
      operator: data.same_operator_lessee ? "" : aircraftErrorCode["operator"][fieldValidation({ ...aircraftErrorCode["operator_obj"], fieldval: data.operator != null ? String(data.operator) : "", })],
    }

    for (let key in validationInputs) {
      if (key === keyParam) {
        this.state.globalValidationInputs[key] = validationInputs[key]
      }
    }
    if (
      Object.keys(this.state.globalValidationInputs).every((k) => {
        return this.state.globalValidationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))
      this.setState({ globalValidationInputs: {} })
    } else {
      this.setState({ error: this.state.globalValidationInputs })
    }
  }

  render() {
    const { formSubmitLoader, data, error } = this.state
    const { portfolios, lessees, operators, pltConstants, aircraftTypes, addAssetModal, toggleAssetType, aircraftMntGrp, leaseStatus, subStatus, } = this.props
    return (
      <Fragment>
        <Drawer
          anchor="right"
          open={addAssetModal}
          onClose={() => {
            toggleAssetType()
          }}
          id="add-aircraft"
        >
          <div className="plt-filter-header">
            <div className="d-flex justify-content-between custom-width">
              <h3>Add Aircraft</h3>
              <CloseIcon
                onClick={() => {
                  toggleAssetType()
                  trackActivity("Item Clicked", {
                    page_title: "Asset Listing",
                    asset_type: "Aircraft",
                    activity_type: "Cancel",
                  })
                }}
              />
            </div>
          </div>
          <div className="plt-filter-body">
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    required
                    id="msn"
                    label="MSN"
                    fullWidth
                    margin="normal"
                    value={data.msn}
                    error={error.msn ? true : false}
                    helperText={error.msn ? error.msn : ""}
                    onChange={(e, value) =>
                      this.onFieldChange("msn", e.target.value)
                    }
                    onFocus={() => this.onRestErrorKey("msn")}
                    inputProps={{ maxLength: 20 }}
                    InputLabelProps={{ shrink: true }}
                    onBlur={() => this.onFocusValidation("msn")}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    options={aircraftTypes}
                    getOptionLabel={(option) => option.name}
                    id="aircraft_type"
                    value={data.aircraft_type}
                    onChange={(e, value) =>
                      this.onFieldChange("aircraft_type", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        error={error.aircraft_type ? true : false}
                        helperText={
                          error.aircraft_type ? error.aircraft_type : ""
                        }
                        onFocus={() => this.onRestErrorKey("aircraft_type")}
                        onBlur={() => this.onFocusValidation("aircraft_type")}
                        {...params}
                        label="Type"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Type"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="current_registration_number"
                    label="Registration"
                    fullWidth
                    margin="normal"
                    value={data.current_registration_number}
                    onChange={(e, value) =>
                      this.onFieldChange(
                        "current_registration_number",
                        e.target.value
                      )
                    }
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={12} md={12} id="date_of_manufacture">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      required
                      margin="normal"
                      id="date_of_manufacture"
                      label="Date of Manufacture"
                      format={fieldDateFormat}
                      fullWidth
                      disableFuture
                      InputLabelProps={{ shrink: true }}
                      minDate={moment().subtract(25, "years")}
                      value={data.date_of_manufacture}
                      error={error.date_of_manufacture ? true : false}
                      helperText={
                        error.date_of_manufacture
                          ? error.date_of_manufacture
                          : ""
                      }
                      onFocus={() => this.onRestErrorKey("date_of_manufacture")}
                      onChange={(data, value) =>
                        this.onFieldChange(
                          "date_of_manufacture",
                          moment(data).format(backendDateFormat)
                        )
                      }
                      onFocusCapture={() =>
                        this.onFocusValidation("date_of_manufacture")
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    options={aircraftMntGrp ? aircraftMntGrp : []}
                    getOptionLabel={(option) => option.label}
                    id="maintenance_event_program_group"
                    value={data.maintenance_event_program_group}
                    onChange={(e, value) =>
                      this.onFieldChange(
                        "maintenance_event_program_group",
                        value
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Maintenance Program Group"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Maintenance Program Group"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    options={pltConstants.filter(
                      (item) => item.type === "credit_classification"
                    )}
                    getOptionLabel={(option) => option.label}
                    id="credit_classification"
                    value={data.credit_classification}
                    onChange={(e, value) =>
                      this.onFieldChange("credit_classification", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Credit Classification"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Credit Classification"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <PlacesAutocomplete
                    value={this.state.address}
                    onChange={(e) => this.handleChange("address", e)}
                    onSelect={this.handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div key={suggestions.description}>
                        <TextField
                          id="location"
                          label="Location"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          {...getInputProps({
                            placeholder: "Search Location ...",
                            className: "location-search-input",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item"
                          
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  // style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel
                      className="MuiInputLabel-shrink"
                      component="label"
                      id="form-label"
                    >
                      Ownership Status
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="ownership_type"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value={1}
                        control={
                          <Radio
                            checked={data.ownership_type === 1}
                            onChange={() =>
                              this.onFieldChange("ownership_type", 1)
                            }
                            color="primary"
                            size="small"
                          />
                        }
                        label="Owned"
                      />
                      <FormControlLabel
                        value={2}
                        control={
                          <Radio
                            checked={data.ownership_type === 2}
                            onChange={() =>
                              this.onFieldChange("ownership_type", 2)
                            }
                            color="primary"
                            size="small"
                          />
                        }
                        label="Managed"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {data.ownership_type === 2 ? (
                  <Grid item xs={12}>
                    <Autocomplete
                      options={portfolios}
                      getOptionLabel={(option) => option.name}
                      id="owner_portfolio"
                      value={data.owner_portfolio}
                      onChange={(e, value) =>
                        this.onFieldChange("owner_portfolio", value)
                      }
                      renderInput={(params) => (
                        <TextField
                          error={error.portfolio ? true : false}
                          required={true}
                          helperText={error.portfolio ? error.portfolio : ""}
                          onFocus={() => this.onRestErrorKey("portfolio")}
                          {...params}
                          label="Portfolio"
                          margin="normal"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          placeholder="Select Portfolio"
                          onBlur={() => this.onFocusValidation("portfolio")}
                        />
                      )}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    options={leaseStatus ? leaseStatus : []}
                    getOptionLabel={(option) => option.label}
                    id="status"
                    value={data.status}
                    onChange={(e, value) => this.onFieldChange("status", value)}
                    onBlur={() => this.onFocusValidation("status")}
                    renderInput={(params) => (
                      <TextField
                        required
                        error={error.status ? true : false}
                        helperText={error.status ? error.status : ""}
                        onFocus={() => this.onRestErrorKey("status")}
                        {...params}
                        label="Lease Status"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Lease Status"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={subStatus ? subStatus : []}
                    getOptionLabel={(option) => option.label}
                    id="sub_status"
                    value={data.sub_status ? data.sub_status : null}
                    onChange={(e, value) =>
                      this.onFieldChange("sub_status", value)
                    }
                    onBlur={() => this.onFocusValidation("sub_status")}
                    renderInput={(params) => (
                      <TextField
                        error={error.sub_status ? true : false}
                        required={
                          data.status && data.status.value == 1 ? true : false
                        }
                        helperText={error.sub_status ? error.sub_status : ""}
                        onFocus={() => this.onRestErrorKey("sub_status")}
                        {...params}
                        label="Sub Status"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        placeholder="Select Sub Status"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={lessees}
                    getOptionLabel={(option) => option.name}
                    id="lessee_id"
                    value={data.lessee_id}
                    onChange={(e, value) =>
                      this.onFieldChange("lessee_id", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        error={error.lessee_id ? true : false}
                        required={
                          data.status && data.status.value == 1 ? true : false
                        }
                        helperText={error.lessee_id ? error.lessee_id : ""}
                        onFocus={() => this.onRestErrorKey("lessee_id")}
                        {...params}
                        label="Lessee"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        placeholder="Add or Select Lessee"
                        onBlur={() => this.onFocusValidation("lessee_id")}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel
                      className="MuiInputLabel-shrink"
                      component="label"
                    >
                      Is Lessee also the Operator?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="same_operator_lessee"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value="is_titled"
                        control={
                          <Radio
                            onChange={() => {
                              this.onFieldChange("same_operator_lessee", true)
                              this.onRestErrorKey("operator")
                            }}
                            checked={data.same_operator_lessee}
                            color="primary"
                            size="small"
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="is_fitted"
                        control={
                          <Radio
                            onChange={() => {
                              this.onFieldChange("same_operator_lessee", false)
                              this.onRestErrorKey("operator")
                            }}
                            checked={!data.same_operator_lessee}
                            color="primary"
                            size="small"
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {!data.same_operator_lessee ? (
                  <Grid item xs={12}>
                    <Autocomplete
                      options={operators}
                      getOptionLabel={(option) => option.name}
                      id="operator"
                      value={data.operator}
                      onChange={(e, value) =>
                        this.onFieldChange("operator", value)
                      }
                      onBlur={() => this.onFocusValidation("operator")}
                      renderInput={(params) => (
                        <TextField
                          required={!data.same_operator_lessee ? true : false}
                          error={error.operator ? true : false}
                          helperText={
                            error.operator ? error.operator : ""
                          }
                          onFocus={() => this.onRestErrorKey("operator")}
                          {...params}
                          label="Operator"
                          margin="normal"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          placeholder="Select Operator"
                        />
                      )}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </form>
          </div>
          <div className="plt-filter-footer">
            <Button color="primary" variant="contained" onClick={this.onAddNewAsset}>
              {formSubmitLoader ? (<CircularProgress color="#ffffff" size={24} />) : ("SAVE CHANGES")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                toggleAssetType()
                trackActivity("Item Clicked", {
                  page_title: "Asset Listing",
                  asset_type: "Aircraft",
                  activity_type: "Cancel",
                })
              }}
            >
              Cancel
            </Button>
          </div>
        </Drawer>
      </Fragment>
    )
  }
}
export default withSnackbar(AddAircraft)
