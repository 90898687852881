export const errorMsg1 =
  "Please enter alphanumeric characters. Space and Hyphen (optional)"
export const regexConstants = {
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phoneNumber: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  fax: /^\+?[0-9]+$/,
  // phoneNumber:/^\+[1-9]{1}[0-9]{3,14}$/,
  alphanumericWithOutSpace: /^[0-9a-zA-Z]+$/,
  alphanumericWithSpace: /^[0-9a-zA-Z\s]+$/,
  // alphanumericWithHyphen: /^[\w\-]+$/,
  numericWithHyphen: /^[\d\-]+$/,
  // characterWithHyphen: /^[a-zA-Z\-]+$/,
  onlyNumeric: /^(\s*|\d+)$/,
  onlyCharacter: /^[a-zA-Z]+$/,
  numberWithDot: /^[0-9]*(\.\d{0,2})?$/,
  alphanumericWithHyphenSpace: /^[\w\s]+([\-]{1}[\w\s]+)*$/,
  alphanumericWithHyphen: /^[\w]+([\-]{1}[\w]+)*$/,
  characterWithHyphen: /^[a-zA-Z]+([\-]{1}[a-zA-Z]+)*$/,
  characterWithHyphenSpace: /^[a-zA-Z\s]+([\-]{1}[a-zA-Z\s]+)*$/,
  characterWithSpace: /^[a-zA-Z\s]+$/,
  onlyNumericExcludeZero: /^([1-9]*|[1-9]*\.[1-9]{1}?[1-9]*)$/,
}
