import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { TableRow, TableCell, Tooltip, IconButton } from "@material-ui/core"
import AssetCard from "../components/AssetCard"
import { Link } from "react-router-dom"
import { assetTypeValues } from "../../../constants"
import { Visibility } from "@material-ui/icons"

const AssetList = ({ item, index, props }) => {
  return (
    <TableRow
      hover
      tabIndex={-1}
      style={{ cursor: "pointer" }}
      component={Link}
      to={`/${item.id}/${item.asset_type}/amp/list`}

    >
      <TableCell><AssetCard item={item} /></TableCell>
      <TableCell><Tooltip title="Click to view" arrow><span>{item.msn ? item.msn : "--"}</span></Tooltip></TableCell>
      <TableCell><Tooltip title="Click to view" arrow><span>{item.current_registration_number ? item.current_registration_number : "--"}</span></Tooltip></TableCell>
      <TableCell><Tooltip title="Click to view" arrow><span>{item.lessor_name && item.lessor_name.name ? item.lessor_name.name : "--"}</span></Tooltip></TableCell>
      <TableCell><Tooltip title="Click to view" arrow><span>{item.owner && item.owner.name ? item.owner.name : "--"}</span></Tooltip></TableCell>
      <TableCell><Tooltip title="Click to view" arrow><span>{item.country ? item.country : "--"}</span></Tooltip></TableCell>
      <TableCell>{item.status && item.status.value === 1 ? (<p className="status on-lease">ON LEASE</p>) : null} {item.status && item.status.value === 2 ? (<p className="status off-lease">OFF LEASE</p>) : null} {item.status && item.status.value === 3 ? (<p className="status on-ground">ON GROUND</p>) : null} {item.status && item.status.value === 4 ? (<p className="status sold">WRITTEN OFF/ SOLD</p>) : null}</TableCell>
      <TableCell><Tooltip title="Click to view" arrow><span>{item.end_lease_date ? item.end_lease_date : "--"}</span></Tooltip></TableCell>
      <TableCell> {assetTypeValues && assetTypeValues[item.asset_type] && assetTypeValues[item.asset_type].urlType ? (
        <Tooltip title="Display Asset" arrow>
          <IconButton className="action-icon" component={Link} to={`/${item.id}/${item.asset_type}/amp/list`}> <Visibility /></IconButton>
        </Tooltip>
      ) : null}
      </TableCell>
      <TableCell><Tooltip title="Click to view" arrow><span>{item.files_count ? item.files_count : "--"}</span></Tooltip></TableCell>

    </TableRow>
  )
}
export default withRouter(AssetList)
