import { regexConstants } from "../../../../constants/regEx"

export const formValues = {
  asset_id: null,
  asset_type_id: null,
  ata_chapter: null,
  sb_ref_number: null,
  sb_issued_by: null,
  sb_applicability: null,
  description: "",
  type: "ALERT",
  effectivity: null,
  effectivity_date: null,
  sb_interval_type: "D",
  sb_interval: null,
  sb_compliance_status: "OPEN",
  sb_compliance_tsn: null,
  sb_compliance_csn: null,
  sb_compliance_date: null,
  service_bulletin: null,
  revision: null,
  revision_date: null,
  ad_no: null,
  subject: null,
  workorder: null,
  remark: "",
  last_done_date: null,
  last_done_fh: null,
  last_done_fc: null,
  sb_compliance_dfp: null,
  release_certificate: null,
  certificate_of_conformity: null,
  ad_due: null,
  remaining_val: null,
  attachments: [],
  dimension_interval_days: null,
  dimension_interval_fc:null
}

export const taskErrorCode = {
  ata_chapter: {
    0: "",
    1: "Required",
  },
  ata_chapter_obj: {
    required: true,
    regexPattern: regexConstants.onlyNumeric,
  },
  sb_ref_number: {
    0: "",
    1: "Required",
  },
  sb_ref_number_obj: {
    required: true,
    regexPattern: regexConstants.numericWithHyphen,
  },
  description: {
    0: "",
    1: "Required",
  },
  description_obj: {
    required: true,
  },
  revision: {
    0: "",
    1: "Required",
  },
  revision_obj: {
    required: true,
  },
  revision_date: {
    0: "",
    1: "Required",
    5: "Please enter valid date",
  },
  revision_date_obj: {
    required: true,
    datePattern: true,
  },
  sb_interval: {
    0: "",
    1: "Required",
    4: "Please enter only Integer",
  },
  sb_interval_obj: {
    required: true,
    regexPattern: regexConstants.onlyNumeric,
  },

  sb_compliance_csn: {
    0: "",
    1: "Required",
    4: "Please enter only Integer",
  },
  sb_compliance_csn_obj: {
    required: true,
    regexPattern: regexConstants.onlyNumeric,
  },

  sb_compliance_tsn: {
    0: "",
    1: "Required",
    4: "Please enter only Integer",
  },
  sb_compliance_tsn_obj: {
    required: true,
    regexPattern: regexConstants.onlyNumeric,
  },

  sb_compliance_date: {
    0: "",
    1: "Required",
    5: "Please enter valid date",
  },
  sb_compliance_date_obj: {
    required: true,
    datePattern: true,
  },

  service_bulletin: {
    0: "",
    1: "Required",
  },
  service_bulletin_obj: {
    required: true,
  },

  last_done_fc: {
    0: "",
    4: "Please enter only Integer",
  },
  last_done_fc_obj: {
    //required: true,
    regexPattern: regexConstants.onlyNumeric,
  },

  last_done_fh: {
    0: "",
    4: "Please enter only Integer",
  },
  last_done_fh_obj: {
    //required: true,
    regexPattern: regexConstants.onlyNumeric,
  },
  dimension_interval_days: {
    0: "",
    1: "Required",
  },
  dimension_interval_days_obj: {
    required: true,
  },
  dimension_interval_fc: {
    0: "",
    1: "Required",
  },
  dimension_interval_fc_obj: {
    required: true,
  },
 

}
