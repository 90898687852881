import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
  TablePagination,
} from "@material-ui/core"

import React, { Fragment } from "react"
import { ModsTableHeadMain } from "../containers/ModsTableHead"

const ModsTable = ({
  bulkOperation = false,
  heads,
  bulkFlag,
  noRecord,
  data,
  sort = "",
  sort_by = "",
  pagination = {},
  onChangePage,
  onChangeRowsPerPage,
  createSortHandler,
  classNames,
  toggleBulkOps,
  currentRecordPerPage,
}) => {
  return (
    <Fragment>
      <div id="AmpTable">
        <Table className="table-wrapper" id="mods">
          <TableHead>
            <TableRow>
              {ModsTableHeadMain.map((row, index) => (
                <TableCell colSpan={row.colspan} align="center" key={index}>
                  {row.label}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {bulkOperation ? (
                <TableCell>
                  <Checkbox
                    size="small"
                    checked={bulkFlag}
                    onChange={(e) => toggleBulkOps(e.target.checked)}
                    name="bulk_operation"
                    color="primary"
                  />
                </TableCell>
              ) : null}
              {heads.map((row, index) => (
                <TableCell
                  key={index}
                  align={row.actionCell ? "right" : "left"}
                  padding={"normal"}
                  sortDirection={
                    sort === row.id ? (sort_by ? sort_by : "asc") : false
                  }
                >
                  {row.sortOption && noRecord === null ? (
                    <TableSortLabel
                      active={sort === row.id}
                      direction={sort_by && sort === row.id ? sort_by : "asc"}
                      onClick={() => createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    <span>{row.label}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {noRecord ? (
              <TableRow id="table-no-records">
                <TableCell
                  colSpan={bulkOperation ? heads.length + 1 : heads.length}
                  align="center"
                >
                  {noRecord}
                </TableCell>
              </TableRow>
            ) : (
              data
            )}
          </TableBody>
        </Table>
      </div>
      {Object.keys(pagination).length && pagination.total > 0 ? (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={pagination.total}
          // rowsPerPage={parseInt(pagination.per_page)}
          rowsPerPage={currentRecordPerPage}
          page={pagination.page - 1}
          backIconButtonProps={{ "aria-label": "previous page" }}
          nextIconButtonProps={{ "aria-label": "next page" }}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      ) : null}
    </Fragment>
  )
}

export default ModsTable
