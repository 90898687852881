import React, { Component, Fragment } from "react"
import { Button, DialogTitle, DialogContent, TextField, DialogActions, Grid, FormControl, InputLabel, MenuItem, Select, FormLabel, FormControlLabel, Radio, Dialog, Tooltip,} from "@material-ui/core"
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined"
// import Radio from '@material-ui/core/Radio';
import RadioGroup from "@material-ui/core/RadioGroup"
import CloseIcon from "@material-ui/icons/Close"
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/SbTaskFormData"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import moment from "moment"
import MomentUtils from "@date-io/moment"
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import { addSbFormApi, deleteAdAttachment, fillSbEditFormApi, UpdateSbTaskFormApi, uploadSbFile,} from "../apiService"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined"
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined"
import SbConfirmDeleteAttachment from "./SbConfirmDeleteAttachment"
import { regexConstants } from "../../../../constants/regEx"
import { permissionCheckFnforCamo } from "../../../../utils"

class SbTaskForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: {},
      data: formValues,
      regex: "^[0-9]+$",
      numberError: false,
      selectLastDDate: null,
      selectNextDueDate: null,
      attachments: [],
      newUploadedAttachments: [],
      remainingValue: "",
      btnStatus: "",
      open: false,
      deleteAttachmentId: 0,
      csn: this.props.headerData.csn,
      tsn: this.props.headerData.tsn,
      showSuccessUpload: false,
      globalValidationInputs: {},
      validatedFields: [],
    }
    this.addSbFormApi = addSbFormApi.bind(this)
    this.dropDownChange = this.dropDownChange.bind(this)
    this.dropDownChange1 = this.dropDownChange1.bind(this)
    this.dropDownChange2 = this.dropDownChange2.bind(this)
  }

  componentDidMount = () => {
    if (this.props.editFormId) {
      this.fillEditData()
    }
  }

  dropDownChange(e, index, value) {
    //set selection to the value selected
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        type: e.target.value,
      },
    }))
  }

  dropDownChange1(e, index, value) {
    //set selection to the value selected
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        sb_interval_type: e.target.value,
      },
    }))
  }

  dropDownChange2(e, index, value) {
    //set selection to the value selected
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        sb_compliance_status: e.target.value,
      },
    }))
  }

  onFieldChange = (keyParam, value) => {
    const { data } = this.state

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [keyParam]: value,
      },
    }))
  }

  onRestErrorKey = (keyParam) => {
    this.setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: "",
      },
    }))
  }
  handleSubmit = (e) => {
    e.preventDefault()
    let validationInputs = {}
    const { data } = this.state
    this.setState({ file: "" })

    validationInputs = {
      ...validationInputs,
      ata_chapter: taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter, })],
      sb_ref_number: taskErrorCode["sb_ref_number"][fieldValidation({ ...taskErrorCode["sb_ref_number_obj"], fieldval: data.sb_ref_number, })],
      sb_interval: taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
      description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description, })],
      revision: taskErrorCode["revision"][fieldValidation({ ...taskErrorCode["revision_obj"], fieldval: data.revision, })],
      revision_date: taskErrorCode["revision_date"][fieldValidation({ ...taskErrorCode["revision_date_obj"], fieldval: data.revision_date, })],
      service_bulletin: taskErrorCode["service_bulletin"][fieldValidation({ ...taskErrorCode["service_bulletin_obj"], fieldval: data.service_bulletin, })],
      last_done_fc: taskErrorCode["last_done_fc"][fieldValidation({ ...taskErrorCode["last_done_fc_obj"], fieldval: data.last_done_fc !== null ? String(data.last_done_fc) : "", })],
      last_done_fh: taskErrorCode["last_done_fh"][fieldValidation({ ...taskErrorCode["last_done_fh_obj"], fieldval: data.last_done_fh !== null ? String(data.last_done_fh) : "", })],
      dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
      dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
    }
    if (data.sb_compliance_status == "COMPLIED") {
      validationInputs = {
        ...validationInputs,
        sb_compliance_tsn: taskErrorCode["sb_compliance_tsn"][fieldValidation({ ...taskErrorCode["sb_compliance_tsn_obj"], fieldval: data.sb_compliance_tsn, })],
        sb_compliance_csn: taskErrorCode["sb_compliance_csn"][fieldValidation({ ...taskErrorCode["sb_compliance_csn_obj"], fieldval: data.sb_compliance_csn, })],
        sb_compliance_date: taskErrorCode["sb_compliance_date"][fieldValidation({ ...taskErrorCode["sb_compliance_date_obj"], fieldval: data.sb_compliance_date, })],
      }
    }
    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))

      // add task api
      const props = this.props.props

      const SbData = data
      delete SbData.ad_due
      delete SbData.remaining_val
      SbData.asset_id = props.match.params.asset
      SbData.asset_type_id = props.match.params.assetType
      SbData.last_done_fc =
        SbData && SbData.last_done_fc === "" ? null : SbData.last_done_fc
      SbData.last_done_fh =
        SbData && SbData.last_done_fh === "" ? null : SbData.last_done_fh
      addSbFormApi(SbData, props).then(async (response) => {
        const uploadId = response.data.data.id
        if (uploadId) {
          await this.uploadFileTest(uploadId, props, false)
        }
      })
      if (this.state.btnStatus !== "addAnother") {
        this.props.closeAddForm()
        this.setState((prevState) => ({
          error: {
            ...prevState.error,
            sb_interval: "",
          },
        }))
      } else {
        this.setState((prevState) => ({
          error: {
            ...prevState.error,
            sb_interval: "",
          },
        }))
        return false
      }
    } else {
      this.setState({ error: validationInputs })
    }
  }

  // edit task form
  fillEditData = () => {
    const updateId = this.props.editFormId
    this.setState({ attachments: this.props.attachments })
    if (updateId === undefined || updateId === null) {
      this.setState({
        data: {},
      })
    } else {
      this.setState({
        data: this.props.editFormData,
      })
    }
  }

  onFocusValidation = (keyParam) => {
    let validationInputs = {}
    const { data } = this.state
    validationInputs = {
      ...validationInputs,
      ata_chapter: taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter, })],
      sb_ref_number: taskErrorCode["sb_ref_number"][fieldValidation({ ...taskErrorCode["sb_ref_number_obj"], fieldval: data.sb_ref_number, })],
      sb_interval: taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval, })],
      description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description, })],
      revision: taskErrorCode["revision"][fieldValidation({ ...taskErrorCode["revision_obj"], fieldval: data.revision, })],
      revision_date: taskErrorCode["revision_date"][fieldValidation({ ...taskErrorCode["revision_date_obj"], fieldval: data.revision_date, })],
      service_bulletin: taskErrorCode["service_bulletin"][fieldValidation({ ...taskErrorCode["service_bulletin_obj"], fieldval: data.service_bulletin, })],
      last_done_fc: taskErrorCode["last_done_fc"][fieldValidation({ ...taskErrorCode["last_done_fc_obj"], fieldval: data.last_done_fc !== null ? String(data.last_done_fc) : "", })],
      last_done_fh: taskErrorCode["last_done_fh"][fieldValidation({ ...taskErrorCode["last_done_fh_obj"], fieldval: data.last_done_fh !== null ? String(data.last_done_fh) : "", })],
      dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
      dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],

    
    }
    if (data.sb_compliance_status == "COMPLIED") {
      validationInputs = {
        ...validationInputs,
        sb_compliance_tsn: taskErrorCode["sb_compliance_tsn"][fieldValidation({ ...taskErrorCode["sb_compliance_tsn_obj"], fieldval: data.sb_compliance_tsn, })],
        sb_compliance_csn: taskErrorCode["sb_compliance_csn"][fieldValidation({ ...taskErrorCode["sb_compliance_csn_obj"], fieldval: data.sb_compliance_csn, })],
        sb_compliance_date: taskErrorCode["sb_compliance_date"][fieldValidation({ ...taskErrorCode["sb_compliance_date_obj"], fieldval: data.sb_compliance_date, })],
      }
    }

    // for (let key in validationInputs) {
    //   if (key === keyParam) {
    //     this.state.globalValidationInputs[key] = validationInputs[key]
    //   }
    // }
    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))
      this.setState({ validationInputs: {} })
    } else {
      this.setState({ error: validationInputs })
    }
  }

  updateData = async (e) => {
    e.preventDefault()
    let validationInputs = {}
    const updateId = this.props.editFormId
    const updatedData = this.state.data
    const { data } = this.state
    updatedData.last_done_fc =
      updatedData && updatedData.last_done_fc === ""
        ? null
        : updatedData.last_done_fc
    updatedData.last_done_fh =
      updatedData && updatedData.last_done_fh === ""
        ? null
        : updatedData.last_done_fh
    const props = this.props.props

    // console.log("ata_chapter: ", typeof data.ata_chapter);

    // Perform field validations

    validationInputs = {
      ...validationInputs,
      ata_chapter: taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter !== null ? String(data.ata_chapter) : "", })],
      sb_ref_number: taskErrorCode["sb_ref_number"][fieldValidation({ ...taskErrorCode["sb_ref_number_obj"], fieldval: data.sb_ref_number !== null ? String(data.sb_ref_number) : "", })],
      sb_interval: taskErrorCode["sb_interval"][fieldValidation({ ...taskErrorCode["sb_interval_obj"], fieldval: data.sb_interval !== null ? String(data.sb_interval) : "", })],
      description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description !== null ? String(data.description) : "", })],
      revision: taskErrorCode["revision"][fieldValidation({ ...taskErrorCode["revision_obj"], fieldval: data.revision !== null ? String(data.revision) : "", })],
      revision_date: taskErrorCode["revision_date"][fieldValidation({ ...taskErrorCode["revision_date_obj"], fieldval: data.revision_date !== null ? String(data.revision_date) : "", })],
      service_bulletin: taskErrorCode["service_bulletin"][fieldValidation({ ...taskErrorCode["service_bulletin_obj"], fieldval: data.service_bulletin !== null ? String(data.service_bulletin) : "", })],
      last_done_fc: taskErrorCode["last_done_fc"][fieldValidation({ ...taskErrorCode["last_done_fc_obj"], fieldval: data.last_done_fc !== null ? String(data.last_done_fc) : "", })],
      last_done_fh: taskErrorCode["last_done_fh"][fieldValidation({ ...taskErrorCode["last_done_fh_obj"], fieldval: data.last_done_fh !== null ? String(data.last_done_fh) : "", })],
      dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
      dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],

    }

    if (data.sb_compliance_status == "COMPLIED") {
      validationInputs = {
        ...validationInputs,
        sb_compliance_tsn: taskErrorCode["sb_compliance_tsn"][fieldValidation({ ...taskErrorCode["sb_compliance_tsn_obj"], fieldval: data.sb_compliance_tsn, })],
        sb_compliance_csn: taskErrorCode["sb_compliance_csn"][fieldValidation({ ...taskErrorCode["sb_compliance_csn_obj"], fieldval: data.sb_compliance_csn, })],
        sb_compliance_date: taskErrorCode["sb_compliance_date"][fieldValidation({ ...taskErrorCode["sb_compliance_date_obj"], fieldval: data.sb_compliance_date, })],
      }
    }

    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      // Validations passdebuggerdate data
      delete updatedData.ad_due
      delete updatedData.remaining_val
      updatedData.asset_id = props.match.params.asset
      updatedData.asset_type_id = props.match.params.assetType

      await this.uploadFileTest(updateId, props, true)
      await this.props.updateFormData(updateId, updatedData, props)
      this.setState((prevState) => ({
        error: {
          ...prevState.error,
          sb_interval: "",
        },
      }))
      this.props.closeAddForm()
    } else {
      // Validations failed, update error state
      this.setState({ error: validationInputs })
    }
  }

  uploadFileTest = async (uploadId, props, isUpdate) => {
    if (this.state.newUploadedAttachments.length > 0) {
      let newAttachmentObj = []
      let formdata = new FormData()
      const module_type_id = 5

      Object.keys(this.state.newUploadedAttachments).map((key, index) => {
        newAttachmentObj.push({
          file: this.state.newUploadedAttachments[key],
          file_name: this.state.newUploadedAttachments[key].name,
          file_type: this.state.newUploadedAttachments[key].type,
          module_type_id,
          module_id: uploadId,
        })
        formdata.append( "[" + key + "]file", this.state.newUploadedAttachments[key])
        formdata.append( "[" + key + "]file_type", this.state.newUploadedAttachments[key].type)
        formdata.append( "[" + key + "]file_name", this.state.newUploadedAttachments[key].name)
        formdata.append("[" + key + "]module_id", uploadId)
        formdata.append("[" + key + "]module_type_id", module_type_id)
      })
      // uploadSbFile(uploadId, formdata, props)
      this.props.fileUploadData(uploadId, formdata, props, true)
      if (this.state.btnStatus === "addAnother") {
        this.resetBothAttachmentArray()
      }
    } else {
      if (this.state.btnStatus === "addAnother") {
        this.setState((prevState) => ({
          ...prevState,
          data: formValues,
          attachments: [],
        }))
        document.getElementById("asset-form").reset()
        this.props.fileUploadData(uploadId, null, props, false)
      } else {
        if (!isUpdate) {
          this.props.fileUploadData(uploadId, null, props, false)
        }
      }
    }
  }

  resetBothAttachmentArray = () => {
    this.setState((prevState) => ({
      ...prevState,
      data: formValues,
      attachments: [],
      newUploadedAttachments: [],
      open: false,
      deleteAttachmentId: 0,
    }))
    document.getElementById("asset-form").reset()
  }

  onUpload = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    for (let i = 0; i < chosenFiles.length; i++) {
      if (chosenFiles[i].size > 52428800) {
        this.props.props.enqueueSnackbar(
          `${chosenFiles[i].name} Maximum file size should be 50MB.`,
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        )
        delete chosenFiles[i]
        continue
      } else {
        this.handleUploadFiles(chosenFiles)
        // document.querySelector(".MuiDialog-paper").scrollTop = 800;
        this.setState({
          showSuccessUpload: true,
        })
        setTimeout(() => {
          if (this.state.attachments) {
            this.setState({
              showSuccessUpload: false,
            })
          }
        }, 2500)
      }
    }
  }

  handleUploadFiles = (files) => {
    const uploaded = [...this.state.newUploadedAttachments]
    const existingAttachments = [...this.state.attachments]
    files.some((file) => {
      uploaded.push(file)
    })
    files.map((file) => {
      var objc = {
        id: 0,
        module_id: 0,
        module_type_id: 3,
        file_name: file.name,
        file_type: file.type,
        file: "",
        name: file.name,
      }
      existingAttachments.push(objc)
    })

    this.setState({
      newUploadedAttachments: uploaded,
      attachments: existingAttachments,
    })
  }
  nextDueValue = () => {
    const { data } = this.state
    if (data.sb_interval_type === "D") {
      data.sb_due = moment(data.last_done_date, "YYYY-MM-DD").add(data.sb_interval, "d").format("DD-MM-YYYY")
    } else if (data.sb_interval_type === "FC") {
      data.sb_due = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2)
    } else if (data.sb_interval_type === "FH") {
      data.sb_due = Number.isInteger(+data.last_done_fh + +data.sb_interval) && (+data.last_done_fh + +data.sb_interval).toFixed(2)
    } else if (data.sb_interval_type === "FC,D") {
      const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2)
      const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY");
      data.sb_due = fcDue < moment(dateDue, "DD-MM-YYYY") ? fcDue : dateDue;
    } else if (data.sb_interval_type === "FH,D") {
      const fhDue = Number.isInteger(+data.last_done_fh + +data.sb_interval) && (+data.last_done_fh + +data.sb_interval).toFixed(2)
      const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY");
      data.sb_due = fhDue < moment(dateDue, "DD-MM-YYYY") ? fhDue : dateDue;
    } else if (data.sb_interval_type === "FC,FH,D") {
      const fhDue = Number.isInteger(+data.last_done_fh + +data.sb_interval) && (+data.last_done_fh + +data.sb_interval).toFixed(2)
      const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2)
      const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY");

      if (fhDue <= fcDue && fhDue <= moment(dateDue, "DD-MM-YYYY")) {
        data.sb_due = fhDue;
      } else if (fcDue <= fhDue && fcDue <= moment(dateDue, "DD-MM-YYYY")) {
        data.sb_due = fcDue;
      } else {
        data.sb_due = dateDue;
      }
    } else if (data.sb_interval_type === "FC,FH") {
      const fcDue = Number.isInteger(+data.last_done_fh + +data.sb_interval) && (+data.last_done_fh + +data.sb_interval).toFixed(2)
      const fhDue = Number.isInteger(+data.last_done_fc + +data.sb_interval) && (+data.last_done_fc + +data.sb_interval).toFixed(2)
      data.sb_due = fcDue < fhDue ? fcDue : fhDue;
    }
    return data.sb_due
  }
  remainingVal = () => {
    const { data } = this.state
    var date = new Date()
    const lDate = moment(data.last_done_date)
    const todayD = moment(date)
    let remDay = lDate.diff(todayD, "days")
    if (data.sb_interval_type === "D") {
      data.remaining_val = moment(remDay).add(data.sb_interval)
    } else if (data.sb_interval_type === "FC") {
      data.remaining_val = Number.isInteger(data.sb_due - this.props.headerCsn) && (data.sb_due - this.props.headerCsn).toFixed(2)
    } else if (data.sb_interval_type === "FH") {
      data.remaining_val = Number.isInteger(data.sb_due - this.props.headerTsn) && (data.sb_due - this.props.headerTsn).toFixed(2)
    } else if (data.sb_interval_type === "FC,D") {
      const fcRem_val = Number.isInteger(data.sb_due - this.props.headerCsn) && (data.sb_due - this.props.headerCsn).toFixed(2)
      const dRem_val = moment(remDay).add(data.dimension_interval_days)
      data.remaining_val = fcRem_val < moment(dRem_val, "DD-MM-YYYY") ? fcRem_val : dRem_val;
    } else if (data.sb_interval_type === "FH,D") {
      const fhRem_val = Number.isInteger(data.sb_due - this.props.headerTsn) && (data.sb_due - this.props.headerTsn).toFixed(2)
      const dRem_val = moment(remDay).add(data.dimension_interval_days)
      data.remaining_val = fhRem_val < moment(dRem_val, "DD-MM-YYYY") ? fhRem_val : dRem_val;
    } else if (data.sb_interval_type === "FC,FH,D") {
      const fcRem_val = Number.isInteger(data.sb_due - this.props.headerCsn) && (data.sb_due - this.props.headerCsn).toFixed(2)
      const fhRem_val = Number.isInteger(data.sb_due - this.props.headerTsn) && (data.sb_due - this.props.headerTsn).toFixed(2)
      const dRem_val = moment(remDay).add(data.dimension_interval_days)

      if (fhRem_val <= fcRem_val && fhRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
        data.remaining_val = fhRem_val;
      } else if (fcRem_val <= fhRem_val && fcRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
        data.remaining_val = fcRem_val;
      } else {
        data.remaining_val = dRem_val;
      }
    } else if (data.sb_interval_type === "FC,FH") {
      const fcRem_val = Number.isInteger(data.sb_due - this.props.headerCsn) && (data.sb_due - this.props.headerCsn).toFixed(2)
      const fhRem_val = Number.isInteger(data.sb_due - this.props.headerTsn) && (data.sb_due - this.props.headerTsn).toFixed(2)
      data.remaining_val = fcRem_val < fhRem_val ? fcRem_val : fhRem_val;
    }
    return data.remaining_val
  }

  handleClickAttachment = (e, id) => {
    this.setState({ open: true })
    this.setState({ deleteAttachmentId: id })
  }

  handleCloseAttachment = (e) => {
    this.setState({ open: false })
    this.setState({ deleteAttachmentId: 0 })
  }

  deleteAttachmentFile = async (id) => {
    if (id !== undefined && id != 0) {
      const props = this.props.props
      deleteAdAttachment(id, props)
      var array = [...this.state.attachments]
      array = array.filter((item) => item.id !== id)
      this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
    }
  }

  removeAttachment = (e, obj) => {
    var array = [...this.state.attachments]
    var newuploadarray = [...this.state.newUploadedAttachments]

    var filteredArray = []

    newuploadarray.map((file) => {
      if (file.name == obj.file_name && file.type == obj.file_type) {
      } else {
        filteredArray.push(file)
      }
    })
    // Set the state with the new filtered array
    array = array.filter((item) => item !== obj)
    this.setState({
      newUploadedAttachments: filteredArray,
      attachments: array,
    })
  }

  saveAndAddAnother = async (e) => {
    await this.setState({ btnStatus: "addAnother" })
    await this.handleSubmit(e)
  }

  addFormData = async (e) => {
    await this.setState({ btnStatus: "add" })
    this.handleSubmit(e)
  }

  render() {
    const fileTypes = [ ".GIF", ".PDF", ".DOC", ".DOCX", ".XLSX", ".TXT", ".XLS", "image/*",]
    const { data, error } = this.state
    return (
      <Fragment>
        <div id="AmpTaskForm">
          <div id="HtTaskForm">
            <DialogTitle>
              {this.props.formTitle}
              <CloseIcon
                onClick={this.props.closeAddForm}
                className="close-icon"
              />
            </DialogTitle>
            <form
              id="asset-form"
              onSubmit={
                this.props.actionType === "add"
                  ? (e) => this.handleSubmit(e)
                  : (e) => this.updateData(e)
              }
            >
              <DialogContent>
                <Grid spacing={1} container>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          ATA Chapter<span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="ata_chapter"
                      id="ata_chapter"
                      value={data.ata_chapter}
                      error={error.ata_chapter ? true : false}
                      helperText={error.ata_chapter ? error.ata_chapter : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 15 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("ata_chapter", input)
                          this.onRestErrorKey("ata_chapter")
                        } else if (
                          input.length > 15 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 15)
                          this.onFieldChange("ata_chapter", truncatedInput)
                          this.onRestErrorKey("ata_chapter")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 15 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("ata_chapter", input)
                          this.onRestErrorKey("ata_chapter")
                        } else if (
                          input.length > 15 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 15)
                          this.onFieldChange("ata_chapter", truncatedInput)
                          this.onRestErrorKey("ata_chapter")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 15 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("ata_chapter", input)
                          this.onRestErrorKey("ata_chapter")
                        } else if (
                          input.length > 15 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 15)
                          this.onFieldChange("ata_chapter", truncatedInput)
                          this.onRestErrorKey("ata_chapter")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("ata_chapter")}
                      onBlur={() => this.onFocusValidation("ata_chapter")}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          SB Reference Number
                          <span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="sb_ref_number"
                      id="sb_ref_number"
                      value={data.sb_ref_number}
                      error={error.sb_ref_number ? true : false}
                      helperText={
                        error.sb_ref_number ? error.sb_ref_number : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("sb_ref_number", input)
                          this.onRestErrorKey("sb_ref_number")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("sb_ref_number", truncatedInput)
                          this.onRestErrorKey("sb_ref_number")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("sb_ref_number")}
                      onBlur={() => this.onFocusValidation("sb_ref_number")}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label="SB Issued by"
                      name="sb_issued_by"
                      id="sb_issued_by"
                      value={data.sb_issued_by}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("sb_issued_by", input)
                          this.onRestErrorKey("sb_issued_by")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("sb_issued_by", truncatedInput)
                          this.onRestErrorKey("sb_issued_by")
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={<React.Fragment>SB Applicability</React.Fragment>}
                      name="sb_applicability"
                      id="sb_applicability"
                      value={data.sb_applicability}
                      error={error.sb_applicability ? true : false}
                      helperText={
                        error.sb_applicability ? error.sb_applicability : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("sb_applicability", input)
                          this.onRestErrorKey("sb_applicability")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("sb_applicability", truncatedInput)
                          this.onRestErrorKey("sb_applicability")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("sb_applicability")}
                    />
                  </Grid>
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          SB Description<span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="description"
                      id="description"
                      multiline
                      minRows={5}
                      value={data.description}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 255) {
                          this.onFieldChange("description", e.target.value)
                        } else {
                          const truncatedInput = input.slice(0, 255)
                          this.onFieldChange("description", truncatedInput)
                          this.onRestErrorKey("description")
                        }
                      }}
                      error={error.description ? true : false}
                      helperText={error.description ? error.description : ""}
                      onFocus={() => this.onRestErrorKey("description")}
                      onBlur={() => this.onFocusValidation("description")}
                    />
                  </Grid>
                </Grid>

                <div className="border-grey"></div>

                <Grid spacing={1} container>
                  <Grid item xs={4}>
                    <Grid container id="ht-dropdown">
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{ padding: "0px 14px" }}
                          >
                            SB Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant="outlined"
                            value={data.type}
                            onChange={this.dropDownChange}
                          >
                            <MenuItem value={"ALERT"}>Alert</MenuItem>
                            <MenuItem value={"STANDARD"}>Standard</MenuItem>
                            <MenuItem value={"OPTIONAL"}>Optional</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="SB Effectivity"
                      name="effectivity"
                      id="effectivity"
                      value={data.effectivity}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("effectivity", input)
                          this.onRestErrorKey("effectivity")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("effectivity", truncatedInput)
                          this.onRestErrorKey("effectivity")
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} className="input-calender">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        id="effectivity_date"
                        label={
                          <React.Fragment>SB Effective Date</React.Fragment>
                        }
                        format={fieldDateFormat}
                        inputVariant="outlined"
                        // autoOk={true}
                        fullWidth
                        disableFuture
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(25, "years")}
                        value={data.effectivity_date}
                        error={error.effectivity_date ? true : false}
                        helperText={
                          error.effectivity_date ? error.effectivity_date : ""
                        }
                        onFocus={() => this.onRestErrorKey("effectivity_date")}
                        onChange={(data, value) => {
                          this.onFieldChange(
                            "effectivity_date",
                            moment(data).format(backendDateFormat)
                          )
                          this.onRestErrorKey("effectivity_date")
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Grid spacing={1} container>
                  <Grid item xs={4} className="input-calender">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        id="last_done_date"
                        label="Last Done Date"
                        format={fieldDateFormat}
                        inputVariant="outlined"
                        // autoOk={true}
                        fullWidth
                        // disableFuture
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(25, "years")}
                        value={data.last_done_date}
                        error={error.last_done_date ? true : false}
                        helperText={
                          error.last_done_date ? error.last_done_date : ""
                        }
                        onFocus={() => this.onRestErrorKey("last_done_date")}
                        onChange={(data, value) =>
                          this.onFieldChange(
                            "last_done_date",
                            moment(data).format(backendDateFormat)
                          )
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="Last Done FH"
                      name="last_done_fh"
                      id="last_done_fh"
                      value={data.last_done_fh}
                      error={error.last_done_fh ? true : false}
                      helperText={error.last_done_fh ? error.last_done_fh : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("last_done_fh", input)
                          this.onRestErrorKey("last_done_fh")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("last_done_fh", truncatedInput)
                          this.onRestErrorKey("last_done_fh")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("last_done_fh", input)
                          this.onRestErrorKey("last_done_fh")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("last_done_fh", truncatedInput)
                          this.onRestErrorKey("last_done_fh")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("last_done_fh", input)
                          this.onRestErrorKey("last_done_fh")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("last_done_fh", truncatedInput)
                          this.onRestErrorKey("last_done_fh")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("last_done_fh")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="Last Done FC"
                      name="last_done_fc"
                      id="last_done_fc"
                      value={data.last_done_fc}
                      error={error.last_done_fc ? true : false}
                      helperText={error.last_done_fc ? error.last_done_fc : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("last_done_fc", input)
                          this.onRestErrorKey("last_done_fc")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("last_done_fc", truncatedInput)
                          this.onRestErrorKey("last_done_fc")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("last_done_fc", input)
                          this.onRestErrorKey("last_done_fc")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("last_done_fc", truncatedInput)
                          this.onRestErrorKey("last_done_fc")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("last_done_fc", input)
                          this.onRestErrorKey("last_done_fc")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("last_done_fc", truncatedInput)
                          this.onRestErrorKey("last_done_fc")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("last_done_fc")}
                    />
                  </Grid>
                </Grid>

                <div className="border-grey"></div>
                {/* copied */}
                <Grid id="data-container" spacing={1} container>
                  <Grid item xs={3}>
                    <label>INTERVAL</label>
                    <Grid spacing={2} container id="ht-dropdown">
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{ padding: "0px 14px" }}
                          >
                            SB Compliance Period
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="sb_interval_type"
                            variant="outlined"
                            value={data.sb_interval_type}
                            onChange={this.dropDownChange1}
                          >
                            <MenuItem value={"D"}>Days</MenuItem>
                            <MenuItem value={"FH"}>FH</MenuItem>
                            <MenuItem value={"FC"}>FC</MenuItem>
                            <MenuItem value={"FC,D"}>FC & D</MenuItem>
                            <MenuItem value={"FH,D"}>FH & D</MenuItem>
                            <MenuItem value={"FC,FH,D"}>FH,FC & D</MenuItem>
                            <MenuItem value={"FC,FH"}>FC & FH</MenuItem>

                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
               <Grid id="dimensions-input" item xs={3}>
                    <Grid spacing={2} container>
                      {data.sb_interval_type == "FC" || data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH" || data.sb_interval_type == "FC,FH,D" ?<Grid
                        item
                        xs={ data.sb_interval_type == "FC" ? 12 : data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH"  ? 6 :  data.sb_interval_type == "FC,FH,D" ? 4 : 12}
                      >
                      <TextField
                          variant="outlined"
                          label={data.sb_interval_type == "FC" || data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH,D"  ||  data.sb_interval_type == "FC,FH" ? "FC Interval" : "FC, Interval"}
                          name="sb_interval"
                          id="sb_interval"
                          value={data.dimension_interval_fc}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {
                            const input = e.target.value
                            if (
                              regexConstants.onlyNumeric.test(input) &&
                              input.length <= 5
                            ) {
                              this.onFieldChange("dimension_interval_fc", input)
                              this.onRestErrorKey("dimension_interval_fc")
                            } else if (
                              regexConstants.onlyNumeric.test(input) &&
                              input.length > 5
                            ) {
                              const truncatedInput = input.slice(0, 5)
                              this.onFieldChange("dimension_interval_fc", truncatedInput)
                              this.onRestErrorKey("dimension_interval_fc")
                            } else {
                              e.preventDefault()
                              this.setState({
                                error: {
                                  dimension_interval_fc:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            }
                          }}
                          onKeyPress={(e) => {
                            const input = e.target.value
                            if (
                              regexConstants.onlyNumeric.test(input) &&
                              input.length <= 5
                            ) {
                              this.onFieldChange("dimension_interval_fc", input)
                              this.onRestErrorKey("dimension_interval_fc")
                            } else if (
                              regexConstants.onlyNumeric.test(input) &&
                              input.length > 5
                            ) {
                              const truncatedInput = input.slice(0, 5)
                              this.onFieldChange("dimension_interval_fc", truncatedInput)
                              this.onRestErrorKey("dimension_interval_fc")
                            } else {
                              e.preventDefault()
                              this.setState({
                                error: {
                                  dimension_interval_fc:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            }
                          }}
                          onPaste={(e) => {
                            const input = e.target.value
                            if (
                              regexConstants.onlyNumeric.test(input) &&
                              input.length <= 5
                            ) {
                              this.onFieldChange("dimension_interval_fc", input)
                              this.onRestErrorKey("dimension_interval_fc")
                            } else if (
                              regexConstants.onlyNumeric.test(input) &&
                              input.length > 5
                            ) {
                              const truncatedInput = input.slice(0, 5)
                              this.onFieldChange("dimension_interval_fc", truncatedInput)
                              this.onRestErrorKey("dimension_interval_fc")
                            } else {
                              e.preventDefault()
                              this.setState({
                                error: {
                                  dimension_interval_fc:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            }
                          }}
                          autoComplete="off"
                          error={error.dimension_interval_fc ? true : false}
                          helperText={error.dimension_interval_fc ? error.dimension_interval_fc : ""}
                          onFocus={() => this.onRestErrorKey("dimension_interval_fc")}
                        />
                      </Grid>: null}
                      {data.sb_interval_type == "FH" || data.sb_interval_type == "FH,D" || data.sb_interval_type == "FC,FH" || data.sb_interval_type == "FC,FH,D" ?<Grid
                        item
                        xs={ data.sb_interval_type == "FH" ? 12 : data.sb_interval_type == "FH,D" || data.sb_interval_type == "FC,FH"  ? 6 :  data.sb_interval_type == "FC,FH,D" ? 4 : 12}
                      >
                      <TextField
                          variant="outlined"
                          label= {data.sb_interval_type == "FH" || data.sb_interval_type == "FH,D" || data.sb_interval_type == "FC,FH,D"  ||  data.sb_interval_type == "FC,FH" ? "FH Interval" : "FH, Interval"}
                          name="sb_interval"
                          id="sb_interval"
                          value={data.sb_interval}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {
                            const input = e.target.value
                            if (
                              regexConstants.onlyNumeric.test(input) &&
                              input.length <= 5
                            ) {
                              this.onFieldChange("sb_interval", input)
                              this.onRestErrorKey("sb_interval")
                            } else if (
                              regexConstants.onlyNumeric.test(input) &&
                              input.length > 5
                            ) {
                              const truncatedInput = input.slice(0, 5)
                              this.onFieldChange("sb_interval", truncatedInput)
                              this.onRestErrorKey("sb_interval")
                            } else {
                              e.preventDefault()
                              this.setState({
                                error: {
                                  sb_interval:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            }
                          }}
                          onKeyPress={(e) => {
                            const input = e.target.value
                            if (
                              regexConstants.onlyNumeric.test(input) &&
                              input.length <= 5
                            ) {
                              this.onFieldChange("sb_interval", input)
                              this.onRestErrorKey("sb_interval")
                            } else if (
                              regexConstants.onlyNumeric.test(input) &&
                              input.length > 5
                            ) {
                              const truncatedInput = input.slice(0, 5)
                              this.onFieldChange("sb_interval", truncatedInput)
                              this.onRestErrorKey("sb_interval")
                            } else {
                              e.preventDefault()
                              this.setState({
                                error: {
                                  sb_interval:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            }
                          }}
                          onPaste={(e) => {
                            const input = e.target.value
                            if (
                              regexConstants.onlyNumeric.test(input) &&
                              input.length <= 5
                            ) {
                              this.onFieldChange("sb_interval", input)
                              this.onRestErrorKey("sb_interval")
                            } else if (
                              regexConstants.onlyNumeric.test(input) &&
                              input.length > 5
                            ) {
                              const truncatedInput = input.slice(0, 5)
                              this.onFieldChange("sb_interval", truncatedInput)
                              this.onRestErrorKey("sb_interval")
                            } else {
                              e.preventDefault()
                              this.setState({
                                error: {
                                  sb_interval:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            }
                          }}
                          autoComplete="off"
                          error={error.sb_interval ? true : false}
                          helperText={error.sb_interval ? error.sb_interval : ""}
                          onFocus={() => this.onRestErrorKey("sb_interval")}
                        />
                      </Grid>: null}
                      {data.sb_interval_type == "D" || data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH,D" ||
                      data.sb_interval_type == "FH,D" ? (
                        <Grid item
                        xs={data.sb_interval_type == "D" ? 12 : data.sb_interval_type == "FC,D" || data.sb_interval_type == "FH,D"  ? 6 : data.sb_interval_type == "FC,FH,D" ? 4: 12 }
                         >
                          <TextField
                            variant="outlined"
                            label={
                              <React.Fragment>
                                Days<span style={{ color: "red" }}> *</span>
                              </React.Fragment>
                            }
                            name="dimension_interval_days"
                            id="dimension_interval_days"
                            value={data.dimension_interval_days}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e, value) => {
                              const input = e.target.value
                              if (
                                input.length <= 5 &&
                                regexConstants.onlyNumeric.test(e.target.value)
                              ) {
                                this.onFieldChange(
                                  "dimension_interval_days",
                                  input
                                )
                                this.onRestErrorKey("dimension_interval_days")
                              } else if (
                                input.length > 5 &&
                                regexConstants.onlyNumeric.test(input)
                              ) {
                                const truncatedInput = input.slice(0, 5)
                                this.onFieldChange(
                                  "dimension_interval_days",
                                  truncatedInput
                                )
                                this.onRestErrorKey("dimension_interval_days")
                                this.setState({
                                  error: {
                                    dimension_interval_days:
                                      "Please enter a 5 digit integer value.",
                                  },
                                })
                              } else {
                                e.preventDefault()
                                this.setState({
                                  error: {
                                    dimension_interval_days:
                                      "Please enter a 5 digit integer value.",
                                  },
                                })
                              }
                            }}
                            onKeyPress={(e, value) => {
                              const input = e.target.value
                              if (
                                input.length <= 5 &&
                                regexConstants.onlyNumeric.test(e.target.value)
                              ) {
                                this.onFieldChange(
                                  "dimension_interval_days",
                                  input
                                )
                                this.onRestErrorKey("dimension_interval_days")
                              } else if (
                                input.length > 5 &&
                                regexConstants.onlyNumeric.test(input)
                              ) {
                                const truncatedInput = input.slice(0, 5)
                                this.onFieldChange(
                                  "dimension_interval_days",
                                  truncatedInput
                                )
                                this.onRestErrorKey("dimension_interval_days")
                                this.setState({
                                  error: {
                                    dimension_interval_days:
                                      "Please enter a 5 digit integer value.",
                                  },
                                })
                              } else {
                                e.preventDefault()
                                this.setState({
                                  error: {
                                    dimension_interval_days:
                                      "Please enter a 5 digit integer value.",
                                  },
                                })
                              }
                            }}
                            onPaste={(e, value) => {
                              const input = e.target.value
                              if (
                                input.length <= 5 &&
                                regexConstants.onlyNumeric.test(e.target.value)
                              ) {
                                this.onFieldChange(
                                  "dimension_interval_days",
                                  input
                                )
                                this.onRestErrorKey("dimension_interval_days")
                              } else if (
                                input.length > 5 &&
                                regexConstants.onlyNumeric.test(input)
                              ) {
                                const truncatedInput = input.slice(0, 5)
                                this.onFieldChange(
                                  "dimension_interval_days",
                                  truncatedInput
                                )
                                this.onRestErrorKey("dimension_interval_days")
                                this.setState({
                                  error: {
                                    dimension_interval_days:
                                      "Please enter a 5 digit integer value.",
                                  },
                                })
                              } else {
                                this.setState({
                                  error: {
                                    dimension_interval_days:
                                      "Please enter a 5 digit integer value.",
                                  },
                                })
                                e.preventDefault()
                              }
                            }}
                            autoComplete="off"
                            error={error.dimension_interval_days ? true : false}
                            helperText={
                              error.dimension_interval_days
                                ? error.dimension_interval_days
                                : ""
                            }
                            onFocus={() =>
                              this.onRestErrorKey("dimension_interval_days")
                            }
                            onBlur={() =>
                              this.onFocusValidation("dimension_interval_days")
                            }
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <label>NEXT DUE </label>
                    <Grid spacing={2} container>
                      <Grid className="remaining-input" item xs={6}>
                        <TextField
                          variant="outlined"
                          label={data.sb_interval_type === "D" ? "Date" : data.sb_interval_type === "FC" ? "FC" : data.sb_interval_type === "FH" ? "FH" : data.sb_interval_type === "FH,D" ? "FH/Date" :
                            data.sb_interval_type === "FC,D" ? "FC/Date" : data.sb_interval_type === "FC,FH,D" ? "FH/FC/Date" : data.sb_interval_type === "FC,FH" ? "FC/FH " : "Date"}
                          name="ad_due"
                          id="ad_due"
                          value={this.nextDueValue()}
                          onChange={(e, value) =>
                            this.onFieldChange("ad_due", e.target.value)
                          }
                          inputProps={{ readOnly: true }}
                          disabled
                          className="disabled-input"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <label>REMAINING</label>
                    <Grid spacing={1} container>
                      <Grid className="remaining-input" item xs={6}>
                        <TextField
                          variant="outlined"
                          label={data.sb_interval_type === "D" ? "Days" : data.sb_interval_type === "FC" ? "FC" : data.sb_interval_type === "FH" ? "FH" :
                            data.sb_interval_type === "FH,D" ? "FH/Days" : data.sb_interval_type === "FC,D" ? "FC/Days" : data.sb_interval_type === "FC,FH,D" ? "FH/FC/Days" : data.sb_interval_type === "FC,FH" ? "FC/FH" : "Days"}
                          name="remaining_val"
                          id="remaining_val"
                          value={this.remainingVal()}
                          onChange={(e, value) =>
                            this.onFieldChange("remaining_val", e.target.value)
                          }
                          inputProps={{ readOnly: true }}
                          disabled
                          className="disabled-input"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <div className="border-grey"></div>

                <Grid spacing={1} container>
                  <Grid item xs={3}>
                    <Grid container id="ht-dropdown">
                      <Grid item>
                        <FormControl>
                          <InputLabel
                            id="demo-simple-select-label"
                            style={{ padding: "0px 14px" }}
                          >
                            SB Compliance Status{" "}
                            <span style={{ color: "red" }}> *</span>
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant="outlined"
                            value={data.sb_compliance_status}
                            onChange={this.dropDownChange2}
                            onBlur={() =>
                              this.onFocusValidation("sb_compliance_status")
                            }
                          >
                            <MenuItem value={"OPEN"}>OPEN</MenuItem>
                            <MenuItem value={"COMPLIED"}>COMPLIED</MenuItem>
                            <MenuItem value={"FACTORY COMPLIED"}>
                              FACTORY COMPLIED
                            </MenuItem>
                            <MenuItem value={"PARTIALLY COMPLIED"}>
                              PARTIALLY COMPLIED
                            </MenuItem>
                            <MenuItem value={"INFORMATION ONLY"}>
                              INFORMATION ONLY
                            </MenuItem>
                            <MenuItem value={"CLOSED"}>CLOSED</MenuItem>
                            <MenuItem value={"NOT APPLICABLE"}>
                              NOT APPLICABLE
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          SB Compliance TSN
                          {data.sb_compliance_status === "COMPLIED" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : null}
                        </React.Fragment>
                      }
                      name="sb_compliance_tsn"
                      id="sb_compliance_tsn"
                      value={data.sb_compliance_tsn}
                      error={error.sb_compliance_tsn ? true : false}
                      helperText={
                        error.sb_compliance_tsn ? error.sb_compliance_tsn : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("sb_compliance_tsn", input)
                          this.onRestErrorKey("sb_compliance_tsn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange(
                            "sb_compliance_tsn",
                            truncatedInput
                          )
                          this.onRestErrorKey("sb_compliance_tsn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("sb_compliance_tsn")}
                      onBlur={() => this.onFocusValidation("sb_compliance_tsn")}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          SB Compliance CSN
                          {data.sb_compliance_status === "COMPLIED" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : null}
                        </React.Fragment>
                      }
                      name="sb_compliance_csn"
                      id="sb_compliance_csn"
                      value={data.sb_compliance_csn}
                      error={error.sb_compliance_csn ? true : false}
                      helperText={
                        error.sb_compliance_csn ? error.sb_compliance_csn : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("sb_compliance_csn", input)
                          this.onRestErrorKey("sb_compliance_csn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange(
                            "sb_compliance_csn",
                            truncatedInput
                          )
                          this.onRestErrorKey("sb_compliance_csn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("sb_compliance_csn", input)
                          this.onRestErrorKey("sb_compliance_csn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange(
                            "sb_compliance_csn",
                            truncatedInput
                          )
                          this.onRestErrorKey("sb_compliance_csn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          this.onFieldChange("sb_compliance_csn", input)
                          this.onRestErrorKey("sb_compliance_csn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(e.target.value)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange(
                            "sb_compliance_csn",
                            truncatedInput
                          )
                          this.onRestErrorKey("sb_compliance_csn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("sb_compliance_csn")}
                      onBlur={() => this.onFocusValidation("sb_compliance_csn")}
                    />
                  </Grid>
                  <Grid item xs={3} className="input-calender">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        id="sb_compliance_date"
                        label={
                          <React.Fragment>
                            SB Compliance Date
                            {data.sb_compliance_status === "COMPLIED" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : null}
                          </React.Fragment>
                        }
                        format={fieldDateFormat}
                        inputVariant="outlined"
                        // autoOk={true}
                        fullWidth
                        disableFuture
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(25, "years")}
                        value={data.sb_compliance_date}
                        error={error.sb_compliance_date ? true : false}
                        helperText={
                          error.sb_compliance_date
                            ? error.sb_compliance_date
                            : ""
                        }
                        onFocus={() =>
                          this.onRestErrorKey("sb_compliance_date")
                        }
                        onChange={(data, value) => {
                          this.onFieldChange(
                            "sb_compliance_date",
                            moment(data).format(backendDateFormat)
                          )
                          this.onRestErrorKey("sb_compliance_date")
                        }}
                        onFocusCapture={() =>
                          this.onFocusValidation("sb_compliance_date")
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Grid spacing={1} container>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Service Bulletin
                          <span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="service_bulletin"
                      id="service_bulletin"
                      value={data.service_bulletin}
                      error={error.service_bulletin ? true : false}
                      helperText={
                        error.service_bulletin ? error.service_bulletin : ""
                      }
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("service_bulletin", input)
                          this.onRestErrorKey("service_bulletin")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("service_bulletin", truncatedInput)
                          this.onRestErrorKey("service_bulletin")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("service_bulletin")}
                      onBlur={() => this.onFocusValidation("service_bulletin")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Revision
                          <span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="revision"
                      id="revision"
                      value={data.revision}
                      error={error.revision ? true : false}
                      helperText={error.revision ? error.revision : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 10) {
                          this.onFieldChange("revision", input)
                          this.onRestErrorKey("revision")
                        } else {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("revision", truncatedInput)
                          this.onRestErrorKey("revision")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("revision")}
                      onBlur={() => this.onFocusValidation("revision")}
                    />
                  </Grid>
                  <Grid item xs={4} className="input-calender">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        id="revision_date"
                        label={
                          <React.Fragment>
                            Revision Date
                            <span style={{ color: "red" }}> *</span>
                          </React.Fragment>
                        }
                        format={fieldDateFormat}
                        inputVariant="outlined"
                        // autoOk={true}
                        fullWidth
                        disableFuture
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(25, "years")}
                        value={data.revision_date}
                        error={error.revision_date ? true : false}
                        helperText={
                          error.revision_date ? error.revision_date : ""
                        }
                        onFocus={() => this.onRestErrorKey("revision_date")}
                        onChange={(data, value) => {
                          this.onFieldChange(
                            "revision_date",
                            moment(data).format(backendDateFormat)
                          )
                          this.onRestErrorKey("revision_date")
                        }}
                        onFocusCapture={() =>
                          this.onFocusValidation("revision_date")
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Grid spacing={1} container>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="AD"
                      name="ad_no"
                      id="ad_no"
                      value={data.ad_no}
                      error={error.ad_no ? true : false}
                      helperText={error.ad_no ? error.ad_no : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("ad_no", input)
                          this.onRestErrorKey("ad_no")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("ad_no", truncatedInput)
                          this.onRestErrorKey("ad_no")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("ad_no")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="Subject"
                      name="subject"
                      id="subject"
                      value={data.subject}
                      error={error.subject ? true : false}
                      helperText={error.subject ? error.subject : ""}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 30) {
                          this.onFieldChange("subject", input)
                          this.onRestErrorKey("subject")
                        } else {
                          const truncatedInput = input.slice(0, 30)
                          this.onFieldChange("subject", truncatedInput)
                          this.onRestErrorKey("subject")
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={<React.Fragment>Work Order</React.Fragment>}
                      name="workorder"
                      id="workorder"
                      value={data.workorder}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 25) {
                          this.onFieldChange("workorder", input)
                          this.onRestErrorKey("workorder")
                        } else {
                          const truncatedInput = input.slice(0, 25)
                          this.onFieldChange("workorder", truncatedInput)
                          this.onRestErrorKey("workorder")
                        }
                      }}
                      error={error.workorder ? true : false}
                      helperText={error.workorder ? error.workorder : ""}
                      onFocus={() => this.onRestErrorKey("workorder")}
                    />
                  </Grid>
                </Grid>

                <div className="border-grey"></div>
                <Grid id="data-container" spacing={3} container>
                  <Grid item xs={3}>
                    <label>SB Compliance DFP</label>
                    <FormControl
                      error={error.sb_compliance_dfp ? true : false}
                      helperText={
                        error.sb_compliance_dfp ? error.sb_compliance_dfp : ""
                      }
                      onFocus={() => this.onRestErrorKey("sb_compliance_dfp")}
                    >
                      <RadioGroup
                        required
                        row
                        aria-label="sb_compliance_dfp"
                        name="sb_compliance_dfp"
                        defaultValue="top"
                        value={data.sb_compliance_dfp}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "sb_compliance_dfp",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                      {error.sb_compliance_dfp && (
                        <FormLabel component="sb_compliance_dfp">
                          <span style={{ color: "red" }}>
                            {error.sb_compliance_dfp}
                          </span>
                        </FormLabel>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <label>Release Certificate</label>
                    <FormControl component="fieldset">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="release_certificate"
                        name="release_certificate"
                        defaultValue="top"
                        value={data.release_certificate}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "release_certificate",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <label>Certificate of Conformity</label>
                    <FormControl component="fieldset">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="certificate_of_conformity"
                        name="certificate_of_conformity"
                        defaultValue="top"
                        value={data.certificate_of_conformity}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "certificate_of_conformity",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                        <FormControlLabel
                          value="N/A"
                          control={<Radio size="small" color="primary" />}
                          label="N/A"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <div className="border-grey"></div>

                <Grid spacing={1} container>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      label="Remark"
                      name="remark"
                      id="remark"
                      multiline
                      minRows={5}
                      value={data.remark}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 255) {
                          this.onFieldChange("remark", e.target.value)
                        } else {
                          const truncatedInput = input.slice(0, 255)
                          this.onFieldChange("remark", truncatedInput)
                          this.onRestErrorKey("remark")
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="upload-textbox">
                      <Button variant="contained" component="label">
                        <div id="upload-success">
                          <span className="d-flex align-items-center">
                            <AddPhotoAlternateOutlinedIcon className="upload-icon" />
                            {/* <span className="upload-text upload">
                              {this.state.attachments.length === 0
                                ? "Upload "
                                : ""}
                            </span> */}
                            <input
                              className="upload-input"
                              onChange={this.onUpload}
                              id="fileUplaod"
                              name="file"
                              type="file"
                              accept={fileTypes}
                              multiple
                            />
                            <span className="upload-text">
                              {this.state.attachments.length === 0
                                ? "Upload or drop a file right here"
                                : " Upload Another?"}
                            </span>
                          </span>
                          <Tooltip title="JPG,PNG,GIF,PDF,DOC,DOCX,XLSX,TXT,JPEG,XLS">
                            <span className="upload-text overflow">
                              &nbsp;JPG,PNG,GIF,PDF,DOC,DOCX,XLSX,TXT,JPEG,XLS
                            </span>
                          </Tooltip>
                        </div>
                        {this.state.showSuccessUpload ? (
                          <div id="success-text">
                            <span className="upload-text">
                              Uploaded successfully!
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid id="data-container" spacing={1} container>
                  <Grid spacing={1} container>
                    <div className="d-flex align-items-center show-attachments">
                      {(() => {
                        if (
                          this.state.attachments &&
                          this.state.attachments.length > 0
                        ) {
                          return Object.keys(this.state.attachments).map(
                            (key1, index) => {
                              return (
                                <div
                                  className="position-relative d-flex align-items-center attachment-margin"
                                  key={index}
                                >
                                  <span className="file-name">
                                    {this.state.attachments[index].name}
                                  </span>
                                  <DescriptionOutlinedIcon className="file-icon" />
                                  {permissionCheckFnforCamo({
                                    primaryKey: "sb_status",
                                    keyIndex: "D",
                                  }) ||
                                  permissionCheckFnforCamo({
                                    primaryKey: "sb_status",
                                    keyIndex: "OD",
                                    userId: this.state.data.created_by
                                      ? this.state.data.created_by
                                      : undefined,
                                  }) ||
                                  this.state.attachments[index].id === 0 ? (
                                    <CancelOutlinedIcon
                                      className="cancel-icon"
                                      onClick={
                                        this.props.actionType === "add"
                                          ? (e) =>
                                              this.removeAttachment(
                                                e,
                                                this.state.attachments[index]
                                              )
                                          : (e) => {
                                              if (
                                                this.state.attachments[index]
                                                  .id === 0
                                              ) {
                                                this.removeAttachment(
                                                  e,
                                                  this.state.attachments[index]
                                                )
                                              } else {
                                                this.handleClickAttachment(
                                                  e,
                                                  this.state.attachments[index]
                                                    .id
                                                )
                                              }
                                            }
                                      }
                                    />
                                  ) : null}
                                </div>
                              )
                            }
                          )
                        }
                      })()}
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="actions-btn">
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={
                    this.props.actionType === "add"
                      ? (e) => this.addFormData(e)
                      : (e) => this.updateData(e)
                  }
                >
                  {this.props.actionType === "add" ? "Add" : "Update"}
                </Button>

                {this.props.actionType === "add" ? (
                  <Button
                    onClick={(e) => this.saveAndAddAnother(e)}
                    type="button"
                    className="btn btn-primary"
                  >
                    Save & Add Another
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.props.closeAddForm}
                >
                  Close
                </Button>
              </DialogActions>
              <Dialog
                open={this.state.open}
                onClose={this.handleCloseAttachment}
                id="htDialog"
              >
                <SbConfirmDeleteAttachment
                  handleCloseAttachment={() => this.handleCloseAttachment()}
                  deleteAttachmentFile={() =>
                    this.deleteAttachmentFile(this.state.deleteAttachmentId)
                  }
                />
              </Dialog>
            </form>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default SbTaskForm
