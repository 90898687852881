import { globalGetService } from "../../utils/globalApiServices";

export function fillChartAmpApi() {
  return new Promise(function (resolve, reject) {
    globalGetService(`camo/amp/analytics/`).then((response) => {
      resolve(response);
    });
  });
}
export function fillChartHtApi() {
  return new Promise(function (resolve, reject) {
    globalGetService(`camo/ht/analytics/`).then((response) => {
      resolve(response);
    });
  });
}
export function fillChartAdApi() {
  return new Promise(function (resolve, reject) {
    globalGetService(`camo/aird/analytics/`).then((response) => {
      resolve(response);
    });
  });
}
export function fillChartSbApi() {
  return new Promise(function (resolve, reject) {
    globalGetService(`camo/sb/analytics/`).then((response) => {
      resolve(response);
    });
  });
}
export function fillChartSrApi() {
  return new Promise(function (resolve, reject) {
    globalGetService(`camo/repair/analytics/`).then((response) => {
      resolve(response);
    });
  });
}
export function fillChartModsApi() {
  return new Promise(function (resolve, reject) {
    globalGetService(`camo/mods/analytics/`).then((response) => {
      resolve(response);
    });
  });
}
