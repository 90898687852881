import React, { Component, Fragment } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, Tooltip, IconButton, Dialog, } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { getAircraftDetailApi } from "./apiServices"
import { permissionCheckFnforCamo } from "../../../src/utils"
import { withSnackbar } from "notistack"
import { connect } from "react-redux"
import { getAircraftTypesAc, getEngineTypesAc, getLesseeListAc, getOperatorListAc, getRegionListAc, getPlatformConstantsAc, getPortfolioListAc, getAircraftMntProgramAc, getLeaseStatus, getSubStatus, } from "../../shared_elements/actionCreators"
import { trackActivity } from "../../utils/mixpanel"
import BasicDetailForm from "./BasicDetailForm"

class BasicDetailHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      headerData: {},
      LHData: {},
      RHData: {},
      formTitle: "",
      openEditForm: false,
      ownersList: [],
    }
  }

  mainHeaderData = (props) => {
    getAircraftDetailApi(props).then((response) => {
      const data = response.data.data
      let tempLHdata = {}
      let tempRHdata = {}

      if (data) {
        tempLHdata = data.engine_detail.length > 0 ? data.engine_detail.find((x) => x.position.value === 1 || x.position.value === 3) : {}
        if (tempLHdata) {
          this.setState({ LHData: tempLHdata })
        }
        tempRHdata = data.engine_detail.length > 0 ? data.engine_detail.find((x) => x.position.value === 2 || x.position.value === 4) : {}
        if (tempRHdata) {
          this.setState({ RHData: tempRHdata })
        }
      }
      this.setState({ headerData: data })
      this.props.func(data.tsn, data.csn)
    })
  }

  propSetValue(props) {
    //console.log(props)
    //props.func(headerData.tsn)
    console.log(this.headerData.tsn)
    //props.func(headerData.tsn)
  }
  componentDidMount() {
    this.mainHeaderData(this.props)
    this.props.getPlatformConstants()
    this.props.getLesseeList()
    this.props.getOperatorList()
    this.props.getRegionList()
    this.props.getPortfolioList()
    this.props.getEngineTypes()
    this.props.getAircraftTypes()
    this.props.getLeaseStatus()
    this.props.getSubStatus()
    trackActivity("Page Visited", {
      page_title: "Asset Listing",
    })
    console.log(this.props.pltConstants)
  }

  handleEditRow = () => {
    this.setState({ formTitle: "Update Assets" })
    this.setState({ openEditForm: true })
  }

  closeUpdateForm = () => {
    this.setState({ openEditForm: false })
  }

  render() {
    const { asset, assetType } = this.props.props.match.params
    const { ownersList, headerData } = this.state
    const { portfolios, regions, aircraftTypes, engineTypes, pltConstants, lessees, operators, aircraftMntGrp, leaseStatus, subStatus, } = this.props
    return (
      <Fragment>
        <div id="AmpTableHeader">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="p-5" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Basic Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid
                  className="left-panel"
                  item
                  xs={12}
                  style={{ backgroundColor: " #efefef" }}
                >
                  <Table className="table-wrapper">
                    <TableHead>
                      {/* className="table-head" */}
                      <TableRow>
                        <TableCell>Aircraft Type/Model</TableCell>
                        <TableCell>MSN</TableCell>
                        <TableCell>Registration</TableCell>
                        <TableCell>Date of Manufacture</TableCell>
                        <TableCell>A/C TSN</TableCell>
                        <TableCell>A/C CSN</TableCell>

                        <div id="edit-basic-detail" className="d-flex">
                          <span class="editBasicDetail">
                            {permissionCheckFnforCamo({ primaryKey: "asset_management", keyIndex: "U", }) ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  className="edit-asset-icon"
                                  onClick={(e) => {
                                    this.handleEditRow(e)
                                  }}
                                >
                                  <CreateOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </span>
                        </div>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="font-b">
                          {this.state.headerData.aircraft_type_name ? this.state.headerData.aircraft_type_name : " -- "}
                        </TableCell>
                        <TableCell className="font-b">
                          {this.state.headerData.msn ? this.state.headerData.msn : " -- "}
                        </TableCell>
                        <TableCell className="font-b">
                          {this.state.headerData.current_registration_number ? this.state.headerData.current_registration_number : " -- "}
                        </TableCell>
                        <TableCell className="font-b">
                          {this.state.headerData.date_of_manufacture ? this.state.headerData.date_of_manufacture : " -- "}
                        </TableCell>
                        <TableCell className="font-b">
                          {assetType === "1" ? this.state.headerData.tsn : " -- "}
                        </TableCell>
                        <TableCell className="font-b">
                          {assetType === "1" ? this.state.headerData.csn : " -- "}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    {this.state.LHData?.id > 0 && this.state.RHData?.id > 0 ? <><TableHead className="table-head">
                      <TableRow>
                        <TableCell>LH Engine Type/Model</TableCell>
                        <TableCell>LH Engine TSN</TableCell>
                        <TableCell>LH Engine CSN</TableCell>
                        <TableCell>RH Engine Type/Model</TableCell>
                        <TableCell>RH Engine TSN</TableCell>
                        <TableCell>RH Engine CSN</TableCell>
                      </TableRow>
                    </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className="font-b">
                            {this.state.LHData && this.state.LHData.engine_type && this.state.LHData.engine_type.name ? this.state.LHData.engine_type.name : " -- "}
                          </TableCell>
                          <TableCell className="font-b">
                            {this.state.LHData && this.state.LHData.tsn ? this.state.LHData.tsn : " -- "}
                          </TableCell>
                          <TableCell className="font-b">
                            {this.state.LHData && this.state.LHData.csn ? this.state.LHData.csn : " -- "}
                          </TableCell>
                          <TableCell className="font-b">
                            {this.state.RHData && this.state.RHData.engine_type && this.state.RHData.engine_type.name ? this.state.RHData.engine_type.name : " -- "}
                          </TableCell>
                          <TableCell className="font-b">
                            {this.state.RHData && this.state.RHData.tsn ? this.state.RHData.tsn : " -- "}
                          </TableCell>
                          <TableCell className="font-b">
                            {this.state.RHData && this.state.RHData.csn ? this.state.RHData.csn : " -- "}
                          </TableCell>
                        </TableRow>
                      </TableBody> </> : null}
                  </Table>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
        <Dialog
          position="relative"
          maxWidth="lg"
          open={this.state.openEditForm}
          onClose={this.closeUpdateForm}
        >
          <BasicDetailForm
            props={this.props}
            closeUpdateForm={() => this.closeUpdateForm()}
            fetchMntGrops={(aircraftId) =>
              this.props.getAircraftMntProgram(aircraftId)
            }
            aircraftMntGrp={aircraftMntGrp}
            leaseStatus={leaseStatus}
            subStatus={subStatus}
            portfolios={portfolios}
            ownersList={ownersList}
            lessees={lessees}
            operators={operators}
            pltConstants={pltConstants}
            aircraftTypes={aircraftTypes}
            headerData={headerData}
            asset={asset}
            LHData={this.state.LHData}
            RHData={this.state.RHData}
          // addAssetType={addAssetType}
          // addAssetModal={addAssetModal}
          // toggleAssetType={() =>
          //   this.setState({ addAssetType: "", addAssetModal: false })
          // }
          // toggleLicenseModalFn={(modal, message) =>
          //   this.setState({
          //     licenseModal: modal,
          //     licenseModalMessage: message,
          //     addAssetModal: false,
          //   })
          // }
          />
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  aircraftTypes: state.shareReducer.aircraftTypes,
  engineTypes: state.shareReducer.engineTypes,
  lessees: state.shareReducer.lessees,
  operators: state.shareReducer.operators,
  regions: state.shareReducer.regions,
  portfolios: state.shareReducer.portfolios,
  pltConstants: state.shareReducer.pltConstants,
  aircraftMntGrp: state.shareReducer.aircraftMntGrp,
  leaseStatus: state.shareReducer.leaseStatus,
  subStatus: state.shareReducer.subStatus,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAircraftTypes: (props, query) =>
      dispatch(getAircraftTypesAc(props, query)),
    getRegionList: () => dispatch(getRegionListAc()),
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
    getLesseeList: () => dispatch(getLesseeListAc()),
    getOperatorList: () => dispatch(getOperatorListAc()),
    getPortfolioList: () => dispatch(getPortfolioListAc()),
    getAircraftMntProgram: (aircraftId) =>
      dispatch(getAircraftMntProgramAc(aircraftId)),
    getPlatformConstants: () =>
      dispatch(getPlatformConstantsAc({ constant_types: ["asset_status", "credit_classification", "engine_position", "lg_position",], })),
    getLeaseStatus: () =>
      dispatch(getLeaseStatus({ constant_types: ["lease_status"] })),
    getSubStatus: () =>
      dispatch(getSubStatus({ constant_types: ["asset_sub_status"] })),
  }
}

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(BasicDetailHeader)
)
