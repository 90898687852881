import React from "react"
import {
  Button,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@material-ui/core"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import LockIcon from "@material-ui/icons/Lock"
import config from "../../../config"

export default function LoginForm({
  error,
  authInfo,
  handleChange,
  onLogin,
  formSubmitLoader,
}) {
  const [values, setValues] = React.useState({
    showPassword: false,
  })
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const longText = `${(
    <ul className="list-unstyled password-tooltip">
      <li>Password must contain minimum of 8 characters</li>
      <li>Password must contain at least 1 digit</li>
      <li>Password must contain at least 1 uppercase character</li>
      <li>Password must contain at least 1 lowercase character</li>
      <li>Password must contain at least 1 special character</li>
    </ul>
  )}`
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onLogin()
      }}
    >
      <TextField
        id="email"
        label="Registered Email Address"
        fullWidth
        placeholder="johndoe@doe.com"
        className="email-textfield"
        margin="normal"
        value={authInfo.email}
        error={error.email ? true : false}
        helperText={error.email}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => handleChange(e.target.value, "email")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutlineIcon />
            </InputAdornment>
          ),
          inputProps: {
            maxLength: 30,
          },
        }}
      />
      <TextField
        id="password"
        type={values.showPassword ? "text" : "password"}
        label={<p>Password</p>}
        fullWidth
        className="email-textfield"
        placeholder="Enter Your Password"
        margin="normal"
        value={authInfo.password}
        error={error.password ? true : false}
        helperText={error.password}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: {
            maxLength: 14
          }
        }}
        onChange={(e) => handleChange(e.target.value, "password")}
      />
      
      <a
        className="forgot-pwd-link"
        href={`${config.domain.subDomian}forgot-password`}
      >
        Forgot Password?
      </a>
      <div className="text-center" style={{ marginTop: "30px" }}>
        <p className="terms-and-condition">
          By clicking Login, you agree to our&nbsp;
          <a
            className="url"
            href={`${config.domain.subDomian}termscondition`}
            target="_blank"
          >
            {" "}
            Terms,{" "}
          </a>
          <a
            className="url"
            href={`${config.domain.subDomian}data-policy`}
            target="_blank"
          >
            &nbsp;Data Policy{" "}
          </a>{" "}
          <br /> and&nbsp;
          <a
            className="url"
            href={`https://qa.console.sparta.aero/privacy-policy/`}
            target="_blank"
          >
            {" "}
            Privacy Policy
          </a>
          .
        </p>
      </div>
      <div className="text-center" style={{ marginTop: "10px" }}>
        <Button
          style={{ width: "100%" }}
          disabled={formSubmitLoader}
          variant="contained"
          color="primary"
          type="submit"
        >
          <>
            {formSubmitLoader ? (
              <CircularProgress color="#ffffff" size={24} />
            ) : (
              "Login"
            )}
          </>
        </Button>
      </div>
    </form>
  )
}
