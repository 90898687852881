import React, { Fragment } from "react";

const AmpSidebar = () => {
  const listItems = [
    {
      listText: "AMP/MRB Compliance",
      link: "/assets/amp",
      class: "active",
    },
    {
      listText: "Airworthiness Directives (ADs)",
      link: "assets/ad",
    },
    {
      listText: "Service Bulletins",
      link: "assets/sb",
    },
    {
      listText: "Hard Time Components",
      link: "assets/ht",
    },
    {
      listText: "OC-CM Components",
      link: "assets/occm",
    },
    {
      listText: "Structure Repairs",
      link: "assets/sr",
    },
    {
      listText: "Modifications",
      link: "assets/mod",
    },
    {
      listText: "Utilizations",
      link: "assets/utils",
    },
    {
      listText: "Annual Reviews",
      link: "assets/ar",
    },
    {
      listText: "Analytics",
      link: "assets/anal",
    },
  ];

  return (
    <Fragment>
      <div id="AmpSidebar">
        <ul className="sidebar-list">
          {listItems.map((listItem, index) => (
            <li className={listItem.class} key={index}>
              {listItem.listText}
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

export default AmpSidebar;
