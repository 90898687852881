import React from "react";
import { Button, MenuItem, Menu, Avatar } from "@material-ui/core";
import { getLocalStorageInfo, getGlobalCookie } from "../../utils";
import { trackLogOut } from "../../utils/mixpanel";
import { Link } from "react-router-dom";

const UserDropDownMenu = ({ logout }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let user = JSON.parse(getGlobalCookie("userName"));
  return (
    <div className="user-menu-dropdown">
      <Button
        id="basic-button"
        aria-controls="basic-dropdown"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ padding: "0px 8px" }}
      >
        <Avatar
          alt={getLocalStorageInfo() ? getLocalStorageInfo().user.name : "--"}
          style={{ marginRight: "10px", width: "34px", height: "34px" }}
          src={
            getLocalStorageInfo() ? getLocalStorageInfo().user.profile_pic : ""
          }
        />
        <div className="" style={{ textAlign: "left" }}>
          <h3>
            {user
              ? user.name && user.name.length > 20
                ? user.name.slice(0, 20) + "..."
                : user.name
              : "--"}
          </h3>
          <span>{user && user.designation ? user.designation : ""}</span>
        </div>
      </Button>
      <Menu
        id="basic-dropdown"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Link to="/profile">
          <MenuItem>
            <p>Profile</p>
          </MenuItem>
        </Link>

        <MenuItem
          onClick={() => {
            handleClose();
            trackLogOut(getLocalStorageInfo());
            setTimeout(() => logout(), 500);
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};
export default UserDropDownMenu;
