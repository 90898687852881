import AMPCompliance from "./amp/containers";
import HTComponent from "./ht/containers";
import ADComponent from "./ad/containers";
import OccmComponents from "./occm/containers";
import SbComponents from "./sb/containers";
import pageLayoutHoc from "../../hocs/pageLayoutHoc";
import userRoleBaseAccessHoc from "../../hocs/userRoleBaseAccessHoc";
import ModsComponents from "./mods/containers";
import SRComponent from "./sr/containers";

export const camoRoutes = [
  {
    path: "/:asset/:assetType/amp/list",
    component: pageLayoutHoc(userRoleBaseAccessHoc(AMPCompliance, []), {
      apps: "AMP",
      layoutPhase: 2,
    }),
    key: "AMP",
  },
  {
    path: "/:asset/:assetType/ht/list",
    component: pageLayoutHoc(userRoleBaseAccessHoc(HTComponent, []), {
      apps: "HT",
      layoutPhase: 2,
    }),
    key: "HT",
  },
  {
    path: "/:asset/:assetType/ad/list",
    component: pageLayoutHoc(userRoleBaseAccessHoc(ADComponent, []), {
      apps: "AD",
      layoutPhase: 2,
    }),
    key: "AD",
  },
  {
    path: "/:asset/:assetType/occm/list",
    component: pageLayoutHoc(userRoleBaseAccessHoc(OccmComponents, []), {
      apps: "OCCM",
      layoutPhase: 2,
    }),
    key: "OCCM",
  },
  {
    path: "/:asset/:assetType/sb/list",
    component: pageLayoutHoc(userRoleBaseAccessHoc(SbComponents, []), {
      apps: "SB",
      layoutPhase: 2,
    }),
    key: "SB",
  },
  {
    path: "/:asset/:assetType/mods/list",
    component: pageLayoutHoc(userRoleBaseAccessHoc(ModsComponents, []), {
      apps: "MODS",
      layoutPhase: 2,
    }),
    key: "MODS",
  },
  {
    path: "/:asset/:assetType/sr/list",
    component: pageLayoutHoc(userRoleBaseAccessHoc(SRComponent, []), {
      apps: "SR",
      layoutPhase: 2,
    }),
    key: "SR",
  },
];
