import { regexConstants } from "../../../../constants/regEx";

export const formValues = {
  asset_id: null,
  asset_type_id: null,
  ata: null,
  ad_no: null,
  issuing_authority: null,
  applicability: null,
  description: "",
  ad_type: "AIRFRAME",
  effectivity: null,
  effective_date: null,
  dimension_type: "",
  dimension_interval: null,
  dimension_interval_days: null,
  dimension_interval_fc:null,
  ad_compliance_status: "OPEN",
  ad_compliance_tsn: null,
  ad_compliance_csn: null,
  ad_compliance_date: null,
  sb_number: null,
  ad_compliance_dfp: null,
  release_certificate: null,
  conformity_crt: null,
  type: null,
  ac_comp: null,
  ref_documents: "",
  last_done_date: null,
  last_done_fh: null,
  last_done_fc: null,
  workorder: "",
  remark: "",
  attachments: [],
  ad_due: "",
  due_date:""
};

export const taskErrorCode = {
  ata: {
    0: "",
    1: "Required",
  },
  ata_obj: {
    required: true,
    regexPattern: regexConstants.onlyNumeric,
  },
  ad_no: {
    0: "",
    1: "Required",
  },
  ad_no_obj: {
    required: true,
  },
  applicability: {
    0: "",
    1: "Required",
    4: "Invalid Format",
  },
  applicability_obj: {
    required: true,
  },
  description: {
    0: "",
    1: "Required",
  },
  description_obj: {
    required: true,
  },
  ad_type: {
    0: "",
    1: "Required",
  },
  ad_type_obj: {
    required: true,
  },
  effective_date: {
    0: "",
    1: "Required",
    4: "Please enter valid date",
    5: "Please enter valid date",
  },
  effective_date_obj: {
    required: true,
    datePattern: true,
  },
  sb_number: {
    0: "",
    1: "Required",
  },
  sb_number_obj: {
    required: true,
    regexPattern: regexConstants.onlyNumeric,
  },
  type: {
    0: "",
    1: "Required",
  },
  type_obj: {
    required: true,
  },
  ac_comp: {
    0: "",
    1: "Required",
  },
  ac_comp_obj: {
    required: true,
  },
  ad_compliance_status: {
    0: "",
    1: "Required",
  },
  ad_compliance_status_obj: {
    required: true,
  },
  ad_compliance_tsn: {
    0: "",
    1: "Required",
  },
  ad_compliance_tsn_obj: {
    required: true,
    regexPattern: regexConstants.onlyNumeric,
  },
  ad_compliance_csn: {
    0: "",
    1: "Required",
  },
  ad_compliance_csn_obj: {
    required: true,
    regexPattern: regexConstants.onlyNumeric,
  },
  ad_compliance_date: {
    0: "",
    1: "Required",
    5: "Please enter valid date",
  },
  ad_compliance_date_obj: {
    required: true,
    datePattern: true,
  },
  ref_documents: {
    0: "",
    1: "Required",
  },
  ref_documents_obj: {
    required: true,
  },
  last_done_date: {
    0: "",
    1: "Required",
    4: "Please enter valid date",
    5: "Please enter valid date",
  },
  last_done_date_obj: {
    required: true,
    datePattern: true,
  },
  ad_compliance_dfp: {
    0: "",
    1: "Required",
  },
  ad_compliance_dfp_obj: {
    required: true,
  },
  workorder: {
    0: "",
    1: "Required",
  },
  workorder_obj: {
    required: true,
  },
  dimension_interval: {
    0: "",
    1: "Required",
  },
  dimension_interval_obj: {
    required: true,
    regexPattern: regexConstants.onlyNumeric,
  },
  dimension_interval_days: {
    0: "",
    1: "Required",
  },
  dimension_interval_days_obj: {
    required: true,
  },
  dimension_interval_fc: {
    0: "",
    1: "Required",
  },
  dimension_interval_fc_obj: {
    required: true,
  },
};
