import { globalGetService } from "../../utils/globalApiServices"

export function getAircraftDetailApi(queryParams = {}) {
  console.log(queryParams)
  const { asset, assetType } = queryParams.props.match.params
  return new Promise(function (resolve, reject) {
    globalGetService(`camo/asset/${asset}/${assetType}/view/`).then(
      (response) => {
        resolve(response)
      }
    )
  })
}
