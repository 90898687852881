import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import homeIcon from "../../assets/images/home_icon.png";
import dbIcon from "../../assets/images/dashbordIcon.svg";
import assetsIcon from "../../assets/images/assets_icon.png";
export const MainNavLink = ({ match }) => {
  return (
    <Fragment>
      <ul className="list-unstyled">
        <li>
          <Link
            className={match.path.includes("home") ? "active" : ""}
            to="/home"
          >
            <img src={homeIcon} alt="Home" width="25" />
            <p>Home</p>
          </Link>
        </li>
        <li>
          <Link
            className={match.path.includes("dashboard") ? "active" : ""}
            to="/dashboard"
          >
            <img src={dbIcon} alt="Dashboard" width="20" />
            <p>Dashboard</p>
          </Link>
        </li>
        <li>
          <Link
            className={match.path.includes("assets") ? "active" : ""}
            to="/assets-listing"
          >
            {/* <img src={assetsIcon} alt="Assets" /> */}
            <img
              src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/asset_list_icon.png"
              alt="Assets"
              width="20"
            />
            <p>Assets</p>
          </Link>
        </li>
      </ul>
    </Fragment>
  );
};

export default withRouter(MainNavLink);
