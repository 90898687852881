import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Dialog,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ConfirmDialog from "../components/ConfirmDialog";
import { fillModsEditFormApi } from "../apiServices";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import moment from "moment";
import ModsTaskForm from "./ModsTaskForm";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments";
import { fieldDateFormat } from "../../../../constants";
import { permissionCheckFnforCamo } from "../../../../utils";

const ModsList = ({
  item,
  index,
  handleDeleteRow,
  updateFormData,
  props,
  fileUploadData,
  currentRecordPerPage,
  downloadAllApi,
  headerTsn,
  headerCsn,
}) => {
  const [open, setOpen] = useState(false);
  const [openEditForm, setEditForm] = useState(false);
  const [editFormId, setEditFormId] = useState(null);
  const [editFormData, setEditFormData] = useState(null);
  const [formTitle, setFormTitle] = useState("");
  const [attachments, setAttachments] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState({});
  const [showFullRemarks, setShowFullRemarks] = useState({});
  const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false);
  const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] =
    useState(null);
  const [moduleId, setmoduleId] = useState(null);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const closeAddForm = () => {
    setEditForm(false);
  };

  const handleEditRow = () => {
    fillModsEditFormApi(item, props).then((response) => {
      setEditFormData(response.data.data);
      setEditFormId(response.data.data.id);
      setAttachments(response.data.data.attachments);
      setFormTitle("Update Modifications");
      setEditForm(true);
    });
  };

  const downAllAttach = () => {
    fillModsEditFormApi(item, props).then((response) => {
      setmoduleId(response.data.data.id);
    });
    if (item.attachments.length > 0) {
      getAttachments(item.attachments);
    }
  };

  const openAttachment = (file) => {
    window.open(`https://qa.camo.sparta.aero:8223${file}`, "_blank");
  };

  const formatDate = (date) => {
    return moment(date).format("DD-MM-YYYY");
  };

  const toggleDescription = (id) => {
    setShowFullDescription({
      ...showFullDescription,
      [id]: !showFullDescription[id],
    });
  };

  const toggleRemarks = (id) => {
    setShowFullRemarks({
      ...showFullRemarks,
      [id]: !showFullRemarks[id],
    });
  };

  const getAttachments = (attachments) => {
    setAllAttachmentsforDialogue(attachments);
    setAttachmentsDialogue(true);
  };

  const handleCloseAttachmentDialogue = () => {
    setAttachmentsDialogue(false);
  };
  const convertToDate = (value) => {
    if (typeof value === "string") {
      return formatDate(value);
    } else if (typeof value === "number") {
      return value;
    } else {
      return "--";
    }
  };

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        style={{ cursor: "pointer" }}
        className={
          item.remainingValues_D < 0 || item.remainingValues_D == null
            ? "red-row"
            : "none"
        }
      >
        <TableCell>
          {permissionCheckFnforCamo({
            primaryKey: "mod_status",
            keyIndex: "U",
          }) ? (
            <Tooltip title="Edit">
              <IconButton
                className="edit-icon"
                onClick={(e) => {
                  handleEditRow(e);
                }}
              >
                <CreateOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {permissionCheckFnforCamo({
            primaryKey: "mod_status",
            keyIndex: "D",
          }) ||
          permissionCheckFnforCamo({
            primaryKey: "mod_status",
            keyIndex: "OD",
            userId: item.created_by,
          }) ? (
            <Tooltip title="Delete" arrow>
              <IconButton className="delete-icon" onClick={handleClick}>
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </TableCell>
        <TableCell>{item.ata_chapter ? item.ata_chapter : "--"}</TableCell>

        <TableCell>
          {item.mod_ref_number ? item.mod_ref_number : "--"}
        </TableCell>
        <TableCell>{item.mod_issued_by ? item.mod_issued_by : "--"}</TableCell>
        <TableCell>
          {item.mod_applicability ? item.mod_applicability : "--"}
        </TableCell>
        <TableCell style={{ width: "200px", textAlign: "left" }}>
          {item.description && item.description.length > 150 ? (
            <div>
              {showFullDescription[item.id] ? (
                <span>
                  {item.description}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleDescription(item.id);
                    }}
                  >
                    ..less
                  </a>
                </span>
              ) : (
                <span>
                  {item.description.substring(0, 150)}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleDescription(item.id);
                    }}
                  >
                    ..more
                  </a>
                </span>
              )}
            </div>
          ) : item.description && item.description.length <= 150 ? (
            <span>{item.description}</span>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell>{item.type ? item.type : "--"}</TableCell>
        <TableCell>{item.effectivity ? item.effectivity : "--"}</TableCell>

        <TableCell>
          {item.effectivity_date
            ? moment(item.effectivity_date).format(fieldDateFormat)
            : "--"}
        </TableCell>
        <TableCell>
          {item.mod_interval_type ? item.mod_interval_type : "--"}
        </TableCell>
        <TableCell>{item.mod_interval ? item.mod_interval : "--"}</TableCell>
        <TableCell>
          {item.mod_compliance_status ? item.mod_compliance_status : "--"}
        </TableCell>
        <TableCell>
          {item.mod_compliance_tsn ? item.mod_compliance_tsn : "--"}
        </TableCell>
        <TableCell>
          {item.mod_compliance_csn ? item.mod_compliance_csn : "--"}
        </TableCell>
        <TableCell>
          {item.mod_compliance_date
            ? moment(item.mod_compliance_date).format(fieldDateFormat)
            : "--"}
        </TableCell>
        <TableCell>{convertToDate(item?.remaining?.due_at)}</TableCell>
        <TableCell>
          {item.attachments.length > 0 ? (
            <>
              <Tooltip title="View Attachments">
                <span
                  className="center"
                  onClick={() => {
                    downAllAttach();
                  }}
                >
                  <AttachFileIcon className="attach-icon" />

                  <a href="#" style={{ color: "#0e7fe1" }}>
                    {item.attachments.length}
                  </a>
                </span>
              </Tooltip>
            </>
          ) : (
            item.attachments.length
          )}
        </TableCell>
      </TableRow>
      <Dialog open={open} onClose={handleClose} id="htDialog">
        <ConfirmDialog
          handleClose={() => handleClose()}
          handleDeleteRow={handleDeleteRow}
        />
      </Dialog>

      {/* edit form dialog */}
      <Dialog
        position="relative"
        maxWidth="lg"
        open={openEditForm}
        onClose={closeAddForm}
      >
        <ModsTaskForm
          formTitle={formTitle}
          closeAddForm={() => closeAddForm()}
          props={props}
          editFormId={editFormId}
          editFormData={editFormData}
          attachments={attachments}
          item={item}
          headerTsn={headerTsn}
          headerCsn={headerCsn}
          updateFormData={updateFormData}
          fileUploadData={fileUploadData}
          currentRecordPerPage={currentRecordPerPage}
        />
      </Dialog>

      <Dialog
        open={openGetAttachmentsDialogue}
        onClose={handleCloseAttachmentDialogue}
        id="htDialog"
      >
        <ShowAttachments
          handleClose={() => handleCloseAttachmentDialogue()}
          handleShowAttachments={AllAttachmentsforDialogue}
          openAttachment={openAttachment}
          files={{ title: "MODs", extension: "zip", key: "" }}
          downloadAllApi={downloadAllApi}
          props={props}
          moduleId={moduleId}
        />
      </Dialog>
    </>
  );
};
export default withRouter(ModsList);
