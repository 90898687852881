import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import maintenanceIcon from "../../../assets/images/maintenance_management_icon.png"
import recordsIcon from "../../../assets/images/records_dataroom_icon.png"
import projectsIcon from "../../../assets/images/projects_management_icon.png"
import leaseIcon from "../../../assets/images/lease_management_icon.png"
import commvergeIcon from "../../../assets/images/commverge_icon.png"
import finsIcon from "../../../assets/images/fin-s_icon.png"
export default class AuthImageSlider extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 320,
          settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true },
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true },
        },
        {
          breakpoint: 1024,
          settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true },
        },
      ],
    }
    return (
      <Slider {...settings} className="image-slider">
        <div className="application-info-card">
          <img src={maintenanceIcon} className="fin-s" alt="fin-s" />
          <h4 className="img-title">CAMO</h4>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
        </div>
        <div className="application-info-card">
          <img src={leaseIcon} alt="Lease" />
          <h4 className="img-title">Lease Management</h4>

          <p>
            Manage multiple assets under leases, forecast maintenance & rental
            cashflow. Track asset utilisation, raise and manage invoices.
            Integrate seamlessly with external systems.
          </p>
        </div>
        <div className="application-info-card">
          <img src={recordsIcon} alt="Records" />
          <h4 className="img-title">Records Dataroom</h4>
          <p>
            Industry compliant cloud based Documents Management System for
            aviation records. Harness the power of digital data with our
            specialised digitisation services.
          </p>
        </div>
        <div className="application-info-card">
          <img src={projectsIcon} alt="Projects" />
          <h4 className="img-title">Projects Management</h4>
          <p>
            Plan, execute, and monitor fleet wide due-diligence. Perform diverse
            projects ranging from asset acquisition, mid lease inspections and
            end of lease.
          </p>
        </div>
        <div className="application-info-card">
          <img src={maintenanceIcon} alt="Maintenance" />
          <h4 className="img-title">Maintenance Claim Management</h4>
          <p>
            High accuracy forecast of maintenance events and associated costs.
            Manage maintenance reserve claims and integrate cashflows from lease
            management system.
          </p>
        </div>
        <div className="application-info-card">
          <img src={commvergeIcon} className="commverge" alt="Commverge" />
          <h4 className="img-title">commVerge</h4>
          <p>
            Compute, forecast and simulate commercial cost exposure for 200+
            aircraft-engine combinations with commVerge. Negotiate the best
            reserve rates through data-driven insights on intervals, on-wing
            life projections and event costs.
          </p>
        </div>
        <div className="application-info-card">
          <img src={finsIcon} className="fin-s" alt="fin-s" />
          <h4 className="img-title">fin-S</h4>
          <p>
            Generate instant and reliable valuations of commercial aircraft with
            fin-S. Simulate 100s scenarios for maintenance adjustments,
            discounted cashflow based values and inflation-impacted future
            values.
          </p>
        </div>
      </Slider>
    )
  }
}
