import Home from "../home/containers/index";
import AssetListing from "../home/containers/assets";
import pageLayoutHoc from "../../hocs/pageLayoutHoc";
import userRoleBaseAccessHoc from "../../hocs/userRoleBaseAccessHoc";
import Profile from "../home/containers/profile";

export const assetRoutes = [
  {
    path: "/home",
    component: pageLayoutHoc(userRoleBaseAccessHoc(Home, []), {
      apps: "Assets",
      layoutPhase: 1,
    }),
    key: "MntAssetList",
  },
  {
    path: "/assets-listing",
    component: pageLayoutHoc(userRoleBaseAccessHoc(AssetListing, []), {
      apps: "AssetListing",
      layoutPhase: 1,
    }),
    key: "MntAssetList",
  },
  {
    path: "/profile",
    component: pageLayoutHoc(userRoleBaseAccessHoc(Profile, []), {
      apps: "Profile",
      layoutPhase: 1,
    }),
    key: "MntAssetList",
  },
];
