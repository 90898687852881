import React, { Component, Fragment } from "react"
import {
  Button,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormLabel,
  FormControlLabel,
  Radio,
  Dialog,
} from "@material-ui/core"
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined"
// import Radio from '@material-ui/core/Radio';
import RadioGroup from "@material-ui/core/RadioGroup"
import CloseIcon from "@material-ui/icons/Close"
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/ModTaskFormData"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import moment from "moment"
import MomentUtils from "@date-io/moment"
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import { addModsFormApi, deleteModsAttachment } from "../apiServices"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined"
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined"
import ModsConfirmDeleteAttachment from "./ModsConfirmDeleteAttachment"
import { regexConstants } from "../../../../constants/regEx"
import { permissionCheckFnforCamo } from "../../../../utils"

class ModsTaskForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: {},
      data: formValues,
      regex: "^[0-9]+$",
      numberError: false,
      selectLastDDate: null,
      selectNextDueDate: null,
      TSN: 30179,
      CSN: 5,
      attachments: [],
      newUploadedAttachments: [],
      remainingValue: "",
      modsDue: null,
      btnStatus: "",
      open: false,
      deleteAttachmentId: 0,
      globalValidationInputs: {},
      validatedFields: [],
    }

    this.addModsFormApi = addModsFormApi.bind(this)
    this.dropDownChange = this.dropDownChange.bind(this)
    this.dropDownChange1 = this.dropDownChange1.bind(this)
    this.dropDownChange2 = this.dropDownChange2.bind(this)
  }

  componentDidMount = () => {
    if (this.props.editFormId) {
      this.fillEditData()
    }
  }

  dropDownChange1(e, keyParam, value) {
    //set selection to the value selected
    this.setState(
      (prevState) => (
        console.log(prevState),
        {
          ...prevState,
          data: {
            ...prevState.data,
            type: e.target.value,
          },
        }
      )
    )
  }
  dropDownChange2(e, keyParam, value) {
    //set selection to the value selected
    this.setState(
      (prevState) => (
        console.log(prevState),
        {
          ...prevState,
          data: {
            ...prevState.data,
            mod_interval_type: e.target.value,
          },
        }
      )
    )
  }

  dropDownChange(e, index, value) {
    //set selection to the value selected
    this.setState(
      (prevState) => (
        console.log(prevState),
        {
          ...prevState,
          data: {
            ...prevState.data,
            mod_compliance_status: e.target.value,
          },
        }
      )
    )
  }

  onFieldChange = (keyParam, value) => {
    const { data } = this.state

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [keyParam]: value,
      },
    }))

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        remaining_val: this.state.remainingValue,
      },
    }))

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        mods_DUE: this.state.modsDue,
      },
    }))
  }

  onRestErrorKey = (keyParam) => {
    this.setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: "",
      },
    }))
  }
  handleSubmit = (e) => {
    e.preventDefault()

    let validationInputs = {}
    const { data } = this.state
    this.setState({ file: "" })

    validationInputs = {
      ...validationInputs,
      ata_chapter:
        taskErrorCode["ata_chapter"][
          fieldValidation({
            ...taskErrorCode["ata_chapter_obj"],
            fieldval: data.ata_chapter,
          })
        ],
      mod_ref_number:
        taskErrorCode["mod_ref_number"][
          fieldValidation({
            ...taskErrorCode["mod_ref_number_obj"],
            fieldval: data.mod_ref_number,
          })
        ],
    }
    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))

      // add task api
      const props = this.props.props
      const modsData = data
      modsData.asset_id = props.match.params.asset
      modsData.asset_type_id = props.match.params.assetType
      // Remove properties from the modsData object
      delete modsData.ad_due
      delete modsData.remaining_val
      modsData.last_done_fc =
        modsData && modsData.last_done_fc === "" ? null : modsData.last_done_fc
      modsData.last_done_fh =
        modsData && modsData.last_done_fh === "" ? null : modsData.last_done_fh
      addModsFormApi(modsData, props).then(async (response) => {
        const uploadId = response.data.data.id
        if (uploadId) {
          await this.uploadFileTest(uploadId, props, false)
        }
      })
      if (this.state.btnStatus !== "addAnother") {
        this.setState((prevState) => ({
          error: {
            ...prevState.error,
            dimension_interval: "",
          },
        }))
        this.props.closeAddForm()
      } else {
        this.setState((prevState) => ({
          error: {
            ...prevState.error,
            dimension_interval: "",
          },
        }))
        return false
      }
    } else {
      this.setState({ error: validationInputs })
    }
  }

  // edit task form
  fillEditData = () => {
    const updateId = this.props.editFormId
    this.setState({ attachments: this.props.attachments })
    if (updateId === undefined || updateId === null) {
      this.setState({
        data: {},
      })
    } else {
      this.setState({
        data: this.props.editFormData,
      })
    }
  }

  onFocusValidation = (keyParam) => {
    let validationInputs = {}
    const { data } = this.state
    validationInputs = {
      ...validationInputs,
      ata_chapter:
        taskErrorCode["ata_chapter"][
          fieldValidation({
            ...taskErrorCode["ata_chapter_obj"],
            fieldval: data.ata_chapter,
          })
        ],
      mod_ref_number:
        taskErrorCode["mod_ref_number"][
          fieldValidation({
            ...taskErrorCode["mod_ref_number_obj"],
            fieldval: data.mod_ref_number,
          })
        ],
    }
    for (let key in validationInputs) {
      if (key === keyParam) {
        this.state.globalValidationInputs[key] = validationInputs[key]
      }
    }
    if (
      Object.keys(this.state.globalValidationInputs).every((k) => {
        return this.state.globalValidationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))
      this.setState({ globalValidationInputs: {} })
    } else {
      this.setState({ error: this.state.globalValidationInputs })
    }
  }

  updateData = async (e) => {
    e.preventDefault()

    let validationInputs = {}
    const { data } = this.state
    this.setState({ file: "" })

    validationInputs = {
      ...validationInputs,
      ata_chapter:
        taskErrorCode["ata_chapter"][
          fieldValidation({
            ...taskErrorCode["ata_chapter_obj"],
            fieldval: data.ata_chapter,
          })
        ],
      mod_ref_number:
        taskErrorCode["mod_ref_number"][
          fieldValidation({
            ...taskErrorCode["mod_ref_number_obj"],
            fieldval: data.mod_ref_number,
          })
        ],
    }
    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))
      const updateId = this.props.editFormId
      // let lastDone = this.state.data.last_done_fc == "" ? null : this.state.data.last_done_fc
      const updatedData = this.state.data
      delete updatedData.ad_due
      delete updatedData.remaining_val
      // if (updatedData.last_done_fc == "" || updatedData.last_done_fh == "") {
      //   this.setState((prevState) => ({
      //     ...prevState,
      //     last_done_fc: null,
      //   }))
      // }
      updatedData.last_done_fc =
        updatedData && updatedData.last_done_fc === ""
          ? null
          : updatedData.last_done_fc
      updatedData.last_done_fh =
        updatedData && updatedData.last_done_fh === ""
          ? null
          : updatedData.last_done_fh
      const props = this.props.props
      this.setState({
        data: this.props.editFormData,
      })
      if (updateId) {
        await this.uploadFileTest(updateId, props, true)
      }
      await this.props.updateFormData(updateId, updatedData, props)
      this.setState((prevState) => ({
        error: {
          ...prevState.error,
          dimension_interval: "",
        },
      }))
      this.props.closeAddForm()
    } else {
      this.setState({ error: validationInputs })
    }
  }

  uploadFileTest = async (uploadId, props, isUpdate) => {
    if (this.state.newUploadedAttachments.length > 0) {
      let newAttachmentObj = []
      let formdata = new FormData()
      const module_type_id = 6

      Object.keys(this.state.newUploadedAttachments).map((key, index) => {
        newAttachmentObj.push({
          file: this.state.newUploadedAttachments[key],
          file_name: this.state.newUploadedAttachments[key].name,
          file_type: this.state.newUploadedAttachments[key].type,
          module_type_id,
          module_id: uploadId,
        })
        formdata.append(
          "[" + key + "]file",
          this.state.newUploadedAttachments[key]
        )
        formdata.append(
          "[" + key + "]file_type",
          this.state.newUploadedAttachments[key].type
        )
        formdata.append(
          "[" + key + "]file_name",
          this.state.newUploadedAttachments[key].name
        )
        formdata.append("[" + key + "]module_id", uploadId)
        formdata.append("[" + key + "]module_type_id", module_type_id)
      })
      // uploadOccmFile(uploadId, formdata, props)
      this.props.fileUploadData(uploadId, formdata, props, true)
      if (this.state.btnStatus === "addAnother") {
        this.resetBothAttachmentArray()
      }
    } else {
      if (this.state.btnStatus === "addAnother") {
        this.setState((prevState) => ({
          ...prevState,
          data: formValues,
          attachments: [],
        }))
        document.getElementById("asset-form").reset()
        this.props.fileUploadData(uploadId, null, props, false)
      } else {
        if (!isUpdate) {
          this.props.fileUploadData(uploadId, null, props, false)
        }
      }
    }
  }

  resetBothAttachmentArray = () => {
    this.setState((prevState) => ({
      ...prevState,
      data: formValues,
      attachments: [],
      newUploadedAttachments: [],
      open: false,
      deleteAttachmentId: 0,
    }))
    document.getElementById("asset-form").reset()
  }

  onUpload = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    for (let i = 0; i < chosenFiles.length; i++) {
      if (chosenFiles[i].size > 52428800) {
        this.props.props.enqueueSnackbar(
          `${chosenFiles[i].name} Maximum file size should be 50MB.`,
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        )
        delete chosenFiles[i]
        continue
      } else {
        this.handleUploadFiles(chosenFiles)
        // document.querySelector(".MuiDialog-paper").scrollTop = 800;
        this.setState({
          showSuccessUpload: true,
        })
        setTimeout(() => {
          if (this.state.attachments) {
            this.setState({
              showSuccessUpload: false,
            })
          }
        }, 2500)
      }
    }
  }

  handleUploadFiles = (files) => {
    const uploaded = [...this.state.newUploadedAttachments]
    const existingAttachments = [...this.state.attachments]
    files.some((file) => {
      uploaded.push(file)
    })
    files.map((file) => {
      var objc = {
        id: 0,
        module_id: 2,
        module_type_id: 6,
        file_name: file.name,
        file_type: file.type,
        file: "",
        name: file.name,
      }
      existingAttachments.push(objc)
    })

    this.setState({
      newUploadedAttachments: uploaded,
      attachments: existingAttachments,
    })
  }

  handleClickAttachment = (e, id) => {
    this.setState({ open: true })
    this.setState({ deleteAttachmentId: id })
  }

  handleCloseAttachment = (e) => {
    this.setState({ open: false })
    this.setState({ deleteAttachmentId: 0 })
  }

  deleteAttachmentFile = async (id) => {
    if (id !== undefined && id != 0) {
      const props = this.props.props
      deleteModsAttachment(id, props)
      var array = [...this.state.attachments]
      array = array.filter((item) => item.id !== id)
      this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
    }
  }

  removeAttachment = (e, obj) => {
    var array = [...this.state.attachments]
    var newuploadarray = [...this.state.newUploadedAttachments]
    var filteredArray = []
    newuploadarray.map((file) => {
      if (file.name == obj.file_name && file.type == obj.file_type) {
      } else {
        filteredArray.push(file)
      }
    })
    array = array.filter((item) => item !== obj)
    this.setState({
      newUploadedAttachments: filteredArray,
      attachments: array,
    })
  }

  saveAndAddAnother = async (e) => {
    await this.setState({ btnStatus: "addAnother" })
    await this.handleSubmit(e)
  }

  addFormData = async (e) => {
    await this.setState({ btnStatus: "add" })
    this.handleSubmit(e)
  }

  render() {
    const fileTypes = [
      ".GIF",
      ".PDF",
      ".DOC",
      ".DOCX",
      ".XLSX",
      ".TXT",
      ".XLS",
      "image/*",
    ]
    const { data, error } = this.state

    var date = new Date()
    const lDate = moment(data.last_done_date)
    const todayD = moment(date)
    let remDay = lDate.diff(todayD, "days")
    return (
      <Fragment>
        <div id="AmpTaskForm">
          <div className="mods" id="HtTaskForm">
            <DialogTitle>
              {this.props.formTitle}
              <CloseIcon
                onClick={this.props.closeAddForm}
                className="close-icon"
              />
            </DialogTitle>
            <form
              id="asset-form"
              onSubmit={
                this.props.actionType === "add"
                  ? (e) => this.handleSubmit(e)
                  : (e) => this.updateData(e)
              }
            >
              <DialogContent>
                <Grid spacing={1} container>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          ATA Chapter<span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="ata_chapter"
                      id="ata_chapter"
                      value={data.ata_chapter}
                      error={error.ata_chapter ? true : false}
                      helperText={error.ata_chapter ? error.ata_chapter : ""}
                      onChange={(e, value) =>
                        this.onFieldChange("ata_chapter", e.target.value)
                      }
                      inputProps={{ maxLength: 15 }}
                      onFocus={() => this.onRestErrorKey("ata_chapter")}
                      onBlur={() => this.onFocusValidation("ata_chapter")}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          MOD Reference Number
                          <span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="mod_ref_number"
                      id="mod_ref_number"
                      value={data.mod_ref_number}
                      error={error.mod_ref_number ? true : false}
                      helperText={
                        error.mod_ref_number ? error.mod_ref_number : ""
                      }
                      inputProps={{ maxLength: 25 }}
                      onChange={(e, value) =>
                        this.onFieldChange("mod_ref_number", e.target.value)
                      }
                      onFocus={() => this.onRestErrorKey("mod_ref_number")}
                      onBlur={() => this.onFocusValidation("mod_ref_number")}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label="MOD Issued by"
                      name="mod_issued_by"
                      id="mod_issued_by"
                      value={data.mod_issued_by}
                      inputProps={{ maxLength: 20 }}
                      onChange={(e, value) =>
                        this.onFieldChange("mod_issued_by", e.target.value)
                      }
                      error={error.mod_issued_by ? true : false}
                      helperText={
                        error.mod_issued_by ? error.mod_issued_by : ""
                      }
                      onFocus={() => this.onRestErrorKey("mod_issued_by")}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label="MOD Applicability"
                      name="mod_applicability"
                      id="mod_applicability"
                      value={data.mod_applicability}
                      inputProps={{ maxLength: 20 }}
                      onChange={(e, value) =>
                        this.onFieldChange("mod_applicability", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label="MOD Description"
                      name="description"
                      id="description"
                      multiline
                      minRows={5}
                      value={data.description}
                      error={error.description ? true : false}
                      helperText={error.description ? error.description : ""}
                      onChange={(e, value) =>
                        this.onFieldChange("description", e.target.value)
                      }
                      onFocus={() => this.onRestErrorKey("description")}
                    />
                  </Grid>
                </Grid>

                <div className="border-grey"></div>
                <Grid spacing={1} container id="ht-dropdown">
                  <Grid item xs={4}>
                    <FormControl>
                      <InputLabel
                        id="demo-simple-select-label"
                        className="dropdown-label"
                      >
                        <React.Fragment>
                          MOD Type
                          <span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      </InputLabel>
                      <Select
                        labelId="type"
                        name="type"
                        id="type"
                        error={error.type ? true : false}
                        helperText={error.type ? error.type : ""}
                        variant="outlined"
                        value={data.type}
                        onChange={this.dropDownChange1}
                      >
                        <MenuItem value={"STC"}>STC</MenuItem>
                        <MenuItem value={"LOCALMODIFICATION"}>
                          Local Modification
                        </MenuItem>
                        <MenuItem value={"OTHERS"}>Others</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="MOD Effectivity"
                      name="effectivity"
                      id="effectivity"
                      value={data.effectivity}
                      inputProps={{ maxLength: 25 }}
                      onChange={(e, value) =>
                        this.onFieldChange("effectivity", e.target.value)
                      }
                      error={error.effectivity ? true : false}
                      helperText={error.effectivity ? error.effectivity : ""}
                      onFocus={() => this.onRestErrorKey("effectivity")}
                    />
                  </Grid>
                  <Grid item xs={4} className="input-calender">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        id="effectivity_date"
                        label="MOD Effective Date"
                        format={fieldDateFormat}
                        inputVariant="outlined"
                        // autoOk={true}
                        fullWidth
                        disableFuture
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(25, "years")}
                        value={data.effectivity_date}
                        error={error.effectivity_date ? true : false}
                        helperText={
                          error.effectivity_date ? error.effectivity_date : ""
                        }
                        onChange={(data, value) => {
                          this.onFieldChange(
                            "effectivity_date",
                            moment(data).format(backendDateFormat)
                          )
                          this.onRestErrorKey("effectivity_date")
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={4} className="input-calender">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        id="last_done_date"
                        label="Last Done Date"
                        format={fieldDateFormat}
                        inputVariant="outlined"
                        // autoOk={true}
                        fullWidth
                        disableFuture
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(25, "years")}
                        value={data.last_done_date}
                        error={error.last_done_date ? true : false}
                        helperText={
                          error.last_done_date ? error.last_done_date : ""
                        }
                        onChange={(data, value) => {
                          this.onFieldChange(
                            "last_done_date",
                            moment(data).format(backendDateFormat)
                          )
                          this.onRestErrorKey("last_done_date")
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="Last Done FH"
                      name="last_done_fh"
                      id="last_done_fh"
                      value={data.last_done_fh}
                      onChange={(e, value) =>
                        this.onFieldChange("last_done_fh", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="Last Done FC"
                      name="last_done_fc"
                      id="last_done_fc"
                      value={data.last_done_fc}
                      onChange={(e, value) =>
                        this.onFieldChange("last_done_fc", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="MOD Compliance TSN"
                      name="mod_compliance_tsn"
                      id="mod_compliance_tsn"
                      value={data.mod_compliance_tsn}
                      inputProps={{ maxLength: 20 }}
                      onChange={(e, value) =>
                        this.onFieldChange("mod_compliance_tsn", e.target.value)
                      }
                      error={error.mod_compliance_tsn ? true : false}
                      helperText={
                        error.mod_compliance_tsn ? error.mod_compliance_tsn : ""
                      }
                      onFocus={() => this.onRestErrorKey("mod_compliance_tsn")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="MOD Compliance CSN"
                      name="mod_compliance_csn"
                      id="mod_compliance_csn"
                      value={data.mod_compliance_csn}
                      error={error.mod_compliance_csn ? true : false}
                      helperText={
                        error.mod_compliance_csn ? error.mod_compliance_csn : ""
                      }
                      onChange={(e, value) =>
                        regexConstants.onlyNumeric.test(e.target.value)
                          ? this.onFieldChange(
                              "mod_compliance_csn",
                              e.target.value
                            )
                          : e.preventDefault()
                      }
                      onFocus={() => this.onRestErrorKey("mod_compliance_csn")}
                    />
                  </Grid>
                  <Grid item xs={4} className="input-calender">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        id="mod_compliance_date"
                        label="MOD Compliance Date"
                        format={fieldDateFormat}
                        inputVariant="outlined"
                        // autoOk={true}
                        fullWidth
                        disableFuture
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(25, "years")}
                        value={data.mod_compliance_date}
                        error={error.mod_compliance_date ? true : false}
                        helperText={
                          error.mod_compliance_date
                            ? error.mod_compliance_date
                            : ""
                        }
                        onChange={(data, value) => {
                          this.onFieldChange(
                            "mod_compliance_date",
                            moment(data).format(backendDateFormat)
                          )
                          this.onRestErrorKey("mod_compliance_date")
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid spacing={1} container id="ht-dropdown">
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="Document Number"
                      name="document_number"
                      id="document_number"
                      value={data.document_number}
                      inputProps={{ maxLength: 25 }}
                      onChange={(e, value) =>
                        this.onFieldChange("document_number", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="Workorder"
                      name="workorder"
                      id="workorder"
                      inputProps={{ maxLength: 25 }}
                      value={data.workorder}
                      onChange={(e, value) =>
                        this.onFieldChange("workorder", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        MOD Compliance Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="outlined"
                        value={data.mod_compliance_status}
                        onChange={this.dropDownChange}
                      >
                        <MenuItem value={"OPEN"}>OPEN</MenuItem>
                        <MenuItem value={"COMPLIED"}>COMPLIED</MenuItem>
                        <MenuItem value={"PARTIALLY COMPLIED"}>
                          PARTIALLY COMPLIED
                        </MenuItem>
                        <MenuItem value={"CLOSED"}>CLOSED</MenuItem>
                        <MenuItem value={"NOT APPLICABLE"}>
                          NOT APPLICABLE
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <div className="border-grey"></div>
                <Grid spacing={1} id="data-container" container>
                  <Grid item xs={3} id="ht-dropdown" className="mod-period">
                    <label className="ml-10px font-14">INTERVAL</label>
                    <FormControl>
                      <InputLabel
                        id="demo-simple-select-label"
                        className="dropdown-label"
                      >
                        MOD Compliance Period
                      </InputLabel>
                      <Select
                        labelId="mod_interval_type"
                        name="mod_interval_type"
                        id="mod_interval_type"
                        error={error.mod_interval_type ? true : false}
                        helperText={
                          error.mod_interval_type ? error.mod_interval_type : ""
                        }
                        variant="outlined"
                        value={data.mod_interval_type}
                        onChange={this.dropDownChange2}
                      >
                        <MenuItem value={"D"}>Days</MenuItem>
                        <MenuItem value={"FH"}>FH</MenuItem>
                        <MenuItem value={"FC"}>FC</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} id="dimensions-input">
                    <TextField
                      variant="outlined"
                      label="MOD Compliance"
                      name="mod_interval"
                      id="mod_interval"
                      value={data.mod_interval}
                      onChange={(e, value) =>
                        this.onFieldChange("mod_interval", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={3} id="custom-padding">
                    <label className="ml-10px font-14">NEXT DUE </label>
                    <Grid spacing={2} container>
                      <Grid className="remaining-input" item xs={6}>
                        <TextField
                          variant="outlined"
                          label="Days"
                          name="ad_due"
                          id="ad_due"
                          value={
                            this.state.data.mod_interval_type === "FH"
                              ? (data.mods_DUE = Number.isInteger(
                                  +data.last_done_fh + +data.mod_interval
                                )
                                  ? +data.last_done_fh + +data.mod_interval
                                  : (
                                      +data.last_done_fh + +data.mod_interval
                                    ).toFixed(2))
                              : this.state.data.mod_interval_type === "FC"
                              ? (data.mods_DUE = Number.isInteger(
                                  +data.last_done_fc + +data.mod_interval
                                )
                                  ? +data.last_done_fc + +data.mod_interval
                                  : (
                                      +data.last_done_fc + +data.mod_interval
                                    ).toFixed(2))
                              : this.state.data.mod_interval_type === "D"
                              ? (data.mods_DUE = moment(
                                  data.last_done_date
                                ).add(data.mod_interval, "days")).format(
                                  "DD-MM-YYYY"
                                )
                              : ""
                          }
                          onChange={(e, value) =>
                            this.onFieldChange("ad_due", e.target.value)
                          }
                          inputProps={{ readOnly: true }}
                          disabled
                          className="disabled-input"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={3} id="custom-padding">
                    <label className="ml-10px font-14">REMAINING</label>
                    <Grid spacing={1} container>
                      <Grid className="remaining-input" item xs={6}>
                        <TextField
                          variant="outlined"
                          label="Days"
                          name="remaining_val"
                          id="remaining_val"
                          value={
                            this.state.data.mod_interval_type === "FH"
                              ? (data.remaining_val = Number.isInteger(
                                  data.mods_DUE - this.props.headerTsn
                                )
                                  ? data.mods_DUE - this.props.headerTsn
                                  : (
                                      data.mods_DUE - this.props.headerTsn
                                    ).toFixed(2))
                              : this.state.data.mod_interval_type === "FC"
                              ? (data.remaining_val = Number.isInteger(
                                  data.mods_DUE - this.props.headerCsn
                                )
                                  ? data.mods_DUE - this.props.headerCsn
                                  : (
                                      data.mods_DUE - this.props.headerCsn
                                    ).toFixed(2))
                              : this.state.data.mod_interval_type === "D"
                              ? (data.remaining_val = moment(remDay).add(
                                  data.mod_interval
                                ))
                              : null
                          }
                          onChange={(e, value) =>
                            this.onFieldChange("remaining_val", e.target.value)
                          }
                          inputProps={{ readOnly: true }}
                          disabled
                          className="disabled-input"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid id="data-container" spacing={1} container>
                  <Grid item xs={3}>
                    <label>MOD Compliance DFP</label>
                    <FormControl
                      //   error={error.mod_compliance_dfp ? true : false}
                      //   helperText={error.mod_compliance_dfp ? error.mod_compliance_dfp : ""}
                      onFocus={() => this.onRestErrorKey("mod_compliance_dfp")}
                    >
                      <RadioGroup
                        required
                        row
                        aria-label="mod_compliance_dfp"
                        name="mod_compliance_dfp"
                        defaultValue="top"
                        value={data.mod_compliance_dfp}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "mod_compliance_dfp",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                      {error.mod_compliance_dfp && (
                        <FormLabel component="mod_compliance_dfp">
                          <span style={{ color: "red" }}>
                            {error.mod_compliance_dfp}
                          </span>
                        </FormLabel>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <label>Release Certificate </label>
                    <FormControl component="fieldset">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="release_certificate"
                        name="release_certificate"
                        defaultValue="top"
                        value={data.release_certificate}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "release_certificate",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <label>Certificate of Conformity</label>
                    <FormControl component="fieldset">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="certificate_of_conformity"
                        name="certificate_of_conformity"
                        defaultValue="top"
                        value={data.certificate_of_conformity}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "certificate_of_conformity",
                            e.target.value
                          )
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                        <FormControlLabel
                          value="N/A"
                          control={<Radio size="small" color="primary" />}
                          label="N/A"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      label="Remark"
                      name="remark"
                      id="remark"
                      multiline
                      minRows={5}
                      value={data.remark}
                      onChange={(e, value) =>
                        this.onFieldChange("remark", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="upload-textbox">
                      <Button variant="contained" component="label">
                        <div id="upload-success">
                          <span className="d-flex align-items-center">
                            <AddPhotoAlternateOutlinedIcon className="upload-icon" />
                            <span className="upload-text upload">
                              {this.state.attachments.length === 0
                                ? "Upload "
                                : ""}
                            </span>
                            <input
                              className="upload-input"
                              onChange={this.onUpload}
                              id="fileUplaod"
                              name="file"
                              type="file"
                              accept={fileTypes}
                              multiple
                            />
                            <span className="upload-text">
                              {this.state.attachments.length === 0
                                ? " or drop a file right here"
                                : " Upload Another?"}
                            </span>
                          </span>
                          <span className="upload-text overflow">
                            &nbsp;JPG,PNG,GIF,PDF,DOC,DOCX,XLSX
                          </span>
                        </div>
                        {this.state.attachments.length === 0 ? (
                          ""
                        ) : (
                          <div id="success-text">
                            <span className="upload-text upload">
                              Uploaded successfully!
                            </span>
                          </div>
                        )}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid id="data-container" spacing={1} container>
                  <Grid spacing={1} container>
                    <div className="d-flex align-items-center show-attachments">
                      {(() => {
                        if (
                          this.state.attachments &&
                          this.state.attachments.length > 0
                        ) {
                          return Object.keys(this.state.attachments).map(
                            (key1, index) => {
                              return (
                                <div
                                  className="position-relative d-flex align-items-center attachment-margin"
                                  key={index}
                                >
                                  <span className="file-name">
                                    {this.state.attachments[index].name}
                                  </span>
                                  <DescriptionOutlinedIcon className="file-icon" />
                                  {permissionCheckFnforCamo({
                                    primaryKey: "mod_status",
                                    keyIndex: "D",
                                  }) ||
                                  permissionCheckFnforCamo({
                                    primaryKey: "mod_status",
                                    keyIndex: "OD",
                                    userId: this.state.data.created_by
                                      ? this.state.data.created_by
                                      : undefined,
                                  }) ||
                                  this.state.attachments[index].id === 0 ? (
                                    <CancelOutlinedIcon
                                      className="cancel-icon"
                                      onClick={
                                        this.props.actionType === "add"
                                          ? (e) =>
                                              this.removeAttachment(
                                                e,
                                                this.state.attachments[index]
                                              )
                                          : (e) => {
                                              if (
                                                this.state.attachments[index]
                                                  .id === 0
                                              ) {
                                                this.removeAttachment(
                                                  e,
                                                  this.state.attachments[index]
                                                )
                                              } else {
                                                this.handleClickAttachment(
                                                  e,
                                                  this.state.attachments[index]
                                                    .id
                                                )
                                              }
                                            }
                                      }
                                    />
                                  ) : null}
                                </div>
                              )
                            }
                          )
                        }
                      })()}
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="actions-btn">
                <Button
                  type="button"
                  className="btn btn-primary"
                  // onClick={ this.props.closeAddForm}
                  onClick={
                    this.props.actionType === "add"
                      ? (e) => this.addFormData(e)
                      : (e) => this.updateData(e)
                  }
                >
                  {this.props.actionType === "add" ? "Add" : "Update"}
                </Button>

                {this.props.actionType === "add" ? (
                  <Button
                    onClick={(e) => this.saveAndAddAnother(e)}
                    type="button"
                    className="btn btn-primary"
                  >
                    Save & Add Another
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.props.closeAddForm}
                >
                  Close
                </Button>
              </DialogActions>
              <Dialog
                open={this.state.open}
                onClose={this.handleCloseAttachment}
                id="htDialog"
              >
                <ModsConfirmDeleteAttachment
                  handleCloseAttachment={() => this.handleCloseAttachment()}
                  deleteAttachmentFile={() =>
                    this.deleteAttachmentFile(this.state.deleteAttachmentId)
                  }
                />
              </Dialog>
            </form>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ModsTaskForm
