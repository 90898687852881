import React, { Fragment, useState } from "react";
import taskList from "../../../../assets/images/task-list.svg";
import SearchIcon from "@material-ui/icons/Search";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FilterListIcon from "@material-ui/icons/FilterList";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DownloadXLSAssetDetail from "../../../../shared_elements/DownloadXLSAssetDetail";
import AddIcon from "@material-ui/icons/Add";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import AdTaskForm from "./AdTaskForm";
import DownloadSample from "../../../../shared_elements/DownloadSample";
import { DownloadAdSampleFile } from "../apiService";
import {
  convertFilterObject,
  removeEmptyKey,
  permissionCheckFnforCamo,
} from "../../../../utils";
import DownloadPDFAssetDetail from "../../../../shared_elements/components/DownloadPDFAssetDetail";

const AdSearchBar = ({
  applyingFilter,
  filterOptions,
  tabIndex,
  pageLoader,
  props,
  searchData,
  fileUploadData,
  importFile,
  exportReportFn,
  headerTsn,
  headerCsn,
  currentRecordPerPage,
}) => {
  const [open, setOpen] = useState(false);
  const [importedFile, setimportedFile] = useState(null);
  const [menuAnchorEl, menuSetAnchorEl] = useState(null);
  const [importAnchorEl, importSetAnchorEl] = useState(null);
  const [exportAnchorEl, exportSetAnchorEl] = useState(null);
  const [downloadAnchorEl, downloadSetAnchorEl] = useState(null);
  const [formTitle, setFormTitle] = useState("");
  const [actionType, setActionType] = useState("");

  const openMenu = Boolean(menuAnchorEl);
  const openImportMenu = Boolean(importAnchorEl);
  const openExportMenu = Boolean(exportAnchorEl);
  const openDownloadMenu = Boolean(downloadAnchorEl);

  const handlefilterButtonClick = (event) => {
    menuSetAnchorEl(event.currentTarget);
  };

  const onHandleClose = () => {
    menuSetAnchorEl(null);
  };

  const addNewTask = () => {
    setOpen(true);
    setFormTitle("Airworthiness Directives (ADs)");
    setActionType("add");
  };

  const closeAddForm = () => {
    setOpen(false);
  };

  const importFileFunc = (e) => {
    const files = e.target.files;
    const formData = new FormData();
    const fileType = files[0].type;
    if (
      !fileType.endsWith(
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      // Handle error for non-xlsx file
      formData.append("file", files[0]);
      importFile(formData, props, false);
    } else {
      formData.append("file", files[0]);
      importFile(formData, props, true);
    }
  };

  const onImportHandleClose = () => {
    importSetAnchorEl(null);
  };

  const onImportHandleClick = (event) => {
    importSetAnchorEl(event.currentTarget);
  };

  const onExportHandleClose = () => {
    exportSetAnchorEl(null);
  };

  const onExportHandleClick = (event) => {
    exportSetAnchorEl(event.currentTarget);
  };

  const ondownloadHandleClose = () => {
    downloadSetAnchorEl(null);
  };

  const ondownloadHandleClick = (event) => {
    downloadSetAnchorEl(event.currentTarget);
  };

  return (
    <Fragment>
      <div id="AmpSearchBar">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div>
              <Button
                id="basic-button"
                className="menu-btn"
                aria-controls={openMenu ? "basic-menu" : ""}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : ""}
                onClick={handlefilterButtonClick}
              >
                <FilterListIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={menuAnchorEl}
                open={openMenu}
                onClose={onHandleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={onHandleClose}>Over due tasks</MenuItem>
                <MenuItem onClick={onHandleClose}>Tasks</MenuItem>
                <MenuItem onClick={onHandleClose}>All tasks</MenuItem>
              </Menu>
            </div>
            <div className="search-input">
              <TextField
                id="search"
                placeholder="Search ATA Chapter, AD Reference Number, AD Issuing Authority, AD Description"
                onChange={searchData}
              />
              <Tooltip
                title="Search ATA Chapter, AD Reference Number, AD Issuing Authority, AD Description"
                variant="contained"
              >
                <SearchIcon className="search-icon" />
              </Tooltip>
            </div>
          </div>
          <div className="btn-wrapper">
            {permissionCheckFnforCamo({
              primaryKey: "ad_status",
              keyIndex: "C",
            }) ? (
              <Tooltip title="Add Task">
                <Button
                  onClick={addNewTask}
                  variant="contained"
                  color="primary"
                >
                  <AddIcon style={{ marginTop: "-1px" }} className="add-icon" />
                  NEW TASK
                </Button>
              </Tooltip>
            ) : null}
            {/* import menu starts */}
            <Button variant="outlined" color="primary" component="label">
              <span>IMPORT</span>
              <input
                className="upload-input"
                onChange={(e) => importFileFunc(e)}
                id="fileUplaod"
                name="file"
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </Button>
            {/* import menu ends */}
            {/* export menu starts */}

            <Button
              variant="outlined"
              color="primary"
              id="basic-button"
              aria-controls={openExportMenu ? "basic-menu" : ""}
              aria-haspopup="true"
              aria-expanded={openExportMenu ? "true" : ""}
              onClick={onExportHandleClick}
            >
              EXPORT
              <KeyboardArrowDownIcon style={{ marginTop: "-1px" }} />
            </Button>
            <Menu
              className="import-menu-item export"
              id="basic-menu"
              anchorEl={exportAnchorEl}
              open={openExportMenu}
              onClose={onExportHandleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem className="export-list">
                <DownloadXLSAssetDetail
                  files={{ title: "EXCEL", extension: "xls", key: "" }}
                  exportReportFn={exportReportFn}
                />
              </MenuItem>
              <MenuItem className="export-list">
                <DownloadPDFAssetDetail
                  files={{ title: "PDF", extension: "pdf", key: "" }}
                  exportReportFn={exportReportFn}
                />
              </MenuItem>
            </Menu>
            {/* export menu ends */}
            {/* download sample starts */}
            <DownloadSample
              variant="outlined"
              color="primary"
              files={{ title: "DOWNLOAD SAMPLE", extension: "xlsx", key: "" }}
              exportSample={(file) =>
                DownloadAdSampleFile(
                  {
                    download: file.extension,
                    ...{
                      ...convertFilterObject(
                        removeEmptyKey(applyingFilter),
                        filterOptions
                      ),
                      asset_type: tabIndex,
                    },
                  },
                  pageLoader,
                  props
                )
              }
            />
            <Menu
              className="import-menu-item export"
              id="basic-menu"
              anchorEl={downloadAnchorEl}
              open={openDownloadMenu}
              onClose={ondownloadHandleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem></MenuItem>
            </Menu>
            {/* download sample ends */}
            <Dialog open={open} onClose={closeAddForm}>
              <AdTaskForm
                formTitle={formTitle}
                closeAddForm={() => closeAddForm()}
                props={props}
                headerTsn={headerTsn}
                headerCsn={headerCsn}
                actionType={actionType}
                fileUploadData={fileUploadData}
                currentRecordPerPage={currentRecordPerPage}
              />
            </Dialog>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdSearchBar;
