import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {
  fillChartAdApi,
  fillChartAmpApi,
  fillChartHtApi,
  fillChartModsApi,
  fillChartSbApi,
  fillChartSrApi,
} from "../apiServices";
import moment from "moment";
import { fieldDateFormat } from "../../../constants";

const DashboardChart = () => {
  const [overdueData, setOverdueData] = useState([]);
  const [upcomingData, setUpcomingData] = useState([]);
  const [pieData, setPieData] = useState({});
  const [selectedData, setSelectedData] = useState("AMP/MRB Compliance");

  useEffect(() => {
    fillChartAmpApi().then((response) => {
      setOverdueData(response.data.overdue);
      setUpcomingData(response.data.upcoming);
      setPieData(response.data);
      console.log(response.data.table_row_title);
    });
    setSelectedData("AMP/MRB Compliance");
  }, []);

  const onSelectAmpItem = () => {
    fillChartAmpApi().then((response) => {
      setOverdueData(response.data.overdue);
      setUpcomingData(response.data.upcoming);
      setPieData(response.data);
    });
  };
  const onSelectHtItem = () => {
    fillChartHtApi().then((response) => {
      setOverdueData(response.data.overdue);
      setUpcomingData(response.data.upcoming);
      setPieData(response.data);
    });
  };
  const onSelectAdItem = () => {
    fillChartAdApi().then((response) => {
      setOverdueData(response.data.overdue);
      setUpcomingData(response.data.upcoming);
      setPieData(response.data);
    });
  };
  const onSelectSbItem = () => {
    fillChartSbApi().then((response) => {
      setOverdueData(response.data.overdue);
      setUpcomingData(response.data.upcoming);
      setPieData(response.data);
    });
  };
  const onSelectSrItem = () => {
    fillChartSrApi().then((response) => {
      setOverdueData(response.data.overdue);
      setUpcomingData(response.data.upcoming);
      setPieData(response.data);
    });
  };
  const onSelectModsItem = () => {
    fillChartModsApi().then((response) => {
      setOverdueData(response.data.overdue);
      setUpcomingData(response.data.upcoming);
      setPieData(response.data);
    });
  };

  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Tasks Overview",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        depth: 35,
        showInLegend: true,
        dataLabels: {
          enabled: false,
          format: "{point.name}",
        },
      },
    },
    series: [
      {
        name: "Tasks",
        colorByPoint: true,
        data: [
          {
            name: `Total Overdue`,
            color: "#a2a05b",
            y: pieData.total_overdue,
          },
          {
            name: `Total Due in 30`,
            color: "#81a27b",
            y: pieData.total_due_in_30,
          },
          {
            name: `Total Due in 60`,
            color: "#5c95db",
            y: pieData.total_due_in_60,
          },
          {
            name: `Total Due in 90`,
            color: "#ff6565",
            y: pieData.total_due_in_90,
          },
        ],
      },
    ],
    credits: {
      enabled: false,
    },
  };

  const dropDownChange = (e) => {
    //set selection to the value selected
    setSelectedData(e.target.value);
  };

  return (
    <>
      <div id="analytics">
        <FormControl>
          <InputLabel id="demo-simple-select-label">Select Module</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="analytics-dropdown"
            variant="outlined"
            value={selectedData}
            onChange={dropDownChange}
          >
            <MenuItem value={"AMP/MRB Compliance"} onClick={onSelectAmpItem}>
              AMP/MRB Compliance
            </MenuItem>
            <MenuItem value={"Hard Time Components"} onClick={onSelectHtItem}>
              Hard Time Components
            </MenuItem>
            <MenuItem
              value={"Airworthiness Directives (ADs)"}
              onClick={onSelectAdItem}
            >
              Airworthiness Directives (ADs)
            </MenuItem>
            <MenuItem value={" Service Bulletins"} onClick={onSelectSbItem}>
              Service Bulletins
            </MenuItem>
            <MenuItem value={"Structure Repairs"} onClick={onSelectSrItem}>
              Structure Repairs
            </MenuItem>
            <MenuItem value={"Modifications"} onClick={onSelectModsItem}>
              Modifications
            </MenuItem>
          </Select>
        </FormControl>

        <div id="analytics-datatable">
          <Grid container className="mt-20">
            <Grid xs={6} item className="table-border">
              <span class="header">Overdue</span>
              <Table className="table-wrapper">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {pieData.table_row_title}
                    </TableCell>
                    <TableCell align="center">Due Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {overdueData.length === 0 ? (
                    <span className="center">No Records Found</span>
                  ) : (
                    <>
                      {overdueData.map((item, i) => {
                        return [
                          <TableRow key={i}>
                            <TableCell>
                              {item.title ? item.title : "--"}
                            </TableCell>
                            <TableCell>
                              {item.due_at
                                ? moment(item.due_at).format(fieldDateFormat)
                                : "--"}
                            </TableCell>
                          </TableRow>,
                        ];
                      })}
                    </>
                  )}
                </TableBody>
              </Table>
            </Grid>
            <Grid xs={6} item className="table-border">
              <span class="header">Upcoming</span>
              <Table className="table-wrapper">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {pieData.table_row_title}
                    </TableCell>
                    <TableCell align="center">Due Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {upcomingData.length === 0 ? (
                    <TableRow>
                      <TableCell className="no-records" colSpan={2}>
                        <span className="center font-600">
                          No Records Found
                        </span>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {upcomingData.map((item, i) => {
                        return [
                          <TableRow key={i}>
                            <TableCell>
                              {item.title ? item.title : "--"}
                            </TableCell>
                            <TableCell>
                              {item.due_at
                                ? moment(item.due_at).format(fieldDateFormat)
                                : "--"}
                            </TableCell>
                          </TableRow>,
                        ];
                      })}
                    </>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <div className="claims-side-card" style={{ background: "#fff" }}>
            <div className="status-data">
              <PieChart highcharts={Highcharts} options={options} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardChart;
