import React, { Component, Fragment } from "react"
import { withSnackbar } from "notistack"
import { Button, CircularProgress, Grid, TextField, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, DialogTitle, DialogContent, DialogActions, } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import MomentUtils from "@date-io/moment"
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers"
import moment from "moment"
import { backendDateFormat, fieldDateFormat } from "../../constants"
import { checkApiStatus } from "../../utils"
import { UpdateAssetApi, UpdateEngine, engineType, getAllEngine } from "../home/apiServices"
import { fieldValidation } from "../../utils/formValidation"
import { aircraftCrud, aircraftErrorCode } from "../home/"
import { trackActivity } from "../../utils/mixpanel"
import CloseIcon from "@material-ui/icons/Close"

class BasicDetailForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitLoader: false,
      data: aircraftCrud,
      error: {},
      openDrawer: false,
      slug: null,
      engineList: [],
      allEngine: [],
      engineData: {
        lh_engine: this.props.LHData.engine_type,
        Rh_engine: this.props.RHData.engine_type
      },
    }
    this.UpdateAssetApi = UpdateAssetApi.bind(this)
    this.UpdateEngine = UpdateEngine.bind(this)
    this.engineType = engineType.bind(this)
    this.getAllEngine = getAllEngine.bind(this)
  }

  componentDidMount() {
    this.engineType(this.props)
    this.getAllEngine(this.props, { slug: this.props.headerData.slug })
    if (this.props.asset) {
      this.fillEditData()
      this.setState({ slug: this.props.headerData.slug })
    }
    console.log(this.props.headerData)

    if (this.props.LHData) {
      const { esn, csn, tsn } = this.props.LHData;
      this.setState(prevState => ({
        engineData: {
          ...prevState.engineData,
          Lh_csn: csn,
          Lh_tsn: tsn,
          lh_esn: esn,
          lh_engine: this.props.LHData.engine_type
        }
      }));
    }
    if (this.props.RHData) {
      const { esn, csn, tsn } = this.props.RHData;
      this.setState(prevState => ({
        engineData: {
          ...prevState.engineData,
          Rh_csn: csn,
          Rh_tsn: tsn,
          rh_esn: esn,
          Rh_engine: this.props.RHData.engine_type
        }
      }));
    }
  }

  onFieldChange = (keyParam, value) => {
    if (keyParam === "aircraft_type") {
      if (value) {
        this.props.fetchMntGrops(value.id)
      } else {
        this.onFieldChange("maintenance_event_program_group", null)
      }
    }
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [keyParam]: value,
      },
    }))

    if (keyParam === "lh_engine") {
      this.setState((prevState) => ({
        ...prevState,
        engineData: {
          ...prevState.engineData,
          [keyParam]: value,
        },
      }))
    }
    if (keyParam === "Rh_engine") {
      this.setState((prevState) => ({
        ...prevState,
        engineData: {
          ...prevState.engineData,
          [keyParam]: value,
        },
      }))
    }
    if (keyParam === "Lh_csn") {
      this.setState((prevState) => ({
        ...prevState,
        engineData: {
          ...prevState.engineData,
          [keyParam]: value,
        },
      }))
    }
    if (keyParam === "Rh_csn") {
      this.setState((prevState) => ({
        ...prevState,
        engineData: {
          ...prevState.engineData,
          [keyParam]: value,
        },
      }))
    }
    if (keyParam === "Lh_tsn") {
      this.setState((prevState) => ({
        ...prevState,
        engineData: {
          ...prevState.engineData,
          [keyParam]: value,
        },
      }))
    }
    if (keyParam === "Rh_tsn") {
      this.setState((prevState) => ({
        ...prevState,
        engineData: {
          ...prevState.engineData,
          [keyParam]: value,
        },
      }))
    }
    if (keyParam === "lh_esn") {
      this.setState((prevState) => ({
        ...prevState,
        engineData: {
          ...prevState.engineData,
          [keyParam]: value,
        },
      }))
    }
    if (keyParam === "rh_esn") {
      this.setState((prevState) => ({
        ...prevState,
        engineData: {
          ...prevState.engineData,
          [keyParam]: value,
        },
      }))
    }
  }
  onRestErrorKey = (keyParam) => {
    this.setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: "",
      },
    }))
  }
  onUpdateAssetAndEngine = () => {
    const { data, allEngine, slug, engineData } = this.state;

    let validationInputs = {};
    validationInputs = {
      ...validationInputs,
      msn: aircraftErrorCode["msn"][fieldValidation({ ...aircraftErrorCode["msnObj"], fieldval: data.msn })],
      aircraft_type: aircraftErrorCode["aircraft_type"][fieldValidation({ ...aircraftErrorCode["aircraft_typeObj"], fieldval: data.aircraft_type, })],
      status: aircraftErrorCode["status"][fieldValidation({ ...aircraftErrorCode["statusObj"], fieldval: data.status, })],
      date_of_manufacture: aircraftErrorCode["date_of_manufacture"][fieldValidation({ ...aircraftErrorCode["date_of_manufactureObj"], fieldval: data.date_of_manufacture, })],
      portfolio: data.ownership_type === 2 ? data.owner_portfolio ? "" : "Please select Portfolio" : "",
      lessee_id: data.status && data.status.value == 1 ? aircraftErrorCode["lessee_id"][fieldValidation({ ...aircraftErrorCode["lessee_idObj"], fieldval: data.lessee_id, })] : "",
      operator: data.same_operator_lessee ? "" : aircraftErrorCode["operator"][fieldValidation({ ...aircraftErrorCode["operator_obj"], fieldval: data?.operator ? data?.operator : '', })],
    }

    const enginePromises = [];

    if (allEngine.length >= 2) {
      allEngine.forEach((engine, index) => {
        const payload = {
          is_titled: engine.is_titled,
          is_fitted: engine.is_fitted,
          engine_type: engine.position.value === 1 || engine.position.value === 3 ? engineData.lh_engine.id : engine.position.value === 2 ? engineData.Rh_engine.id : '' || engine.position.value === 4 ? engineData.Rh_engine?.id : '',
          csn: engine.position.value === 1 || engine.position.value === 3 ? engineData.Lh_csn : engine.position.value === 2 ? engineData.Rh_csn : '' || engine.position.value === 4 ? engineData.Rh_csn : '',
          tsn: engine.position.value === 1 || engine.position.value === 3 ? engineData.Lh_tsn : engine.position.value === 2 ? engineData.Rh_tsn : '' || engine.position.value === 4 ? engineData.Rh_tsn : '',
          esn: engine.position.value === 1 || engine.position.value === 3 ? engineData.lh_esn : engine.position.value === 2 ? engineData.rh_esn : '' || engine.position.value === 4 ? engineData.rh_esn : '',
        };
        const engineID = engine.id;
        const updatePromise = this.UpdateEngine(this.props, `console/aircraft/${slug}/engine/${engineID}/`, payload);
        enginePromises.push(updatePromise);
      });
    } else {
      // this.props.enqueueSnackbar("Engine data is not available.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
    }

    if (Object.values(validationInputs).every(val => val === "")) {
      this.setState({ formSubmitLoader: true });
      let assetPayload = Object.assign({}, data);
      assetPayload = {
        ...assetPayload,
        aircraft_type: data.aircraft_type.id,
        credit_classification: data.credit_classification && data.credit_classification.value ? data.credit_classification.value : null,
        maintenance_event_program_group: data.maintenance_event_program_group && data.maintenance_event_program_group.value ? data.maintenance_event_program_group.value : null,
        status: data.status.value,
        sub_status: data.sub_status.value,
        lessee_id: data.lessee_id && data.lessee_id.id ? data.lessee_id.id : null,
        operator:data?.operator?.id ? data.operator?.id : null,
        id: this.props.asset,
        off_lease_status: typeof data.off_lease_status === "object" ? null : data.off_lease_status,
        country:data.country ? data.country : null
      };

      if (assetPayload.ownership_type === 2 && assetPayload.owner_portfolio.id) {
        assetPayload = {
          ...assetPayload,
          owner_portfolio: assetPayload.owner_portfolio.id,
        };
      } else if (assetPayload.ownership_type === 1) {
        assetPayload = {
          ...assetPayload,
          owner_portfolio: null,
        };
      }

      const updateAssetPromise = this.UpdateAssetApi(this.props, `console/aircraft-overview/${slug}/`, assetPayload);
      let anyPromiseFailed = false; 

      Promise.all([...enginePromises, updateAssetPromise])
        .then(responses => {
          this.setState({ formSubmitLoader: false });
          responses.forEach(response => {
            if (!checkApiStatus(response)) {
              anyPromiseFailed = true; 
            }
          });
          if (anyPromiseFailed) {
            this.props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
          } else {
            this.props.enqueueSnackbar("Technical Specification Updated Successfully!", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" } });
            trackActivity("Item Added", { asset_type: "Aircraft", page_title: "Asset Listing" });
            setTimeout(()=>{
              window.location.reload();
            },2000)
          }
        })
        .catch(error => {
          console.error('An error occurred:', error);
        });
    } else {
      this.setState({ error: validationInputs });
    }
  };

  toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    this.setState({ openDrawer: true })
  }

  fillEditData = (props) => {
    const updateId = this.props.asset
    if (updateId === undefined || updateId === null) {
      this.setState({
        data: null,
      })
    } else {
      this.setState({
        data: this.props.headerData,
      })
    }
  }

  render() {
    const { formSubmitLoader, data, error, engineData } = this.state
    const { portfolios, lessees, operators, pltConstants, aircraftTypes, addAssetModal, toggleAssetType, aircraftMntGrp, leaseStatus, subStatus, headerData, asset } = this.props
    return (
      <Fragment>
        {/* <Drawer
          anchor="right"
          open={addAssetModal}
          onClose={() => {
            toggleAssetType()
          }}
          id="add-aircraft"
        > */}
        <div id="AmpTaskForm">
          {/* <div className="plt-filter-header">
            <div className="d-flex justify-content-between custom-width">
              <h3>Add Aircraft</h3>
              <CloseIcon
                onClick={() => {
                  toggleAssetType()
                  trackActivity("Item Clicked", {
                    page_title: "Asset Listing",
                    asset_type: "Aircraft",
                    activity_type: "Cancel",
                  })
                }}
              />
            </div>
          </div> */}
          <DialogTitle className="just-center">
            Update Aircraft
            <CloseIcon
              onClick={this.props.closeUpdateForm}
              className="close-icon"
            />
          </DialogTitle>
          <DialogContent>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    variant="outlined"
                    id="msn"
                    label="MSN"
                    fullWidth
                    margin="normal"
                    value={data.msn}
                    error={error.msn ? true : false}
                    helperText={error.msn ? error.msn : ""}
                    onChange={(e, value) => this.onFieldChange("msn", e.target.value)}
                    onFocus={() => this.onRestErrorKey("msn")}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    className="autoComp"
                    options={aircraftTypes}
                    getOptionLabel={(option) => option.name}
                    id="aircraft_type"
                    value={data.aircraft_type}
                    onChange={(e, value) => this.onFieldChange("aircraft_type", value)}
                    renderInput={(params) => (
                      console.log(params),
                      (
                        <TextField
                          {...params}
                          variant="outlined"
                          required
                          error={error.aircraft_type ? true : false}
                          helperText={
                            error.aircraft_type ? error.aircraft_type : ""
                          }
                          onFocus={() => this.onRestErrorKey("aircraft_type")}
                          label="Type"
                          margin="normal"
                          fullWidth
                          placeholder="Select Type"
                        />
                      )
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    variant="outlined"
                    id="current_registration_number"
                    label="Registration"
                    fullWidth
                    margin="normal"
                    value={data.current_registration_number}
                    onChange={(e, value) => this.onFieldChange("current_registration_number", e.target.value)}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    variant="outlined"
                    id="csn"
                    label="CSN"
                    fullWidth
                    margin="normal"
                    value={data.csn}
                    onChange={(e, value) => this.onFieldChange("csn", e.target.value)}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    variant="outlined"
                    id="tsn"
                    label="TSN"
                    fullWidth
                    margin="normal"
                    value={data.tsn}
                    onChange={(e, value) => this.onFieldChange("tsn", e.target.value)}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      required
                      margin="normal"
                      id="date_of_manufacture"
                      label="Date of Manufacture"
                      format={fieldDateFormat}
                      fullWidth
                      disableFuture
                      minDate={moment().subtract(25, "years")}
                      value={data.date_of_manufacture}
                      error={error.date_of_manufacture ? true : false}
                      helperText={error.date_of_manufacture ? error.date_of_manufacture : " "}
                      onFocus={() => this.onRestErrorKey("date_of_manufacture")}
                      onChange={(data, value) =>
                        this.onFieldChange(
                          "date_of_manufacture",
                          moment(data).format(backendDateFormat)
                        )
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options={aircraftMntGrp ? aircraftMntGrp : []}
                    getOptionLabel={(option) => option.label}
                    id="maintenance_event_program_group"
                    value={data.maintenance_event_program_group}
                    onChange={(e, value) => this.onFieldChange("maintenance_event_program_group", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Maintenance Program Group"
                        margin="normal"
                        fullWidth
                        placeholder="Select Maintenance Program Group"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options={pltConstants.filter(
                      (item) => item.type === "credit_classification"
                    )}
                    getOptionLabel={(option) => option.label}
                    id="credit_classification"
                    value={data.credit_classification}
                    onChange={(e, value) => this.onFieldChange("credit_classification", value)}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}
                        label="Credit Classification"
                        margin="normal"
                        fullWidth
                        placeholder="Select Credit Classification"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    options={leaseStatus ? leaseStatus : []}
                    getOptionLabel={(option) => option.label}
                    id="status"
                    value={data.status}
                    onChange={(e, value) => this.onFieldChange("status", value)}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        required
                        error={error.status ? true : false}
                        helperText={error.status ? error.status : ""}
                        onFocus={() => this.onRestErrorKey("status")}
                        {...params}
                        label="Lease Status"
                        margin="normal"
                        fullWidth
                        placeholder="Select Lease Status"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    options={subStatus ? subStatus : []}
                    getOptionLabel={(option) => option.label}
                    id="sub_status"
                    value={data.sub_status ? data.sub_status : null}
                    onChange={(e, value) => this.onFieldChange("sub_status", value)}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        error={error.sub_status ? true : false}
                        required={
                          data.status && data.status.value == 1 ? true : false
                        }
                        helperText={error.sub_status ? error.sub_status : ""}
                        onFocus={() => this.onRestErrorKey("sub_status")}
                        {...params}
                        label="Sub Status"
                        margin="normal"
                        fullWidth
                        placeholder="Select Sub Status"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    options={lessees}
                    getOptionLabel={(option) => option.name}
                    id="lessee_id"
                    value={data.lessee}
                    onChange={(e, value) => this.onFieldChange("lessee_id", value)}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        error={error.lessee_id ? true : false}
                        required={
                          data.status && data.status.value == 1 ? true : false
                        }
                        helperText={error.lessee_id ? error.lessee_id : ""}
                        onFocus={() => this.onRestErrorKey("lessee_id")}
                        {...params}
                        label="Lessee"
                        margin="normal"
                        fullWidth
                        placeholder="Add or Select Lessee"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="outlined"
                    id="country"
                    label="country"
                    fullWidth
                    margin="normal"
                    value={data.country}
                    onChange={(e, value) => this.onFieldChange("country", e.target.value)}
                    inputProps={{ maxLength: 50 }}
                    placeholder="Search Location"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormLabel className="MuiInputLabel-shrink" component="label" id="form-label" > Ownership Status</FormLabel>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="ownership_type"
                      defaultValue="top"
                    >
                      <FormControlLabel
                        value={1}
                        control={
                          <Radio
                            checked={data.ownership_type === 1}
                            onChange={() =>
                              this.onFieldChange("ownership_type", 1)
                            }
                            color="primary"
                            size="small"
                          />
                        }
                        label="Owned"
                      />
                      <FormControlLabel
                        value={2}
                        control={
                          <Radio
                            checked={data.ownership_type === 2}
                            onChange={() =>
                              this.onFieldChange("ownership_type", 2)
                            }
                            color="primary"
                            size="small"
                          />
                        }
                        label="Managed"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {data.ownership_type === 2 ? (
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      options={portfolios}
                      getOptionLabel={(option) => option.name}
                      id="owner_portfolio"
                      value={data.owner_portfolio}
                      onChange={(e, value) =>
                        this.onFieldChange("owner_portfolio", value)
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          error={error.portfolio ? true : false}
                          required={true}
                          helperText={error.portfolio ? error.portfolio : ""}
                          onFocus={() => this.onRestErrorKey("portfolio")}
                          {...params}
                          label="Portfolio"
                          margin="normal"
                          fullWidth
                          placeholder="Select Portfolio"
                        />
                      )}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormLabel className="MuiInputLabel-shrink" component="label" > Is Lessee also the Operator?</FormLabel>
                    <RadioGroup row aria-label="position" name="same_operator_lessee" defaultValue="top" >
                      <FormControlLabel
                        value="is_titled"
                        control={
                          <Radio
                            onChange={() => {
                              this.onFieldChange("same_operator_lessee", true)
                              this.onRestErrorKey("operator")
                            }}
                            checked={data.same_operator_lessee}
                            color="primary"
                            size="small"
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="is_fitted"
                        control={
                          <Radio
                            onChange={() => {
                              this.onFieldChange("same_operator_lessee", false)
                              this.onRestErrorKey("operator")
                            }}
                            checked={!data.same_operator_lessee}
                            color="primary"
                            size="small"
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {!data.same_operator_lessee ? (
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      options={operators}
                      getOptionLabel={(option) => option.name}
                      id="operator"
                      value={data?.operator ? data?.operator : ''}
                      onChange={(e, value) => this.onFieldChange("operator", value)}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          required={!data.same_operator_lessee ? true : false}
                          error={error.operator ? true : false}
                          helperText={error.operator ? error.operator : " "}
                          onFocus={() => this.onRestErrorKey("operator")}
                          {...params}
                          label="Operator"
                          margin="normal"
                          fullWidth
                          placeholder="Select Operator"
                        />
                      )}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </form>  
            {this.state.allEngine?.length > 0 && <>
              <hr />
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      className="autoComp"
                      options={this.state.engineList}
                      getOptionLabel={(option) => option.name}
                      id="engine_name"
                      value={engineData.lh_engine}
                      onChange={(e, value) => this.onFieldChange("lh_engine", value)}
                      renderInput={(params) => (
                        (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="LH Engine Type/Model"
                            margin="normal"
                            fullWidth
                            placeholder="Select Type"
                          />
                        )
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      variant="outlined"
                      label="LH Engine TSN"
                      fullWidth
                      margin="normal"
                      value={engineData?.Lh_tsn ? engineData?.Lh_tsn : ''}
                      onChange={(e, value) => this.onFieldChange("Lh_tsn", e.target.value)}
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      variant="outlined"
                      label="LH Engine CSN"
                      fullWidth
                      margin="normal"
                      value={engineData?.Lh_csn ? engineData?.Lh_csn : ''}
                      onChange={(e, value) => this.onFieldChange("Lh_csn", e.target.value)}
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      variant="outlined"
                      label="LH Serial Number"
                      fullWidth
                      margin="normal"
                      value={engineData?.lh_esn ? engineData?.lh_esn : ''}
                      onChange={(e, value) => this.onFieldChange("lh_esn", e.target.value)}
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      className="autoComp"
                      options={this.state.engineList}
                      getOptionLabel={(option) => option.name}
                      id="engine_name"
                      value={engineData?.Rh_engine}
                      onChange={(e, value) => this.onFieldChange("Rh_engine", value)}
                      renderInput={(params) => (
                        (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="RH Engine Type/Model"
                            margin="normal"
                            fullWidth
                            placeholder="Select Type"
                          />
                        )
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      variant="outlined"
                      label="RH Engine TSN"
                      fullWidth
                      margin="normal"
                      value={engineData?.Rh_tsn ? engineData?.Rh_tsn : ''}
                      onChange={(e, value) => this.onFieldChange("Rh_tsn", e.target.value)}
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      variant="outlined"
                      label="RH Engine CSN"
                      fullWidth
                      margin="normal"
                      value={engineData?.Rh_csn ? engineData?.Rh_csn : ''}
                      onChange={(e, value) => this.onFieldChange("Rh_csn", e.target.value)}
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      variant="outlined"
                      label="RH Serial Number"
                      fullWidth
                      margin="normal"
                      value={engineData?.rh_esn ? engineData?.rh_esn : ''}
                      onChange={(e, value) => this.onFieldChange("rh_esn", e.target.value)}
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>
                </Grid>
              </form> </>}
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" onClick={this.onUpdateAssetAndEngine}>
              {formSubmitLoader ? (<CircularProgress color="#ffffff" size={24} />) : ("SAVE CHANGES")}
            </Button>
            <Button color="primary" variant="contained" onClick={this.props.closeUpdateForm} >CANCEL</Button>
          </DialogActions>
        </div>
        {/* </Drawer> */}
      </Fragment>
    )
  }
}
export default withSnackbar(BasicDetailForm)
