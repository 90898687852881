import React, { Component } from "react";
import DashboardChart from "../componenets/DashboardChart";

export default function DashboardWrapper(HocComponent, extraProps = {}) {
  return class extends Component {
    render() {
      return (
        <>
          <div id="dashboard">
            <DashboardChart />
          </div>
        </>
      );
    }
  };
}
