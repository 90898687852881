import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, } from "@material-ui/core"
import React, { Component, Fragment } from "react"
import CloseIcon from "@material-ui/icons/Close"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import moment from "moment"
import { formValues, taskErrorCode } from "../containers/AdTaskFormData"
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined"
import { addAdTaskFormApi, deleteAttachment, fillAdEditFormApi, } from "../apiService"
import { fieldValidation } from "../../../../utils/formValidation"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined"
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined"
import AdConfirmDeleteAttachment from "./AdConfirmDeleteAttachment"
import { regexConstants } from "../../../../constants/regEx"
import { permissionCheckFnforCamo } from "../../../../utils"

class AdTaskForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: {},
      data: formValues,
      regex: "^[0-9]+$",
      numberError: false,
      selectLastDDate: null,
      selectNextDueDate: null,
      attachments: [],
      newUploadedAttachments: [],
      remainingValue: "",
      ad_due: "",
      btnStatus: "",
      open: false,
      deleteAttachmentId: 0,
      showSuccessUpload: false,
      globalValidationInputs: {},
      validatedFields: [],
    }

    this.addAdTaskFormApi = addAdTaskFormApi.bind(this)
    this.dropDownChange = this.dropDownChange.bind(this)
    this.dropDownChange1 = this.dropDownChange1.bind(this)
    this.dropDownChange2 = this.dropDownChange2.bind(this)
  }

  componentDidMount = () => {
    if (this.props.editFormId) {
      this.fillEditData()
    }
  }

  dropDownChange(e, keyParam, value) {
    //set selection to the value selected
    this.setState(
      (prevState) => (
        {
          ...prevState,
          data: {
            ...prevState.data,
            ad_compliance_status: e.target.value,
          },
        }
      )
    )
  }
  dropDownChange1(e, keyParam, value) {
    //set selection to the value selected
    this.setState(
      (prevState) => (
        {
          ...prevState,
          data: {
            ...prevState.data,
            ad_type: e.target.value,
          },
        }
      )
    )
  }
  dropDownChange2(e, keyParam, value) {
    //set selection to the value selected
    this.setState(
      (prevState) => (
        {
          ...prevState,
          data: {
            ...prevState.data,
            dimension_type: e.target.value,
          },
        }
      )
    )
  }

  onFieldChange = (keyParam, value) => {
    const { data } = this.state

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [keyParam]: value,
      },
    }))

    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        remaining_val: this.state.remainingValue,
      },
    }))

  }

  onRestErrorKey = (keyParam) => {
    this.setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: "",
      },
    }))
  }
  handleSubmit = (e) => {
    e.preventDefault()

    let validationInputs = {}
    const { data } = this.state
    this.setState({ file: "" })

    validationInputs = {
      ...validationInputs,
      ata: taskErrorCode["ata"][fieldValidation({ ...taskErrorCode["ata_obj"], fieldval: data.ata, })],
      ad_no: taskErrorCode["ad_no"][fieldValidation({ ...taskErrorCode["ad_no_obj"], fieldval: data.ad_no, })],
      description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description, })],
      ad_type: taskErrorCode["ad_type"][fieldValidation({ ...taskErrorCode["ad_type_obj"], fieldval: data.ad_type, })],
      effective_date: taskErrorCode["effective_date"][fieldValidation({ ...taskErrorCode["effective_date_obj"], fieldval: data.effective_date, })],
      type: taskErrorCode["type"][fieldValidation({ ...taskErrorCode["type_obj"], fieldval: data.type, })],
      ac_comp: taskErrorCode["ac_comp"][fieldValidation({ ...taskErrorCode["ac_comp_obj"], fieldval: data.ac_comp, })],
      ad_compliance_status: taskErrorCode["ad_compliance_status"][fieldValidation({ ...taskErrorCode["ad_compliance_status_obj"], fieldval: data.ad_compliance_status, })],
      ref_documents: taskErrorCode["ref_documents"][fieldValidation({ ...taskErrorCode["ref_documents_obj"], fieldval: data.ref_documents, })],
      last_done_date: taskErrorCode["last_done_date"][fieldValidation({ ...taskErrorCode["last_done_date_obj"], fieldval: data.last_done_date, })],
      ad_compliance_dfp: taskErrorCode["ad_compliance_dfp"][fieldValidation({ ...taskErrorCode["ad_compliance_dfp_obj"], fieldval: data.ad_compliance_dfp, })],
      workorder: taskErrorCode["workorder"][fieldValidation({ ...taskErrorCode["workorder_obj"], fieldval: data.workorder, })],
      //  dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval !== null ? String(data.dimension_interval_fc) : "", })],
      dimension_interval_days: data.dimension_type === '' || data.dimension_type === 'FC' || data.dimension_type === 'FH' || data.dimension_type === 'FC,FH' || data.dimension_type === 'FC,FH,D' ? '' : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],

    }
    if (data.ad_compliance_status == "COMPLIED") {
      validationInputs = {
        ...validationInputs,
        ad_compliance_tsn: taskErrorCode["ad_compliance_tsn"][fieldValidation({ ...taskErrorCode["ad_compliance_tsn_obj"], fieldval: data.ad_compliance_tsn, })],
        ad_compliance_csn: taskErrorCode["ad_compliance_csn"][fieldValidation({ ...taskErrorCode["ad_compliance_csn_obj"], fieldval: data.ad_compliance_csn, })],
        ad_compliance_date: taskErrorCode["ad_compliance_date"][fieldValidation({ ...taskErrorCode["ad_compliance_date_obj"], fieldval: data.ad_compliance_date, })],
      }
    }
    if (Object.keys(validationInputs).every((k) => {
      return validationInputs[k] === ""
    })
    ) {
      const props = this.props.props
      let payload = Object.assign({}, data)
      payload = {
        ...payload,
        due_date: data.dimension_type === '' ? data.due_date : null,
        asset_id: props.match.params.asset,
        asset_type_id: props.match.params.assetType

      }
      addAdTaskFormApi(payload, props).then(async (response) => {
        const uploadId = response.data.data.id
        if (uploadId) {
          await this.uploadFileTest(uploadId, props, false)
        }
        this.setState((prevState) => ({
          error: {
            ...prevState.error,
            dimension_interval: "",
          },
        }))
      })
      if (this.state.btnStatus !== "addAnother") {
        this.setState((prevState) => ({
          error: {
            ...prevState.error,
            dimension_interval: "",
          },
        }))
        this.props.closeAddForm()
      } else {
        this.setState((prevState) => ({
          error: {
            ...prevState.error,
            dimension_interval: "",
          },
        }))
        return false
      }

    } else {
      this.setState({ error: validationInputs })
    }
  }

  // edit task form
  fillEditData = () => {
    const updateId = this.props.editFormId
    this.setState({ attachments: this.props.attachments })
    if (updateId === undefined || updateId === null) {
      this.setState({
        data: {},
      })
    } else {
      this.setState({
        data: this.props.editFormData,
      })
    }
  }

  onFocusValidation = (keyParam) => {
    let validationInputs = {}
    const { data } = this.state
    validationInputs = {
      ...validationInputs,
      ata: taskErrorCode["ata"][fieldValidation({ ...taskErrorCode["ata_obj"], fieldval: data.ata, })],
      ad_no: taskErrorCode["ad_no"][fieldValidation({ ...taskErrorCode["ad_no_obj"], fieldval: data.ad_no, })],
      description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description, })],
      ad_type: taskErrorCode["ad_type"][fieldValidation({ ...taskErrorCode["ad_type_obj"], fieldval: data.ad_type, })],
      effective_date: taskErrorCode["effective_date"][fieldValidation({ ...taskErrorCode["effective_date_obj"], fieldval: data.effective_date, })],
      type: taskErrorCode["type"][fieldValidation({ ...taskErrorCode["type_obj"], fieldval: data.type, })],
      ac_comp: taskErrorCode["ac_comp"][fieldValidation({ ...taskErrorCode["ac_comp_obj"], fieldval: data.ac_comp, })],
      ad_compliance_status: taskErrorCode["ad_compliance_status"][fieldValidation({ ...taskErrorCode["ad_compliance_status_obj"], fieldval: data.ad_compliance_status, })],
      ref_documents: taskErrorCode["ref_documents"][fieldValidation({ ...taskErrorCode["ref_documents_obj"], fieldval: data.ref_documents, })],
      last_done_date: taskErrorCode["last_done_date"][fieldValidation({ ...taskErrorCode["last_done_date_obj"], fieldval: data.last_done_date, })],
      ad_compliance_dfp: taskErrorCode["ad_compliance_dfp"][fieldValidation({ ...taskErrorCode["ad_compliance_dfp_obj"], fieldval: data.ad_compliance_dfp, })],
      workorder: taskErrorCode["workorder"][fieldValidation({ ...taskErrorCode["workorder_obj"], fieldval: data.workorder, })],
      dimension_interval: taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval !== null ? String(data.dimension_interval) : "", })],
      dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
      dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],

    }
    if (data.ad_compliance_status == "COMPLIED") {
      validationInputs = {
        ...validationInputs,
        ad_compliance_tsn: taskErrorCode["ad_compliance_tsn"][fieldValidation({ ...taskErrorCode["ad_compliance_tsn_obj"], fieldval: data.ad_compliance_tsn, })],
        ad_compliance_csn: taskErrorCode["ad_compliance_csn"][fieldValidation({ ...taskErrorCode["ad_compliance_csn_obj"], fieldval: data.ad_compliance_csn, })],
        ad_compliance_date: taskErrorCode["ad_compliance_date"][fieldValidation({ ...taskErrorCode["ad_compliance_date_obj"], fieldval: data.ad_compliance_date, })],
      }
    }
    // for (let key in validationInputs) {
    //   if (key === keyParam) {
    //     this.state.globalValidationInputs[key] = validationInputs[key]
    //   }
    // }
    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
        },
      }))
      this.setState({ validationInputs: {} })
    } else {
      this.setState({ error: validationInputs })
    }
  }

  updateData = async (e) => {
    e.preventDefault()
    let validationInputs = {}
    const { data } = this.state
    this.setState({ file: "" })

    validationInputs = {
      ...validationInputs,
      ata: taskErrorCode["ata"][fieldValidation({ ...taskErrorCode["ata_obj"], fieldval: data.ata, })],
      ad_no: taskErrorCode["ad_no"][fieldValidation({ ...taskErrorCode["ad_no_obj"], fieldval: data.ad_no, })],
      description: taskErrorCode["description"][fieldValidation({ ...taskErrorCode["description_obj"], fieldval: data.description, })],
      ad_type: taskErrorCode["ad_type"][fieldValidation({ ...taskErrorCode["ad_type_obj"], fieldval: data.ad_type, })],
      effective_date: taskErrorCode["effective_date"][fieldValidation({ ...taskErrorCode["effective_date_obj"], fieldval: data.effective_date, })],
      type: taskErrorCode["type"][fieldValidation({ ...taskErrorCode["type_obj"], fieldval: data.type, })],
      ac_comp: taskErrorCode["ac_comp"][fieldValidation({ ...taskErrorCode["ac_comp_obj"], fieldval: data.ac_comp, })],
      ad_compliance_status: taskErrorCode["ad_compliance_status"][fieldValidation({ ...taskErrorCode["ad_compliance_status_obj"], fieldval: data.ad_compliance_status, })],
      ref_documents: taskErrorCode["ref_documents"][fieldValidation({ ...taskErrorCode["ref_documents_obj"], fieldval: data.ref_documents, })],
      last_done_date: taskErrorCode["last_done_date"][fieldValidation({ ...taskErrorCode["last_done_date_obj"], fieldval: data.last_done_date, })],
      ad_compliance_dfp: taskErrorCode["ad_compliance_dfp"][fieldValidation({ ...taskErrorCode["ad_compliance_dfp_obj"], fieldval: data.ad_compliance_dfp, })],
      workorder: taskErrorCode["workorder"][fieldValidation({ ...taskErrorCode["workorder_obj"], fieldval: data.workorder, })],
      dimension_interval_days: data.dimension_type === '' || data.dimension_type === 'FC' || data.dimension_type === 'FH' || data.dimension_type === 'FC,FH' || data.dimension_type === 'FC,FH,D' ? '' : taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],

    }
    if (data.ad_compliance_status == "COMPLIED") {
      validationInputs = {
        ...validationInputs,
        ad_compliance_tsn: taskErrorCode["ad_compliance_tsn"][fieldValidation({ ...taskErrorCode["ad_compliance_tsn_obj"], fieldval: data.ad_compliance_tsn, })],
        ad_compliance_csn: taskErrorCode["ad_compliance_csn"][fieldValidation({ ...taskErrorCode["ad_compliance_csn_obj"], fieldval: data.ad_compliance_csn, })],
        ad_compliance_date: taskErrorCode["ad_compliance_date"][fieldValidation({ ...taskErrorCode["ad_compliance_date_obj"], fieldval: data.ad_compliance_date, })],
      }
    }

    if (
      Object.keys(validationInputs).every((k) => {
        return validationInputs[k] === ""
      })
    ) {

      let payload = Object.assign({}, data)
      payload = {
        ...payload,
        due_date: data.dimension_type === '' ? data.due_date : null
      }
      const updateId = this.props.editFormId
      const props = this.props.props
      if (updateId) {
        this.uploadFileTest(updateId, props, true)
      }
      this.props.updateFormData(updateId, payload, props)
      this.props.closeAddForm()
    } else {
      this.setState({ error: validationInputs })
    }


  }

  uploadFileTest = async (uploadId, props, isUpdate) => {
    if (this.state.newUploadedAttachments.length > 0) {
      let newAttachmentObj = []
      let formdata = new FormData()
      const module_type_id = 4
      Object.keys(this.state.newUploadedAttachments).map((key, index) => {
        newAttachmentObj.push({
          file: this.state.newUploadedAttachments[key],
          file_name: this.state.newUploadedAttachments[key].name,
          file_type: this.state.newUploadedAttachments[key].type,
          module_type_id,
          module_id: uploadId,
        })
        formdata.append(
          "[" + key + "]file",
          this.state.newUploadedAttachments[key]
        )
        formdata.append(
          "[" + key + "]file_type",
          this.state.newUploadedAttachments[key].type
        )
        formdata.append(
          "[" + key + "]file_name",
          this.state.newUploadedAttachments[key].name
        )
        formdata.append("[" + key + "]module_id", uploadId)
        formdata.append("[" + key + "]module_type_id", module_type_id)
      })
      // uploadOccmFile(uploadId, formdata, props)
      this.props.fileUploadData(uploadId, formdata, props, true)
      if (this.state.btnStatus === "addAnother") {
        this.resetBothAttachmentArray()
      }
    } else {
      if (this.state.btnStatus === "addAnother") {
        this.setState((prevState) => ({
          ...prevState,
          data: formValues,
          attachments: [],
        }))
        document.getElementById("asset-form").reset()
        this.props.fileUploadData(uploadId, null, props, false)
      } else {
        if (!isUpdate) {
          this.props.fileUploadData(uploadId, null, props, false)
        }
      }
    }
  }

  resetBothAttachmentArray = () => {
    this.setState((prevState) => ({
      ...prevState,
      data: formValues,
      attachments: [],
      newUploadedAttachments: [],
      open: false,
      deleteAttachmentId: 0,
    }))
    document.getElementById("asset-form").reset()
  }

  onUpload = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    for (let i = 0; i < chosenFiles.length; i++) {
      if (chosenFiles[i].size > 52428800) {
        this.props.props.enqueueSnackbar(
          `${chosenFiles[i].name} Maximum file size should be 50MB.`,
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }
        )
        delete chosenFiles[i]
        continue
      } else {
        this.handleUploadFiles(chosenFiles)
        // document.querySelector(".MuiDialog-paper").scrollTop = 800;
        this.setState({
          showSuccessUpload: true,
        })
        setTimeout(() => {
          if (this.state.attachments) {
            this.setState({
              showSuccessUpload: false,
            })
          }
        }, 2500)
      }
    }
  }


  nextDueValue = () => {
    const { data } = this.state
    if (data.dimension_type === "D") {
      data.ad_due = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY")
    } else if (data.dimension_type === "FC") {
      data.ad_due = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && +data.last_done_fc + +data.dimension_interval_fc
    } else if (data.dimension_type === "FH") {
      data.ad_due = Number.isInteger(+data.last_done_fh + +data.dimension_interval) && +data.last_done_fh + +data.dimension_interval
    } else if (data.dimension_type === "FC,D") {
      const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && +data.last_done_fc + +data.dimension_interval_fc;
      const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY");
      data.ad_due = fcDue < moment(dateDue, "DD-MM-YYYY") ? fcDue : dateDue;
    } else if (data.dimension_type === "FH,D") {
      const fhDue = Number.isInteger(+data.last_done_fh + +data.dimension_interval) && +data.last_done_fh + +data.dimension_interval;
      const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY");
      data.ad_due = fhDue < moment(dateDue, "DD-MM-YYYY") ? fhDue : dateDue;
    } else if (data.dimension_type === "FC,FH,D") {
      const fhDue = Number.isInteger(+data.last_done_fh + +data.dimension_interval) ? +data.last_done_fh + +data.dimension_interval : (+data.last_done_fh + +data.dimension_interval).toFixed(2);
      const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) ? +data.last_done_fc + +data.dimension_interval_fc : (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2);
      const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY");

      if (fhDue <= fcDue && fhDue <= moment(dateDue, "DD-MM-YYYY")) {
        data.ad_due = fhDue;
      } else if (fcDue <= fhDue && fcDue <= moment(dateDue, "DD-MM-YYYY")) {
        data.ad_due = fcDue;
      } else {
        data.ad_due = dateDue;
      }
    } else if (data.dimension_type === "FC,FH") {
      const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && +data.last_done_fc + +data.dimension_interval_fc;
      const fhDue = Number.isInteger(+data.last_done_fh + +data.dimension_interval) && +data.last_done_fh + +data.dimension_interval;
      data.ad_due = fcDue < fhDue ? fcDue : fhDue;
    }
    return data.ad_due
  }

  remainingVal = () => {

    const { data } = this.state
    let date = new Date()
    const lDate = moment(data.last_done_date)
    const todayD = moment(date)
    let remDay = lDate.diff(todayD, "days")
    if (data.dimension_type === '') {
      const dueDate = new Date(data.due_date);
      const differenceInMilliseconds = dueDate - date;
      const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      data.remaining_val = differenceInDays
    }
    if (data.dimension_type === "D") {
      data.remaining_val = moment(remDay).add(data.dimension_interval_days)
    } else if (data.dimension_type === "FC") {
      data.remaining_val = Number.isInteger(data.ad_due - this.props.headerCsn) && data.ad_due - this.props.headerCsn
    } else if (data.dimension_type === "FH") {
      data.remaining_val = Number.isInteger(data.ad_due - this.props.headerTsn) && (data.ad_due - this.props.headerTsn)
    } else if (data.dimension_type === "FC,D") {
      const fcRem_val = Number.isInteger(data.ad_due - this.props.headerCsn) && data.ad_due - this.props.headerCsn
      const dRem_val = moment(remDay).add(data.dimension_interval_days)
      data.remaining_val = fcRem_val < moment(dRem_val, "DD-MM-YYYY") ? fcRem_val : dRem_val;
    } else if (data.dimension_type === "FH,D") {
      const fhRem_val = Number.isInteger(data.ad_due - this.props.headerTsn) && data.ad_due - this.props.headerTsn
      const dRem_val = moment(remDay).add(data.dimension_interval_days)
      data.remaining_val = fhRem_val < moment(dRem_val, "DD-MM-YYYY") ? fhRem_val : dRem_val;
    } else if (data.dimension_type === "FC,FH,D") {
      const fcRem_val = Number.isInteger(data.ad_due - this.props.headerCsn) && data.ad_due - this.props.headerCsn
      const fhRem_val = Number.isInteger(data.ad_due - this.props.headerTsn) && data.ad_due - this.props.headerTsn
      const dRem_val = moment(remDay).add(data.dimension_interval_days)

      if (fhRem_val <= fcRem_val && fhRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
        data.remaining_val = fhRem_val;
      } else if (fcRem_val <= fhRem_val && fcRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
        data.remaining_val = fcRem_val;
      } else {
        data.remaining_val = dRem_val;
      }
    } else if (data.dimension_type === "FC,FH") {
      const fcRem_val = Number.isInteger(data.ad_due - this.props.headerCsn) && data.ad_due - this.props.headerCsn
      const fhRem_val = Number.isInteger(data.ad_due - this.props.headerTsn) && data.ad_due - this.props.headerTsn
      data.remaining_val = fcRem_val < fhRem_val ? fcRem_val : fhRem_val;
    }
    return data.remaining_val
  }

  handleUploadFiles = (files) => {
    const uploaded = [...this.state.newUploadedAttachments]
    const existingAttachments = [...this.state.attachments]
    files.some((file) => {
      uploaded.push(file)
    })
    files.map((file) => {
      var objc = {
        id: 0,
        module_id: 0,
        module_type_id: 2,
        file_name: file.name,
        file_type: file.type,
        file: "",
        name: file.name,
      }
      existingAttachments.push(objc)
    })
    this.setState({
      newUploadedAttachments: uploaded,
      attachments: existingAttachments,
    })
  }

  handleClickAttachment = (e, id) => {
    this.setState({ open: true })
    this.setState({ deleteAttachmentId: id })
  }

  handleCloseAttachment = (e) => {
    this.setState({ open: false })
    this.setState({ deleteAttachmentId: 0 })
  }

  deleteAttachmentFile = async (id) => {
    if (id !== undefined && id != 0) {
      const props = this.props.props
      deleteAttachment(id, props)
      var array = [...this.state.attachments]
      array = array.filter((item) => item.id !== id)
      this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
    }
  }

  removeAttachment = (e, obj) => {
    var array = [...this.state.attachments]
    var newuploadarray = [...this.state.newUploadedAttachments]

    var filteredArray = []

    newuploadarray.map((file) => {
      if (file.name == obj.file_name && file.type == obj.file_type) {
      } else {
        filteredArray.push(file)
      }
    })
    // Set the state with the new filtered array
    array = array.filter((item) => item !== obj)
    this.setState({
      newUploadedAttachments: filteredArray,
      attachments: array,
    })
  }

  saveAndAddAnother = async (e) => {
    await this.setState({ btnStatus: "addAnother" })
    await this.handleSubmit(e)
  }

  addFormData = async (e) => {
    await this.setState({ btnStatus: "add" })
    this.handleSubmit(e)
  }

  render() {
    const fileTypes = [".GIF", ".PDF", ".DOC", ".DOCX", ".XLSX", ".TXT", ".XLS", "image/*",]
    const { data, error } = this.state
    return (
      <Fragment>
        <div id="AmpTaskForm">
          <div id="adTaskForm">
            <DialogTitle>
              {this.props.formTitle}
              <CloseIcon
                onClick={this.props.closeAddForm}
                className="close-icon"
              />
            </DialogTitle>
            <form
              id="asset-form"
              onSubmit={
                this.props.actionType === "add"
                  ? (e) => this.handleSubmit(e)
                  : (e) => this.updateData(e)
              }
            >
              <DialogContent>
                <Grid spacing={2} container>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          ATA Chapter<span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="ata"
                      id="ata"
                      error={error.ata ? true : false}
                      helperText={error.ata ? error.ata : ""}
                      value={data.ata}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 15 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("ata", input)
                          this.onRestErrorKey("ata")
                        } else if (
                          input.length > 15 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 15)
                          this.onFieldChange("ata", truncatedInput)
                          this.onRestErrorKey("ata")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 15 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("ata", input)
                          this.onRestErrorKey("ata")
                        } else if (
                          input.length > 15 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 15)
                          this.onFieldChange("ata", truncatedInput)
                          this.onRestErrorKey("ata")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 15 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("ata", input)
                          this.onRestErrorKey("ata")
                        } else if (
                          input.length > 15 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 15)
                          this.onFieldChange("ata", truncatedInput)
                          this.onRestErrorKey("ata")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("ata")}
                      onBlur={() => this.onFocusValidation("ata")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          AD Reference Number
                          <span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="ad_no"
                      id="ad_no"
                      error={error.ad_no ? true : false}
                      helperText={error.ad_no ? error.ad_no : ""}
                      value={data.ad_no}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 20 &&
                          regexConstants.numericWithHyphen.test(input)
                        ) {
                          this.onFieldChange("ad_no", input)
                          this.onRestErrorKey("ad_no")
                        } else if (
                          input.length > 20 &&
                          regexConstants.numericWithHyphen.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("ad_no", truncatedInput)
                          this.onRestErrorKey("ad_no")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      // onKeyPress={(e, value) => {
                      //   const input = e.target.value
                      //   if (
                      //     input.length <= 20 &&
                      //     regexConstants.onlyNumeric.test(input)
                      //   ) {
                      //     this.onFieldChange("ad_no", input)
                      //     this.onRestErrorKey("ad_no")
                      //   } else if (
                      //     input.length > 20 &&
                      //     regexConstants.onlyNumeric.test(input)
                      //   ) {
                      //     const truncatedInput = input.slice(0, 20)
                      //     this.onFieldChange("ad_no", truncatedInput)
                      //     this.onRestErrorKey("ad_no")
                      //   } else {
                      //     e.preventDefault()
                      //   }
                      // }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("ad_no", input)
                          this.onRestErrorKey("ad_no")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("ad_no", truncatedInput)
                          this.onRestErrorKey("ad_no")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("ad_no")}
                      onBlur={() => this.onFocusValidation("ad_no")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="Issuing Authority"
                      name="issuing_authority"
                      id="issuing_authority"
                      value={data.issuing_authority}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("issuing_authority", input)
                          this.onRestErrorKey("issuing_authority")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange(
                            "issuing_authority",
                            truncatedInput
                          )
                          this.onRestErrorKey("issuing_authority")
                        }
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Applicability<span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="applicability"
                      id="applicability"
                      error={error.applicability ? true : false}
                      helperText={
                        error.applicability ? error.applicability : ""
                      }
                      value={data.applicability}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("applicability", input)
                          this.onRestErrorKey("applicability")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("applicability", truncatedInput)
                          this.onRestErrorKey("applicability")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("applicability")}
                      onBlur={() => this.onFocusValidation("applicability")}
                    />
                  </Grid> */}
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      minRows={5}
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Description<span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="description"
                      id="description"
                      error={error.description ? true : false}
                      helperText={error.description ? error.description : ""}
                      value={data.description}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 255) {
                          this.onFieldChange("description", input)
                          this.onRestErrorKey("description")
                        } else {
                          const truncatedInput = input.slice(0, 255)
                          this.onFieldChange("description", truncatedInput)
                          this.onRestErrorKey("description")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("description")}
                      onBlur={() => this.onFocusValidation("description")}
                    />
                  </Grid>
                </Grid>
                <div className="border-grey"></div>

                <Grid spacing={1} container id="ht-dropdown">
                  <Grid item xs={4}>
                    <FormControl>
                      <InputLabel
                        id="demo-simple-select-label"
                        className="dropdown-label"
                      >
                        AD Type<span style={{ color: "red" }}> *</span>
                      </InputLabel>
                      <Select
                        labelId="ad_type"
                        name="ad_type"
                        id="ad_type"
                        error={error.ad_type ? true : false}
                        helperText={error.ad_type ? error.ad_type : ""}
                        variant="outlined"
                        value={data.ad_type}
                        onChange={this.dropDownChange1}
                        onBlur={() => this.onFocusValidation("ad_type")}
                      >
                        <MenuItem value={"AIRFRAME"}>Airframe</MenuItem>
                        <MenuItem value={"ENGINE"}>Engine</MenuItem>
                        <MenuItem value={"APPLIANCE"}>Appliance</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label="Effectivity"
                      name="effectivity"
                      id="effectivity"
                      value={data.effectivity}
                      onChange={(e, value) =>
                        this.onFieldChange(
                          "effectivity",
                          e.target.value.length > 200
                            ? e.target.value.slice(0, 200)
                            : e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={4} className="input-calender">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        label={
                          <React.Fragment>
                            Effective Date
                            <span style={{ color: "red" }}> *</span>
                          </React.Fragment>
                        }
                        id="effective_date"
                        format={fieldDateFormat}
                        inputVariant="outlined"
                        fullWidth
                        disableFuture
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(25, "years")}
                        error={error.effective_date ? true : false}
                        helperText={
                          error.effective_date ? error.effective_date : ""
                        }
                        value={data.effective_date}
                        onChange={(data, value) => {
                          this.onFieldChange(
                            "effective_date",
                            moment(data).format(backendDateFormat)
                          )
                          this.onRestErrorKey("effective_date")
                        }}
                        onFocus={() => this.onRestErrorKey("effective_date")}
                        onFocusCapture={() =>
                          this.onFocusValidation("effective_date")
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={<React.Fragment>SB Number</React.Fragment>}
                      name="sb_number"
                      id="sb_number"
                      error={error.sb_number ? true : false}
                      helperText={error.sb_number ? error.sb_number : ""}
                      value={data.sb_number}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 20 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("sb_number", input)
                          this.onRestErrorKey("sb_number")
                        } else if (
                          input.length > 20 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("sb_number", truncatedInput)
                          this.onRestErrorKey("sb_number")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 20 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("sb_number", input)
                          this.onRestErrorKey("sb_number")
                        } else if (
                          input.length > 20 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("sb_number", truncatedInput)
                          this.onRestErrorKey("sb_number")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 20 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("sb_number", input)
                          this.onRestErrorKey("sb_number")
                        } else if (
                          input.length > 20 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("sb_number", truncatedInput)
                          this.onRestErrorKey("sb_number")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("sb_number")}
                      onBlur={() => this.onFocusValidation("sb_number")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Type<span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="type"
                      id="type"
                      error={error.type ? true : false}
                      helperText={error.type ? error.type : ""}
                      value={data.type}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("type", input)
                          this.onRestErrorKey("type")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("type", truncatedInput)
                          this.onRestErrorKey("type")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("type")}
                      onBlur={() => this.onFocusValidation("type")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          A/C Comp<span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="ac_comp"
                      id="ac_comp"
                      error={error.ac_comp ? true : false}
                      helperText={error.ac_comp ? error.ac_comp : ""}
                      value={data.ac_comp}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 20) {
                          this.onFieldChange("ac_comp", input)
                          this.onRestErrorKey("ac_comp")
                        } else {
                          const truncatedInput = input.slice(0, 20)
                          this.onFieldChange("ac_comp", truncatedInput)
                          this.onRestErrorKey("ac_comp")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("ac_comp")}
                      onBlur={() => this.onFocusValidation("ac_comp")}
                    />
                  </Grid>
                </Grid>

                <div className="border-grey"></div>
                <Grid spacing={1} container id="ht-dropdown">
                  <Grid item xs={3}>
                    <Grid item>
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">
                          AD Compliance Status
                        </InputLabel>
                        <Select
                          labelId="ad_compliance_status"
                          id="ad_compliance_status"
                          name="ad_compliance_status"
                          variant="outlined"
                          error={error.ad_compliance_status ? true : false}
                          helperText={
                            error.ad_compliance_status
                              ? error.ad_compliance_status
                              : ""
                          }
                          value={data.ad_compliance_status}
                          onChange={this.dropDownChange}
                          onBlur={() =>
                            this.onFocusValidation("ad_compliance_status")
                          }
                        >
                          <MenuItem value={"OPEN"}>OPEN</MenuItem>
                          <MenuItem value={"COMPLIED"}>COMPLIED</MenuItem>
                          <MenuItem value={"PARTIALLY COMPLIED"}>
                            PARTIALLY COMPLIED
                          </MenuItem>

                          <MenuItem value={"NOT APPLICABLE"}>
                            NOT APPLICABLE
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          AD Compliance TSN
                          {data.ad_compliance_status !== "COMPLIED" ? null : (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </React.Fragment>
                      }
                      name="ad_compliance_tsn"
                      id="ad_compliance_tsn"
                      error={error.ad_compliance_tsn ? true : false}
                      helperText={
                        error.ad_compliance_tsn ? error.ad_compliance_tsn : ""
                      }
                      value={data.ad_compliance_tsn}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("ad_compliance_tsn", input)
                          this.onRestErrorKey("ad_compliance_tsn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange(
                            "ad_compliance_tsn",
                            truncatedInput
                          )
                          this.onRestErrorKey("ad_compliance_tsn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("ad_compliance_tsn", input)
                          this.onRestErrorKey("ad_compliance_tsn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange(
                            "ad_compliance_tsn",
                            truncatedInput
                          )
                          this.onRestErrorKey("ad_compliance_tsn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("ad_compliance_tsn", input)
                          this.onRestErrorKey("ad_compliance_tsn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange(
                            "ad_compliance_tsn",
                            truncatedInput
                          )
                          this.onRestErrorKey("ad_compliance_tsn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("ad_compliance_tsn")}
                      onBlur={() => this.onFocusValidation("ad_compliance_tsn")}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          AD Compliance CSN
                          {data.ad_compliance_status !== "COMPLIED" ? null : (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </React.Fragment>
                      }
                      name="ad_compliance_csn"
                      id="ad_compliance_csn"
                      error={error.ad_compliance_csn ? true : false}
                      helperText={
                        error.ad_compliance_csn ? error.ad_compliance_csn : ""
                      }
                      value={data.ad_compliance_csn}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("ad_compliance_csn", input)
                          this.onRestErrorKey("ad_compliance_csn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange(
                            "ad_compliance_csn",
                            truncatedInput
                          )
                          this.onRestErrorKey("ad_compliance_csn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("ad_compliance_csn", input)
                          this.onRestErrorKey("ad_compliance_csn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange(
                            "ad_compliance_csn",
                            truncatedInput
                          )
                          this.onRestErrorKey("ad_compliance_csn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("ad_compliance_csn", input)
                          this.onRestErrorKey("ad_compliance_csn")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange(
                            "ad_compliance_csn",
                            truncatedInput
                          )
                          this.onRestErrorKey("ad_compliance_csn")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("ad_compliance_csn")}
                      onBlur={() => this.onFocusValidation("ad_compliance_csn")}
                    />
                  </Grid>
                  <Grid className="input-calender" item xs={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        label={
                          <React.Fragment>
                            Date of compliance
                            {data.ad_compliance_status !== "COMPLIED" ? null : (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </React.Fragment>
                        }
                        id="ad_compliance_date"
                        format={fieldDateFormat}
                        inputVariant="outlined"
                        fullWidth
                        disableFuture
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(25, "years")}
                        error={error.ad_compliance_date ? true : false}
                        helperText={
                          error.ad_compliance_date
                            ? error.ad_compliance_date
                            : ""
                        }
                        value={data.ad_compliance_date}
                        onChange={(data, value) => {
                          this.onFieldChange(
                            "ad_compliance_date",
                            moment(data).format(backendDateFormat)
                          )
                          this.onRestErrorKey("ad_compliance_date")
                        }}
                        onFocus={() =>
                          this.onRestErrorKey("ad_compliance_date")
                        }
                        onFocusCapture={() =>
                          this.onFocusValidation("ad_compliance_date")
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label={
                        <React.Fragment>
                          Reference Documents
                          <span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="ref_documents"
                      id="ref_documents"
                      error={error.ref_documents ? true : false}
                      helperText={
                        error.ref_documents ? error.ref_documents : ""
                      }
                      multiline
                      minRows={5}
                      value={data.ref_documents}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 255) {
                          this.onFieldChange("ref_documents", input)
                          this.onRestErrorKey("ref_documents")
                        } else {
                          const truncatedInput = input.slice(0, 255)
                          this.onFieldChange("ref_documents", truncatedInput)
                          this.onRestErrorKey("ref_documents")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("ref_documents")}
                      onBlur={() => this.onFocusValidation("ref_documents")}
                    />
                  </Grid>
                </Grid>
                <div className="border-grey"></div>
                {/* new start */}
                <Grid spacing={1} container id="data-container">
                  <Grid item xs={3} className="input-calender">
                    <label className="mb-10">LAST DONE</label>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        label={
                          <React.Fragment>
                            Date <span style={{ color: "red" }}> *</span>
                          </React.Fragment>
                        }
                        id="last_done_date"
                        format={fieldDateFormat}
                        inputVariant="outlined"
                        // autoOk={true}
                        fullWidth
                        disableFuture
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(25, "years")}
                        error={error.last_done_date ? true : false}
                        helperText={
                          error.last_done_date ? error.last_done_date : ""
                        }
                        value={data.last_done_date}
                        // onChange={(e, value) =>
                        //   this.onFieldChange("last_done_date", e.target.value)
                        onChange={(data, value) => {
                          this.onFieldChange(
                            "last_done_date",
                            moment(data).format(backendDateFormat)
                          )
                          this.onRestErrorKey("last_done_date")
                        }}
                        onFocus={() => this.onRestErrorKey("last_done_date")}
                        onFocusCapture={() =>
                          this.onFocusValidation("last_done_date")
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={3} id="lastdone-input">
                    <TextField
                      variant="outlined"
                      label="Last Done FH"
                      name="last_done_fh"
                      id="last_done_fh"
                      value={data.last_done_fh}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("last_done_fh", input)
                          this.onRestErrorKey("last_done_fh")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("last_done_fh", truncatedInput)
                          this.onRestErrorKey("last_done_fh")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("last_done_fh", input)
                          this.onRestErrorKey("last_done_fh")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("last_done_fh", truncatedInput)
                          this.onRestErrorKey("last_done_fh")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("last_done_fh", input)
                          this.onRestErrorKey("last_done_fh")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("last_done_fh", truncatedInput)
                          this.onRestErrorKey("last_done_fh")
                        } else {
                          e.preventDefault()
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} id="lastdone-input">
                    <TextField
                      variant="outlined"
                      label="Last Done FC"
                      name="last_done_fc"
                      id="last_done_fc"
                      value={data.last_done_fc}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("last_done_fc", input)
                          this.onRestErrorKey("last_done_fc")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("last_done_fc", truncatedInput)
                          this.onRestErrorKey("last_done_fc")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onKeyPress={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("last_done_fc", input)
                          this.onRestErrorKey("last_done_fc")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("last_done_fc", truncatedInput)
                          this.onRestErrorKey("last_done_fc")
                        } else {
                          e.preventDefault()
                        }
                      }}
                      onPaste={(e, value) => {
                        const input = e.target.value
                        if (
                          input.length <= 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          this.onFieldChange("last_done_fc", input)
                          this.onRestErrorKey("last_done_fc")
                        } else if (
                          input.length > 10 &&
                          regexConstants.onlyNumeric.test(input)
                        ) {
                          const truncatedInput = input.slice(0, 10)
                          this.onFieldChange("last_done_fc", truncatedInput)
                          this.onRestErrorKey("last_done_fc")
                        } else {
                          e.preventDefault()
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} id="lastdone-input">
                    <TextField
                      variant="outlined"
                      multiline
                      //minRows={5}
                      label={
                        <React.Fragment>
                          Workorder
                          <span style={{ color: "red" }}> *</span>
                        </React.Fragment>
                      }
                      name="workorder"
                      id="workorder"
                      error={error.workorder ? true : false}
                      helperText={error.workorder ? error.workorder : ""}
                      value={data.workorder}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 25) {
                          this.onFieldChange("workorder", input)
                          this.onRestErrorKey("workorder")
                        } else {
                          const truncatedInput = input.slice(0, 25)
                          this.onFieldChange("workorder", truncatedInput)
                          this.onRestErrorKey("workorder")
                        }
                      }}
                      onFocus={() => this.onRestErrorKey("workorder")}
                      onBlur={() => this.onFocusValidation("workorder")}
                    />
                  </Grid>
                </Grid>
                <Grid id="data-container" spacing={1} container>
                  <Grid item xs={3}>
                    <label className="ml-10px font-14">INTERVAL</label>
                    <Grid spacing={2} container id="ht-dropdown">
                      <Grid item>
                        <FormControl>
                          <InputLabel id="demo-simple-select-label">
                            Dimension
                          </InputLabel>
                          <Select
                            labelId="dimension_type"
                            name="dimension_type"
                            id="dimension_type"
                            variant="outlined"
                            value={data.dimension_type}
                            onChange={this.dropDownChange2}
                          >
                            <MenuItem value=''><em>None</em> </MenuItem>
                            <MenuItem value={"D"}>Days</MenuItem>
                            <MenuItem value={"FH"}>FH</MenuItem>
                            <MenuItem value={"FC"}>FC</MenuItem>
                            <MenuItem value={"FC,D"}>FC & D</MenuItem>
                            <MenuItem value={"FH,D"}>FH & D</MenuItem>
                            <MenuItem value={"FC,FH,D"}>FH,FC & D</MenuItem>
                            <MenuItem value={"FC,FH"}>FC & FH</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid id="dimensions-input-ht" item xs={3}>
                    <Grid spacing={2} container>
                      {data.dimension_type == "FC" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH" || data.dimension_type == "FC,FH,D" ? <Grid
                        item
                        xs={data.dimension_type == "FC" ? 12 : data.dimension_type == "FC,D" || data.dimension_type == "FC,FH" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}
                      >
                        <TextField
                          variant="outlined"
                          label={
                            <React.Fragment>
                              {data.dimension_type == "FC" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH,D" || data.dimension_type == "FC,FH" ? "FC Interval" : "FC, Interval"}
                              <span style={{ color: "red" }}> *</span>
                            </React.Fragment>
                          }
                          name="dimension_interval"
                          id="dimension_interval"
                          value={data.dimension_interval_fc}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e, value) => {
                            const input = e.target.value
                            if (
                              input.length <= 5 &&
                              regexConstants.onlyNumeric.test(e.target.value)
                            ) {
                              this.onFieldChange("dimension_interval_fc", input)
                              this.onRestErrorKey("dimension_interval_fc")
                            } else if (
                              input.length > 5 &&
                              regexConstants.onlyNumeric.test(input)
                            ) {
                              const truncatedInput = input.slice(0, 5)
                              this.onFieldChange(
                                "dimension_interval_fc",
                                truncatedInput
                              )
                              this.onRestErrorKey("dimension_interval_fc")
                              this.setState({
                                error: {
                                  dimension_interval_fc:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            } else {
                              e.preventDefault()
                              this.setState({
                                error: {
                                  dimension_interval_fc:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            }
                          }}
                          onKeyPress={(e, value) => {
                            const input = e.target.value
                            if (
                              input.length <= 5 &&
                              regexConstants.onlyNumeric.test(e.target.value)
                            ) {
                              this.onFieldChange("dimension_interval_fc", input)
                              this.onRestErrorKey("dimension_interval_fc")
                            } else if (
                              input.length > 5 &&
                              regexConstants.onlyNumeric.test(input)
                            ) {
                              const truncatedInput = input.slice(0, 5)
                              this.onFieldChange(
                                "dimension_interval_fc",
                                truncatedInput
                              )
                              this.onRestErrorKey("dimension_interval_fc")
                              this.setState({
                                error: {
                                  dimension_interval_fc:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            } else {
                              e.preventDefault()
                              this.setState({
                                error: {
                                  dimension_interval_fc:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            }
                          }}
                          onPaste={(e, value) => {
                            const input = e.target.value
                            if (
                              input.length <= 5 &&
                              regexConstants.onlyNumeric.test(e.target.value)
                            ) {
                              this.onFieldChange("dimension_interval_fc", input)
                              this.onRestErrorKey("dimension_interval_fc")
                            } else if (
                              input.length > 5 &&
                              regexConstants.onlyNumeric.test(input)
                            ) {
                              const truncatedInput = input.slice(0, 5)
                              this.onFieldChange(
                                "dimension_interval_fc",
                                truncatedInput
                              )
                              this.onRestErrorKey("dimension_interval_fc")
                              this.setState({
                                error: {
                                  dimension_interval_fc:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            } else {
                              this.setState({
                                error: {
                                  dimension_interval_fc:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                              e.preventDefault()
                            }
                          }}
                          autoComplete="off"
                          error={error.dimension_interval_fc ? true : false}
                          helperText={
                            error.dimension_interval_fc
                              ? error.dimension_interval_fc
                              : ""
                          }
                          onFocus={() =>
                            this.onRestErrorKey("dimension_interval_fc")
                          }
                          onBlur={() =>
                            this.onFocusValidation("dimension_interval_fc")
                          }
                        />
                      </Grid> : null}
                      {data.dimension_type == "FH" || data.dimension_type == "FH,D" || data.dimension_type == "FC,FH" || data.dimension_type == "FC,FH,D" ? <Grid
                        item
                        xs={data.dimension_type == "FH" ? 12 : data.dimension_type == "FH,D" || data.dimension_type == "FC,FH" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}
                      >
                        <TextField
                          variant="outlined"
                          label={
                            <React.Fragment>
                              {data.dimension_type == "FH" || data.dimension_type == "FH,D" || data.dimension_type == "FC,FH,D" || data.dimension_type == "FC,FH" ? "FH Interval" : "FH, Interval"}
                              <span style={{ color: "red" }}> *</span>
                            </React.Fragment>
                          }
                          name="dimension_interval"
                          id="dimension_interval"
                          InputLabelProps={{ shrink: true }}
                          value={data.dimension_interval}
                          onChange={(e, value) => {
                            const input = e.target.value
                            if (
                              input.length <= 5 &&
                              regexConstants.onlyNumeric.test(e.target.value)
                            ) {
                              this.onFieldChange("dimension_interval", input)
                              this.onRestErrorKey("dimension_interval")
                            } else if (
                              input.length > 5 &&
                              regexConstants.onlyNumeric.test(input)
                            ) {
                              const truncatedInput = input.slice(0, 5)
                              this.onFieldChange(
                                "dimension_interval",
                                truncatedInput
                              )
                              this.onRestErrorKey("dimension_interval")
                              this.setState({
                                error: {
                                  dimension_interval:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            } else {
                              e.preventDefault()
                              this.setState({
                                error: {
                                  dimension_interval:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            }
                          }}
                          onKeyPress={(e, value) => {
                            const input = e.target.value
                            if (
                              input.length <= 5 &&
                              regexConstants.onlyNumeric.test(e.target.value)
                            ) {
                              this.onFieldChange("dimension_interval", input)
                              this.onRestErrorKey("dimension_interval")
                            } else if (
                              input.length > 5 &&
                              regexConstants.onlyNumeric.test(input)
                            ) {
                              const truncatedInput = input.slice(0, 5)
                              this.onFieldChange(
                                "dimension_interval",
                                truncatedInput
                              )
                              this.onRestErrorKey("dimension_interval")
                              this.setState({
                                error: {
                                  dimension_interval:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            } else {
                              e.preventDefault()
                              this.setState({
                                error: {
                                  dimension_interval:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            }
                          }}
                          onPaste={(e, value) => {
                            const input = e.target.value
                            if (
                              input.length <= 5 &&
                              regexConstants.onlyNumeric.test(e.target.value)
                            ) {
                              this.onFieldChange("dimension_interval", input)
                              this.onRestErrorKey("dimension_interval")
                            } else if (
                              input.length > 5 &&
                              regexConstants.onlyNumeric.test(input)
                            ) {
                              const truncatedInput = input.slice(0, 5)
                              this.onFieldChange(
                                "dimension_interval",
                                truncatedInput
                              )
                              this.onRestErrorKey("dimension_interval")
                              this.setState({
                                error: {
                                  dimension_interval:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                            } else {
                              this.setState({
                                error: {
                                  dimension_interval:
                                    "Please enter a 5 digit integer value.",
                                },
                              })
                              e.preventDefault()
                            }
                          }}
                          autoComplete="off"
                          error={error.dimension_interval ? true : false}
                          helperText={
                            error.dimension_interval
                              ? error.dimension_interval
                              : ""
                          }
                          onFocus={() =>
                            this.onRestErrorKey("dimension_interval")
                          }
                          onBlur={() =>
                            this.onFocusValidation("dimension_interval")
                          }
                        />
                      </Grid> : null}
                      {data.dimension_type == "D" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH,D" ||
                        data.dimension_type == "FH,D" ? (
                        <Grid item xs={data.dimension_type == "D" ? 12 : data.dimension_type == "FC,D" || data.dimension_type == "FH,D" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}>
                          <TextField
                            variant="outlined"
                            label={
                              <React.Fragment>
                                Days<span style={{ color: "red" }}> *</span>
                              </React.Fragment>
                            }
                            name="dimension_interval_days"
                            id="dimension_interval_days"
                            value={data.dimension_interval_days}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e, value) => {
                              const input = e.target.value
                              if (
                                input.length <= 5 &&
                                regexConstants.onlyNumeric.test(e.target.value)
                              ) {
                                this.onFieldChange(
                                  "dimension_interval_days",
                                  input
                                )
                                this.onRestErrorKey("dimension_interval_days")
                              } else if (
                                input.length > 5 &&
                                regexConstants.onlyNumeric.test(input)
                              ) {
                                const truncatedInput = input.slice(0, 5)
                                this.onFieldChange(
                                  "dimension_interval_days",
                                  truncatedInput
                                )
                                this.onRestErrorKey("dimension_interval_days")
                                this.setState({
                                  error: {
                                    dimension_interval_days:
                                      "Please enter a 5 digit integer value.",
                                  },
                                })
                              } else {
                                e.preventDefault()
                                this.setState({
                                  error: {
                                    dimension_interval_days:
                                      "Please enter a 5 digit integer value.",
                                  },
                                })
                              }
                            }}
                            onKeyPress={(e, value) => {
                              const input = e.target.value
                              if (
                                input.length <= 5 &&
                                regexConstants.onlyNumeric.test(e.target.value)
                              ) {
                                this.onFieldChange(
                                  "dimension_interval_days",
                                  input
                                )
                                this.onRestErrorKey("dimension_interval_days")
                              } else if (
                                input.length > 5 &&
                                regexConstants.onlyNumeric.test(input)
                              ) {
                                const truncatedInput = input.slice(0, 5)
                                this.onFieldChange(
                                  "dimension_interval_days",
                                  truncatedInput
                                )
                                this.onRestErrorKey("dimension_interval_days")
                                this.setState({
                                  error: {
                                    dimension_interval_days:
                                      "Please enter a 5 digit integer value.",
                                  },
                                })
                              } else {
                                e.preventDefault()
                                this.setState({
                                  error: {
                                    dimension_interval_days:
                                      "Please enter a 5 digit integer value.",
                                  },
                                })
                              }
                            }}
                            onPaste={(e, value) => {
                              const input = e.target.value
                              if (
                                input.length <= 5 &&
                                regexConstants.onlyNumeric.test(e.target.value)
                              ) {
                                this.onFieldChange(
                                  "dimension_interval_days",
                                  input
                                )
                                this.onRestErrorKey("dimension_interval_days")
                              } else if (
                                input.length > 5 &&
                                regexConstants.onlyNumeric.test(input)
                              ) {
                                const truncatedInput = input.slice(0, 5)
                                this.onFieldChange(
                                  "dimension_interval_days",
                                  truncatedInput
                                )
                                this.onRestErrorKey("dimension_interval_days")
                                this.setState({
                                  error: {
                                    dimension_interval_days:
                                      "Please enter a 5 digit integer value.",
                                  },
                                })
                              } else {
                                this.setState({
                                  error: {
                                    dimension_interval_days:
                                      "Please enter a 5 digit integer value.",
                                  },
                                })
                                e.preventDefault()
                              }
                            }}
                            autoComplete="off"
                            error={error.dimension_interval_days ? true : false}
                            helperText={
                              error.dimension_interval_days
                                ? error.dimension_interval_days
                                : ""
                            }
                            onFocus={() =>
                              this.onRestErrorKey("dimension_interval_days")
                            }
                            onBlur={() =>
                              this.onFocusValidation("dimension_interval_days")
                            }
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <label className="ml-10px font-14">NEXT DUE </label>
                    <Grid spacing={2} container>
                      <Grid className="remaining-input" item xs={6}>
                        {data.dimension_type === '' ?
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              label='Date'
                              keyParam='due_date'
                              format={fieldDateFormat}
                              inputVariant="outlined"
                              InputLabelProps={{ shrink: true }}
                              value={data.due_date ? data.due_date : null}
                              onChange={(value) => { this.onFieldChange("due_date", moment(value).format(backendDateFormat)) }}
                            />
                          </MuiPickersUtilsProvider> :
                          <TextField
                            variant="outlined"
                            label={data.dimension_type === "D" ? "Date" : data.dimension_type === "FC" ? "FC" : data.dimension_type === "FH" ? "FH" : data.dimension_type === "FH,D" ? "FH/Date" :
                              data.dimension_type === "FC,D" ? "FC/Date" : data.dimension_type === "FC,FH,D" ? "FH/FC/Date" : data.dimension_type === "FC,FH" ? "FC/FH " : "Date"}
                            name="ad_due"
                            id="ad_due"
                            value={this.nextDueValue()}
                            onChange={(e, value) => this.onFieldChange("ad_due", e.target.value)}
                            inputProps={{ readOnly: true }}
                            InputLabelProps={{ shrink: true }}
                            disabled
                            className="disabled-input"
                          />
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <label className="ml-10px font-14">REMAINING</label>
                    <Grid spacing={1} container>
                      <Grid className="remaining-input" item xs={6}>
                        <TextField
                          variant="outlined"
                          label={data.dimension_type === "D" ? "Days" : data.dimension_type === "FC" ? "FC" : data.dimension_type === "FH" ? "FH" :
                            data.dimension_type === "FH,D" ? "FH/Days" : data.dimension_type === "FC,D" ? "FC/Days" : data.dimension_type === "FC,FH,D" ? "FH/FC/Days" : data.dimension_type === "FC,FH" ? "FC/FH" : "Days"}
                          name="remaining_val"
                          id="remaining_val"
                          value={this.remainingVal()}
                          onChange={(e, value) =>
                            this.onFieldChange("remaining_val", e.target.value)
                          }
                          inputProps={{ readOnly: true }}
                          disabled
                          className="disabled-input"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* new end */}
                <div className="border-grey"></div>
                <Grid id="data-container" spacing={1} container>
                  <Grid item xs={3}>
                    <label>
                      AD Compliance DFP <span style={{ color: "red" }}> *</span>
                    </label>

                    <FormControl
                      id="d-block"
                      onFocus={() => this.onRestErrorKey("ad_compliance_dfp")}
                    >
                      <RadioGroup
                        row
                        required
                        aria-label="ad_compliance_dfp"
                        name="ad_compliance_dfp"
                        defaultValue="top"
                        value={data.ad_compliance_dfp}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "ad_compliance_dfp",
                            e.target.value
                          )
                        }
                        onBlur={() =>
                          this.onFocusValidation("ad_compliance_dfp")
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                      {error.ad_compliance_dfp && (
                        <FormLabel component="ad_compliance_dfp">
                          <span style={{ color: "red" }}>
                            {error.ad_compliance_dfp}
                          </span>
                        </FormLabel>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <label>Release certificate</label>
                    <FormControl id="d-block">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="release_certificate"
                        name="release_certificate"
                        defaultValue="top"
                        value={data.release_certificate}
                        onChange={(e, value) =>
                          this.onFieldChange(
                            "release_certificate",
                            e.target.value
                          )
                        }
                        onBlur={() =>
                          this.onFocusValidation("release_certificate")
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <label>Certificate of Conformity</label>
                    <FormControl id="d-block">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        aria-label="conformity_crt"
                        name="conformity_crt"
                        defaultValue="top"
                        value={data.conformity_crt}
                        onChange={(e, value) =>
                          this.onFieldChange("conformity_crt", e.target.value)
                        }
                        onBlur={() => this.onFocusValidation("conformity_crt")}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" color="primary" />}
                          label="No"
                        />
                        <FormControlLabel
                          value="N/A"
                          control={<Radio size="small" color="primary" />}
                          label="N/A"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid spacing={1} container>
                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      label="Remark"
                      name="remark"
                      id="remark"
                      multiline
                      minRows={5}
                      value={data.remark}
                      onChange={(e, value) => {
                        const input = e.target.value
                        if (input.length <= 255) {
                          this.onFieldChange("remark", input)
                          this.onRestErrorKey("remark")
                        } else {
                          const truncatedInput = input.slice(0, 255)
                          this.onFieldChange("remark", truncatedInput)
                          this.onRestErrorKey("remark")
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="upload-textbox">
                      <Button variant="contained" component="label">
                        <div id="upload-success">
                          <span className="d-flex align-items-center">
                            <AddPhotoAlternateOutlinedIcon className="upload-icon" />
                            {/* <span className="upload-text upload">
                              {Array.isArray(this.state.attachments) &&
                              this.state.attachments.length === 0
                                ? "Upload"
                                : ""}
                            </span> */}
                            <input
                              className="upload-input"
                              onChange={this.onUpload}
                              id="fileUplaod"
                              name="file"
                              type="file"
                              accept={fileTypes}
                              multiple
                            />
                            <span className="upload-text">
                              {this.state.attachments.length === 0
                                ? "Upload or drop a file right here"
                                : " Upload Another?"}
                            </span>
                          </span>
                          <Tooltip title="JPG,PNG,GIF,PDF,DOC,DOCX,XLSX,TXT,JPEG,XLS">
                            <span className="upload-text overflow">
                              &nbsp;JPG,PNG,GIF,PDF,DOC,DOCX,XLSX,TXT,JPEG,XLS
                            </span>
                          </Tooltip>
                        </div>
                        {this.state.showSuccessUpload ? (
                          <div id="success-text">
                            <span className="upload-text">
                              Uploaded successfully!
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid id="data-container" spacing={1} container>
                  <Grid spacing={1} container>
                    <div className="d-flex align-items-center show-attachments">
                      {(() => {
                        if (
                          this.state.attachments &&
                          this.state.attachments.length > 0
                        ) {
                          return Object.keys(this.state.attachments).map(
                            (key1, index) => {
                              return (
                                <div
                                  className="position-relative d-flex align-items-center attachment-margin"
                                  key={index}
                                >
                                  <span className="file-name">
                                    {this.state.attachments[index].name}
                                  </span>
                                  <DescriptionOutlinedIcon className="file-icon" />
                                  {permissionCheckFnforCamo({
                                    primaryKey: "ad_status",
                                    keyIndex: "D",
                                  }) ||
                                    permissionCheckFnforCamo({
                                      primaryKey: "ad_status",
                                      keyIndex: "OD",
                                      userId: this.state.data.created_by
                                        ? this.state.data.created_by
                                        : undefined,
                                    }) ||
                                    this.state.attachments[index].id === 0 ? (
                                    <CancelOutlinedIcon
                                      className="cancel-icon"
                                      onClick={
                                        this.props.actionType === "add"
                                          ? (e) =>
                                            this.removeAttachment(
                                              e,
                                              this.state.attachments[index]
                                            )
                                          : (e) => {
                                            if (
                                              this.state.attachments[index]
                                                .id === 0
                                            ) {
                                              this.removeAttachment(
                                                e,
                                                this.state.attachments[index]
                                              )
                                            } else {
                                              this.handleClickAttachment(
                                                e,
                                                this.state.attachments[index]
                                                  .id
                                              )
                                            }
                                          }
                                      }
                                    />
                                  ) : null}
                                </div>
                              )
                            }
                          )
                        }
                      })()}
                    </div>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="actions-btn">
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={
                    this.props.actionType === "add"
                      ? (e) => this.addFormData(e)
                      : (e) => this.updateData(e)
                  }
                >
                  {this.props.actionType === "add" ? "Add" : "Update"}
                </Button>

                {this.props.actionType === "add" ? (
                  <Button
                    onClick={(e) => this.saveAndAddAnother(e)}
                    type="button"
                    className="btn btn-primary"
                  >
                    Save & Add Another
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.props.closeAddForm}
                >
                  Close
                </Button>
              </DialogActions>
              <Dialog
                open={this.state.open}
                onClose={this.handleCloseAttachment}
                id="htDialog"
              >
                <AdConfirmDeleteAttachment
                  handleCloseAttachment={() => this.handleCloseAttachment()}
                  deleteAttachmentFile={() =>
                    this.deleteAttachmentFile(this.state.deleteAttachmentId)
                  }
                />
              </Dialog>
            </form>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default AdTaskForm
