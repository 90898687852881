import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import {
  Dialog,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
} from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined"
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined"
import ConfirmDialog from "../components/ConfirmDialog"
import { fillHtEditFormApi } from "../apiService"
import HtTaskForm from "./HtTaskForm"
// import ShowAttachments from "../../ShowAttachments";
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined"
import moment from "moment"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import { permissionCheckFnforCamo } from "../../../../utils"

const HtList = ({
  item,
  index,
  handleDeleteRow,
  updateFormData,
  props,
  fileUploadData,
  currentRecordPerPage,
  downloadAllApi,
  headerTsn,
  headerCsn,
}) => {
  const [open, setOpen] = useState(false)
  const [openEditForm, setEditForm] = useState(false)
  const [editFormId, setEditFormId] = useState(null)
  const [editFormData, setEditFormData] = useState(null)
  const [formTitle, setFormTitle] = useState("")
  const [attachments, setAttachments] = useState(null)
  const [showFullDescription, setShowFullDescription] = useState({})
  const [showFullRemarks, setShowFullRemarks] = useState({})
  const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false)
  const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] =
    useState(null)
  const [moduleId, setmoduleId] = useState(null)

  const handleClick = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const closeAddForm = () => {
    setEditForm(false)
  }

  const handleEditRow = () => {
    fillHtEditFormApi(item, props).then((response) => {
      setEditFormData(response.data.data)
      setEditFormId(response.data.data.id)
      setAttachments(response.data.data.attachments)
      setFormTitle("Update HT Components")
      setEditForm(true)
    })
  }

  const downAllAttach = () => {
    fillHtEditFormApi(item, props).then((response) => {
      setmoduleId(response.data.data.id)
    })
    if (item.attachments.length > 0) {
      getAttachments(item.attachments)
    }
  }

  const openAttachment = (file) => {
    window.open(`https://qa.camo.sparta.aero:8223${file}`, "_blank")
  }

  const formatDate = (date) => {
    return moment(date).format("DD-MM-YYYY")
  }

  const toggleDescription = (id) => {
    setShowFullDescription({
      ...showFullDescription,
      [id]: !showFullDescription[id],
    })
  }

  const toggleRemarks = (id) => {
    setShowFullRemarks({
      ...showFullRemarks,
      [id]: !showFullRemarks[id],
    })
  }

  const getAttachments = (attachments) => {
    setAllAttachmentsforDialogue(attachments)
    setAttachmentsDialogue(true)
  }

  const handleCloseAttachmentDialogue = () => {
    setAttachmentsDialogue(false)
  }

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        style={{ cursor: "pointer" }}
        className={
          item.remainingValues_D < 0 || item.remainingValues_D == null
            ? "red-row"
            : "none"
        }
      >
        <TableCell>
          {permissionCheckFnforCamo({
            primaryKey: "ht_component",
            keyIndex: "U",
          }) ? (
            <Tooltip title="Edit">
              <IconButton
                className="edit-icon"
                onClick={(e) => {
                  handleEditRow(e)
                }}
              >
                <CreateOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {permissionCheckFnforCamo({
            primaryKey: "ht_component",
            keyIndex: "D",
          }) ||
          permissionCheckFnforCamo({
            primaryKey: "ht_component",
            keyIndex: "OD",
            userId: item.created_by,
          }) ? (
            <Tooltip title="Delete" arrow>
              <IconButton className="delete-icon" onClick={handleClick}>
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </TableCell>
        <TableCell>{item.ata_chapter ? item.ata_chapter : "--"}</TableCell>
        <TableCell style={{ width: "200px", textAlign: "left" }}>
          {item.part_description && item.part_description.length > 150 ? (
            <div>
              {showFullDescription[item.id] ? (
                <span>
                  {item.part_description}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault()
                      toggleDescription(item.id)
                    }}
                  >
                    ..less
                  </a>
                </span>
              ) : (
                <span>
                  {item.part_description.substring(0, 150)}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault()
                      toggleDescription(item.id)
                    }}
                  >
                    ..more
                  </a>
                </span>
              )}
            </div>
          ) : item.part_description && item.part_description.length <= 150 ? (
            <span>{item.part_description}</span>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell>{item.part_number ? item.part_number : "--"}</TableCell>
        <TableCell>
          {item.part_serial_number ? item.part_serial_number : "--"}
        </TableCell>
        <TableCell>
          {item.part_installation_date
            ? formatDate(item.part_installation_date)
            : "--"}
        </TableCell>
        <TableCell>
          {item.aircraft_tsn_installation
            ? item.aircraft_tsn_installation
            : "--"}
        </TableCell>
        <TableCell>
          {item.aircraft_csn_installation
            ? item.aircraft_csn_installation
            : "--"}
        </TableCell>

        <TableCell>
          {item.part_tsn_installation ? item.part_tsn_installation : "--"}
        </TableCell>
        <TableCell>
          {item.part_csn_installation ? item.part_csn_installation : "--"}
        </TableCell>
        <TableCell>
          {item.ht_requirement ? item.ht_requirement : "--"}
        </TableCell>

        <TableCell>
          {item.dimension_type === "D" ||
          item.dimension_type === "FH,D" ||
          item.dimension_type === "FC,D" || item.dimension_type === "FC,FH,D" 
            ? "D"
            : "--"}
        </TableCell>
        <TableCell>
          {item.dimension_type === "FH" || item.dimension_type === "FH,D" || item.dimension_type === "FC,FH,D"  || item.dimension_type === "FC,FH" 
            ? "FH"
            : "--"}
        </TableCell>
        <TableCell>
          {item.dimension_type === "FC" || item.dimension_type === "FC,D" || item.dimension_type === "FC,FH,D" || item.dimension_type === "FC,FH" 
            ? "FC"
            : "--"}
        </TableCell>

        <TableCell>
          {item.dimension_type === "D" ||
          item.dimension_type === "FC,D" ||
          item.dimension_type === "FH,D" || item.dimension_type === "FC,FH,D" 
            ? item.remaining.due_at_type === "d"
              ? item.remaining.due_at
              : "--"
            : "--"}
        </TableCell>
        <TableCell>
          {item.dimension_type === "FH" || item.dimension_type === "FH,D" || item.dimension_type === "FC,FH"  || item.dimension_type === "FC,FH,D" 
           ? item.remaining.due_at_type === "s" && item.remaining.s_type === 'fh'
           ? item.remaining.due_at
           : item.remaining.due_at
            : "--"}
        </TableCell>
        <TableCell>
          {item.dimension_type === "FC" || item.dimension_type === "FC,D" || item.dimension_type === "FC,FH"  || item.dimension_type === "FC,FH,D" 
            ? item.remaining.due_at_type === "s" && item.remaining.s_type === 'fc'
            ? item.remaining.due_at
            : item.remaining.due_at_type === "s" && item.remaining.s_type === null  &&  item.remaining.due_at
             : "--"}
        </TableCell>

        <TableCell>
          {item.dimension_type === "D" ||
          item.dimension_type === "FC,D" ||
          item.dimension_type === "FH,D"
            ? item.remaining.due_at_type === "d"
              ? item.remaining.remaining
              : "--"
            : "--"}
        </TableCell>
        <TableCell>
          {item.dimension_type === "FH" || item.dimension_type === "FH,D"  || item.dimension_type === "FC,FH"  || item.dimension_type === "FC,FH,D" 
            ? item.remaining.due_at_type === "s" && item.remaining.s_type === 'fh'
            ? item.remaining.remaining
            : item.remaining.due_at_type === "s" && item.remaining.s_type === null  && item.remaining.remaining
             : "--"}
        </TableCell>
        <TableCell>
          {item.dimension_type === "FC" || item.dimension_type === "FC,D" || item.dimension_type === "FC,FH"  || item.dimension_type === "FC,FH,D" 
             ? item.remaining.due_at_type === "s" && item.remaining.s_type === 'fc'
             ? item.remaining.remaining
             : item.remaining.due_at_type === "s" && item.remaining.s_type === null  && item.remaining.remaining
              : "--"}
        </TableCell>

        <TableCell style={{ width: "200px", textAlign: "left" }}>
          {item.remark && item.remark.length > 150 ? (
            <div>
              {showFullDescription[item.id] ? (
                <span>
                  {item.remark}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault()
                      toggleDescription(item.id)
                    }}
                  >
                    ..less
                  </a>
                </span>
              ) : (
                <span>
                  {item.remark.substring(0, 150)}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault()
                      toggleDescription(item.id)
                    }}
                  >
                    ..more
                  </a>
                </span>
              )}
            </div>
          ) : item.remark && item.remark.length <= 150 ? (
            <span>{item.remark}</span>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell>
          {item.attachments.length > 0 ? (
            <>
              <Tooltip title="View Attachments">
                <span
                  className="center"
                  onClick={() => {
                    downAllAttach()
                  }}
                >
                  <AttachFileIcon className="attach-icon" />

                  <a href="#" style={{ color: "#0e7fe1" }}>
                    {item.attachments.length}
                  </a>
                </span>
              </Tooltip>
            </>
          ) : (
            item.attachments.length
          )}
        </TableCell>
      </TableRow>
      <Dialog open={open} onClose={handleClose} id="htDialog">
        <ConfirmDialog
          handleClose={() => handleClose()}
          handleDeleteRow={handleDeleteRow}
        />
      </Dialog>

      {/* edit form dialog */}
      <Dialog
        position="relative"
        maxWidth="lg"
        open={openEditForm}
        onClose={closeAddForm}
      >
        <HtTaskForm
          formTitle={formTitle}
          closeAddForm={() => closeAddForm()}
          props={props}
          editFormId={editFormId}
          editFormData={editFormData}
          attachments={attachments}
          item={item}
          headerTsn={headerTsn}
          headerCsn={headerCsn}
          updateFormData={updateFormData}
          fileUploadData={fileUploadData}
          currentRecordPerPage={currentRecordPerPage}
        />
      </Dialog>

      <Dialog
        open={openGetAttachmentsDialogue}
        onClose={handleCloseAttachmentDialogue}
        id="htDialog"
      >
        <ShowAttachments
          handleClose={() => handleCloseAttachmentDialogue()}
          handleShowAttachments={AllAttachmentsforDialogue}
          openAttachment={openAttachment}
          files={{ title: "AMP", extension: "zip", key: "" }}
          downloadAllApi={downloadAllApi}
          props={props}
          moduleId={moduleId}
        />
      </Dialog>
    </>
  )
}
export default withRouter(HtList)
