import React, { useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { logDOM } from "@testing-library/react";
// import { downloadAll } from "./apiServices";

// const downloadAllFiles = () => {
//   downloadAll();
// };

const ShowAttachments = ({
  handleClose,
  handleShowAttachments,
  openAttachment,
  downloadAllApi,
  props,
  moduleId,
  item,
  files,
}) => {
  useEffect(() => {
    console.log(props);
  }, []);

  // const downloadAll = (files) => {
  //   const id = moduleId;
  //   downloadAllApi(files);
  // };

  const downloadAll = (event, files, triggerExport) => {
    if (triggerExport) {
      downloadAllApi(files);
    }
  };

  return (
    <div class="getAttachmentDialogue">
      <DialogTitle>Attachments</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton>
          <Close onClick={handleClose} style={{ fill: "white" }} />
        </IconButton>
      </Box>
      <DialogContent className="showAttachmentList">
        {(() => {
          if (handleShowAttachments.length > 0) {
            return Object.keys(handleShowAttachments).map((key1, index) => {
              const file = handleShowAttachments[key1].file;
              return (
                <Grid container spacing={3}>
                  <Grid item xs={10}>
                    <p>{handleShowAttachments[key1].file_name}</p>
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title="Download">
                      <button
                        className="downloadIcon"
                        onClick={() => {
                          openAttachment(file);
                        }}
                      >
                        <SaveAltIcon key={key1} />
                      </button>
                    </Tooltip>
                  </Grid>
                </Grid>
              );
            });
          }
        })()}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          // onClick={(e) => {
          //   downloadAll(files);
          // }}
          onClick={(event) => downloadAll(event, files, "triggerExport")}
          files={{ title: "AMP", extension: "zip", key: "" }}
        >
          Download All
        </Button>
        <Button color="primary" variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        {/* <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            // handleDeleteRow(item);
            handleClose();
          }}
        >
          Download All
        </Button> */}
      </DialogActions>
    </div>
  );
};

export default ShowAttachments;
