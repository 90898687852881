import React from 'react';
import { Link } from '@material-ui/core';
export default function Footer(){
  return(
    <footer>
      <div className="text-center footer-copyright">
        <Link to="">
          <img src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/phase-2/acumen.png" alt="" />
        </Link>
        <p>© 2023 Acumen Aviation Europe Limited. All rights reserved. <a href="https://www.acumen.aero/" target="_blank" rel="noreferrer">www.acumen.aero</a></p>
      </div>
    </footer>
  )
}
