import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Dialog,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ConfirmDialog from "../components/ConfirmDialog";
import AmpTaskForm from "./AmpTaskForm";
import { addTaskFormApi, fillEditFromApi } from "../apiServices";
// import ShowAttachments from "../../ShowAttachments";
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import moment from "moment";
import { fieldDateFormat } from "../../../../constants";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { permissionCheckFnforCamo } from "../../../../utils";

const AmpList = ({
  item,
  index,
  handleDeleteRow,
  updateFormData,
  props,
  fileUploadData,
  currentRecordPerPage,
  downloadAllApi,
  headerTsn,
  headerCsn,
}) => {
  const [open, setOpen] = useState(false);
  const [openEditForm, setEditForm] = useState(false);
  const [editFormId, setEditFormId] = useState(null);
  const [editFormData, setEditFormData] = useState(null);
  const [formTitle, setFormTitle] = useState("");
  const [attachments, setAttachments] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState({});
  const [showFullRemarks, setShowFullRemarks] = useState({});
  const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false);
  const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] =
    useState(null);
  const [moduleId, setmoduleId] = useState(null);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let todayDate = new Date();
  const remainingValuesDate =
    item.interval_D -
    Math.round(
      (todayDate - new Date(Date.parse(item.lastDone_D))) /
        (1000 * 60 * 60 * 24)
    );

  const closeAddForm = () => {
    setEditForm(false);
  };

  const handleEditRow = () => {
    fillEditFromApi(item, props).then((response) => {
      setEditFormData(response.data.data);
      setEditFormId(response.data.data.id);
      setAttachments(response.data.data.attachments);
      setFormTitle("Update AMP/MRB Compliance");
      setEditForm(true);
    });
  };
  const downAllAttach = () => {
    fillEditFromApi(item, props).then((response) => {
      setmoduleId(response.data.data.id);
    });
    if (item.attachments.length > 0) {
      getAttachments(item.attachments);
    }
  };

  const openAttachment = (file) => {
    window.open(`https://qa.camo.sparta.aero:8223${file}`, "_blank");
  };

  const formatDate = (date) => {
    return moment(date).format("DD-MM-YYYY");
  };

  const toggleDescription = (id) => {
    setShowFullDescription({
      ...showFullDescription,
      [id]: !showFullDescription[id],
    });
  };

  const toggleRemarks = (id) => {
    setShowFullRemarks({
      ...showFullRemarks,
      [id]: !showFullRemarks[id],
    });
  };

  const getAttachments = (attachments) => {
    setAllAttachmentsforDialogue(attachments);
    setAttachmentsDialogue(true);
  };

  const handleCloseAttachmentDialogue = () => {
    setAttachmentsDialogue(false);
  };

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        style={{ cursor: "pointer" }}
        className={
          item.remainingValues_D < 0 || item.remainingValues_D == null
            ? "red-row"
            : "none"
        }
      >
        <TableCell>
          {permissionCheckFnforCamo({
            primaryKey: "amp_compliance",
            keyIndex: "U",
          }) ? (
            <Tooltip title="Edit">
              <IconButton
                className="edit-icon"
                onClick={(e) => {
                  handleEditRow(e);
                }}
              >
                <CreateOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          {permissionCheckFnforCamo({
            primaryKey: "amp_compliance",
            keyIndex: "D",
          }) ||
          permissionCheckFnforCamo({
            primaryKey: "amp_compliance",
            keyIndex: "OD",
            userId: item.created_by,
          }) ? (
            <Tooltip title="Delete" arrow>
              <IconButton className="delete-icon" onClick={handleClick}>
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </TableCell>
        <TableCell>{item.MPD_item_no ? item.MPD_item_no : "--"}</TableCell>
        <TableCell>{item.task_card_no ? item.task_card_no : "--"}</TableCell>
        <TableCell>
          {item.previous_LDND_Taskcard ? item.previous_LDND_Taskcard : "--"}
        </TableCell>
        {/* <TableCell style={{ textAlign: "inherit" }}>
          {item.Task_Description ? item.Task_Description : "--"}
        </TableCell> */}

        <TableCell style={{ width: "200px", textAlign: "left" }}>
          {item.Task_Description && item.Task_Description.length > 150 ? (
            <div>
              {showFullDescription[item.id] ? (
                <span>
                  {item.Task_Description}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleDescription(item.id);
                    }}
                  >
                    ..less
                  </a>
                </span>
              ) : (
                <span>
                  {item.Task_Description.substring(0, 150)}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleDescription(item.id);
                    }}
                  >
                    ..more
                  </a>
                </span>
              )}
            </div>
          ) : item.Task_Description && item.Task_Description.length <= 150 ? (
            <span>{item.Task_Description}</span>
          ) : (
            "--"
          )}
        </TableCell>

        <TableCell>{item.threshold_D ? item.threshold_D : "--"}</TableCell>
        <TableCell>{item.threshold_FH ? item.threshold_FH : "--"}</TableCell>
        <TableCell>{item.threshold_FC ? item.threshold_FC : "--"}</TableCell>

        <TableCell>{item.interval_D ? item.interval_D : "--"}</TableCell>
        <TableCell>{item.interval_FH ? item.interval_FH : "--"}</TableCell>
        <TableCell>{item.interval_FC ? item.interval_FC : "--"}</TableCell>

        <TableCell>
          {item.lastDone_D
            ? moment(item.lastDone_D).format(fieldDateFormat)
            : "--"}
        </TableCell>
        <TableCell>{item.lastDone_FH ? item.lastDone_FH : "--"}</TableCell>
        <TableCell>{item.lastDone_FC ? item.lastDone_FC : "--"}</TableCell>

        <TableCell>
          {item.nextDue_D
            ? moment(item.nextDue_D).format(fieldDateFormat)
            : "--"}
        </TableCell>
        <TableCell>{item.nextDue_FH ? item.nextDue_FH : "--"}</TableCell>
        <TableCell>{item.nextDue_FC ? item.nextDue_FC : "--"}</TableCell>

        <TableCell>
          {item.remainingValues_D ? item.remainingValues_D : "--"}
        </TableCell>
        <TableCell>
          {item.remainingValues_FH ? item.remainingValues_FH : "--"}
        </TableCell>
        <TableCell>
          {item.remainingValues_FC ? item.remainingValues_FC : "--"}
        </TableCell>
        <TableCell style={{ width: "200px", textAlign: "left" }}>
          {item.remarks && item.remarks.length > 150 ? (
            <div>
              {showFullDescription[item.id] ? (
                <span>
                  {item.remarks}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleDescription(item.id);
                    }}
                  >
                    ..less
                  </a>
                </span>
              ) : (
                <span>
                  {item.remarks.substring(0, 150)}
                  <a
                    href="#"
                    style={{ color: "#0e7fe1" }}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleDescription(item.id);
                    }}
                  >
                    ..more
                  </a>
                </span>
              )}
            </div>
          ) : item.remarks && item.remarks.length <= 150 ? (
            <span>{item.remarks}</span>
          ) : (
            "--"
          )}
        </TableCell>
        <TableCell>
          {item.attachments.length > 0 ? (
            <>
              <Tooltip title="View Attachments">
                <span
                  className="center"
                  onClick={() => {
                    downAllAttach();
                  }}
                >
                  <AttachFileIcon className="attach-icon" />

                  <a href="#" style={{ color: "#0e7fe1" }}>
                    {item.attachments.length}
                  </a>
                </span>
              </Tooltip>
            </>
          ) : (
            item.attachments.length
          )}
        </TableCell>
      </TableRow>
      <Dialog open={open} onClose={handleClose} id="htDialog">
        <ConfirmDialog
          handleClose={() => handleClose()}
          handleDeleteRow={handleDeleteRow}
        />
      </Dialog>

      {/* edit form dialog */}
      <Dialog
        position="relative"
        maxWidth="lg"
        open={openEditForm}
        onClose={closeAddForm}
      >
        <AmpTaskForm
          formTitle={formTitle}
          closeAddForm={() => closeAddForm()}
          props={props}
          editFormId={editFormId}
          editFormData={editFormData}
          attachments={attachments}
          item={item}
          headerTsn={headerTsn}
          headerCsn={headerCsn}
          updateFormData={updateFormData}
          fileUploadData={fileUploadData}
          currentRecordPerPage={currentRecordPerPage}
        />
      </Dialog>
      <Dialog
        open={openGetAttachmentsDialogue}
        onClose={handleCloseAttachmentDialogue}
        id="htDialog"
      >
        <ShowAttachments
          handleClose={() => handleCloseAttachmentDialogue()}
          handleShowAttachments={AllAttachmentsforDialogue}
          openAttachment={openAttachment}
          files={{ title: "AMP", extension: "zip", key: "" }}
          downloadAllApi={downloadAllApi}
          props={props}
          moduleId={moduleId}
        />
      </Dialog>
    </>
  );
};
export default withRouter(AmpList);
